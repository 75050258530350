@charset "UTF-8";
@font-face {
  font-family: "iconfont";
  src: url("/iconfont.eot");
  /* IE9 Compat Modes */
  src: url("/iconfont.eot?#iefix") format("embedded-opentype"), url("/iconfont.woff") format("woff"), url("/iconfont.ttf") format("truetype"), url("/iconfont.svg") format("svg");
  /* Legacy iOS */ }

.icon {
  font-style: normal;
  font-weight: 400; }
  .icon.accordion-close:before {
    font-family: "iconfont";
    content: ""; }
  .icon.accordion-open:before {
    font-family: "iconfont";
    content: ""; }
  .icon.add-folder:before {
    font-family: "iconfont";
    content: ""; }
  .icon.add:before {
    font-family: "iconfont";
    content: ""; }
  .icon.admin:before {
    font-family: "iconfont";
    content: ""; }
  .icon.all-day:before {
    font-family: "iconfont";
    content: ""; }
  .icon.arrow-left:before {
    font-family: "iconfont";
    content: ""; }
  .icon.arrow-right:before {
    font-family: "iconfont";
    content: ""; }
  .icon.assignment:before {
    font-family: "iconfont";
    content: ""; }
  .icon.association-clinic:before {
    font-family: "iconfont";
    content: ""; }
  .icon.association:before {
    font-family: "iconfont";
    content: ""; }
  .icon.bars:before {
    font-family: "iconfont";
    content: ""; }
  .icon.calendar-2:before {
    font-family: "iconfont";
    content: ""; }
  .icon.calendar-today:before {
    font-family: "iconfont";
    content: ""; }
  .icon.calendar:before {
    font-family: "iconfont";
    content: ""; }
  .icon.call-list:before {
    font-family: "iconfont";
    content: ""; }
  .icon.checkbox-off:before {
    font-family: "iconfont";
    content: ""; }
  .icon.checkbox-on:before {
    font-family: "iconfont";
    content: ""; }
  .icon.checkbox-square:before {
    font-family: "iconfont";
    content: ""; }
  .icon.circle-add:before {
    font-family: "iconfont";
    content: ""; }
  .icon.circle-check:before {
    font-family: "iconfont";
    content: ""; }
  .icon.circle-info:before {
    font-family: "iconfont";
    content: ""; }
  .icon.circlo-close:before {
    font-family: "iconfont";
    content: ""; }
  .icon.clinic:before {
    font-family: "iconfont";
    content: ""; }
  .icon.close-slim:before {
    font-family: "iconfont";
    content: ""; }
  .icon.close:before {
    font-family: "iconfont";
    content: ""; }
  .icon.closed-folder:before {
    font-family: "iconfont";
    content: ""; }
  .icon.copy:before {
    font-family: "iconfont";
    content: ""; }
  .icon.delete:before {
    font-family: "iconfont";
    content: ""; }
  .icon.disconnect:before {
    font-family: "iconfont";
    content: ""; }
  .icon.dotted-arrow-right:before {
    font-family: "iconfont";
    content: ""; }
  .icon.dotted-arrow:before {
    font-family: "iconfont";
    content: ""; }
  .icon.edit:before {
    font-family: "iconfont";
    content: ""; }
  .icon.email:before {
    font-family: "iconfont";
    content: ""; }
  .icon.featured:before {
    font-family: "iconfont";
    content: ""; }
  .icon.handle-h:before {
    font-family: "iconfont";
    content: ""; }
  .icon.handle-v:before {
    font-family: "iconfont";
    content: ""; }
  .icon.help-circle:before {
    font-family: "iconfont";
    content: ""; }
  .icon.help:before {
    font-family: "iconfont";
    content: ""; }
  .icon.history:before {
    font-family: "iconfont";
    content: ""; }
  .icon.info:before {
    font-family: "iconfont";
    content: ""; }
  .icon.lock-open:before {
    font-family: "iconfont";
    content: ""; }
  .icon.lock:before {
    font-family: "iconfont";
    content: ""; }
  .icon.lodging:before {
    font-family: "iconfont";
    content: ""; }
  .icon.magnifying-glass:before {
    font-family: "iconfont";
    content: ""; }
  .icon.map:before {
    font-family: "iconfont";
    content: ""; }
  .icon.marker:before {
    font-family: "iconfont";
    content: ""; }
  .icon.notifications:before {
    font-family: "iconfont";
    content: ""; }
  .icon.open-folder:before {
    font-family: "iconfont";
    content: ""; }
  .icon.pencil:before {
    font-family: "iconfont";
    content: ""; }
  .icon.piechart:before {
    font-family: "iconfont";
    content: ""; }
  .icon.pin:before {
    font-family: "iconfont";
    content: ""; }
  .icon.profile:before {
    font-family: "iconfont";
    content: ""; }
  .icon.schedule:before {
    font-family: "iconfont";
    content: ""; }
  .icon.settings:before {
    font-family: "iconfont";
    content: ""; }
  .icon.small-down-arrow:before {
    font-family: "iconfont";
    content: ""; }
  .icon.small-up-arrow:before {
    font-family: "iconfont";
    content: ""; }
  .icon.status-check:before {
    font-family: "iconfont";
    content: ""; }
  .icon.surgery-optional:before {
    font-family: "iconfont";
    content: ""; }
  .icon.surgery:before {
    font-family: "iconfont";
    content: ""; }
  .icon.time:before {
    font-family: "iconfont";
    content: ""; }
  .icon.timesheet:before {
    font-family: "iconfont";
    content: ""; }
  .icon.triangle:before {
    font-family: "iconfont";
    content: ""; }
  .icon.tsa:before {
    font-family: "iconfont";
    content: ""; }
  .icon.validation:before {
    font-family: "iconfont";
    content: ""; }
  .icon.vet:before {
    font-family: "iconfont";
    content: ""; }

.availabilities__toolbar {
  padding-bottom: 2em;
  border-bottom: 1px solid #d8dfe5; }
  .availabilities__toolbar > * {
    display: inline-block; }
  .availabilities__toolbar__title {
    position: relative; }
    .availabilities__toolbar__title > h2 {
      display: inline-block;
      font-size: 1.875em;
      font-weight: 700;
      margin-right: 0.9375em;
      color: #414a52;
      position: relative;
      top: 0.1875em;
      padding: 0;
      text-transform: unset;
      min-width: 15.625rem;
      text-align: left; }
  .availabilities__toolbar__add-availability {
    display: inline-block;
    font-size: 0.75em;
    font-weight: 300;
    color: #414a52;
    background: none;
    outline: none;
    position: relative;
    height: 2.1875rem;
    padding-right: 3.25rem;
    text-align: right;
    width: 8.4375rem;
    line-height: 1.0625rem;
    float: right; }
    .availabilities__toolbar__add-availability:before {
      font-family: "iconfont";
      font-style: normal;
      font-weight: 400;
      content: ""; }
    .availabilities__toolbar__add-availability:before {
      width: 2.1875rem;
      padding: 0.625rem 0;
      font-size: 1.25em;
      color: #fff;
      position: absolute;
      background-color: #90c6de;
      border-radius: 0.1875rem;
      right: 0;
      top: 0;
      line-height: 0.9375rem;
      text-align: center;
      box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.025);
      backface-visibility: hidden;
      -webkit-filter: blur(0);
      -webkit-font-smoothing: subpixel-antialiased;
      z-index: 2; }
    .availabilities__toolbar__add-availability:after {
      content: "";
      width: 2.1875rem;
      height: 2.1875rem;
      border-radius: 0.1875rem;
      position: absolute;
      right: 0;
      top: 0;
      transition: opacity 0.125s cubic-bezier(0.77, 0, 0.175, 1), transform 0.125s cubic-bezier(0.77, 0, 0.175, 1);
      box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.055);
      opacity: 0;
      backface-visibility: hidden;
      -webkit-filter: blur(0);
      -webkit-font-smoothing: subpixel-antialiased;
      z-index: 1; }
    .availabilities__toolbar__add-availability:hover:before {
      width: 2.40625rem;
      font-size: 1.3em;
      top: -0.10938rem;
      right: -0.10938rem;
      padding: 0.73438rem 0; }
    .availabilities__toolbar__add-availability:hover:after {
      opacity: 1;
      transform: scale(1.1, 1.1); }
  @media only screen and (max-width: 47.9rem) {
    .availabilities__toolbar {
      padding-bottom: 0.5em;
      border-bottom: 0px; } }
  @media (max-width: 40.625rem) {
    .availabilities__toolbar__title > h2 {
      min-width: auto; }
    .availabilities__toolbar__add-availability {
      width: 0;
      padding-right: 0; }
      .availabilities__toolbar__add-availability span {
        display: none; } }
  @media only screen and (max-width: 26rem) {
    .availabilities__toolbar__title > h2 {
      font-size: 1.5em; } }
