@charset "UTF-8";
@font-face {
  font-family: "iconfont";
  src: url("/iconfont.eot");
  /* IE9 Compat Modes */
  src: url("/iconfont.eot?#iefix") format("embedded-opentype"), url("/iconfont.woff") format("woff"), url("/iconfont.ttf") format("truetype"), url("/iconfont.svg") format("svg");
  /* Legacy iOS */ }

.icon {
  font-style: normal;
  font-weight: 400; }
  .icon.accordion-close:before {
    font-family: "iconfont";
    content: ""; }
  .icon.accordion-open:before {
    font-family: "iconfont";
    content: ""; }
  .icon.add-folder:before {
    font-family: "iconfont";
    content: ""; }
  .icon.add:before {
    font-family: "iconfont";
    content: ""; }
  .icon.admin:before {
    font-family: "iconfont";
    content: ""; }
  .icon.all-day:before {
    font-family: "iconfont";
    content: ""; }
  .icon.arrow-left:before {
    font-family: "iconfont";
    content: ""; }
  .icon.arrow-right:before {
    font-family: "iconfont";
    content: ""; }
  .icon.assignment:before {
    font-family: "iconfont";
    content: ""; }
  .icon.association-clinic:before {
    font-family: "iconfont";
    content: ""; }
  .icon.association:before {
    font-family: "iconfont";
    content: ""; }
  .icon.bars:before {
    font-family: "iconfont";
    content: ""; }
  .icon.calendar-2:before {
    font-family: "iconfont";
    content: ""; }
  .icon.calendar-today:before {
    font-family: "iconfont";
    content: ""; }
  .icon.calendar:before {
    font-family: "iconfont";
    content: ""; }
  .icon.call-list:before {
    font-family: "iconfont";
    content: ""; }
  .icon.checkbox-off:before {
    font-family: "iconfont";
    content: ""; }
  .icon.checkbox-on:before {
    font-family: "iconfont";
    content: ""; }
  .icon.checkbox-square:before {
    font-family: "iconfont";
    content: ""; }
  .icon.circle-add:before {
    font-family: "iconfont";
    content: ""; }
  .icon.circle-check:before {
    font-family: "iconfont";
    content: ""; }
  .icon.circle-info:before {
    font-family: "iconfont";
    content: ""; }
  .icon.circlo-close:before {
    font-family: "iconfont";
    content: ""; }
  .icon.clinic:before {
    font-family: "iconfont";
    content: ""; }
  .icon.close-slim:before {
    font-family: "iconfont";
    content: ""; }
  .icon.close:before {
    font-family: "iconfont";
    content: ""; }
  .icon.closed-folder:before {
    font-family: "iconfont";
    content: ""; }
  .icon.copy:before {
    font-family: "iconfont";
    content: ""; }
  .icon.delete:before {
    font-family: "iconfont";
    content: ""; }
  .icon.disconnect:before {
    font-family: "iconfont";
    content: ""; }
  .icon.dotted-arrow-right:before {
    font-family: "iconfont";
    content: ""; }
  .icon.dotted-arrow:before {
    font-family: "iconfont";
    content: ""; }
  .icon.edit:before {
    font-family: "iconfont";
    content: ""; }
  .icon.email:before {
    font-family: "iconfont";
    content: ""; }
  .icon.featured:before {
    font-family: "iconfont";
    content: ""; }
  .icon.handle-h:before {
    font-family: "iconfont";
    content: ""; }
  .icon.handle-v:before {
    font-family: "iconfont";
    content: ""; }
  .icon.help-circle:before {
    font-family: "iconfont";
    content: ""; }
  .icon.help:before {
    font-family: "iconfont";
    content: ""; }
  .icon.history:before {
    font-family: "iconfont";
    content: ""; }
  .icon.info:before {
    font-family: "iconfont";
    content: ""; }
  .icon.lock-open:before {
    font-family: "iconfont";
    content: ""; }
  .icon.lock:before {
    font-family: "iconfont";
    content: ""; }
  .icon.lodging:before {
    font-family: "iconfont";
    content: ""; }
  .icon.magnifying-glass:before {
    font-family: "iconfont";
    content: ""; }
  .icon.map:before {
    font-family: "iconfont";
    content: ""; }
  .icon.marker:before {
    font-family: "iconfont";
    content: ""; }
  .icon.notifications:before {
    font-family: "iconfont";
    content: ""; }
  .icon.open-folder:before {
    font-family: "iconfont";
    content: ""; }
  .icon.pencil:before {
    font-family: "iconfont";
    content: ""; }
  .icon.piechart:before {
    font-family: "iconfont";
    content: ""; }
  .icon.pin:before {
    font-family: "iconfont";
    content: ""; }
  .icon.profile:before {
    font-family: "iconfont";
    content: ""; }
  .icon.schedule:before {
    font-family: "iconfont";
    content: ""; }
  .icon.settings:before {
    font-family: "iconfont";
    content: ""; }
  .icon.small-down-arrow:before {
    font-family: "iconfont";
    content: ""; }
  .icon.small-up-arrow:before {
    font-family: "iconfont";
    content: ""; }
  .icon.status-check:before {
    font-family: "iconfont";
    content: ""; }
  .icon.surgery-optional:before {
    font-family: "iconfont";
    content: ""; }
  .icon.surgery:before {
    font-family: "iconfont";
    content: ""; }
  .icon.time:before {
    font-family: "iconfont";
    content: ""; }
  .icon.timesheet:before {
    font-family: "iconfont";
    content: ""; }
  .icon.triangle:before {
    font-family: "iconfont";
    content: ""; }
  .icon.tsa:before {
    font-family: "iconfont";
    content: ""; }
  .icon.validation:before {
    font-family: "iconfont";
    content: ""; }
  .icon.vet:before {
    font-family: "iconfont";
    content: ""; }

.day-mandate {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between; }
  .day-mandate--date {
    text-transform: uppercase;
    font-size: 0.5625rem;
    color: #a0adb9;
    padding: 0.75em 0 0.5em 0;
    border-radius: 0.3125em;
    letter-spacing: .1em;
    width: 2.25rem;
    text-align: center; }
    .day-mandate--date span {
      color: #414a52;
      font-size: 1.0625rem;
      display: block;
      padding-top: 0.3125em;
      font-weight: 500;
      letter-spacing: .05em; }
  .day-mandate .field .field__checkbox__label {
    margin-right: 0; }
    .day-mandate .field .field__checkbox__label:before {
      margin-right: 0; }
    .day-mandate .field .field__checkbox__label span {
      display: none; }
  .day-mandate .field.field--border {
    width: 5.3125rem;
    position: relative;
    top: 0.3125rem; }
    .day-mandate .field.field--border .field__label {
      display: none; }
  .day-mandate .field.field--select {
    min-width: 5.625rem;
    margin: 0;
    padding: 0.625rem 0; }
    .day-mandate .field.field--select label {
      display: none; }
    .day-mandate .field.field--select .field__error {
      width: 18.75rem;
      max-width: 18.75rem;
      bottom: -0.3125rem; }
    .day-mandate .field.field--select select {
      text-align: center;
      text-align-last: center; }
    .day-mandate .field.field--select .field__select__wrapper:before {
      display: none; }
  .day-mandate__arrow {
    display: block;
    color: #d8dfe5; }
    .day-mandate__arrow:before {
      font-family: "iconfont";
      font-style: normal;
      font-weight: 400;
      content: ""; }
  .day-mandate .checkbox-tooltip__wrapper {
    position: relative; }
    .day-mandate .checkbox-tooltip__wrapper:hover .checkbox-tooltip {
      display: block; }
    .day-mandate .checkbox-tooltip__wrapper .checkbox-tooltip {
      position: absolute;
      font-size: 0.75em;
      bottom: 2.8125em;
      z-index: 2;
      right: 0.368rem;
      padding: 0.625em;
      border-radius: 0.1875em;
      background-color: #414a52;
      text-align: center;
      line-height: 1.125em;
      display: none;
      color: #fff;
      white-space: nowrap; }
      .day-mandate .checkbox-tooltip__wrapper .checkbox-tooltip:before {
        content: "";
        width: 0;
        height: 0;
        border-left: 0.625rem solid transparent;
        border-top: 0.625rem solid #414a52;
        position: absolute;
        bottom: -0.5625rem;
        right: 0; }
  .day-mandate__checkbox {
    padding: 0;
    margin: 0; }
    .day-mandate__checkbox .field__checkbox__label {
      padding: 0.84375rem 0 0.625rem; }
    .day-mandate__checkbox .field__checkbox + .field__checkbox__label:before,
    .day-mandate__checkbox .field__checkbox:checked + .field__checkbox__label:before {
      font-size: .85rem; }
  .day-mandate .field--priority .field__select {
    background-color: #f7f8f9;
    border-color: #f7f8f9; }
    .day-mandate .field--priority .field__select:focus {
      border-color: #414a52; }
