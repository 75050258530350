@charset "UTF-8";
@font-face {
  font-family: "iconfont";
  src: url("/iconfont.eot");
  /* IE9 Compat Modes */
  src: url("/iconfont.eot?#iefix") format("embedded-opentype"), url("/iconfont.woff") format("woff"), url("/iconfont.ttf") format("truetype"), url("/iconfont.svg") format("svg");
  /* Legacy iOS */ }

.icon {
  font-style: normal;
  font-weight: 400; }
  .icon.accordion-close:before {
    font-family: "iconfont";
    content: ""; }
  .icon.accordion-open:before {
    font-family: "iconfont";
    content: ""; }
  .icon.add-folder:before {
    font-family: "iconfont";
    content: ""; }
  .icon.add:before {
    font-family: "iconfont";
    content: ""; }
  .icon.admin:before {
    font-family: "iconfont";
    content: ""; }
  .icon.all-day:before {
    font-family: "iconfont";
    content: ""; }
  .icon.arrow-left:before {
    font-family: "iconfont";
    content: ""; }
  .icon.arrow-right:before {
    font-family: "iconfont";
    content: ""; }
  .icon.assignment:before {
    font-family: "iconfont";
    content: ""; }
  .icon.association-clinic:before {
    font-family: "iconfont";
    content: ""; }
  .icon.association:before {
    font-family: "iconfont";
    content: ""; }
  .icon.bars:before {
    font-family: "iconfont";
    content: ""; }
  .icon.calendar-2:before {
    font-family: "iconfont";
    content: ""; }
  .icon.calendar-today:before {
    font-family: "iconfont";
    content: ""; }
  .icon.calendar:before {
    font-family: "iconfont";
    content: ""; }
  .icon.call-list:before {
    font-family: "iconfont";
    content: ""; }
  .icon.checkbox-off:before {
    font-family: "iconfont";
    content: ""; }
  .icon.checkbox-on:before {
    font-family: "iconfont";
    content: ""; }
  .icon.checkbox-square:before {
    font-family: "iconfont";
    content: ""; }
  .icon.circle-add:before {
    font-family: "iconfont";
    content: ""; }
  .icon.circle-check:before {
    font-family: "iconfont";
    content: ""; }
  .icon.circle-info:before {
    font-family: "iconfont";
    content: ""; }
  .icon.circlo-close:before {
    font-family: "iconfont";
    content: ""; }
  .icon.clinic:before {
    font-family: "iconfont";
    content: ""; }
  .icon.close-slim:before {
    font-family: "iconfont";
    content: ""; }
  .icon.close:before {
    font-family: "iconfont";
    content: ""; }
  .icon.closed-folder:before {
    font-family: "iconfont";
    content: ""; }
  .icon.copy:before {
    font-family: "iconfont";
    content: ""; }
  .icon.delete:before {
    font-family: "iconfont";
    content: ""; }
  .icon.disconnect:before {
    font-family: "iconfont";
    content: ""; }
  .icon.dotted-arrow-right:before {
    font-family: "iconfont";
    content: ""; }
  .icon.dotted-arrow:before {
    font-family: "iconfont";
    content: ""; }
  .icon.edit:before {
    font-family: "iconfont";
    content: ""; }
  .icon.email:before {
    font-family: "iconfont";
    content: ""; }
  .icon.featured:before {
    font-family: "iconfont";
    content: ""; }
  .icon.handle-h:before {
    font-family: "iconfont";
    content: ""; }
  .icon.handle-v:before {
    font-family: "iconfont";
    content: ""; }
  .icon.help-circle:before {
    font-family: "iconfont";
    content: ""; }
  .icon.help:before {
    font-family: "iconfont";
    content: ""; }
  .icon.history:before {
    font-family: "iconfont";
    content: ""; }
  .icon.info:before {
    font-family: "iconfont";
    content: ""; }
  .icon.lock-open:before {
    font-family: "iconfont";
    content: ""; }
  .icon.lock:before {
    font-family: "iconfont";
    content: ""; }
  .icon.lodging:before {
    font-family: "iconfont";
    content: ""; }
  .icon.magnifying-glass:before {
    font-family: "iconfont";
    content: ""; }
  .icon.map:before {
    font-family: "iconfont";
    content: ""; }
  .icon.marker:before {
    font-family: "iconfont";
    content: ""; }
  .icon.notifications:before {
    font-family: "iconfont";
    content: ""; }
  .icon.open-folder:before {
    font-family: "iconfont";
    content: ""; }
  .icon.pencil:before {
    font-family: "iconfont";
    content: ""; }
  .icon.piechart:before {
    font-family: "iconfont";
    content: ""; }
  .icon.pin:before {
    font-family: "iconfont";
    content: ""; }
  .icon.profile:before {
    font-family: "iconfont";
    content: ""; }
  .icon.schedule:before {
    font-family: "iconfont";
    content: ""; }
  .icon.settings:before {
    font-family: "iconfont";
    content: ""; }
  .icon.small-down-arrow:before {
    font-family: "iconfont";
    content: ""; }
  .icon.small-up-arrow:before {
    font-family: "iconfont";
    content: ""; }
  .icon.status-check:before {
    font-family: "iconfont";
    content: ""; }
  .icon.surgery-optional:before {
    font-family: "iconfont";
    content: ""; }
  .icon.surgery:before {
    font-family: "iconfont";
    content: ""; }
  .icon.time:before {
    font-family: "iconfont";
    content: ""; }
  .icon.timesheet:before {
    font-family: "iconfont";
    content: ""; }
  .icon.triangle:before {
    font-family: "iconfont";
    content: ""; }
  .icon.tsa:before {
    font-family: "iconfont";
    content: ""; }
  .icon.validation:before {
    font-family: "iconfont";
    content: ""; }
  .icon.vet:before {
    font-family: "iconfont";
    content: ""; }

.schedule__toolbar {
  padding-bottom: 2.1875em;
  border-bottom: 1px solid #d8dfe5;
  display: flex;
  justify-content: space-between; }
  .schedule__toolbar > * {
    display: inline-block; }
  .schedule__toolbar__sides {
    width: 20%; }
  .schedule__toolbar__current-date {
    position: relative; }
    .schedule__toolbar__current-date .month-picker__open {
      display: inline-block;
      font-size: 1.875em;
      font-weight: 300;
      margin: 0;
      color: #414a52;
      position: relative;
      top: 0.1875em;
      padding: 0;
      text-transform: capitalize;
      text-align: left; }
  .schedule__toolbar__action {
    display: inline-block;
    background: none;
    border: 1px solid #d8dfe5;
    border-radius: 3px;
    height: 2.1875rem;
    width: 2.1875rem;
    font-size: 1rem;
    line-height: 1rem;
    padding: 0;
    cursor: pointer;
    transition: color 0.125s cubic-bezier(0.77, 0, 0.175, 1);
    outline: none;
    color: #a0adb9;
    position: relative; }
    .schedule__toolbar__action:hover {
      color: #4c4648; }
    .schedule__toolbar__action:before {
      position: relative;
      top: 0.0625rem; }
    .schedule__toolbar__action--prev:before {
      font-family: "iconfont";
      font-style: normal;
      font-weight: 400;
      content: ""; }
    .schedule__toolbar__action--next:before {
      font-family: "iconfont";
      font-style: normal;
      font-weight: 400;
      content: ""; }
    .schedule__toolbar__action--today:before {
      font-family: "iconfont";
      font-style: normal;
      font-weight: 400;
      content: ""; }
    .schedule__toolbar__action + .schedule__toolbar__action {
      margin-left: 0.4375rem; }
  .schedule__toolbar__add-availability, .schedule__toolbar__edit-mandate {
    display: inline-block;
    font-size: 0.75em;
    font-weight: 300;
    color: #414a52;
    background: none;
    outline: none;
    position: relative;
    height: 2.1875rem;
    padding-right: 3.25rem;
    text-align: right;
    width: 9.375rem;
    line-height: 1.0625rem;
    float: right; }
    .schedule__toolbar__add-availability:before, .schedule__toolbar__edit-mandate:before {
      font-family: "iconfont";
      font-style: normal;
      font-weight: 400;
      content: ""; }
    .schedule__toolbar__add-availability:before, .schedule__toolbar__edit-mandate:before {
      width: 2.1875rem;
      padding: 0.625rem 0;
      font-size: 1.25em;
      color: #fff;
      position: absolute;
      background-color: #90c6de;
      border-radius: 0.1875rem;
      right: 0;
      top: 0;
      line-height: 0.9375rem;
      text-align: center;
      box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.025);
      backface-visibility: hidden;
      -webkit-filter: blur(0);
      -webkit-font-smoothing: subpixel-antialiased;
      z-index: 2; }
    .schedule__toolbar__add-availability:after, .schedule__toolbar__edit-mandate:after {
      content: "";
      width: 2.1875rem;
      height: 2.1875rem;
      border-radius: 0.1875rem;
      position: absolute;
      right: 0;
      top: 0;
      transition: opacity 0.125s cubic-bezier(0.77, 0, 0.175, 1), transform 0.125s cubic-bezier(0.77, 0, 0.175, 1);
      box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.055);
      opacity: 0;
      backface-visibility: hidden;
      -webkit-filter: blur(0);
      -webkit-font-smoothing: subpixel-antialiased;
      z-index: 1; }
    .schedule__toolbar__add-availability:hover:before, .schedule__toolbar__edit-mandate:hover:before {
      width: 2.40625rem;
      font-size: 1.3em;
      top: -0.10938rem;
      right: -0.10938rem;
      padding: 0.73438rem 0; }
    .schedule__toolbar__add-availability:hover:after, .schedule__toolbar__edit-mandate:hover:after {
      opacity: 1;
      transform: scale(1.1, 1.1); }
  .schedule__toolbar__edit-mandate:before {
    font-family: "iconfont";
    font-style: normal;
    font-weight: 400;
    content: ""; }
  .schedule__toolbar__today {
    font-size: 0.75em;
    color: #fff;
    line-height: 2.1875rem;
    background-color: #90c6de;
    margin-left: 0.625rem;
    border-radius: 0.1875rem;
    transition: background-color 0.125s cubic-bezier(0.77, 0, 0.175, 1); }
    .schedule__toolbar__today:hover {
      background-color: #94d394; }
  .schedule__toolbar__month-selector input {
    display: block;
    position: absolute;
    left: 0;
    top: 0.3125em;
    z-index: 5;
    width: 100%;
    height: 100%;
    cursor: pointer;
    opacity: 0; }
  .schedule__toolbar__month-selector .calendar-container {
    background-color: white;
    position: absolute;
    top: 1.25em;
    z-index: 20;
    left: 50%;
    transform: translate(-50%, 0);
    width: 18.75rem;
    box-shadow: 1px 1px 3px 0px rgba(0, 0, 0, 0.3);
    padding-bottom: 1.25em; }
    .schedule__toolbar__month-selector .calendar-container .section_mp {
      border-bottom: 1px solid #f7f8f9;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 0.625em 0;
      margin-bottom: 1.25em; }
      .schedule__toolbar__month-selector .calendar-container .section_mp > * {
        color: #414a52;
        margin: 0 0.3125em; }
    .schedule__toolbar__month-selector .calendar-container .arrows_mp {
      cursor: pointer; }
    .schedule__toolbar__month-selector .calendar-container > .col_mp {
      display: inline-block;
      width: 25%;
      text-align: center;
      padding: 0.625em 0;
      cursor: pointer; }
      .schedule__toolbar__month-selector .calendar-container > .col_mp:hover {
        background-color: #90c6de;
        color: #fff; }
  .schedule__toolbar .month-picker > .rmp-container.show {
    left: calc(50% - 167.5px); }
    .schedule__toolbar .month-picker > .rmp-container.show .rmp-popup.light {
      background-color: white; }
    @media only screen and (max-width: 768px) {
      .schedule__toolbar .month-picker > .rmp-container.show {
        left: 0; }
        .schedule__toolbar .month-picker > .rmp-container.show .rmp-popup.show {
          bottom: 2.8125rem;
          box-shadow: none;
          border-radius: 0; } }
  .schedule__toolbar .month-picker__close {
    display: none; }
  .schedule__toolbar--agenda {
    border-bottom: none;
    padding-bottom: 0.9375em;
    display: flex;
    align-items: center;
    justify-content: space-between; }
    .schedule__toolbar--agenda .schedule__toolbar__current-date {
      border-right: 1px solid #d8dfe5;
      width: 100%;
      margin-right: 0.9375em; }
      .schedule__toolbar--agenda .schedule__toolbar__current-date .month-picker__open {
        text-transform: capitalize;
        font-size: 1.25em;
        position: relative;
        padding-right: 1.875em;
        height: 2.1875rem;
        width: 100%;
        top: 0;
        margin-right: 0.9375rem;
        text-align: left; }
        .schedule__toolbar--agenda .schedule__toolbar__current-date .month-picker__open:before {
          font-family: "iconfont";
          font-style: normal;
          font-weight: 400;
          content: ""; }
        .schedule__toolbar--agenda .schedule__toolbar__current-date .month-picker__open:before {
          font-size: 0.75em;
          position: absolute;
          top: 0.625rem;
          right: 0.75rem;
          color: #a0adb9; }
      .schedule__toolbar--agenda .schedule__toolbar__current-date .month-picker__close {
        position: fixed;
        width: 100%;
        bottom: 0;
        left: 0;
        text-align: center;
        z-index: 1000;
        padding: 0.625em 0;
        background-color: rgba(255, 255, 255, 0.96);
        font-size: 1.125em;
        color: #666;
        display: none; }
        .schedule__toolbar--agenda .schedule__toolbar__current-date .month-picker__close:before {
          font-family: "iconfont";
          font-style: normal;
          font-weight: 400;
          content: ""; }
        @media only screen and (max-width: 768px) {
          .schedule__toolbar--agenda .schedule__toolbar__current-date .month-picker__close {
            display: block; } }
    .schedule__toolbar--agenda .schedule__toolbar__month-selector .calendar-container {
      left: 0;
      transform: none;
      width: 100%; }
    .schedule__toolbar--agenda .schedule__toolbar__add-availability {
      width: 2.1875rem;
      padding: 0; }
