@charset "UTF-8";
@font-face {
  font-family: "iconfont";
  src: url("/iconfont.eot");
  /* IE9 Compat Modes */
  src: url("/iconfont.eot?#iefix") format("embedded-opentype"), url("/iconfont.woff") format("woff"), url("/iconfont.ttf") format("truetype"), url("/iconfont.svg") format("svg");
  /* Legacy iOS */ }

.icon {
  font-style: normal;
  font-weight: 400; }
  .icon.accordion-close:before {
    font-family: "iconfont";
    content: ""; }
  .icon.accordion-open:before {
    font-family: "iconfont";
    content: ""; }
  .icon.add-folder:before {
    font-family: "iconfont";
    content: ""; }
  .icon.add:before {
    font-family: "iconfont";
    content: ""; }
  .icon.admin:before {
    font-family: "iconfont";
    content: ""; }
  .icon.all-day:before {
    font-family: "iconfont";
    content: ""; }
  .icon.arrow-left:before {
    font-family: "iconfont";
    content: ""; }
  .icon.arrow-right:before {
    font-family: "iconfont";
    content: ""; }
  .icon.assignment:before {
    font-family: "iconfont";
    content: ""; }
  .icon.association-clinic:before {
    font-family: "iconfont";
    content: ""; }
  .icon.association:before {
    font-family: "iconfont";
    content: ""; }
  .icon.bars:before {
    font-family: "iconfont";
    content: ""; }
  .icon.calendar-2:before {
    font-family: "iconfont";
    content: ""; }
  .icon.calendar-today:before {
    font-family: "iconfont";
    content: ""; }
  .icon.calendar:before {
    font-family: "iconfont";
    content: ""; }
  .icon.call-list:before {
    font-family: "iconfont";
    content: ""; }
  .icon.checkbox-off:before {
    font-family: "iconfont";
    content: ""; }
  .icon.checkbox-on:before {
    font-family: "iconfont";
    content: ""; }
  .icon.checkbox-square:before {
    font-family: "iconfont";
    content: ""; }
  .icon.circle-add:before {
    font-family: "iconfont";
    content: ""; }
  .icon.circle-check:before {
    font-family: "iconfont";
    content: ""; }
  .icon.circle-info:before {
    font-family: "iconfont";
    content: ""; }
  .icon.circlo-close:before {
    font-family: "iconfont";
    content: ""; }
  .icon.clinic:before {
    font-family: "iconfont";
    content: ""; }
  .icon.close-slim:before {
    font-family: "iconfont";
    content: ""; }
  .icon.close:before {
    font-family: "iconfont";
    content: ""; }
  .icon.closed-folder:before {
    font-family: "iconfont";
    content: ""; }
  .icon.copy:before {
    font-family: "iconfont";
    content: ""; }
  .icon.delete:before {
    font-family: "iconfont";
    content: ""; }
  .icon.disconnect:before {
    font-family: "iconfont";
    content: ""; }
  .icon.dotted-arrow-right:before {
    font-family: "iconfont";
    content: ""; }
  .icon.dotted-arrow:before {
    font-family: "iconfont";
    content: ""; }
  .icon.edit:before {
    font-family: "iconfont";
    content: ""; }
  .icon.email:before {
    font-family: "iconfont";
    content: ""; }
  .icon.featured:before {
    font-family: "iconfont";
    content: ""; }
  .icon.handle-h:before {
    font-family: "iconfont";
    content: ""; }
  .icon.handle-v:before {
    font-family: "iconfont";
    content: ""; }
  .icon.help-circle:before {
    font-family: "iconfont";
    content: ""; }
  .icon.help:before {
    font-family: "iconfont";
    content: ""; }
  .icon.history:before {
    font-family: "iconfont";
    content: ""; }
  .icon.info:before {
    font-family: "iconfont";
    content: ""; }
  .icon.lock-open:before {
    font-family: "iconfont";
    content: ""; }
  .icon.lock:before {
    font-family: "iconfont";
    content: ""; }
  .icon.lodging:before {
    font-family: "iconfont";
    content: ""; }
  .icon.magnifying-glass:before {
    font-family: "iconfont";
    content: ""; }
  .icon.map:before {
    font-family: "iconfont";
    content: ""; }
  .icon.marker:before {
    font-family: "iconfont";
    content: ""; }
  .icon.notifications:before {
    font-family: "iconfont";
    content: ""; }
  .icon.open-folder:before {
    font-family: "iconfont";
    content: ""; }
  .icon.pencil:before {
    font-family: "iconfont";
    content: ""; }
  .icon.piechart:before {
    font-family: "iconfont";
    content: ""; }
  .icon.pin:before {
    font-family: "iconfont";
    content: ""; }
  .icon.profile:before {
    font-family: "iconfont";
    content: ""; }
  .icon.schedule:before {
    font-family: "iconfont";
    content: ""; }
  .icon.settings:before {
    font-family: "iconfont";
    content: ""; }
  .icon.small-down-arrow:before {
    font-family: "iconfont";
    content: ""; }
  .icon.small-up-arrow:before {
    font-family: "iconfont";
    content: ""; }
  .icon.status-check:before {
    font-family: "iconfont";
    content: ""; }
  .icon.surgery-optional:before {
    font-family: "iconfont";
    content: ""; }
  .icon.surgery:before {
    font-family: "iconfont";
    content: ""; }
  .icon.time:before {
    font-family: "iconfont";
    content: ""; }
  .icon.timesheet:before {
    font-family: "iconfont";
    content: ""; }
  .icon.triangle:before {
    font-family: "iconfont";
    content: ""; }
  .icon.tsa:before {
    font-family: "iconfont";
    content: ""; }
  .icon.validation:before {
    font-family: "iconfont";
    content: ""; }
  .icon.vet:before {
    font-family: "iconfont";
    content: ""; }

.helpcenter__accordion__subject {
  margin-bottom: 7.5rem; }
  .helpcenter__accordion__subject:last-child {
    margin-bottom: 6.25rem; }
  .helpcenter__accordion__subject__title h3 {
    max-width: 95%;
    color: #90c6de;
    font-weight: 600;
    text-align: center; }

.helpcenter__accordion__container {
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
  padding: 1.875rem 1.875rem 1.5625rem;
  width: 100%; }
  .helpcenter__accordion__container + .helpcenter__accordion__container {
    border-top: 3px solid #f7f8f9;
    border-top-width: 80%; }
  .helpcenter__accordion__container--active {
    background-color: #ffffff; }
    .helpcenter__accordion__container--active:hover {
      background-color: #ffffff; }
  .helpcenter__accordion__container:hover .helpcenter__accordion__action {
    color: #90c6de; }

.helpcenter__accordion__action {
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  color: #787877; }
  .helpcenter__accordion__action:hover,
  .helpcenter__accordion__action:hover button,
  .helpcenter__accordion__action button:focus {
    cursor: pointer;
    color: #90c6de; }
  .helpcenter__accordion__action--active {
    color: #90c6de; }
  .helpcenter__accordion__action__icon--open {
    font-size: 1.25rem; }
    .helpcenter__accordion__action__icon--open:before {
      font-family: "iconfont";
      font-style: normal;
      font-weight: 400;
      content: ""; }
  .helpcenter__accordion__action__icon--close {
    font-size: 1.25rem; }
    .helpcenter__accordion__action__icon--close:before {
      font-family: "iconfont";
      font-style: normal;
      font-weight: 400;
      content: ""; }

.helpcenter__accordion__title {
  text-align: left;
  line-height: 1.3;
  max-width: 95%; }
  .helpcenter__accordion__title span {
    font-weight: 600;
    font-size: 1.625rem; }
  .helpcenter__accordion__title--active {
    color: #90c6de; }
  .helpcenter__accordion__title:hover {
    color: #90c6de; }

.helpcenter__accordion__panel {
  max-height: 0;
  overflow: hidden;
  max-width: 95%;
  line-height: 1.5;
  color: #787877;
  transition: max-height 0.2s ease-out; }
  .helpcenter__accordion__panel p {
    text-align: left; }
  .helpcenter__accordion__panel--active {
    max-height: unset;
    padding-top: 1.875rem; }

@media (max-width: 75rem) {
  .helpcenter__faq__container {
    padding: 3.75rem; } }

@media (max-width: 62.5rem) {
  .helpcenter__accordion__title span {
    font-size: 1.125rem; } }

@media (max-width: 50rem) {
  .helpcenter__faq__container {
    padding: 3.75rem 1.5625rem; } }

@media (max-width: 37.5rem) {
  .helpcenter__accordion__title span {
    font-size: 1.125rem; } }

@media (max-width: 25rem) {
  .helpcenter__faq__container {
    padding: 2.5rem 0.3125rem; }
  .helpcenter__accordion__container {
    padding: 0.625rem 0.9375rem; }
  .helpcenter__accordion__title span {
    font-size: 0.875rem; } }
