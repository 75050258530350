.notification__wrapper {
  padding: 2.1875rem 2em;
  border-top: solid 1px rgba(65, 74, 82, 0.15); }
  .notification__wrapper:first-child {
    border-top: none; }

.notification {
  padding: 1em 1.2em;
  background-color: white;
  border: 0.05rem solid rgba(65, 74, 82, 0.15);
  border-left: 0.25rem solid #90c6de;
  border-radius: 0.1875rem;
  box-shadow: 0 3px 4px 1px rgba(0, 0, 0, 0.05);
  margin: 1em 0;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: flex-start;
  position: relative; }
  .notification--disabled {
    border-left-color: #b2bfcb; }
  .notification--pending {
    border-left-color: #f9b55a; }
  .notification--confirmed {
    border-left-color: #94d394; }
  .notification--rejected {
    border-left-color: #f76b7e; }
  .notification--confirmedRejected {
    border-left-color: #f76b7e; }
    .notification--confirmedRejected:after {
      content: '';
      background-color: #94d394;
      border-top-left-radius: 0.1875rem;
      height: 50%;
      left: -4px;
      position: absolute;
      top: -1px;
      width: 4px; }
  .notification--timesheets {
    border-left-color: #c49ee8; }
  .notification--expired {
    border-left-color: #f5e067; }
  .notification:last-child {
    margin-bottom: 0; }
  .notification:first-child {
    margin-top: 0; }
  .notification__message {
    color: #414a52;
    margin: 0;
    padding-bottom: 0.85em;
    font-size: 0.875rem;
    font-weight: normal;
    line-height: 1.5; }
  .notification__date {
    color: #4c4648;
    font-size: 0.8em;
    opacity: 0.7;
    font-weight: 300;
    padding-top: 0.6em; }
  .notification__content {
    position: relative;
    max-width: 90%; }
  .notification__button {
    color: #f76b7e;
    background-color: currentColor;
    flex-grow: 0;
    border-radius: 50%;
    width: 0.8rem;
    height: 0.8rem;
    border: solid .1rem currentColor;
    padding: 0;
    margin-top: .3em; }
    .notification__button:hover {
      background-color: rgba(247, 107, 126, 0.4); }
  .notification__link {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%; }

.notification__list > div {
  margin-top: 0.9375rem; }

.notification__title {
  color: #414a52;
  font-size: 0.8em;
  text-transform: uppercase;
  font-weight: bold;
  padding: 0 1.6em 0.6em;
  position: relative; }
  .notification__title:before {
    content: "";
    position: absolute;
    display: block;
    top: -0.165em;
    left: 0;
    width: 1em;
    height: 1em;
    border-radius: 50%;
    background: #f76b7e; }
  .notification__title--read:before {
    background: #d8dfe5; }

.notification__actions {
  margin-top: 1em;
  padding: 1.5625em 0.9375em 2.1875em;
  border-top: solid 1px rgba(65, 74, 82, 0.15); }
