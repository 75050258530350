.field__radio {
  display: none; }
  .field__radio:checked + .field__radio__label:after {
    content: "";
    display: block;
    width: 0.5rem;
    height: 0.5rem;
    position: absolute;
    top: 0.25rem;
    left: 0.125rem;
    background-color: #403b42;
    border-radius: 50%; }
  .field__radio[disabled] + .field__radio__label {
    opacity: .5;
    cursor: default; }

.field__radio__label {
  user-select: none;
  position: relative;
  display: flex;
  align-items: center;
  cursor: pointer;
  margin-right: 0.625rem; }
  .field__radio__label:before {
    content: "";
    display: block;
    border: 1px solid #403b42;
    width: 0.625rem;
    height: 0.625rem;
    margin-right: 0.5rem;
    border-radius: 50%; }

.radio-custom {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  position: relative;
  margin: 0;
  padding: 0 0.625em;
  width: 100%; }
  .radio-custom .field {
    padding: 0;
    min-width: 30%; }
    .radio-custom .field .field__radio__label {
      display: block;
      padding: 3.125rem 0.625rem 0.625rem;
      text-align: center;
      position: relative;
      cursor: pointer;
      border-radius: 0.1875rem;
      color: #a0adb9;
      font-size: 0.6875em;
      margin: 0; }
      .radio-custom .field .field__radio__label:before {
        background-color: #fff; }
      .radio-custom .field .field__radio__label:before, .radio-custom .field .field__radio__label:after {
        position: absolute;
        left: 0;
        right: 0;
        margin: 0 auto;
        top: 1.25rem;
        border-color: #a0adb9;
        z-index: 6; }
      .radio-custom .field .field__radio__label:after {
        top: 1.375rem; }
  .radio-custom .field__radio:checked + .field__radio__label {
    background-color: #f3f5f6;
    color: #414a52; }
    .radio-custom .field__radio:checked + .field__radio__label:before, .radio-custom .field__radio:checked + .field__radio__label:after {
      border-color: #414a52; }
    .radio-custom .field__radio:checked + .field__radio__label .radio-custom__hide {
      background-color: #f3f5f6; }
  .radio-custom__hide {
    content: "";
    display: block;
    width: 2.5rem;
    height: 0.625rem;
    z-index: 0;
    position: absolute;
    left: 0;
    right: 0;
    margin: 0 auto;
    top: 1.25rem;
    background-color: #fff; }
  .radio-custom__small {
    justify-content: center; }
    .radio-custom__small .field {
      min-width: 40%; }
  .radio-custom__distance {
    padding: 0;
    justify-content: space-between;
    max-width: 20.3125rem;
    margin-left: auto;
    margin-right: auto; }
    .radio-custom__distance__spacer {
      position: relative;
      top: -1.3125rem;
      left: -0.0625rem;
      margin: 0 -1.25rem;
      display: flex;
      justify-content: center;
      width: 0rem;
      overflow: visible;
      font-size: 1.5rem;
      z-index: +1;
      letter-spacing: -.05em;
      color: #b2bfcb;
      text-align: center;
      user-select: none; }
    .radio-custom__distance .field__radio__label {
      max-width: 5.3125rem;
      line-height: 1.25em; }
    .radio-custom__distance .field {
      min-width: 0;
      width: auto;
      position: relative; }
    .radio-custom__distance .field:after {
      display: none; }

.radio-inline {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;
  font-size: 0.75em; }
  .radio-inline .field__radio:checked + .field__radio__label:after {
    top: 0.125rem; }

.radio-boolean {
  display: flex;
  flex-wrap: wrap;
  font-size: 0.75em;
  margin-top: 0.3125em;
  margin-bottom: 0.3125em;
  padding-top: 0.9375em;
  padding-bottom: 0.9375em; }
  .radio-boolean__label {
    display: block;
    font-size: 0.875rem;
    line-height: 1.125rem;
    width: 40%;
    padding-right: 1.25rem;
    color: #403b42; }
  .radio-boolean__wrapper {
    display: flex; }
  .radio-boolean .field {
    margin: 0;
    padding: 0; }
    .radio-boolean .field + .field {
      margin-left: 0.625rem; }
  .radio-boolean .field__radio__label {
    color: #403b42;
    font-size: 0.875rem;
    line-height: 1.125rem; }
    .radio-boolean .field__radio__label:before {
      border: 1px solid #a0adb9;
      position: relative;
      top: -0.125rem; }
  .radio-boolean .field__radio:checked + .field__radio__label:after {
    top: 0.1875rem;
    background-color: currentColor; }
  .radio-boolean .field__radio:checked + .field__radio__label:before {
    border-color: currentColor; }
  .radio-boolean--wrap {
    display: block; }
    .radio-boolean--wrap .radio-boolean__label {
      width: 100%;
      padding-right: 0;
      padding-bottom: 0.625rem; }

.radio-list {
  position: relative;
  display: block;
  padding: 1.25rem 0; }
  .radio-list--label {
    margin-top: 1.875rem;
    padding-top: 1.75rem; }
  .radio-list .field {
    display: block;
    margin: 0 0 1rem;
    padding: 0; }
  .radio-list .field:last-child {
    margin-bottom: 0; }
  .radio-list .field__radio__label {
    color: #403b42;
    font-size: 0.8125rem;
    line-height: 1.125rem; }
    .radio-list .field__radio__label:before {
      border: 1px solid #a0adb9;
      position: relative;
      top: -0.125rem; }
  .radio-list .field__radio:checked + .field__radio__label:before {
    border-color: currentColor; }
  .radio-list .field__radio:checked + .field__radio__label:after {
    top: 0.1875rem; }
  .radio-list .radio-custom__hide {
    display: none; }
