@charset "UTF-8";
@font-face {
  font-family: "iconfont";
  src: url("/iconfont.eot");
  /* IE9 Compat Modes */
  src: url("/iconfont.eot?#iefix") format("embedded-opentype"), url("/iconfont.woff") format("woff"), url("/iconfont.ttf") format("truetype"), url("/iconfont.svg") format("svg");
  /* Legacy iOS */ }

.icon {
  font-style: normal;
  font-weight: 400; }
  .icon.accordion-close:before {
    font-family: "iconfont";
    content: ""; }
  .icon.accordion-open:before {
    font-family: "iconfont";
    content: ""; }
  .icon.add-folder:before {
    font-family: "iconfont";
    content: ""; }
  .icon.add:before {
    font-family: "iconfont";
    content: ""; }
  .icon.admin:before {
    font-family: "iconfont";
    content: ""; }
  .icon.all-day:before {
    font-family: "iconfont";
    content: ""; }
  .icon.arrow-left:before {
    font-family: "iconfont";
    content: ""; }
  .icon.arrow-right:before {
    font-family: "iconfont";
    content: ""; }
  .icon.assignment:before {
    font-family: "iconfont";
    content: ""; }
  .icon.association-clinic:before {
    font-family: "iconfont";
    content: ""; }
  .icon.association:before {
    font-family: "iconfont";
    content: ""; }
  .icon.bars:before {
    font-family: "iconfont";
    content: ""; }
  .icon.calendar-2:before {
    font-family: "iconfont";
    content: ""; }
  .icon.calendar-today:before {
    font-family: "iconfont";
    content: ""; }
  .icon.calendar:before {
    font-family: "iconfont";
    content: ""; }
  .icon.call-list:before {
    font-family: "iconfont";
    content: ""; }
  .icon.checkbox-off:before {
    font-family: "iconfont";
    content: ""; }
  .icon.checkbox-on:before {
    font-family: "iconfont";
    content: ""; }
  .icon.checkbox-square:before {
    font-family: "iconfont";
    content: ""; }
  .icon.circle-add:before {
    font-family: "iconfont";
    content: ""; }
  .icon.circle-check:before {
    font-family: "iconfont";
    content: ""; }
  .icon.circle-info:before {
    font-family: "iconfont";
    content: ""; }
  .icon.circlo-close:before {
    font-family: "iconfont";
    content: ""; }
  .icon.clinic:before {
    font-family: "iconfont";
    content: ""; }
  .icon.close-slim:before {
    font-family: "iconfont";
    content: ""; }
  .icon.close:before {
    font-family: "iconfont";
    content: ""; }
  .icon.closed-folder:before {
    font-family: "iconfont";
    content: ""; }
  .icon.copy:before {
    font-family: "iconfont";
    content: ""; }
  .icon.delete:before {
    font-family: "iconfont";
    content: ""; }
  .icon.disconnect:before {
    font-family: "iconfont";
    content: ""; }
  .icon.dotted-arrow-right:before {
    font-family: "iconfont";
    content: ""; }
  .icon.dotted-arrow:before {
    font-family: "iconfont";
    content: ""; }
  .icon.edit:before {
    font-family: "iconfont";
    content: ""; }
  .icon.email:before {
    font-family: "iconfont";
    content: ""; }
  .icon.featured:before {
    font-family: "iconfont";
    content: ""; }
  .icon.handle-h:before {
    font-family: "iconfont";
    content: ""; }
  .icon.handle-v:before {
    font-family: "iconfont";
    content: ""; }
  .icon.help-circle:before {
    font-family: "iconfont";
    content: ""; }
  .icon.help:before {
    font-family: "iconfont";
    content: ""; }
  .icon.history:before {
    font-family: "iconfont";
    content: ""; }
  .icon.info:before {
    font-family: "iconfont";
    content: ""; }
  .icon.lock-open:before {
    font-family: "iconfont";
    content: ""; }
  .icon.lock:before {
    font-family: "iconfont";
    content: ""; }
  .icon.lodging:before {
    font-family: "iconfont";
    content: ""; }
  .icon.magnifying-glass:before {
    font-family: "iconfont";
    content: ""; }
  .icon.map:before {
    font-family: "iconfont";
    content: ""; }
  .icon.marker:before {
    font-family: "iconfont";
    content: ""; }
  .icon.notifications:before {
    font-family: "iconfont";
    content: ""; }
  .icon.open-folder:before {
    font-family: "iconfont";
    content: ""; }
  .icon.pencil:before {
    font-family: "iconfont";
    content: ""; }
  .icon.piechart:before {
    font-family: "iconfont";
    content: ""; }
  .icon.pin:before {
    font-family: "iconfont";
    content: ""; }
  .icon.profile:before {
    font-family: "iconfont";
    content: ""; }
  .icon.schedule:before {
    font-family: "iconfont";
    content: ""; }
  .icon.settings:before {
    font-family: "iconfont";
    content: ""; }
  .icon.small-down-arrow:before {
    font-family: "iconfont";
    content: ""; }
  .icon.small-up-arrow:before {
    font-family: "iconfont";
    content: ""; }
  .icon.status-check:before {
    font-family: "iconfont";
    content: ""; }
  .icon.surgery-optional:before {
    font-family: "iconfont";
    content: ""; }
  .icon.surgery:before {
    font-family: "iconfont";
    content: ""; }
  .icon.time:before {
    font-family: "iconfont";
    content: ""; }
  .icon.timesheet:before {
    font-family: "iconfont";
    content: ""; }
  .icon.triangle:before {
    font-family: "iconfont";
    content: ""; }
  .icon.tsa:before {
    font-family: "iconfont";
    content: ""; }
  .icon.validation:before {
    font-family: "iconfont";
    content: ""; }
  .icon.vet:before {
    font-family: "iconfont";
    content: ""; }

.schedule {
  position: relative; }
  @media only screen and (min-width: 62.5rem) {
    .schedule .basic-loader:before {
      top: 4.4375rem; } }
  @media only screen and (max-width: 62.5rem) {
    .schedule {
      min-height: 18.75rem; } }
  .schedule .rbc-month-view {
    border: none;
    min-height: 50em; }
  .schedule .rbc-header {
    padding: 1.75em 0;
    border-left: none; }
    .schedule .rbc-header span {
      text-transform: uppercase;
      font-weight: 300;
      color: #a0adb9;
      font-size: 0.875em; }
  .schedule .rbc-date-cell {
    padding: 0.625em;
    text-align: left;
    color: #a0adb9; }
  .schedule .rbc-day-bg {
    background-color: #fff; }
  .schedule .rbc-off-range-bg {
    background-color: #f7f8f9; }
  .schedule .rbc-now a {
    background-color: #414a52;
    color: #fff; }
  .schedule .rbc-row-content .rbc-row {
    height: auto; }
    .schedule .rbc-row-content .rbc-row .rbc-row-segment {
      height: 0; }
  .schedule--clinic .rbc-row-content {
    min-height: 7.5rem; }
    .schedule--clinic .rbc-row-content > * {
      width: 100%; }
    .schedule--clinic .rbc-row-content .rbc-row {
      height: auto; }
      .schedule--clinic .rbc-row-content .rbc-row .rbc-row-segment {
        height: auto; }
        .schedule--clinic .rbc-row-content .rbc-row .rbc-row-segment .rbc-event, .schedule--clinic .rbc-row-content .rbc-row .rbc-row-segment .rbc-event-content {
          height: auto; }
  .schedule--clinic .rbc-month-row {
    display: block; }
  .schedule--clinic .schedule__event--replacement.schedule__event--pending {
    background: repeating-linear-gradient(35deg, transparent, transparent 5px, rgba(144, 198, 222, 0.25) 5px, rgba(144, 198, 222, 0.25) 10px), rgba(216, 234, 242, 0.9);
    border-color: #90c6de; }
    .schedule--clinic .schedule__event--replacement.schedule__event--pending .schedule__event-details__head--date:before {
      color: #90c6de; }
    .schedule--clinic .schedule__event--replacement.schedule__event--pending .schedule__event-details__pending {
      text-transform: uppercase;
      font-weight: 500;
      font-size: 0.5625rem;
      padding: 0.875rem 1.125rem 0.625rem;
      letter-spacing: .09em;
      color: #414a52;
      border-bottom: 1px solid #d8dfe5;
      background: repeating-linear-gradient(35deg, transparent, transparent 5px, rgba(144, 198, 222, 0.25) 5px, rgba(144, 198, 222, 0.25) 10px), rgba(216, 234, 242, 0.9); }
    .schedule--clinic .schedule__event--replacement.schedule__event--pending .schedule__event__toggle:before {
      background-color: #90c6de; }
  .schedule--clinic .schedule__event--replacement.schedule__event--pending_clinic {
    background: #fde4c5;
    border-color: #f9b55a; }
    .schedule--clinic .schedule__event--replacement.schedule__event--pending_clinic .schedule__event-details__head--date:before {
      color: #f9b55a; }
    .schedule--clinic .schedule__event--replacement.schedule__event--pending_clinic .schedule__event__toggle:before {
      background-color: #f9b55a; }
  @media only screen and (min-width: 62.5rem) {
    .schedule--clinic .schedule__event--toggled, .schedule--clinic .schedule__event:hover {
      box-shadow: 0 10px 10px -10px rgba(0, 0, 0, 0.075); }
    .schedule--clinic .schedule__event__toggle:before {
      display: none; }
    .schedule--clinic .schedule__event--first .schedule__event__toggle:before {
      display: block; }
    .schedule--clinic .schedule__event--replacement {
      min-height: 3.375rem;
      position: absolute;
      width: calc(14.2857% - 20px);
      margin-left: 0px;
      border-left: 4px solid #90c6de; }
      .schedule--clinic .schedule__event--replacement.schedule__event--pending {
        border-color: #90c6de; }
      .schedule--clinic .schedule__event--replacement.schedule__event--pending_clinic {
        border-color: #f9b55a; }
      .schedule--clinic .schedule__event--replacement.schedule__event--confirmed, .schedule--clinic .schedule__event--replacement.schedule__event--pending_admin {
        border-color: #94d394; }
      .schedule--clinic .schedule__event--replacement.schedule__event--disabled {
        border-color: #d8dfe5; }
      .schedule--clinic .schedule__event--replacement.schedule__event--first {
        width: calc(14.2857% - 10px); }
      .schedule--clinic .schedule__event--replacement.schedule__event--last {
        margin-left: -10px;
        width: calc(14.2857% - 6px);
        border-color: transparent;
        border-left: 0px;
        padding-left: 27px; }
      .schedule--clinic .schedule__event--replacement.schedule__event--middle {
        margin-left: -10px;
        width: 14.2857%;
        border-color: transparent;
        border-left: 0px;
        padding-left: 27px; }
    .schedule--clinic .schedule__event__expander {
      display: block;
      height: 3.5rem; } }

.message {
  margin-top: 2.1875em;
  padding: 2.1875em 3.125em 1.875em;
  background-color: white;
  border: solid 1px #f7f8f9;
  border-left: solid 4px #90c6de;
  border-top-left-radius: 0.3125em;
  border-bottom-left-radius: 0.3125em;
  box-shadow: 0 5px 15px 0 rgba(0, 0, 0, 0.05); }
  .message p {
    margin-bottom: 0; }
  .message--blue {
    border-left-color: #90c6de; }
  .message--green {
    border-left-color: #94d394; }
  .message--orange {
    border-left-color: #f9b55a; }

.rmp-overlay {
  display: none; }

.modal__container {
  display: flex;
  justify-content: center;
  white-space: pre-line;
  flex-direction: row-reverse;
  border: 1px solid #ffffff;
  border-radius: 0.625rem;
  background-color: #ffffff;
  margin: 0 auto;
  margin-bottom: 3.4375rem;
  padding: 1rem 1.875rem 0.9375rem;
  text-align: center;
  box-shadow: 0.25rem 0.25rem 0.9375rem rgba(56, 56, 56, 0.35);
  max-width: 75rem;
  width: 80%; }
  .modal__container button {
    align-self: flex-start;
    justify-self: flex-end; }
  .modal__container .icon {
    border-radius: 50%;
    color: #414a52;
    font-size: 0.9375rem;
    left: 1.25rem;
    line-height: 0.85;
    padding: 0.625rem;
    position: relative;
    -webkit-transition: background-color ease-in-out 200ms;
    -moz-transition: background-color ease-in-out 200ms;
    -ms-transition: background-color ease-in-out 200ms;
    -o-transition: background-color ease-in-out 200ms;
    transition: background-color ease-in-out 200ms; }
    .modal__container .icon--close:before {
      font-family: "iconfont";
      font-style: normal;
      font-weight: 400;
      content: ""; }
    .modal__container .icon:hover {
      background-color: #d8dfe5; }
  .modal__container .icon--profile {
    padding: 0.3125rem; }
    .modal__container .icon--profile:before {
      font-family: "iconfont";
      font-style: normal;
      font-weight: 400;
      content: ""; }
    .modal__container .icon--profile:before {
      font-size: 0.9375rem; }
  .modal__container .icon--clinic {
    padding: 0.3125rem; }
    .modal__container .icon--clinic:before {
      font-family: "iconfont";
      font-style: normal;
      font-weight: 400;
      content: ""; }
    .modal__container .icon--clinic:before {
      font-size: 0.9375rem; }
  .modal__container span {
    margin: 0.625rem 0;
    max-width: 90%;
    font-size: 0.875rem;
    color: #414a52;
    line-height: 1.5; }

.modal__content {
  width: 95%;
  display: flex;
  flex-direction: column;
  align-items: center; }
  .modal__content p {
    margin: 0;
    width: 100%;
    padding: 0.3125rem; }

@media (max-width: 46.875rem) {
  .modal__container {
    width: 100%; }
  .modal__content span {
    max-width: 100%;
    line-height: 1.5; } }

@media (max-width: 31.25rem) {
  .modal__content span {
    font-size: 0.75rem;
    max-width: 100%;
    line-height: 1.5; } }
