@charset "UTF-8";
@font-face {
  font-family: "iconfont";
  src: url("/iconfont.eot");
  /* IE9 Compat Modes */
  src: url("/iconfont.eot?#iefix") format("embedded-opentype"), url("/iconfont.woff") format("woff"), url("/iconfont.ttf") format("truetype"), url("/iconfont.svg") format("svg");
  /* Legacy iOS */ }

.icon {
  font-style: normal;
  font-weight: 400; }
  .icon.accordion-close:before {
    font-family: "iconfont";
    content: ""; }
  .icon.accordion-open:before {
    font-family: "iconfont";
    content: ""; }
  .icon.add-folder:before {
    font-family: "iconfont";
    content: ""; }
  .icon.add:before {
    font-family: "iconfont";
    content: ""; }
  .icon.admin:before {
    font-family: "iconfont";
    content: ""; }
  .icon.all-day:before {
    font-family: "iconfont";
    content: ""; }
  .icon.arrow-left:before {
    font-family: "iconfont";
    content: ""; }
  .icon.arrow-right:before {
    font-family: "iconfont";
    content: ""; }
  .icon.assignment:before {
    font-family: "iconfont";
    content: ""; }
  .icon.association-clinic:before {
    font-family: "iconfont";
    content: ""; }
  .icon.association:before {
    font-family: "iconfont";
    content: ""; }
  .icon.bars:before {
    font-family: "iconfont";
    content: ""; }
  .icon.calendar-2:before {
    font-family: "iconfont";
    content: ""; }
  .icon.calendar-today:before {
    font-family: "iconfont";
    content: ""; }
  .icon.calendar:before {
    font-family: "iconfont";
    content: ""; }
  .icon.call-list:before {
    font-family: "iconfont";
    content: ""; }
  .icon.checkbox-off:before {
    font-family: "iconfont";
    content: ""; }
  .icon.checkbox-on:before {
    font-family: "iconfont";
    content: ""; }
  .icon.checkbox-square:before {
    font-family: "iconfont";
    content: ""; }
  .icon.circle-add:before {
    font-family: "iconfont";
    content: ""; }
  .icon.circle-check:before {
    font-family: "iconfont";
    content: ""; }
  .icon.circle-info:before {
    font-family: "iconfont";
    content: ""; }
  .icon.circlo-close:before {
    font-family: "iconfont";
    content: ""; }
  .icon.clinic:before {
    font-family: "iconfont";
    content: ""; }
  .icon.close-slim:before {
    font-family: "iconfont";
    content: ""; }
  .icon.close:before {
    font-family: "iconfont";
    content: ""; }
  .icon.closed-folder:before {
    font-family: "iconfont";
    content: ""; }
  .icon.copy:before {
    font-family: "iconfont";
    content: ""; }
  .icon.delete:before {
    font-family: "iconfont";
    content: ""; }
  .icon.disconnect:before {
    font-family: "iconfont";
    content: ""; }
  .icon.dotted-arrow-right:before {
    font-family: "iconfont";
    content: ""; }
  .icon.dotted-arrow:before {
    font-family: "iconfont";
    content: ""; }
  .icon.edit:before {
    font-family: "iconfont";
    content: ""; }
  .icon.email:before {
    font-family: "iconfont";
    content: ""; }
  .icon.featured:before {
    font-family: "iconfont";
    content: ""; }
  .icon.handle-h:before {
    font-family: "iconfont";
    content: ""; }
  .icon.handle-v:before {
    font-family: "iconfont";
    content: ""; }
  .icon.help-circle:before {
    font-family: "iconfont";
    content: ""; }
  .icon.help:before {
    font-family: "iconfont";
    content: ""; }
  .icon.history:before {
    font-family: "iconfont";
    content: ""; }
  .icon.info:before {
    font-family: "iconfont";
    content: ""; }
  .icon.lock-open:before {
    font-family: "iconfont";
    content: ""; }
  .icon.lock:before {
    font-family: "iconfont";
    content: ""; }
  .icon.lodging:before {
    font-family: "iconfont";
    content: ""; }
  .icon.magnifying-glass:before {
    font-family: "iconfont";
    content: ""; }
  .icon.map:before {
    font-family: "iconfont";
    content: ""; }
  .icon.marker:before {
    font-family: "iconfont";
    content: ""; }
  .icon.notifications:before {
    font-family: "iconfont";
    content: ""; }
  .icon.open-folder:before {
    font-family: "iconfont";
    content: ""; }
  .icon.pencil:before {
    font-family: "iconfont";
    content: ""; }
  .icon.piechart:before {
    font-family: "iconfont";
    content: ""; }
  .icon.pin:before {
    font-family: "iconfont";
    content: ""; }
  .icon.profile:before {
    font-family: "iconfont";
    content: ""; }
  .icon.schedule:before {
    font-family: "iconfont";
    content: ""; }
  .icon.settings:before {
    font-family: "iconfont";
    content: ""; }
  .icon.small-down-arrow:before {
    font-family: "iconfont";
    content: ""; }
  .icon.small-up-arrow:before {
    font-family: "iconfont";
    content: ""; }
  .icon.status-check:before {
    font-family: "iconfont";
    content: ""; }
  .icon.surgery-optional:before {
    font-family: "iconfont";
    content: ""; }
  .icon.surgery:before {
    font-family: "iconfont";
    content: ""; }
  .icon.time:before {
    font-family: "iconfont";
    content: ""; }
  .icon.timesheet:before {
    font-family: "iconfont";
    content: ""; }
  .icon.triangle:before {
    font-family: "iconfont";
    content: ""; }
  .icon.tsa:before {
    font-family: "iconfont";
    content: ""; }
  .icon.validation:before {
    font-family: "iconfont";
    content: ""; }
  .icon.vet:before {
    font-family: "iconfont";
    content: ""; }

.field__checkbox {
  display: none;
  color: #a0adb9; }
  .field__checkbox__label:before {
    position: relative;
    top: -0.15625em;
    left: 0;
    border-radius: 2px;
    width: 0.75rem;
    height: 0.75rem; }
  .field__checkbox:checked + .field__checkbox__label:before {
    font-family: "iconfont";
    font-style: normal;
    font-weight: 400;
    content: ""; }
  .field__checkbox:checked + .field__checkbox__label:before {
    border: none;
    width: 0.75rem;
    height: 0.75rem;
    font-size: 0.75rem;
    overflow: hidden; }
  .field__checkbox:checked + .field__checkbox__label:after {
    display: none; }
  .field__checkbox--round .field__checkbox__label:before {
    font-family: "iconfont";
    font-style: normal;
    font-weight: 400;
    content: ""; }
  .field__checkbox--round .field__checkbox__label:before {
    color: #a0adb9;
    border: none;
    width: 0.75rem;
    height: 0.75rem;
    font-size: 0.75rem;
    border-radius: 0; }
  .field__checkbox--round .field__checkbox:checked + .field__checkbox__label:before {
    font-family: "iconfont";
    font-style: normal;
    font-weight: 400;
    content: ""; }
  .field__checkbox--round .field__checkbox:checked + .field__checkbox__label:before {
    color: #414a52;
    overflow: visible; }
  .field__checkbox--round .field__error {
    position: initial;
    margin-top: 0.3125rem; }
  .field__checkbox--priority .field__checkbox__label:before,
  .field__checkbox--priority .field__checkbox:checked + .field__checkbox__label:before {
    font-family: "iconfont";
    font-style: normal;
    font-weight: 400;
    content: ""; }
  .field__checkbox--flexible .field__checkbox__label:before,
  .field__checkbox--flexible .field__checkbox:checked + .field__checkbox__label:before {
    font-family: "iconfont";
    font-style: normal;
    font-weight: 400;
    content: ""; }
  .field__checkbox--full-width {
    width: 100%; }
  .field__checkbox--clustered:not(:last-child) {
    padding-bottom: 0; }
  .field__checkbox[disabled] + .field__checkbox__label {
    opacity: .5;
    cursor: default; }

.field__error--checkbox {
  display: block;
  position: static;
  font-size: 0.75rem;
  line-height: 1rem;
  padding-top: 0.5rem; }

.field__checkbox__label {
  position: relative;
  display: flex;
  align-items: center;
  cursor: pointer;
  margin-right: 0.625rem;
  user-select: none; }
  .field__checkbox__label:before {
    content: "";
    display: block;
    border: 1px solid #403b42;
    width: 0.625rem;
    height: 0.625rem;
    margin-right: 0.5rem; }
