@charset "UTF-8";
@font-face {
  font-family: "iconfont";
  src: url("/iconfont.eot");
  /* IE9 Compat Modes */
  src: url("/iconfont.eot?#iefix") format("embedded-opentype"), url("/iconfont.woff") format("woff"), url("/iconfont.ttf") format("truetype"), url("/iconfont.svg") format("svg");
  /* Legacy iOS */ }

.icon {
  font-style: normal;
  font-weight: 400; }
  .icon.accordion-close:before {
    font-family: "iconfont";
    content: ""; }
  .icon.accordion-open:before {
    font-family: "iconfont";
    content: ""; }
  .icon.add-folder:before {
    font-family: "iconfont";
    content: ""; }
  .icon.add:before {
    font-family: "iconfont";
    content: ""; }
  .icon.admin:before {
    font-family: "iconfont";
    content: ""; }
  .icon.all-day:before {
    font-family: "iconfont";
    content: ""; }
  .icon.arrow-left:before {
    font-family: "iconfont";
    content: ""; }
  .icon.arrow-right:before {
    font-family: "iconfont";
    content: ""; }
  .icon.assignment:before {
    font-family: "iconfont";
    content: ""; }
  .icon.association-clinic:before {
    font-family: "iconfont";
    content: ""; }
  .icon.association:before {
    font-family: "iconfont";
    content: ""; }
  .icon.bars:before {
    font-family: "iconfont";
    content: ""; }
  .icon.calendar-2:before {
    font-family: "iconfont";
    content: ""; }
  .icon.calendar-today:before {
    font-family: "iconfont";
    content: ""; }
  .icon.calendar:before {
    font-family: "iconfont";
    content: ""; }
  .icon.call-list:before {
    font-family: "iconfont";
    content: ""; }
  .icon.checkbox-off:before {
    font-family: "iconfont";
    content: ""; }
  .icon.checkbox-on:before {
    font-family: "iconfont";
    content: ""; }
  .icon.checkbox-square:before {
    font-family: "iconfont";
    content: ""; }
  .icon.circle-add:before {
    font-family: "iconfont";
    content: ""; }
  .icon.circle-check:before {
    font-family: "iconfont";
    content: ""; }
  .icon.circle-info:before {
    font-family: "iconfont";
    content: ""; }
  .icon.circlo-close:before {
    font-family: "iconfont";
    content: ""; }
  .icon.clinic:before {
    font-family: "iconfont";
    content: ""; }
  .icon.close-slim:before {
    font-family: "iconfont";
    content: ""; }
  .icon.close:before {
    font-family: "iconfont";
    content: ""; }
  .icon.closed-folder:before {
    font-family: "iconfont";
    content: ""; }
  .icon.copy:before {
    font-family: "iconfont";
    content: ""; }
  .icon.delete:before {
    font-family: "iconfont";
    content: ""; }
  .icon.disconnect:before {
    font-family: "iconfont";
    content: ""; }
  .icon.dotted-arrow-right:before {
    font-family: "iconfont";
    content: ""; }
  .icon.dotted-arrow:before {
    font-family: "iconfont";
    content: ""; }
  .icon.edit:before {
    font-family: "iconfont";
    content: ""; }
  .icon.email:before {
    font-family: "iconfont";
    content: ""; }
  .icon.featured:before {
    font-family: "iconfont";
    content: ""; }
  .icon.handle-h:before {
    font-family: "iconfont";
    content: ""; }
  .icon.handle-v:before {
    font-family: "iconfont";
    content: ""; }
  .icon.help-circle:before {
    font-family: "iconfont";
    content: ""; }
  .icon.help:before {
    font-family: "iconfont";
    content: ""; }
  .icon.history:before {
    font-family: "iconfont";
    content: ""; }
  .icon.info:before {
    font-family: "iconfont";
    content: ""; }
  .icon.lock-open:before {
    font-family: "iconfont";
    content: ""; }
  .icon.lock:before {
    font-family: "iconfont";
    content: ""; }
  .icon.lodging:before {
    font-family: "iconfont";
    content: ""; }
  .icon.magnifying-glass:before {
    font-family: "iconfont";
    content: ""; }
  .icon.map:before {
    font-family: "iconfont";
    content: ""; }
  .icon.marker:before {
    font-family: "iconfont";
    content: ""; }
  .icon.notifications:before {
    font-family: "iconfont";
    content: ""; }
  .icon.open-folder:before {
    font-family: "iconfont";
    content: ""; }
  .icon.pencil:before {
    font-family: "iconfont";
    content: ""; }
  .icon.piechart:before {
    font-family: "iconfont";
    content: ""; }
  .icon.pin:before {
    font-family: "iconfont";
    content: ""; }
  .icon.profile:before {
    font-family: "iconfont";
    content: ""; }
  .icon.schedule:before {
    font-family: "iconfont";
    content: ""; }
  .icon.settings:before {
    font-family: "iconfont";
    content: ""; }
  .icon.small-down-arrow:before {
    font-family: "iconfont";
    content: ""; }
  .icon.small-up-arrow:before {
    font-family: "iconfont";
    content: ""; }
  .icon.status-check:before {
    font-family: "iconfont";
    content: ""; }
  .icon.surgery-optional:before {
    font-family: "iconfont";
    content: ""; }
  .icon.surgery:before {
    font-family: "iconfont";
    content: ""; }
  .icon.time:before {
    font-family: "iconfont";
    content: ""; }
  .icon.timesheet:before {
    font-family: "iconfont";
    content: ""; }
  .icon.triangle:before {
    font-family: "iconfont";
    content: ""; }
  .icon.tsa:before {
    font-family: "iconfont";
    content: ""; }
  .icon.validation:before {
    font-family: "iconfont";
    content: ""; }
  .icon.vet:before {
    font-family: "iconfont";
    content: ""; }

.availabilities__load-more {
  text-align: center; }
  .availabilities__load-more__btn {
    background: #414a52;
    color: #fff;
    padding: 1.0625em 1.125em 0.875em;
    text-transform: uppercase;
    font-size: 0.6875em;
    line-height: 1.0625em;
    font-weight: 700;
    border: none;
    cursor: pointer;
    border-radius: 0.1875em;
    outline: none;
    letter-spacing: 0.8px;
    font-size: .9em; }
    .availabilities__load-more__btn:hover {
      background-color: #384047;
      color: #fff; }
    .availabilities__load-more__btn[disabled] {
      opacity: 0.4;
      background: #414a52;
      cursor: default;
      color: #fff; }
    .availabilities__load-more__btn--disabled {
      pointer-events: none;
      opacity: 0.3; }

.availabilities-list {
  box-shadow: 0 5px 15px 0 rgba(0, 0, 0, 0.075);
  margin: 2.1875em 0;
  border-left: 4px #90c6de solid;
  border-radius: 0.1875rem;
  overflow: hidden; }
  .availabilities-list__header {
    background-color: #fff;
    padding: 2.5em 2.8125em;
    border-bottom: 1px solid #d8dfe5; }
    .availabilities-list__header > h3 {
      display: inline-block;
      font-size: 1.875em;
      font-weight: 300;
      margin-right: 0.9375em;
      color: #414a52;
      position: relative;
      top: 0.1875em;
      padding: 0;
      text-transform: capitalize;
      min-width: 15.625rem;
      text-align: left; }
    @media only screen and (max-width: 47.9rem) {
      .availabilities-list__header {
        padding: 1.875em 1.875em; }
        .availabilities-list__header .availabilities__toolbar__add-availability {
          margin-top: -0.15625rem; }
          .availabilities-list__header .availabilities__toolbar__add-availability span {
            display: none; } }
    @media only screen and (max-width: 38rem) {
      .availabilities-list__header {
        padding: 1.875em 1.5625em; }
        .availabilities-list__header > h3 {
          font-size: 1.375em;
          min-width: 0; } }
    @media only screen and (max-width: 26rem) {
      .availabilities-list__header {
        padding: 1.875em 1.25em; }
        .availabilities-list__header > h3 {
          font-size: 1.1875em; }
        .availabilities-list__header .availabilities__toolbar__add-availability {
          margin-top: -0.46875rem; } }
  .availabilities-list__content {
    background-color: rgba(247, 248, 249, 0.5); }
  .availabilities-list__week {
    list-style-type: none;
    padding: 0;
    margin: 0;
    line-height: 0;
    border-bottom: 1px solid #d8dfe5; }
    .availabilities-list__week:last-child {
      border-bottom: 0; }
  .availabilities-list__no-availabilities {
    padding: 1.5rem 2.8125rem;
    font-size: 0.875rem;
    color: #414a52;
    font-weight: 300; }
    @media only screen and (max-width: 47.9rem) {
      .availabilities-list__no-availabilities {
        padding: 1.5rem 1.875rem; } }
    @media only screen and (max-width: 38rem) {
      .availabilities-list__no-availabilities {
        padding: 1.5em 1.5625em; } }
    @media only screen and (max-width: 26rem) {
      .availabilities-list__no-availabilities {
        padding: 1.5em 1.25em; } }
  .availabilities-list .list-item {
    font-size: 0.875rem;
    line-height: 1.375em;
    font-weight: 300;
    padding: 0 2.1875em;
    width: 100%;
    color: #414a52; }
    @media only screen and (max-width: 70rem) {
      .availabilities-list .list-item--open {
        background-color: rgba(216, 223, 229, 0.25); } }
    .availabilities-list .list-item--clickable .list-item__infos {
      pointer-events: auto; }
    .availabilities-list .list-item__header {
      padding: 1.25em 0.625em 1.125em;
      display: flex;
      width: 100%;
      align-items: center;
      justify-content: space-between;
      flex-direction: row;
      border-bottom: 1px solid rgba(216, 223, 229, 0.65); }
    .availabilities-list .list-item:last-child .list-item__header {
      border-bottom: 0; }
    .availabilities-list .list-item:last-child .list-item__drawer {
      border-bottom: none; }
    .availabilities-list .list-item--open:last-child .list-item__drawer {
      border-top: 1px solid rgba(216, 223, 229, 0.65); }
    .availabilities-list .list-item__infos {
      display: flex;
      flex-grow: 1;
      align-items: center;
      -webkit-user-select: none;
      /* Safari */
      -moz-user-select: none;
      /* Firefox */
      -ms-user-select: none;
      /* IE10+/Edge */
      user-select: none;
      /* Standard */
      pointer-events: none; }
      @media only screen and (max-width: 70rem) {
        .availabilities-list .list-item__infos {
          pointer-events: auto;
          padding-right: 1.25rem; } }
      @media only screen and (max-width: 25rem) {
        .availabilities-list .list-item__infos {
          font-size: 0.75rem; } }
      .availabilities-list .list-item__infos > * {
        padding-left: 2.1875em; }
        .availabilities-list .list-item__infos > *:first-child {
          margin-left: 0; }
    .availabilities-list .list-item__actions {
      white-space: nowrap;
      display: flex;
      flex-grow: 0;
      align-items: center;
      justify-content: flex-end;
      height: 100%; }
    .availabilities-list .list-item__action {
      color: #a0adb9;
      font-size: 1rem;
      padding: 0; }
      .availabilities-list .list-item__action:last-child {
        margin-left: 0.875em; }
      .availabilities-list .list-item__action:hover {
        color: #414a52; }
      .availabilities-list .list-item__action--edit:before {
        font-family: "iconfont";
        font-style: normal;
        font-weight: 400;
        content: ""; }
      .availabilities-list .list-item__action--delete:before {
        font-family: "iconfont";
        font-style: normal;
        font-weight: 400;
        content: ""; }
    .availabilities-list .list-item__date {
      width: 36px;
      text-transform: uppercase;
      font-size: 0.5em;
      line-height: 0.75em;
      text-align: center;
      padding: 0.625em 0;
      border-radius: 0.3125em; }
      .availabilities-list .list-item__date span.date {
        font-size: 1rem;
        line-height: 1em;
        display: block;
        padding-top: 0.3125em;
        font-weight: 600; }
      .availabilities-list .list-item__date + .list-item__month {
        padding-left: 0.9375em; }
      .availabilities-list .list-item__date .month {
        padding-top: 0.25em;
        display: none; }
    .availabilities-list .list-item__month {
      min-width: 15%;
      text-transform: capitalize; }
    .availabilities-list .list-item__hours {
      width: 20%;
      font-weight: 700;
      white-space: nowrap;
      letter-spacing: 0; }
      @media only screen and (max-width: 25rem) {
        .availabilities-list .list-item__hours {
          letter-spacing: 0;
          white-space: inherit; } }
    .availabilities-list .list-item__type {
      width: 25%;
      display: flex;
      align-items: center; }
      .availabilities-list .list-item__type .type__text span + span:before {
        content: ' & '; }
      .availabilities-list .list-item__type .type__icon {
        display: none;
        padding: 0 0.125em;
        color: #414a52; }
        .availabilities-list .list-item__type .type__icon--surgery:before {
          font-family: "iconfont";
          font-style: normal;
          font-weight: 400;
          content: ""; }
        .availabilities-list .list-item__type .type__icon--consult:before {
          font-family: "iconfont";
          font-style: normal;
          font-weight: 400;
          content: ""; }
        .availabilities-list .list-item__type .type__icon--message {
          padding-left: 0.4375rem;
          position: relative;
          top: 0.125rem; }
          .availabilities-list .list-item__type .type__icon--message:before {
            font-family: "iconfont";
            font-style: normal;
            font-weight: 400;
            content: ""; }
          .availabilities-list .list-item__type .type__icon--message.type__icon--active {
            display: inline-block !important; }
    .availabilities-list .list-item__drawer {
      position: relative;
      border-bottom: 1px solid rgba(216, 223, 229, 0.65);
      display: none; }
      .availabilities-list .list-item__drawer--desktop {
        display: block; }
      @media only screen and (max-width: 70rem) {
        .availabilities-list .list-item__drawer {
          display: block; } }
      .availabilities-list .list-item__drawer__infos {
        padding: 1.875em 0.625em 1.5625em; }
      .availabilities-list .list-item__drawer__close {
        padding: 0.3125em 0.6875em;
        margin: 0;
        display: block;
        position: absolute;
        top: 1.875em;
        right: 0.625em;
        color: #a0adb9;
        z-index: +1; }
        .availabilities-list .list-item__drawer__close:before {
          font-family: "iconfont";
          font-style: normal;
          font-weight: 400;
          content: ""; }
        .availabilities-list .list-item__drawer__close:hover {
          color: #414a52; }
      .availabilities-list .list-item__drawer__actions {
        border-top: 1px solid #d8dfe5;
        padding: 1.25em 0.625em 1.25em 1.875em;
        display: none; }
      .availabilities-list .list-item__drawer .list-item__btn {
        font-weight: 700;
        margin-right: 1.25em;
        display: block;
        width: 100%; }
        .availabilities-list .list-item__drawer .list-item__btn:last-child {
          margin-right: 0; }
        .availabilities-list .list-item__drawer .list-item__btn--delete {
          background: #f76b7e;
          color: #fff;
          padding: 1.0625em 1.125em 0.875em;
          text-transform: uppercase;
          font-size: 0.6875em;
          line-height: 1.0625em;
          font-weight: 700;
          border: none;
          cursor: pointer;
          border-radius: 0.1875em;
          outline: none;
          letter-spacing: 0.8px;
          font-size: .9em; }
          .availabilities-list .list-item__drawer .list-item__btn--delete:hover {
            background-color: #f6586d;
            color: #fff; }
          .availabilities-list .list-item__drawer .list-item__btn--delete[disabled] {
            opacity: 0.4;
            background: #f76b7e;
            cursor: default;
            color: #fff; }
        .availabilities-list .list-item__drawer .list-item__btn--edit {
          background: #414a52;
          color: #fff;
          padding: 1.0625em 1.125em 0.875em;
          text-transform: uppercase;
          font-size: 0.6875em;
          line-height: 1.0625em;
          font-weight: 700;
          border: none;
          cursor: pointer;
          border-radius: 0.1875em;
          outline: none;
          letter-spacing: 0.8px;
          font-size: .9em; }
          .availabilities-list .list-item__drawer .list-item__btn--edit:hover {
            background-color: #384047;
            color: #fff; }
          .availabilities-list .list-item__drawer .list-item__btn--edit[disabled] {
            opacity: 0.4;
            background: #414a52;
            cursor: default;
            color: #fff; }
    .availabilities-list .list-item__bullet {
      position: relative;
      padding-left: 2.5rem;
      padding-bottom: 0.375rem; }
      .availabilities-list .list-item__bullet:last-child {
        padding-bottom: 0; }
      .availabilities-list .list-item__bullet:before {
        content: "-";
        position: absolute;
        top: 0;
        left: 0;
        font-size: 0.875em; }
      .availabilities-list .list-item__bullet strong {
        display: block;
        font-weight: 700;
        margin-bottom: 0.375em; }
      .availabilities-list .list-item__bullet--hours:before {
        font-family: "iconfont";
        font-style: normal;
        font-weight: 400;
        content: ""; }
      .availabilities-list .list-item__bullet--marker:before {
        font-family: "iconfont";
        font-style: normal;
        font-weight: 400;
        content: ""; }
      .availabilities-list .list-item__bullet--surgery:before {
        font-family: "iconfont";
        font-style: normal;
        font-weight: 400;
        content: ""; }
      .availabilities-list .list-item__bullet--consult:before {
        font-family: "iconfont";
        font-style: normal;
        font-weight: 400;
        content: ""; }
      .availabilities-list .list-item__bullet--info:before {
        font-family: "iconfont";
        font-style: normal;
        font-weight: 400;
        content: ""; }
      .availabilities-list .list-item__bullet--mobile {
        display: none; }
    .availabilities-list .list-item__distance {
      width: 22%; }
      .availabilities-list .list-item__distance .distance__tag {
        display: none;
        padding: 0.375em 0.375em 0.125em;
        background-color: #414a52;
        border-radius: 0.1875em;
        color: white;
        font-size: 0.875em;
        font-weight: 700;
        letter-spacing: 0;
        white-space: nowrap; }
      @media only screen and (max-width: 25rem) {
        .availabilities-list .list-item__distance .distance__tag {
          font-size: 0.625em;
          line-height: 1.25em;
          white-space: nowrap; } }
    .availabilities-list .list-item .arrow {
      font-size: 80%;
      color: #a3a0a0;
      padding-left: .35em;
      padding-right: .35em; }
      .availabilities-list .list-item .arrow:before {
        font-family: "iconfont";
        font-style: normal;
        font-weight: 400;
        content: ""; }
    @media only screen and (max-width: 70rem) {
      .availabilities-list .list-item__month {
        min-width: 20%; }
      .availabilities-list .list-item__hours {
        width: 30%; }
      .availabilities-list .list-item__type {
        width: 15%;
        white-space: nowrap; }
        .availabilities-list .list-item__type .type__text {
          display: none; }
        .availabilities-list .list-item__type .type__icon {
          display: inline-block;
          opacity: 0; }
        .availabilities-list .list-item__type .type__icon--active {
          opacity: 1; }
        .availabilities-list .list-item__type .type__icon--message {
          top: 0.0625rem;
          padding-left: 0.1875em; }
      .availabilities-list .list-item__distance {
        width: auto;
        order: 4; }
        .availabilities-list .list-item__distance .distance__text {
          display: none; }
        .availabilities-list .list-item__distance .distance__tag {
          display: block; }
      .availabilities-list .list-item__bullet--mobile {
        display: block; }
      .availabilities-list .list-item__bullet--info {
        margin-top: 0.625rem; }
        .availabilities-list .list-item__bullet--info:first-child {
          margin-top: 0; } }
    @media only screen and (max-width: 47.9rem) {
      .availabilities-list .list-item {
        padding: 0 1.875em; }
        .availabilities-list .list-item__header {
          padding-left: 0;
          padding-right: 0; }
        .availabilities-list .list-item__infos {
          justify-content: space-between;
          padding-right: 0; }
          .availabilities-list .list-item__infos > * {
            margin-left: 1.25em;
            padding-left: 1.875em; }
        .availabilities-list .list-item__date {
          padding-left: 0; }
          .availabilities-list .list-item__date .month {
            display: block; }
        .availabilities-list .list-item__month {
          display: none; }
        .availabilities-list .list-item__type {
          width: auto; }
        .availabilities-list .list-item__hours {
          margin-left: 0;
          padding-left: 0.9375em; }
        .availabilities-list .list-item__distance {
          margin-left: 0; }
        .availabilities-list .list-item__actions {
          display: none; }
        .availabilities-list .list-item__drawer__infos {
          padding-left: 0;
          padding-right: 0; }
        .availabilities-list .list-item__drawer__actions {
          display: flex;
          padding-left: 0;
          padding-right: 0; }
        .availabilities-list .list-item__drawer__close {
          right: -0.3125em; } }
    @media only screen and (max-width: 38rem) {
      .availabilities-list .list-item {
        padding: 0 1.5625em; }
        .availabilities-list .list-item__infos > * {
          margin-left: 0; }
        .availabilities-list .list-item__date {
          order: 1;
          flex-grow: 0; }
        .availabilities-list .list-item__hours {
          width: auto;
          order: 3;
          flex-grow: 1; }
          .availabilities-list .list-item__hours .arrow {
            font-size: 120%; }
            .availabilities-list .list-item__hours .arrow:before {
              display: none; }
            .availabilities-list .list-item__hours .arrow:after {
              content: "-"; }
        .availabilities-list .list-item__type {
          width: auto;
          order: 2;
          margin-left: 0.625rem; }
        .availabilities-list .list-item__distance {
          width: auto;
          flex-grow: 0; } }
    @media only screen and (max-width: 28.125rem) {
      .availabilities-list .list-item__infos > * {
        padding-left: 0; } }
    @media only screen and (max-width: 26rem) {
      .availabilities-list .list-item {
        padding: 0 1.25em; } }
