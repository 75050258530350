.page__logged-in .not-found h2 {
  font-size: 1.875em;
  font-weight: 700;
  color: #414a52;
  text-transform: capitalize;
  margin-bottom: 1.5625em; }

.not-found h2 {
  font-size: 1.875em;
  font-weight: 700;
  color: #414a52;
  text-transform: capitalize;
  margin-bottom: 1.5625em; }
