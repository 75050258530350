@charset "UTF-8";
@font-face {
  font-family: "iconfont";
  src: url("/iconfont.eot");
  /* IE9 Compat Modes */
  src: url("/iconfont.eot?#iefix") format("embedded-opentype"), url("/iconfont.woff") format("woff"), url("/iconfont.ttf") format("truetype"), url("/iconfont.svg") format("svg");
  /* Legacy iOS */ }

.icon {
  font-style: normal;
  font-weight: 400; }
  .icon.accordion-close:before {
    font-family: "iconfont";
    content: ""; }
  .icon.accordion-open:before {
    font-family: "iconfont";
    content: ""; }
  .icon.add-folder:before {
    font-family: "iconfont";
    content: ""; }
  .icon.add:before {
    font-family: "iconfont";
    content: ""; }
  .icon.admin:before {
    font-family: "iconfont";
    content: ""; }
  .icon.all-day:before {
    font-family: "iconfont";
    content: ""; }
  .icon.arrow-left:before {
    font-family: "iconfont";
    content: ""; }
  .icon.arrow-right:before {
    font-family: "iconfont";
    content: ""; }
  .icon.assignment:before {
    font-family: "iconfont";
    content: ""; }
  .icon.association-clinic:before {
    font-family: "iconfont";
    content: ""; }
  .icon.association:before {
    font-family: "iconfont";
    content: ""; }
  .icon.bars:before {
    font-family: "iconfont";
    content: ""; }
  .icon.calendar-2:before {
    font-family: "iconfont";
    content: ""; }
  .icon.calendar-today:before {
    font-family: "iconfont";
    content: ""; }
  .icon.calendar:before {
    font-family: "iconfont";
    content: ""; }
  .icon.call-list:before {
    font-family: "iconfont";
    content: ""; }
  .icon.checkbox-off:before {
    font-family: "iconfont";
    content: ""; }
  .icon.checkbox-on:before {
    font-family: "iconfont";
    content: ""; }
  .icon.checkbox-square:before {
    font-family: "iconfont";
    content: ""; }
  .icon.circle-add:before {
    font-family: "iconfont";
    content: ""; }
  .icon.circle-check:before {
    font-family: "iconfont";
    content: ""; }
  .icon.circle-info:before {
    font-family: "iconfont";
    content: ""; }
  .icon.circlo-close:before {
    font-family: "iconfont";
    content: ""; }
  .icon.clinic:before {
    font-family: "iconfont";
    content: ""; }
  .icon.close-slim:before {
    font-family: "iconfont";
    content: ""; }
  .icon.close:before {
    font-family: "iconfont";
    content: ""; }
  .icon.closed-folder:before {
    font-family: "iconfont";
    content: ""; }
  .icon.copy:before {
    font-family: "iconfont";
    content: ""; }
  .icon.delete:before {
    font-family: "iconfont";
    content: ""; }
  .icon.disconnect:before {
    font-family: "iconfont";
    content: ""; }
  .icon.dotted-arrow-right:before {
    font-family: "iconfont";
    content: ""; }
  .icon.dotted-arrow:before {
    font-family: "iconfont";
    content: ""; }
  .icon.edit:before {
    font-family: "iconfont";
    content: ""; }
  .icon.email:before {
    font-family: "iconfont";
    content: ""; }
  .icon.featured:before {
    font-family: "iconfont";
    content: ""; }
  .icon.handle-h:before {
    font-family: "iconfont";
    content: ""; }
  .icon.handle-v:before {
    font-family: "iconfont";
    content: ""; }
  .icon.help-circle:before {
    font-family: "iconfont";
    content: ""; }
  .icon.help:before {
    font-family: "iconfont";
    content: ""; }
  .icon.history:before {
    font-family: "iconfont";
    content: ""; }
  .icon.info:before {
    font-family: "iconfont";
    content: ""; }
  .icon.lock-open:before {
    font-family: "iconfont";
    content: ""; }
  .icon.lock:before {
    font-family: "iconfont";
    content: ""; }
  .icon.lodging:before {
    font-family: "iconfont";
    content: ""; }
  .icon.magnifying-glass:before {
    font-family: "iconfont";
    content: ""; }
  .icon.map:before {
    font-family: "iconfont";
    content: ""; }
  .icon.marker:before {
    font-family: "iconfont";
    content: ""; }
  .icon.notifications:before {
    font-family: "iconfont";
    content: ""; }
  .icon.open-folder:before {
    font-family: "iconfont";
    content: ""; }
  .icon.pencil:before {
    font-family: "iconfont";
    content: ""; }
  .icon.piechart:before {
    font-family: "iconfont";
    content: ""; }
  .icon.pin:before {
    font-family: "iconfont";
    content: ""; }
  .icon.profile:before {
    font-family: "iconfont";
    content: ""; }
  .icon.schedule:before {
    font-family: "iconfont";
    content: ""; }
  .icon.settings:before {
    font-family: "iconfont";
    content: ""; }
  .icon.small-down-arrow:before {
    font-family: "iconfont";
    content: ""; }
  .icon.small-up-arrow:before {
    font-family: "iconfont";
    content: ""; }
  .icon.status-check:before {
    font-family: "iconfont";
    content: ""; }
  .icon.surgery-optional:before {
    font-family: "iconfont";
    content: ""; }
  .icon.surgery:before {
    font-family: "iconfont";
    content: ""; }
  .icon.time:before {
    font-family: "iconfont";
    content: ""; }
  .icon.timesheet:before {
    font-family: "iconfont";
    content: ""; }
  .icon.triangle:before {
    font-family: "iconfont";
    content: ""; }
  .icon.tsa:before {
    font-family: "iconfont";
    content: ""; }
  .icon.validation:before {
    font-family: "iconfont";
    content: ""; }
  .icon.vet:before {
    font-family: "iconfont";
    content: ""; }

.picture-uploader {
  padding-top: 0;
  padding-bottom: 0;
  margin-bottom: 0; }
  .picture-uploader__preview {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 12.5rem;
    width: 12.5rem;
    background-color: #90c6de;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    margin: 0 0 0.625rem;
    left: calc(50% - 6.25rem);
    top: 0rem;
    -webkit-transition: all 200ms;
    /* For Safari 3.1 to 6.0 */
    transition: all 200ms;
    overflow: hidden;
    border-radius: 50%; }
    .picture-uploader__preview--minified {
      width: 6.25rem;
      height: 6.25rem;
      position: absolute;
      left: calc(50% - 3.125rem);
      top: 1.875rem;
      z-index: +1;
      margin-bottom: 0; }
    .picture-uploader__preview--filled.picture-uploader__preview--minified .picture-uploader__preview__filler {
      opacity: 0; }
    .picture-uploader__preview__filler {
      position: absolute;
      height: 100%;
      width: 100%;
      background-color: transparent;
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
      overflow: hidden;
      border-radius: 50%;
      z-index: +1; }
    .picture-uploader__preview__placeholder {
      position: absolute;
      width: 100%;
      transition: opacity 200ms; }
    .picture-uploader__preview__edit {
      display: flex;
      align-items: center;
      justify-content: center;
      position: absolute;
      height: 100%;
      width: 100%;
      background-color: rgba(65, 74, 82, 0.5);
      opacity: 0;
      transition: all ease-in-out 200ms;
      pointer-events: none;
      border-radius: 50%;
      z-index: +2; }
      .picture-uploader__preview__edit span {
        position: relative;
        color: white;
        font-size: 0.875rem;
        line-height: 0.875rem;
        max-width: 90%; }
    .picture-uploader__preview:hover .picture-uploader__preview__edit {
      opacity: 1;
      pointer-events: auto;
      cursor: pointer; }
  .picture-uploader__uppy {
    margin: 0 auto;
    padding-bottom: 1rem; }
  .picture-uploader__filename {
    display: block;
    text-align: center;
    max-width: 100%;
    color: #a0adb9;
    font-size: 0.75rem;
    padding: 0.8125rem 0; }
    .picture-uploader__filename svg {
      color: #94d394; }
  .picture-uploader__note {
    font-weight: 700;
    display: block;
    text-align: center;
    padding: 0.5rem 1rem;
    margin: 0 auto;
    font-size: 0.75rem;
    line-height: 1.25em;
    background-color: #414a52;
    color: white;
    border-radius: 0.375rem;
    max-width: 75%;
    position: relative; }
    .picture-uploader__note:after {
      content: "";
      left: 50%;
      bottom: 100%;
      border: solid transparent;
      height: 0;
      width: 0;
      position: absolute;
      pointer-events: none;
      border-color: rgba(136, 183, 213, 0);
      border-bottom-color: #414a52;
      border-width: 0.375rem;
      margin-top: -0.75rem; }
  .picture-uploader .uppy-DragDrop-container {
    border-radius: 0;
    position: relative; }
  .picture-uploader .uppy-DragDrop-inner {
    padding: 4.6875rem 0.9375rem 1.125rem; }
  .picture-uploader .uppy-DragDrop-label {
    font-size: .9em; }
  .picture-uploader .uppy-DragDrop-arrow {
    width: 3.125rem;
    height: 3.125rem; }
  .picture-uploader .uppy-DragDrop-dragText {
    color: #7cbcd8;
    font-weight: 500; }
    .picture-uploader .uppy-DragDrop-dragText:hover {
      color: #51a6cb; }
  .picture-uploader .uppy-StatusBar {
    width: calc(100% - 4px);
    margin-left: auto;
    margin-right: auto; }
    .picture-uploader .uppy-StatusBar:before {
      top: -0.75rem; }
    .picture-uploader .uppy-StatusBar-progress {
      height: 0.625rem;
      top: -0.75rem;
      background-color: #90c6de; }
    .picture-uploader .uppy-StatusBar.is-complete .uppy-StatusBar-progress {
      background-color: #94d394; }
    .picture-uploader .uppy-StatusBar.is-complete .uppy-StatusBar-statusIndicator {
      color: #94d394; }
    .picture-uploader .uppy-StatusBar.is-complete .uppy-StatusBar-content {
      display: none; }
    .picture-uploader .uppy-StatusBar.is-complete {
      height: 0; }
    .picture-uploader .uppy-StatusBar-content {
      padding-left: 0;
      width: 100%;
      justify-content: center; }
    .picture-uploader .uppy-StatusBar-status {
      max-width: 100%;
      padding: 0;
      color: #a0adb9;
      font-size: 0.75rem;
      font-family: "F37Ginger", "HelveticaNeue-Light", "Helvetica Neue Light", "Helvetica Neue", Helvetica, Arial, "Lucida Grande", sans-serif; }
  .picture-uploader--changed .uppy-DragDrop-label {
    display: none; }
