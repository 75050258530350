.field__textarea {
  height: 6.25rem;
  width: 100%;
  max-width: 100%;
  -webkit-appearance: none;
  border-radius: 0.1875em; }
  .field__textarea[readonly], .field__textarea[disabled] {
    background-color: #f7f8f9;
    border-color: #f7f8f9;
    cursor: default; }
  .field__textarea[disabled] {
    color: #a0adb9; }
