@charset "UTF-8";
@font-face {
  font-family: "iconfont";
  src: url("/iconfont.eot");
  /* IE9 Compat Modes */
  src: url("/iconfont.eot?#iefix") format("embedded-opentype"), url("/iconfont.woff") format("woff"), url("/iconfont.ttf") format("truetype"), url("/iconfont.svg") format("svg");
  /* Legacy iOS */ }

.icon {
  font-style: normal;
  font-weight: 400; }
  .icon.accordion-close:before {
    font-family: "iconfont";
    content: ""; }
  .icon.accordion-open:before {
    font-family: "iconfont";
    content: ""; }
  .icon.add-folder:before {
    font-family: "iconfont";
    content: ""; }
  .icon.add:before {
    font-family: "iconfont";
    content: ""; }
  .icon.admin:before {
    font-family: "iconfont";
    content: ""; }
  .icon.all-day:before {
    font-family: "iconfont";
    content: ""; }
  .icon.arrow-left:before {
    font-family: "iconfont";
    content: ""; }
  .icon.arrow-right:before {
    font-family: "iconfont";
    content: ""; }
  .icon.assignment:before {
    font-family: "iconfont";
    content: ""; }
  .icon.association-clinic:before {
    font-family: "iconfont";
    content: ""; }
  .icon.association:before {
    font-family: "iconfont";
    content: ""; }
  .icon.bars:before {
    font-family: "iconfont";
    content: ""; }
  .icon.calendar-2:before {
    font-family: "iconfont";
    content: ""; }
  .icon.calendar-today:before {
    font-family: "iconfont";
    content: ""; }
  .icon.calendar:before {
    font-family: "iconfont";
    content: ""; }
  .icon.call-list:before {
    font-family: "iconfont";
    content: ""; }
  .icon.checkbox-off:before {
    font-family: "iconfont";
    content: ""; }
  .icon.checkbox-on:before {
    font-family: "iconfont";
    content: ""; }
  .icon.checkbox-square:before {
    font-family: "iconfont";
    content: ""; }
  .icon.circle-add:before {
    font-family: "iconfont";
    content: ""; }
  .icon.circle-check:before {
    font-family: "iconfont";
    content: ""; }
  .icon.circle-info:before {
    font-family: "iconfont";
    content: ""; }
  .icon.circlo-close:before {
    font-family: "iconfont";
    content: ""; }
  .icon.clinic:before {
    font-family: "iconfont";
    content: ""; }
  .icon.close-slim:before {
    font-family: "iconfont";
    content: ""; }
  .icon.close:before {
    font-family: "iconfont";
    content: ""; }
  .icon.closed-folder:before {
    font-family: "iconfont";
    content: ""; }
  .icon.copy:before {
    font-family: "iconfont";
    content: ""; }
  .icon.delete:before {
    font-family: "iconfont";
    content: ""; }
  .icon.disconnect:before {
    font-family: "iconfont";
    content: ""; }
  .icon.dotted-arrow-right:before {
    font-family: "iconfont";
    content: ""; }
  .icon.dotted-arrow:before {
    font-family: "iconfont";
    content: ""; }
  .icon.edit:before {
    font-family: "iconfont";
    content: ""; }
  .icon.email:before {
    font-family: "iconfont";
    content: ""; }
  .icon.featured:before {
    font-family: "iconfont";
    content: ""; }
  .icon.handle-h:before {
    font-family: "iconfont";
    content: ""; }
  .icon.handle-v:before {
    font-family: "iconfont";
    content: ""; }
  .icon.help-circle:before {
    font-family: "iconfont";
    content: ""; }
  .icon.help:before {
    font-family: "iconfont";
    content: ""; }
  .icon.history:before {
    font-family: "iconfont";
    content: ""; }
  .icon.info:before {
    font-family: "iconfont";
    content: ""; }
  .icon.lock-open:before {
    font-family: "iconfont";
    content: ""; }
  .icon.lock:before {
    font-family: "iconfont";
    content: ""; }
  .icon.lodging:before {
    font-family: "iconfont";
    content: ""; }
  .icon.magnifying-glass:before {
    font-family: "iconfont";
    content: ""; }
  .icon.map:before {
    font-family: "iconfont";
    content: ""; }
  .icon.marker:before {
    font-family: "iconfont";
    content: ""; }
  .icon.notifications:before {
    font-family: "iconfont";
    content: ""; }
  .icon.open-folder:before {
    font-family: "iconfont";
    content: ""; }
  .icon.pencil:before {
    font-family: "iconfont";
    content: ""; }
  .icon.piechart:before {
    font-family: "iconfont";
    content: ""; }
  .icon.pin:before {
    font-family: "iconfont";
    content: ""; }
  .icon.profile:before {
    font-family: "iconfont";
    content: ""; }
  .icon.schedule:before {
    font-family: "iconfont";
    content: ""; }
  .icon.settings:before {
    font-family: "iconfont";
    content: ""; }
  .icon.small-down-arrow:before {
    font-family: "iconfont";
    content: ""; }
  .icon.small-up-arrow:before {
    font-family: "iconfont";
    content: ""; }
  .icon.status-check:before {
    font-family: "iconfont";
    content: ""; }
  .icon.surgery-optional:before {
    font-family: "iconfont";
    content: ""; }
  .icon.surgery:before {
    font-family: "iconfont";
    content: ""; }
  .icon.time:before {
    font-family: "iconfont";
    content: ""; }
  .icon.timesheet:before {
    font-family: "iconfont";
    content: ""; }
  .icon.triangle:before {
    font-family: "iconfont";
    content: ""; }
  .icon.tsa:before {
    font-family: "iconfont";
    content: ""; }
  .icon.validation:before {
    font-family: "iconfont";
    content: ""; }
  .icon.vet:before {
    font-family: "iconfont";
    content: ""; }

.wrapper--fullwidth {
  margin: -2.1em -4.375em -6.5625em; }
  @media only screen and (max-width: 38rem) {
    .wrapper--fullwidth {
      margin: -2.1em -1.25em; } }

.matchmaking__calendar {
  width: 100%;
  height: auto;
  position: relative; }
  .matchmaking__calendar__wrapper {
    overflow: scroll;
    overflow-anchor: none;
    width: 100%;
    height: calc(100vh - 70px); }
    .matchmaking__calendar__wrapper:not(:last-child) {
      height: calc(70vh - 70px); }
  .matchmaking__calendar__header {
    width: 100%;
    position: sticky;
    top: 0;
    left: 0;
    z-index: +11; }
    .matchmaking__calendar__header .matchmaking__row {
      background: #f7f8f9; }
      .matchmaking__calendar__header .matchmaking__row:last-child {
        box-shadow: 0 5px 30px 0 rgba(0, 0, 0, 0.075); }
  .matchmaking__calendar--proposals .matchmaking__event--unmatchable {
    pointer-events: none; }
    .matchmaking__calendar--proposals .matchmaking__event--unmatchable .matchmaking__timerange,
    .matchmaking__calendar--proposals .matchmaking__event--unmatchable .matchmaking__mandate__header {
      opacity: 0.3; }
    .matchmaking__calendar--proposals .matchmaking__event--unmatchable .matchmaking__mandate__action--delete {
      pointer-events: all; }
  .matchmaking__calendar--proposals .matchmaking__event--interactive {
    pointer-events: auto; }
  .matchmaking__calendar .matchmaking__row {
    display: inline-flex;
    flex-flow: row nowrap;
    position: relative; }
    .matchmaking__calendar .matchmaking__row--date {
      background-color: white;
      min-height: 3.375em;
      box-shadow: 0 3px 15px 0 rgba(0, 0, 0, 0.04); }
      .matchmaking__calendar .matchmaking__row--date .day {
        padding: 0.625em 0.6em 0.4em; }
      .matchmaking__calendar .matchmaking__row--date .day--today {
        background-color: #414a52;
        border-radius: 0.1875em;
        color: rgba(255, 255, 255, 0.7); }
        .matchmaking__calendar .matchmaking__row--date .day--today strong {
          color: white; }
      .matchmaking__calendar .matchmaking__row--date .day--holiday {
        background-color: #fefbef; }
        .matchmaking__calendar .matchmaking__row--date .day--holiday--tag {
          background-color: #f8e994;
          border-radius: 0.1875em;
          bottom: 0.3125rem;
          color: #414a52;
          font-size: 0.5rem;
          letter-spacing: 0.01562rem;
          padding: 0.125rem 0.25rem;
          position: absolute;
          text-transform: none;
          width: fit-content; }
        .matchmaking__calendar .matchmaking__row--date .day--holiday strong {
          color: #414a52; }
      .matchmaking__calendar .matchmaking__row--date:last-child {
        box-shadow: none; }
    .matchmaking__calendar .matchmaking__row--header {
      min-height: 4em; }
    .matchmaking__calendar .matchmaking__row:nth-last-child(-n+5):not(:nth-child(-n+4)) .matchmaking__tooltip {
      top: auto;
      bottom: calc(100% - 5.2em);
      padding-top: 0;
      padding-bottom: 2em; }
      .matchmaking__calendar .matchmaking__row:nth-last-child(-n+5):not(:nth-child(-n+4)) .matchmaking__tooltip > div {
        border-top: 0.0625em solid #d8dfe5;
        border-bottom: none; }
        .matchmaking__calendar .matchmaking__row:nth-last-child(-n+5):not(:nth-child(-n+4)) .matchmaking__tooltip > div:after {
          bottom: auto;
          top: 100%;
          border-color: white transparent transparent transparent; }
  .matchmaking__calendar .matchmaking__day {
    border-right: 0.0625em solid #d8dfe5;
    border-bottom: 0.0625em solid #d8dfe5;
    display: flex;
    flex-shrink: 0;
    width: 8.5rem;
    text-align: center;
    position: relative; }
    .matchmaking__calendar .matchmaking__day:last-child {
      border-right: 0; }
    .matchmaking__calendar .matchmaking__day--multiple {
      flex-direction: column; }
      .matchmaking__calendar .matchmaking__day--multiple .matchmaking__day__add {
        position: absolute; }
    .matchmaking__calendar .matchmaking__day--date {
      text-transform: uppercase;
      font-weight: 300;
      font-size: 0.75em;
      color: #a3a0a0;
      border-right: 0;
      border-bottom: 0.0625em solid #d8dfe5;
      display: flex;
      justify-content: center;
      align-items: center; }
      .matchmaking__calendar .matchmaking__day--date strong {
        font-weight: 700;
        color: #414a52; }
    .matchmaking__calendar .matchmaking__day--weekend {
      background-color: #f3f5f6; }
    .matchmaking__calendar .matchmaking__day--holiday {
      background-color: #fefbef; }
    .matchmaking__calendar .matchmaking__day__add {
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 1rem;
      color: #a0adb9;
      opacity: 0;
      transition: opacity 0.2s cubic-bezier(0.77, 0, 0.175, 1);
      width: calc(100% - 2em);
      height: calc(100% - 1em);
      margin: .5em 1em;
      padding: 0; }
      .matchmaking__calendar .matchmaking__day__add:before {
        font-family: "iconfont";
        font-style: normal;
        font-weight: 400;
        content: ""; }
      .matchmaking__calendar .matchmaking__day__add:hover {
        opacity: 1; }
      .matchmaking__calendar .matchmaking__day__add--float {
        position: absolute;
        top: -9px;
        right: -17px;
        background-color: #90c6de;
        width: 24px;
        height: 24px;
        z-index: +5;
        border-radius: 0.1875rem;
        color: white;
        box-shadow: 0px 1px 5px 0 rgba(0, 0, 0, 0.3); }
    .matchmaking__calendar .matchmaking__day--empty {
      border-right: 0; }
  .matchmaking__calendar .matchmaking__user {
    width: 20rem;
    padding: 1.125rem 0.9375rem 1.125rem 1.25rem;
    border-bottom: 0.0625em solid #d8dfe5;
    border-right: 0.0625em solid #d8dfe5;
    display: flex;
    justify-content: space-between;
    flex-grow: 0;
    flex-shrink: 0;
    align-items: center;
    font-weight: 300;
    font-size: 0.75rem;
    line-height: 1.125rem;
    color: #414a52;
    z-index: +10;
    background-color: #f7f8f9;
    position: sticky;
    left: 0;
    top: 0;
    box-shadow: 5px 0 20px 0 rgba(0, 0, 0, 0.05); }
    .matchmaking__calendar .matchmaking__user__title {
      text-transform: uppercase;
      font-size: 0.875rem;
      line-height: 1.25rem;
      font-weight: 700; }
    .matchmaking__calendar .matchmaking__user__infos {
      display: flex;
      align-items: center;
      padding-right: 0.75rem; }
    .matchmaking__calendar .matchmaking__user__image {
      border: 0.0625rem solid rgba(65, 74, 82, 0.3);
      border-radius: 50%;
      background-color: #d8dfe5;
      width: 1.875rem;
      height: 1.875rem;
      flex-grow: 0;
      flex-shrink: 0;
      margin-right: 0.75rem;
      background-position: center;
      background-size: cover;
      background-repeat: no-repeat; }
      .matchmaking__calendar .matchmaking__user__image--empty {
        color: #414a52;
        border-radius: 50%;
        font-size: 1.125rem;
        text-align: center;
        line-height: 1.6875rem;
        padding-left: .1em; }
        .matchmaking__calendar .matchmaking__user__image--empty:before {
          font-family: "iconfont";
          font-style: normal;
          font-weight: 400;
          content: ""; }
    .matchmaking__calendar .matchmaking__user__name {
      color: #414a52;
      display: flex;
      align-items: center; }
    .matchmaking__calendar .matchmaking__user__status {
      font-size: 1.5rem;
      display: flex;
      align-items: center; }
      .matchmaking__calendar .matchmaking__user__status--confirmed {
        color: #94d394; }
        .matchmaking__calendar .matchmaking__user__status--confirmed:before {
          font-family: "iconfont";
          font-style: normal;
          font-weight: 400;
          content: ""; }
      .matchmaking__calendar .matchmaking__user__status--pending {
        color: #f9b55a; }
        .matchmaking__calendar .matchmaking__user__status--pending:before {
          font-family: "iconfont";
          font-style: normal;
          font-weight: 400;
          content: ""; }
      .matchmaking__calendar .matchmaking__user__status--expired {
        color: #d8dfe5; }
        .matchmaking__calendar .matchmaking__user__status--expired:before {
          font-family: "iconfont";
          font-style: normal;
          font-weight: 400;
          content: ""; }
      .matchmaking__calendar .matchmaking__user__status--rejected {
        color: #f76b7e; }
        .matchmaking__calendar .matchmaking__user__status--rejected:before {
          font-family: "iconfont";
          font-style: normal;
          font-weight: 400;
          content: ""; }
    .matchmaking__calendar .matchmaking__user__actions {
      display: grid;
      column-gap: 0.625rem;
      grid-template-columns: auto auto 0;
      grid-template-areas: 'miniprofile pin .';
      white-space: nowrap; }
      .matchmaking__calendar .matchmaking__user__actions--proposal {
        grid-template-columns: auto auto 3.125rem 0;
        grid-template-areas: 'miniprofile pin tag .'; }
      .matchmaking__calendar .matchmaking__user__actions > * {
        display: inline-block;
        font-size: 1.4em; }
    .matchmaking__calendar .matchmaking__user__action {
      padding: 0 4px;
      color: #a0adb9;
      position: relative;
      transition: all ease-in-out 200ms; }
      .matchmaking__calendar .matchmaking__user__action--select {
        grid-area: pin; }
        .matchmaking__calendar .matchmaking__user__action--select:before {
          font-family: "iconfont";
          font-style: normal;
          font-weight: 400;
          content: ""; }
      .matchmaking__calendar .matchmaking__user__action--unselect {
        grid-area: pin; }
        .matchmaking__calendar .matchmaking__user__action--unselect:before {
          font-family: "iconfont";
          font-style: normal;
          font-weight: 400;
          content: ""; }
      .matchmaking__calendar .matchmaking__user__action--showProfile {
        grid-area: miniprofile; }
        .matchmaking__calendar .matchmaking__user__action--showProfile:before {
          font-family: "iconfont";
          font-style: normal;
          font-weight: 400;
          content: ""; }
      .matchmaking__calendar .matchmaking__user__action--hideProfile {
        position: relative;
        grid-area: miniprofile; }
        .matchmaking__calendar .matchmaking__user__action--hideProfile:before {
          font-family: "iconfont";
          font-style: normal;
          font-weight: 400;
          content: ""; }
        .matchmaking__calendar .matchmaking__user__action--hideProfile:after {
          content: "";
          background-color: #d8dfe5;
          border-radius: 50%;
          padding: 0.9375rem 0.9375rem;
          position: absolute;
          right: -4px;
          top: -0.4375rem;
          z-index: -1; }
      .matchmaking__calendar .matchmaking__user__action:hover {
        color: #4c4648; }
    .matchmaking__calendar .matchmaking__user__proximity-tag {
      cursor: default;
      grid-area: tag;
      padding: 0.75em 0.625em 0.375em;
      background-color: #414a52;
      border-radius: 0.375em;
      color: white;
      font-size: 0.625rem;
      line-height: 0.625rem;
      font-weight: 700;
      letter-spacing: 0;
      white-space: nowrap;
      display: inline-block;
      justify-self: flex-start; }
    .matchmaking__calendar .matchmaking__user--search {
      border-bottom: 0.0625em solid #d8dfe5;
      padding: 0.3125rem 0.625rem 0.3125rem 0.9375rem; }
      .matchmaking__calendar .matchmaking__user--search form {
        width: 100%;
        display: flex; }
      .matchmaking__calendar .matchmaking__user--search .field {
        padding: 0;
        background-color: transparent;
        flex-grow: 1; }
      .matchmaking__calendar .matchmaking__user--search .field__input {
        background-color: rgba(255, 255, 255, 0);
        border: 0;
        -webkit-appearance: none;
        -webkit-border-radius: 0; }
      .matchmaking__calendar .matchmaking__user--search .form__submit {
        text-indent: -9999px;
        white-space: nowrap;
        background-color: #90c6de; }
        .matchmaking__calendar .matchmaking__user--search .form__submit:before {
          font-family: "iconfont";
          font-style: normal;
          font-weight: 400;
          content: ""; }
        .matchmaking__calendar .matchmaking__user--search .form__submit:before {
          text-indent: 0;
          float: left; }
  .matchmaking__calendar .matchmaking__clinic__image {
    border: 0.0625rem solid rgba(65, 74, 82, 0.3);
    border-radius: 50%;
    background-color: #d8dfe5;
    width: 1.875rem;
    height: 1.875rem;
    flex-grow: 0;
    flex-shrink: 0;
    margin-right: 0.75rem;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat; }
    .matchmaking__calendar .matchmaking__clinic__image--empty {
      color: #414a52;
      border-radius: 50%;
      font-size: 1.125rem;
      text-align: center;
      line-height: 1.6875rem;
      padding-left: .1em; }
      .matchmaking__calendar .matchmaking__clinic__image--empty:before {
        font-family: "iconfont";
        font-style: normal;
        font-weight: 400;
        content: ""; }
  .matchmaking__calendar .matchmaking__clinic__relationship {
    border-radius: 50%;
    padding: 0.125rem;
    width: 2.5rem;
    height: 2.5rem;
    margin-right: 0.625rem; }
    .matchmaking__calendar .matchmaking__clinic__relationship--is-clinic-user {
      border: 3px solid #94d394; }
    .matchmaking__calendar .matchmaking__clinic__relationship--is-not-clinic-user {
      border: 3px solid transparent; }
    .matchmaking__calendar .matchmaking__clinic__relationship--is-blacklisted {
      border: 3px solid #f76b7e; }
  .matchmaking__calendar .matchmaking__clinic__status {
    font-size: 1.5rem;
    display: flex;
    align-items: center; }
    .matchmaking__calendar .matchmaking__clinic__status--confirmed, .matchmaking__calendar .matchmaking__clinic__status--pending_admin {
      color: #94d394; }
      .matchmaking__calendar .matchmaking__clinic__status--confirmed:before, .matchmaking__calendar .matchmaking__clinic__status--pending_admin:before {
        font-family: "iconfont";
        font-style: normal;
        font-weight: 400;
        content: ""; }
    .matchmaking__calendar .matchmaking__clinic__status--pending, .matchmaking__calendar .matchmaking__clinic__status--pending_clinic {
      color: #f9b55a; }
      .matchmaking__calendar .matchmaking__clinic__status--pending:before, .matchmaking__calendar .matchmaking__clinic__status--pending_clinic:before {
        font-family: "iconfont";
        font-style: normal;
        font-weight: 400;
        content: ""; }
    .matchmaking__calendar .matchmaking__clinic__status--expired {
      color: #d8dfe5; }
      .matchmaking__calendar .matchmaking__clinic__status--expired:before {
        font-family: "iconfont";
        font-style: normal;
        font-weight: 400;
        content: ""; }
    .matchmaking__calendar .matchmaking__clinic__status--rejected {
      color: #f76b7e; }
      .matchmaking__calendar .matchmaking__clinic__status--rejected:before {
        font-family: "iconfont";
        font-style: normal;
        font-weight: 400;
        content: ""; }
    .matchmaking__calendar .matchmaking__clinic__status--no-action {
      width: 1.5rem; }
  .matchmaking__calendar .matchmaking__mandate {
    margin: 0.7em;
    text-align: center;
    box-shadow: 0 3px 4px 0 rgba(0, 0, 0, 0.3);
    border-radius: 0.1875rem;
    padding: 0.6em;
    background-color: #fff;
    position: relative;
    width: calc(100% - 1.4em);
    height: 4.0625rem; }
    .matchmaking__calendar .matchmaking__mandate__wrapper {
      position: absolute;
      font-size: 0.6em;
      height: 0;
      width: 100%;
      margin: 0; }
    .matchmaking__calendar .matchmaking__mandate:after {
      content: "";
      position: absolute;
      right: -0.3em;
      top: 0;
      background-color: white;
      width: 0.8em;
      z-index: +1;
      height: 100%; }
    .matchmaking__calendar .matchmaking__mandate--start .matchmaking__tooltip {
      left: 0; }
      .matchmaking__calendar .matchmaking__mandate--start .matchmaking__tooltip > div:after {
        left: 25%; }
    .matchmaking__calendar .matchmaking__mandate--end .matchmaking__tooltip {
      left: -100%; }
      .matchmaking__calendar .matchmaking__mandate--end .matchmaking__tooltip > div:after {
        left: 75%; }
    .matchmaking__calendar .matchmaking__mandate--first {
      margin-right: 0;
      padding-right: 1.3em;
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
      width: calc(100% - 0.7em); }
    .matchmaking__calendar .matchmaking__mandate--middle {
      margin-left: -1px;
      margin-right: -1px;
      padding-left: 1.3em;
      padding-right: 1.3em;
      border-radius: 0;
      width: 100%; }
      .matchmaking__calendar .matchmaking__mandate--middle:after {
        right: -0.4em;
        width: 0.8em; }
    .matchmaking__calendar .matchmaking__mandate--last {
      margin-left: 0;
      padding-left: 1.3em;
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
      width: calc(100% - 0.7em); }
      .matchmaking__calendar .matchmaking__mandate--last:after {
        display: none; }
    .matchmaking__calendar .matchmaking__mandate__header {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      height: 1.625rem; }
    .matchmaking__calendar .matchmaking__mandate__count {
      font-weight: 700;
      color: #414a52;
      padding: 0 0.6em 0.3em;
      display: block; }
    .matchmaking__calendar .matchmaking__mandate__actions {
      width: 100%;
      text-align: right; }
    .matchmaking__calendar .matchmaking__mandate__action {
      font-size: 0.8125rem;
      padding: 0.3rem 0.2rem;
      color: #a0adb9;
      margin: 0; }
      .matchmaking__calendar .matchmaking__mandate__action:hover {
        color: #4c4648; }
      .matchmaking__calendar .matchmaking__mandate__action--delete:before {
        font-family: "iconfont";
        font-style: normal;
        font-weight: 400;
        content: ""; }
      .matchmaking__calendar .matchmaking__mandate__action--edit:before {
        font-family: "iconfont";
        font-style: normal;
        font-weight: 400;
        content: ""; }
    .matchmaking__calendar .matchmaking__mandate .matchmaking__event {
      padding: 0;
      margin: 0;
      box-shadow: none;
      border-bottom: 0; }
      .matchmaking__calendar .matchmaking__mandate .matchmaking__event .matchmaking__event__header {
        display: none; }
  .matchmaking__calendar .matchmaking__event {
    width: 100%;
    margin: 0.7em;
    font-size: 0.6em;
    text-align: center;
    border-bottom: 0.0625em solid #d8dfe5;
    background-color: #fff;
    box-shadow: 0 3px 4px 0 rgba(0, 0, 0, 0.075);
    border-radius: 0.1875rem;
    padding: 0.6em; }
    .matchmaking__calendar .matchmaking__event--disabled {
      opacity: 0.3;
      pointer-events: none; }
    .matchmaking__calendar .matchmaking__event--discarded .matchmaking__timerange {
      background: repeating-linear-gradient(35deg, transparent, transparent 4px, rgba(0, 0, 0, 0.07) 4px, rgba(0, 0, 0, 0.07) 8px), #d8dfe5; }
      .matchmaking__calendar .matchmaking__event--discarded .matchmaking__timerange button {
        border: none;
        padding: 0.8em 0.7em 0.6em; }
    .matchmaking__calendar .matchmaking__event--discarded .matchmaking__mandate__action--delete {
      pointer-events: initial; }
    .matchmaking__calendar .matchmaking__event__header {
      display: flex;
      justify-content: space-between;
      height: 1.625rem; }
    .matchmaking__calendar .matchmaking__event__icon {
      color: #a0adb9;
      font-size: 0.8125rem;
      padding: 0.3rem 0.1rem;
      display: inline-block; }
      .matchmaking__calendar .matchmaking__event__icon--consult:before {
        font-family: "iconfont";
        font-style: normal;
        font-weight: 400;
        content: ""; }
      .matchmaking__calendar .matchmaking__event__icon--empty {
        width: 1.125rem; }
      .matchmaking__calendar .matchmaking__event__icon--surgery {
        color: #f76b7e;
        opacity: 0.7; }
        .matchmaking__calendar .matchmaking__event__icon--surgery:before {
          font-family: "iconfont";
          font-style: normal;
          font-weight: 400;
          content: ""; }
        .matchmaking__calendar .matchmaking__event__icon--surgery--optional {
          color: #a0adb9;
          opacity: 1; }
      .matchmaking__calendar .matchmaking__event__icon--surgery-substitute {
        color: #a0adb9;
        opacity: 1; }
        .matchmaking__calendar .matchmaking__event__icon--surgery-substitute:before {
          font-family: "iconfont";
          font-style: normal;
          font-weight: 400;
          content: ""; }
      .matchmaking__calendar .matchmaking__event__icon--lodging:before {
        font-family: "iconfont";
        font-style: normal;
        font-weight: 400;
        content: ""; }
      .matchmaking__calendar .matchmaking__event__icon--message {
        justify-self: center; }
        .matchmaking__calendar .matchmaking__event__icon--message:before {
          font-family: "iconfont";
          font-style: normal;
          font-weight: 400;
          content: ""; }
      .matchmaking__calendar .matchmaking__event__icon--admin-note {
        justify-self: center; }
        .matchmaking__calendar .matchmaking__event__icon--admin-note:before {
          font-family: "iconfont";
          font-style: normal;
          font-weight: 400;
          content: ""; }
    .matchmaking__calendar .matchmaking__event__actions {
      display: flex; }
      .matchmaking__calendar .matchmaking__event__actions > div {
        padding: 0.1875rem; }
    .matchmaking__calendar .matchmaking__event__action {
      font-size: 0.8125rem;
      padding: 0.3rem 0.2rem;
      color: #a0adb9;
      margin: 0; }
      .matchmaking__calendar .matchmaking__event__action:hover {
        color: #4c4648; }
      .matchmaking__calendar .matchmaking__event__action--delete:before {
        font-family: "iconfont";
        font-style: normal;
        font-weight: 400;
        content: ""; }
      .matchmaking__calendar .matchmaking__event__action--edit:before {
        font-family: "iconfont";
        font-style: normal;
        font-weight: 400;
        content: ""; }
      .matchmaking__calendar .matchmaking__event__action--no-action {
        width: 1.5rem; }
    .matchmaking__calendar .matchmaking__event__unselect {
      background: #414a52;
      color: white;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: +1;
      padding-top: 0.6em; }
      .matchmaking__calendar .matchmaking__event__unselect:before {
        font-family: "iconfont";
        font-style: normal;
        font-weight: 400;
        content: ""; }
    .matchmaking__calendar .matchmaking__event__select {
      font-size: 0.6875rem;
      line-height: 0.75rem;
      width: 100%;
      border: 0.05em solid #3a434a;
      padding: 0.75em 0.65em 0.55em;
      margin: 0;
      color: #414a52;
      position: relative;
      font-weight: 700;
      letter-spacing: 0.05em; }
    .matchmaking__calendar .matchmaking__event:hover .matchmaking__tooltip {
      display: block; }
  .matchmaking__calendar .matchmaking__timerange {
    text-align: center;
    border-radius: 0.2em;
    position: relative;
    overflow: hidden;
    font-weight: 700;
    display: flex;
    justify-content: center;
    cursor: default; }
    .matchmaking__calendar .matchmaking__timerange div {
      font-size: 0.6875rem;
      line-height: 0.75rem;
      padding: 0.8em 0.7em 0.6em;
      letter-spacing: 0.05em; }
    .matchmaking__calendar .matchmaking__timerange span {
      color: #414a52;
      white-space: nowrap;
      font-size: 0.75em; }
    .matchmaking__calendar .matchmaking__timerange--bordered:after {
      content: "";
      width: 100%;
      position: absolute;
      bottom: 0;
      left: 0;
      height: 0.1875rem;
      background-color: #414a52; }
    .matchmaking__calendar .matchmaking__timerange--call-to-all:after {
      content: "";
      width: 100%;
      position: absolute;
      bottom: 0;
      left: 0;
      height: 0.1875rem;
      background-color: #f5e067; }
    .matchmaking__calendar .matchmaking__timerange--canceled-call-to-all:after {
      content: "";
      width: 100%;
      position: absolute;
      bottom: 0;
      left: 0;
      height: 0.1875rem;
      background-color: #f76b7e; }
    .matchmaking__calendar .matchmaking__timerange__icon {
      position: absolute;
      padding-right: 0.4375em; }
      .matchmaking__calendar .matchmaking__timerange__icon:before {
        font-family: "iconfont";
        font-style: normal;
        font-weight: 400;
        content: ""; }
      .matchmaking__calendar .matchmaking__timerange__icon:before {
        position: relative;
        top: -0.07em; }
      .matchmaking__calendar .matchmaking__timerange__icon + span {
        padding-left: 1.6em; }
    .matchmaking__calendar .matchmaking__timerange--rejected {
      background: #f76b7e; }
    .matchmaking__calendar .matchmaking__timerange--expired {
      background: #f5e067; }
    .matchmaking__calendar .matchmaking__timerange--confirmed {
      background: #94d394; }
    .matchmaking__calendar .matchmaking__timerange--replacement, .matchmaking__calendar .matchmaking__timerange--availability {
      background: white; }
      .matchmaking__calendar .matchmaking__timerange--replacement:not(.matchmaking__timerange--matchable), .matchmaking__calendar .matchmaking__timerange--availability:not(.matchmaking__timerange--matchable) {
        border: 0.1em solid #d8dfe5; }
        .matchmaking__calendar .matchmaking__timerange--replacement:not(.matchmaking__timerange--matchable) div, .matchmaking__calendar .matchmaking__timerange--availability:not(.matchmaking__timerange--matchable) div {
          padding: 0.7em 0.6em 0.5em; }
      .matchmaking__calendar .matchmaking__timerange--replacement.matchmaking__timerange--active, .matchmaking__calendar .matchmaking__timerange--availability.matchmaking__timerange--active {
        border-color: #414a52; }
    .matchmaking__calendar .matchmaking__timerange--pending {
      background: #f9b55a; }
    .matchmaking__calendar .matchmaking__timerange--pending_clinic {
      background: repeating-linear-gradient(35deg, transparent, transparent 4px, rgba(255, 255, 255, 0.25) 4px, rgba(255, 255, 255, 0.25) 8px), #f9b55a; }
    .matchmaking__calendar .matchmaking__timerange--pending_admin {
      background: repeating-linear-gradient(35deg, transparent, transparent 4px, rgba(255, 255, 255, 0.25) 4px, rgba(255, 255, 255, 0.25) 8px), #94d394; }
    .matchmaking__calendar .matchmaking__timerange--active {
      border-color: #414a52; }
  .matchmaking__calendar .matchmaking__tooltip {
    display: none;
    left: -45%;
    margin: 0 auto;
    max-width: 15.625rem;
    min-width: 15.625rem;
    padding-top: 2em;
    position: absolute;
    right: 0;
    top: calc(100% - 0.7em);
    width: calc(150% - 1.2em);
    z-index: +10; }
    .matchmaking__calendar .matchmaking__tooltip__proposals {
      height: auto;
      border-top: 0.0625em solid #d8dfe5; }
    .matchmaking__calendar .matchmaking__tooltip__date {
      font-size: 0.9375rem;
      color: #414a52;
      font-weight: 700;
      text-transform: uppercase;
      letter-spacing: .05em;
      display: flex;
      align-items: center; }
    .matchmaking__calendar .matchmaking__tooltip__header {
      text-align: left;
      padding: 1.125rem 1.125rem 0.875rem;
      border-bottom: none;
      display: flex;
      justify-content: space-between;
      align-items: center; }
      .matchmaking__calendar .matchmaking__tooltip__header + .matchmaking__tooltip__footer {
        border-top: none; }
    .matchmaking__calendar .matchmaking__tooltip__footer {
      text-align: left;
      padding: 1.125rem 1.125rem 1.125rem;
      border-top: 0.0625em solid #d8dfe5;
      display: flex;
      justify-content: center;
      flex-wrap: wrap; }
      .matchmaking__calendar .matchmaking__tooltip__footer .matchmaking__event__message,
      .matchmaking__calendar .matchmaking__tooltip__footer .matchmaking__event__admin-note {
        display: flex;
        color: #414a52;
        flex-direction: column;
        font-size: 0.6875rem;
        font-weight: 300;
        line-height: 1.25rem;
        white-space: pre-wrap;
        width: 100%; }
      .matchmaking__calendar .matchmaking__tooltip__footer .matchmaking__event__icon {
        align-self: center;
        justify-content: center; }
      .matchmaking__calendar .matchmaking__tooltip__footer--dates {
        color: #a0adb9; }
        .matchmaking__calendar .matchmaking__tooltip__footer--dates span {
          margin: 0.125rem 0;
          font-size: 0.5625rem; }
    .matchmaking__calendar .matchmaking__tooltip li {
      padding: 0.75rem 1.125rem;
      background-color: rgba(65, 74, 82, 0.05);
      border-top: 0.0625em solid #d8dfe5;
      display: flex;
      justify-content: space-between;
      align-items: center;
      text-align: left; }
      .matchmaking__calendar .matchmaking__tooltip li:first-child {
        border-top: none; }
    .matchmaking__calendar .matchmaking__tooltip .matchmaking__user__name {
      display: block;
      font-size: 0.6875rem;
      line-height: 1rem;
      font-weight: 700;
      letter-spacing: .05em; }
      .matchmaking__calendar .matchmaking__tooltip .matchmaking__user__name .date {
        font-size: 0.625rem;
        font-weight: 300;
        text-transform: uppercase;
        display: block;
        margin-top: 0.1875rem; }
    .matchmaking__calendar .matchmaking__tooltip > div {
      background-color: white;
      border-left: 0.0625em solid #d8dfe5;
      border-right: 0.0625em solid #d8dfe5;
      border-bottom: 0.0625em solid #d8dfe5;
      border-radius: 0.1875rem;
      box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.2);
      position: relative; }
      .matchmaking__calendar .matchmaking__tooltip > div:after {
        content: " ";
        position: absolute;
        bottom: 100%;
        left: 50%;
        margin-left: -7px;
        border-width: 7px;
        border-style: solid;
        border-color: transparent transparent white transparent; }
    .matchmaking__calendar .matchmaking__tooltip__action {
      background: #414a52;
      color: #fff;
      padding: 1.0625em 1.125em 0.875em;
      text-transform: uppercase;
      font-size: 0.6875em;
      line-height: 1.0625em;
      font-weight: 700;
      border: none;
      cursor: pointer;
      border-radius: 0.1875em;
      outline: none;
      letter-spacing: 0.8px;
      font-size: 0.75rem;
      margin: 0; }
      .matchmaking__calendar .matchmaking__tooltip__action:hover {
        background-color: #384047;
        color: #fff; }
      .matchmaking__calendar .matchmaking__tooltip__action[disabled] {
        opacity: 0.4;
        background: #414a52;
        cursor: default;
        color: #fff; }
      .matchmaking__calendar .matchmaking__tooltip__action--full {
        width: 100%; }
  .matchmaking__calendar__pagination {
    width: 20rem;
    padding: 1.125rem 0.9375rem 1.125rem 1.25rem;
    border-bottom: 0.0625em solid #d8dfe5;
    border-right: 0.0625em solid #d8dfe5;
    display: flex;
    justify-content: center;
    flex-grow: 0;
    flex-shrink: 0;
    align-items: center;
    z-index: +10;
    background-color: #f7f8f9;
    position: -webkit-sticky;
    position: sticky;
    left: 0;
    top: 0;
    box-shadow: 5px 0 20px 0 rgba(0, 0, 0, 0.05); }

.matchmaking__proposals {
  position: fixed;
  bottom: 0;
  background-color: white;
  width: calc(100% - 70px);
  box-shadow: 0 0 15px 5px rgba(0, 0, 0, 0.2);
  height: 30vh;
  z-index: +5; }
  .matchmaking__proposals .side-panel__form {
    height: 100%;
    display: flex;
    flex-direction: column;
    padding-bottom: 0; }
  .matchmaking__proposals__header {
    padding: 0.75rem 1.25rem;
    width: 100%;
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.25);
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
    z-index: +1; }
    .matchmaking__proposals__header h2 {
      text-transform: uppercase;
      color: #403b42; }
      .matchmaking__proposals__header h2:before {
        font-family: "iconfont";
        font-style: normal;
        font-weight: 400;
        content: ""; }
      .matchmaking__proposals__header h2:before {
        margin-right: .5em;
        color: #f9b55a;
        font-size: 1.25em;
        position: relative;
        top: .15em; }
  .matchmaking__proposals__action:last-child {
    margin-left: .8em; }
  .matchmaking__proposals__action--cancel {
    background: #f76b7e;
    color: #fff;
    padding: 1.0625em 1.125em 0.875em;
    text-transform: uppercase;
    font-size: 0.6875em;
    line-height: 1.0625em;
    font-weight: 700;
    border: none;
    cursor: pointer;
    border-radius: 0.1875em;
    outline: none;
    letter-spacing: 0.8px; }
    .matchmaking__proposals__action--cancel:hover {
      background-color: #f6586d;
      color: #fff; }
    .matchmaking__proposals__action--cancel[disabled] {
      opacity: 0.4;
      background: #f76b7e;
      cursor: default;
      color: #fff; }
  .matchmaking__proposals__action--submit {
    background: #414a52;
    color: #fff;
    padding: 1.0625em 1.125em 0.875em;
    text-transform: uppercase;
    font-size: 0.6875em;
    line-height: 1.0625em;
    font-weight: 700;
    border: none;
    cursor: pointer;
    border-radius: 0.1875em;
    outline: none;
    letter-spacing: 0.8px; }
    .matchmaking__proposals__action--submit:hover {
      background-color: #384047;
      color: #fff; }
    .matchmaking__proposals__action--submit[disabled] {
      opacity: 0.4;
      background: #414a52;
      cursor: default;
      color: #fff; }
  .matchmaking__proposals__content {
    min-height: 0;
    height: 100%;
    max-width: 100%;
    display: flex;
    align-items: stretch; }
    .matchmaking__proposals__content > div {
      padding: 1.125rem 1.25rem;
      overflow: scroll;
      min-width: 100%; }
      .matchmaking__proposals__content > div > div {
        display: flex;
        flex-direction: row;
        align-items: flex-start; }
  .matchmaking__proposals__clinic {
    flex-grow: 0;
    flex-shrink: 0;
    padding: 0.6em 1em;
    background-color: white;
    border-left: 0.25rem solid #f9b55a;
    border-radius: 0.1875rem;
    box-shadow: 2px 1px 3px 1px rgba(0, 0, 0, 0.25);
    margin: 0 0.9375rem;
    width: 18.75rem; }
    .matchmaking__proposals__clinic:first-child {
      margin-left: 0; }
    .matchmaking__proposals__clinic:last-child {
      margin-right: 0; }
    .matchmaking__proposals__clinic .clinic__wrapper {
      display: flex;
      justify-content: space-between;
      align-items: center; }
    .matchmaking__proposals__clinic .prices__wrapper {
      align-items: center;
      border-top: 1px solid #d8dfe5;
      display: flex;
      flex-direction: row;
      height: 3.125rem;
      position: relative; }
    .matchmaking__proposals__clinic .prices__wrapper .price__fields__wrapper {
      display: flex;
      justify-content: space-between;
      margin: 0 auto;
      width: 75%; }
  .matchmaking__proposals .prices__clinic__icon {
    color: #a0adb9;
    font-size: 1rem;
    padding: 0 0.25rem 0 0;
    position: relative;
    top: 4px; }
    .matchmaking__proposals .prices__clinic__icon--lock {
      font-size: 0.6875rem;
      position: absolute;
      top: calc(50% - 5px); }
      .matchmaking__proposals .prices__clinic__icon--lock:before {
        font-family: "iconfont";
        font-style: normal;
        font-weight: 400;
        content: ""; }
    .matchmaking__proposals .prices__clinic__icon--unlock {
      font-size: 0.6875rem;
      font-weight: 700;
      position: absolute;
      top: calc(50% - 5px); }
      .matchmaking__proposals .prices__clinic__icon--unlock:before {
        font-family: "iconfont";
        font-style: normal;
        font-weight: 400;
        content: ""; }
    .matchmaking__proposals .prices__clinic__icon--clinic:before {
      font-family: "iconfont";
      font-style: normal;
      font-weight: 400;
      content: ""; }
    .matchmaking__proposals .prices__clinic__icon--substitute:before {
      font-family: "iconfont";
      font-style: normal;
      font-weight: 400;
      content: ""; }
  .matchmaking__proposals .clinic__name {
    color: #414a52;
    padding: .6em 0; }
  .matchmaking__proposals .price__field .field__error {
    display: none; }
  .matchmaking__proposals .price__field input {
    border: 1px solid #d8dfe5;
    color: #414a52;
    font-size: 0.75rem;
    height: 1.5625rem;
    text-align: center;
    width: 3.75rem; }
    .matchmaking__proposals .price__field input.field__input.field--invalid {
      border: 1px solid #f76b7e; }
    .matchmaking__proposals .price__field input[disabled] {
      color: #a0adb9; }
    .matchmaking__proposals .price__field input[type=number]::-webkit-inner-spin-button, .matchmaking__proposals .price__field input[type=number]::-webkit-outer-spin-button {
      -webkit-appearance: none;
      margin: 0; }
  .matchmaking__proposals .clinic__proposal {
    font-size: 0.875rem;
    font-weight: 300;
    padding: 0.78125rem 0;
    width: 100%;
    color: #414a52;
    border-top: 1px solid #d8dfe5;
    display: flex;
    align-items: center;
    justify-content: space-between; }
    .matchmaking__proposals .clinic__proposal__infos {
      display: flex;
      flex-grow: 1;
      flex-wrap: wrap;
      align-items: center;
      -webkit-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none; }
      .matchmaking__proposals .clinic__proposal__infos > * {
        margin-right: 1em; }
    .matchmaking__proposals .clinic__proposal__actions {
      white-space: nowrap; }
    .matchmaking__proposals .clinic__proposal__action {
      color: #a0adb9;
      font-size: 1rem;
      padding: 0; }
      .matchmaking__proposals .clinic__proposal__action:last-child {
        margin-left: 0.875em; }
      .matchmaking__proposals .clinic__proposal__action:hover {
        color: #414a52; }
      .matchmaking__proposals .clinic__proposal__action--edit:before {
        font-family: "iconfont";
        font-style: normal;
        font-weight: 400;
        content: ""; }
      .matchmaking__proposals .clinic__proposal__action--delete:before {
        font-family: "iconfont";
        font-style: normal;
        font-weight: 400;
        content: ""; }
    .matchmaking__proposals .clinic__proposal__date {
      background-color: #f9b55a;
      width: 2.5rem;
      text-transform: uppercase;
      font-size: 0.5em;
      text-align: center;
      padding: 0.625em 0;
      border-radius: 0.3125em; }
      .matchmaking__proposals .clinic__proposal__date--rejected {
        color: #f76b7e; }
      .matchmaking__proposals .clinic__proposal__date--replacement {
        color: #f9b55a; }
      .matchmaking__proposals .clinic__proposal__date--expired {
        color: #f5e067; }
      .matchmaking__proposals .clinic__proposal__date span.date {
        font-size: 1rem;
        display: block;
        padding-top: 0.3125em;
        font-weight: 600; }
      .matchmaking__proposals .clinic__proposal__date .month {
        padding-top: 0.25em; }
    .matchmaking__proposals .clinic__proposal__hours {
      font-weight: 700;
      white-space: nowrap; }
    .matchmaking__proposals .clinic__proposal__type .type__icon {
      font-size: .8em;
      display: block;
      padding: 0.125em 0;
      color: #a0adb9; }
      .matchmaking__proposals .clinic__proposal__type .type__icon--priority:before {
        font-family: "iconfont";
        font-style: normal;
        font-weight: 400;
        content: ""; }
      .matchmaking__proposals .clinic__proposal__type .type__icon--flexible:before {
        font-family: "iconfont";
        font-style: normal;
        font-weight: 400;
        content: ""; }
      .matchmaking__proposals .clinic__proposal__type .type__icon--active {
        color: #414a52; }
    .matchmaking__proposals .clinic__proposal__message {
      flex-grow: inherit;
      order: 4; }
      .matchmaking__proposals .clinic__proposal__message .field.message__field {
        display: none;
        width: 100%; }
        .matchmaking__proposals .clinic__proposal__message .field.message__field--show {
          display: inline-block; }
        .matchmaking__proposals .clinic__proposal__message .field.message__field input {
          border: 0.0625rem solid #d8dfe5;
          color: #414a52;
          font-size: 0.75rem;
          margin: -0.3125rem 0 0.0625rem; }
          .matchmaking__proposals .clinic__proposal__message .field.message__field input.field__input.field--invalid {
            border: 0.0625rem solid #f76b7e; }
    .matchmaking__proposals .clinic__proposal__action--delete {
      padding: 0 0.2em;
      color: #a0adb9;
      position: relative;
      top: -0.0625rem;
      margin-left: 1em; }
      .matchmaking__proposals .clinic__proposal__action--delete:before {
        font-family: "iconfont";
        font-style: normal;
        font-weight: 400;
        content: ""; }
    .matchmaking__proposals .clinic__proposal__action--message {
      padding: 0 0.2em;
      color: #a0adb9;
      position: relative;
      margin-left: 1em; }
      .matchmaking__proposals .clinic__proposal__action--message:before {
        font-family: "iconfont";
        font-style: normal;
        font-weight: 400;
        content: ""; }
      .matchmaking__proposals .clinic__proposal__action--message:after {
        content: "+";
        position: absolute;
        background: #94d394;
        border-radius: 100%;
        color: #ffffff;
        font-size: .85em;
        height: 0.05em;
        line-height: 0.25;
        padding: 0.45em 0.08em;
        right: -0.25em;
        top: -0.4em;
        width: 0.75em; }
    .matchmaking__proposals .clinic__proposal .arrow {
      font-size: 80%;
      color: #a3a0a0; }
      .matchmaking__proposals .clinic__proposal .arrow:before {
        font-family: "iconfont";
        font-style: normal;
        font-weight: 400;
        content: ""; }
    .matchmaking__proposals .clinic__proposal .field {
      padding: 0; }
  .matchmaking__proposals .field {
    margin: 0;
    padding: 0.5em 0; }
  .matchmaking__proposals .field__checkbox__label {
    margin-right: 0;
    margin-left: 0; }
    .matchmaking__proposals .field__checkbox__label:before {
      top: 0;
      margin-right: 0; }
    .matchmaking__proposals .field__checkbox__label span {
      display: none; }
  .matchmaking__proposals .tooltip__wrapper {
    position: relative;
    display: inline-block; }
    .matchmaking__proposals .tooltip__wrapper .tooltip {
      bottom: 100%;
      right: 70%; }

.tooltip__wrapper {
  position: relative; }
  .tooltip__wrapper:hover .tooltip {
    display: block; }
  .tooltip__wrapper .tooltip {
    position: absolute;
    font-size: 0.625rem;
    bottom: calc(100% + 0.3625rem);
    z-index: 2;
    right: 50%;
    padding: 0.4375rem;
    border-radius: 0.125rem;
    background-color: #414a52;
    text-align: center;
    line-height: 1.125em;
    display: none;
    color: #fff;
    white-space: nowrap; }
    .tooltip__wrapper .tooltip--timesheet-message {
      right: 100%;
      bottom: calc(100% + 0.5rem);
      white-space: normal;
      width: max-content;
      max-width: 50vw; }
    .tooltip__wrapper .tooltip:before {
      content: "";
      width: 0;
      height: 0;
      border-left: 0.625rem solid transparent;
      border-top: 0.625rem solid #414a52;
      position: absolute;
      bottom: -0.5625rem;
      right: 0; }
  .tooltip__wrapper--email .field {
    width: 100%;
    position: relative; }
    .tooltip__wrapper--email .field:before {
      font-family: "iconfont";
      font-style: normal;
      font-weight: 400;
      content: ""; }
    .tooltip__wrapper--email .field:before {
      position: absolute;
      height: 1.125rem;
      top: calc(50% - 9px);
      right: 0.625em;
      color: #a0adb9; }
  .tooltip__wrapper--email .tooltip {
    font-size: 0.75rem;
    line-height: 1rem;
    letter-spacing: 0.05em;
    white-space: normal;
    max-width: calc(100% - 1.875rem);
    bottom: calc( 100% - 1.25rem);
    right: 1.5em; }
    .tooltip__wrapper--email .tooltip a {
      color: #90c6de; }
    .tooltip__wrapper--email .tooltip a:hover {
      color: #f9b55a; }
