.alert-message {
  border-radius: 0.125em;
  margin-bottom: 0.625em;
  position: relative;
  max-width: 300px;
  overflow: hidden;
  opacity: 1;
  animation: 500ms ease-in-alert; }
  .alert-message__wrapper {
    position: fixed;
    bottom: 0.9375rem;
    left: 0.9375rem;
    z-index: 900; }
  .alert-message--error {
    background-color: #f76b7e; }
  .alert-message--notification {
    background-color: #90c6de; }
  .alert-message--success {
    background-color: #94d394; }
  .alert-message.--hidden {
    animation: 500ms ease-out-alert;
    opacity: 0; }
  .alert-message > div {
    padding: 0.9375rem 0.625rem 0.625rem 1.25rem;
    font-size: 0.875em; }
    .alert-message > div:before {
      content: "";
      display: block;
      width: 0.1875em;
      background-color: #414a52;
      position: absolute;
      top: 0.625em;
      bottom: 0.625em;
      left: 0.625em; }

@keyframes ease-in-alert {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

@keyframes ease-out-alert {
  from {
    opacity: 1; }
  to {
    opacity: 0; } }
