@charset "UTF-8";
@font-face {
  font-family: "iconfont";
  src: url("/iconfont.eot");
  /* IE9 Compat Modes */
  src: url("/iconfont.eot?#iefix") format("embedded-opentype"), url("/iconfont.woff") format("woff"), url("/iconfont.ttf") format("truetype"), url("/iconfont.svg") format("svg");
  /* Legacy iOS */ }

.icon {
  font-style: normal;
  font-weight: 400; }
  .icon.accordion-close:before {
    font-family: "iconfont";
    content: ""; }
  .icon.accordion-open:before {
    font-family: "iconfont";
    content: ""; }
  .icon.add-folder:before {
    font-family: "iconfont";
    content: ""; }
  .icon.add:before {
    font-family: "iconfont";
    content: ""; }
  .icon.admin:before {
    font-family: "iconfont";
    content: ""; }
  .icon.all-day:before {
    font-family: "iconfont";
    content: ""; }
  .icon.arrow-left:before {
    font-family: "iconfont";
    content: ""; }
  .icon.arrow-right:before {
    font-family: "iconfont";
    content: ""; }
  .icon.assignment:before {
    font-family: "iconfont";
    content: ""; }
  .icon.association-clinic:before {
    font-family: "iconfont";
    content: ""; }
  .icon.association:before {
    font-family: "iconfont";
    content: ""; }
  .icon.bars:before {
    font-family: "iconfont";
    content: ""; }
  .icon.calendar-2:before {
    font-family: "iconfont";
    content: ""; }
  .icon.calendar-today:before {
    font-family: "iconfont";
    content: ""; }
  .icon.calendar:before {
    font-family: "iconfont";
    content: ""; }
  .icon.call-list:before {
    font-family: "iconfont";
    content: ""; }
  .icon.checkbox-off:before {
    font-family: "iconfont";
    content: ""; }
  .icon.checkbox-on:before {
    font-family: "iconfont";
    content: ""; }
  .icon.checkbox-square:before {
    font-family: "iconfont";
    content: ""; }
  .icon.circle-add:before {
    font-family: "iconfont";
    content: ""; }
  .icon.circle-check:before {
    font-family: "iconfont";
    content: ""; }
  .icon.circle-info:before {
    font-family: "iconfont";
    content: ""; }
  .icon.circlo-close:before {
    font-family: "iconfont";
    content: ""; }
  .icon.clinic:before {
    font-family: "iconfont";
    content: ""; }
  .icon.close-slim:before {
    font-family: "iconfont";
    content: ""; }
  .icon.close:before {
    font-family: "iconfont";
    content: ""; }
  .icon.closed-folder:before {
    font-family: "iconfont";
    content: ""; }
  .icon.copy:before {
    font-family: "iconfont";
    content: ""; }
  .icon.delete:before {
    font-family: "iconfont";
    content: ""; }
  .icon.disconnect:before {
    font-family: "iconfont";
    content: ""; }
  .icon.dotted-arrow-right:before {
    font-family: "iconfont";
    content: ""; }
  .icon.dotted-arrow:before {
    font-family: "iconfont";
    content: ""; }
  .icon.edit:before {
    font-family: "iconfont";
    content: ""; }
  .icon.email:before {
    font-family: "iconfont";
    content: ""; }
  .icon.featured:before {
    font-family: "iconfont";
    content: ""; }
  .icon.handle-h:before {
    font-family: "iconfont";
    content: ""; }
  .icon.handle-v:before {
    font-family: "iconfont";
    content: ""; }
  .icon.help-circle:before {
    font-family: "iconfont";
    content: ""; }
  .icon.help:before {
    font-family: "iconfont";
    content: ""; }
  .icon.history:before {
    font-family: "iconfont";
    content: ""; }
  .icon.info:before {
    font-family: "iconfont";
    content: ""; }
  .icon.lock-open:before {
    font-family: "iconfont";
    content: ""; }
  .icon.lock:before {
    font-family: "iconfont";
    content: ""; }
  .icon.lodging:before {
    font-family: "iconfont";
    content: ""; }
  .icon.magnifying-glass:before {
    font-family: "iconfont";
    content: ""; }
  .icon.map:before {
    font-family: "iconfont";
    content: ""; }
  .icon.marker:before {
    font-family: "iconfont";
    content: ""; }
  .icon.notifications:before {
    font-family: "iconfont";
    content: ""; }
  .icon.open-folder:before {
    font-family: "iconfont";
    content: ""; }
  .icon.pencil:before {
    font-family: "iconfont";
    content: ""; }
  .icon.piechart:before {
    font-family: "iconfont";
    content: ""; }
  .icon.pin:before {
    font-family: "iconfont";
    content: ""; }
  .icon.profile:before {
    font-family: "iconfont";
    content: ""; }
  .icon.schedule:before {
    font-family: "iconfont";
    content: ""; }
  .icon.settings:before {
    font-family: "iconfont";
    content: ""; }
  .icon.small-down-arrow:before {
    font-family: "iconfont";
    content: ""; }
  .icon.small-up-arrow:before {
    font-family: "iconfont";
    content: ""; }
  .icon.status-check:before {
    font-family: "iconfont";
    content: ""; }
  .icon.surgery-optional:before {
    font-family: "iconfont";
    content: ""; }
  .icon.surgery:before {
    font-family: "iconfont";
    content: ""; }
  .icon.time:before {
    font-family: "iconfont";
    content: ""; }
  .icon.timesheet:before {
    font-family: "iconfont";
    content: ""; }
  .icon.triangle:before {
    font-family: "iconfont";
    content: ""; }
  .icon.tsa:before {
    font-family: "iconfont";
    content: ""; }
  .icon.validation:before {
    font-family: "iconfont";
    content: ""; }
  .icon.vet:before {
    font-family: "iconfont";
    content: ""; }

.primary-nav {
  height: 100vh;
  position: fixed;
  z-index: 100;
  top: 0;
  left: 0;
  background-color: #414a52;
  max-width: 4.375rem;
  min-width: 0;
  overflow: hidden;
  transition: all 0.25s cubic-bezier(0.77, 0, 0.175, 1); }
  @media only screen and (max-width: 38rem) {
    .primary-nav {
      max-width: 0;
      transition: all 0.25s linear;
      z-index: 80; } }
  .primary-nav--open {
    max-width: 100vw;
    min-width: 21.875rem;
    box-shadow: 1px 0px 2px 0px rgba(0, 0, 0, 0.4); }
    @media only screen and (max-width: 38rem) {
      .primary-nav--open {
        max-width: 100%;
        width: 100%; } }
  .primary-nav__head {
    background-color: #90c6de;
    height: 4.375rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    max-width: 100%; }
    @media only screen and (max-width: 38rem) {
      .primary-nav__head {
        display: none; } }
    .primary-nav__head > * {
      flex-shrink: 0; }
    .primary-nav__head .primary-nav__vetreseau {
      width: 100%;
      flex-shrink: 1;
      line-height: 0; }
      .primary-nav__head .primary-nav__vetreseau img {
        height: 1.25rem;
        width: auto;
        display: inline-block; }
  .primary-nav__logo a {
    width: 4.375rem;
    height: 4.375rem;
    display: flex;
    align-items: center; }
  .primary-nav__logo img {
    width: 2.625rem;
    height: 2.625rem;
    display: block;
    margin: 0 auto; }
  .primary-nav__env__badge {
    background-color: #f76b7e;
    color: #fff;
    display: inline-block;
    padding: .25em .4em;
    position: absolute;
    font-size: 75%;
    font-weight: 700;
    left: 0.5em;
    line-height: 1;
    text-align: center;
    top: 0.5em;
    white-space: nowrap;
    vertical-align: baseline;
    border-radius: .25rem; }
  .primary-nav__close {
    border: none;
    height: 4.375rem;
    width: 4.375rem;
    background: none;
    cursor: pointer;
    outline: none;
    position: relative;
    display: flex;
    justify-content: center; }
    @media only screen and (max-width: 38rem) {
      .primary-nav__close {
        position: fixed;
        left: 4.375rem;
        top: 0;
        z-index: 100; } }
    .primary-nav__close:hover > span {
      width: 1.5625rem; }
    .primary-nav__close > span {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 0.125rem;
      width: 1.25rem;
      background-color: #fff;
      position: absolute;
      transition: all 0.15s cubic-bezier(0.77, 0, 0.175, 1);
      top: calc(50% - 0.06rem); }
      .primary-nav__close > span:first-child {
        transform: rotate(45deg); }
      .primary-nav__close > span:last-child {
        transform: rotate(-45deg); }
  @media only screen and (max-width: 38rem) {
    .primary-nav__lists {
      padding-top: 4.375rem; } }
  .primary-nav__lists > ul {
    padding: 1.875em 0.9375em 0; }
    .primary-nav__lists > ul.primary-nav__lists__main {
      padding-bottom: 1.875em;
      background-color: #3a434a; }
  .primary-nav__lists a {
    display: flex;
    align-items: center;
    cursor: pointer;
    font-size: 0.75em;
    line-height: 0.75em;
    text-transform: uppercase;
    white-space: nowrap;
    color: #a0adb9;
    padding: 0.3125rem 0 0.25rem;
    border-radius: 0.1875rem;
    transition: color 0.15s cubic-bezier(0.77, 0, 0.175, 1); }
    .primary-nav__lists a:hover {
      color: white; }
      .primary-nav__lists a:hover .primary-nav__icon:before {
        font-size: 1.5rem; }
    .primary-nav__lists a.active {
      background-color: rgba(160, 173, 185, 0.2);
      color: white; }
  .primary-nav__lists button {
    display: flex;
    align-items: center;
    cursor: pointer;
    font-size: 0.75em;
    text-transform: uppercase;
    white-space: nowrap;
    color: #a0adb9;
    padding: 0.3125rem 0;
    width: 100%;
    border-radius: 0.1875rem;
    transition: color 0.15s cubic-bezier(0.77, 0, 0.175, 1); }
    .primary-nav__lists button:hover {
      color: white;
      cursor: pointer; }
      .primary-nav__lists button:hover .primary-nav__icon:before {
        font-size: 1.5rem; }
    .primary-nav__lists button.active {
      color: white;
      background-color: rgba(160, 173, 185, 0.2); }
  .primary-nav__lists__sorting {
    position: relative; }
    .primary-nav__lists__sorting:last-child {
      margin-top: 30px; }
    .primary-nav__lists__sorting:last-child:before {
      content: '';
      display: block;
      background-color: #403b42;
      height: 0.0625rem;
      border-radius: 0.125rem;
      width: calc(100% - (0.9375em * 2));
      position: absolute;
      top: 0;
      left: 0.9375em; }
    .primary-nav__lists__sorting li {
      margin-bottom: 0.875em; }
  .primary-nav__lists__sorting--dotted li {
    position: relative; }
    .primary-nav__lists__sorting--dotted li:after {
      position: absolute;
      content: "...";
      width: 5px;
      color: rgba(163, 160, 160, 0.8);
      display: block;
      transform: rotate(90deg);
      font-size: 1.5em;
      bottom: -0.475em;
      left: 0.86em;
      letter-spacing: -0.08125rem; }
    .primary-nav__lists__sorting--dotted li:last-child:after {
      display: none; }
  .primary-nav__lists__main a {
    padding: 0.3125rem 0;
    color: #fff;
    border-radius: 0.1875rem; }
    .primary-nav__lists__main a:hover {
      color: #fff; }
    .primary-nav__lists__main a.active {
      background-color: rgba(160, 173, 185, 0.2); }
  .primary-nav .account__types li:first-of-type {
    margin-bottom: 0.625rem; }
  .primary-nav__icon {
    display: flex;
    align-items: center;
    text-align: center;
    justify-content: center;
    width: 1.875rem;
    height: 1.875rem;
    flex-shrink: 0;
    margin-right: 1.5625rem;
    margin-left: 0.3125rem; }
    .primary-nav__icon:before {
      font-size: 1.25rem;
      transition: font-size 0.125s cubic-bezier(0.77, 0, 0.175, 1); }
    .primary-nav__icon--calendar {
      color: #90c6de; }
      .primary-nav__icon--calendar:before {
        font-family: "iconfont";
        font-style: normal;
        font-weight: 400;
        content: ""; }
    .primary-nav__icon--assignment {
      color: #90c6de; }
      .primary-nav__icon--assignment:before {
        font-family: "iconfont";
        font-style: normal;
        font-weight: 400;
        content: ""; }
    .primary-nav__icon--matchmaking-substitute {
      color: #90c6de; }
      .primary-nav__icon--matchmaking-substitute:before {
        font-family: "iconfont";
        font-style: normal;
        font-weight: 400;
        content: ""; }
    .primary-nav__icon--matchmaking-clinic {
      color: #f9b55a; }
      .primary-nav__icon--matchmaking-clinic:before {
        font-family: "iconfont";
        font-style: normal;
        font-weight: 400;
        content: ""; }
    .primary-nav__icon--schedule {
      color: #f9b55a; }
      .primary-nav__icon--schedule:before {
        font-family: "iconfont";
        font-style: normal;
        font-weight: 400;
        content: ""; }
    .primary-nav__icon--circle-check {
      color: #94d394; }
      .primary-nav__icon--circle-check:before {
        font-family: "iconfont";
        font-style: normal;
        font-weight: 400;
        content: ""; }
    .primary-nav__icon--timesheet {
      color: #c49ee8; }
      .primary-nav__icon--timesheet:before {
        font-family: "iconfont";
        font-style: normal;
        font-weight: 400;
        content: ""; }
    .primary-nav__icon--history {
      color: #a0adb9; }
      .primary-nav__icon--history:before {
        font-family: "iconfont";
        font-style: normal;
        font-weight: 400;
        content: ""; }
    .primary-nav__icon--vet {
      color: #90c6de; }
      .primary-nav__icon--vet:before {
        font-family: "iconfont";
        font-style: normal;
        font-weight: 400;
        content: ""; }
    .primary-nav__icon--tech {
      color: #90c6de; }
      .primary-nav__icon--tech:before {
        font-family: "iconfont";
        font-style: normal;
        font-weight: 400;
        content: ""; }
    .primary-nav__icon--faq {
      color: #a0adb9; }
      .primary-nav__icon--faq:before {
        font-family: "iconfont";
        font-style: normal;
        font-weight: 400;
        content: ""; }
