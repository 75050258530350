@media only screen and (max-width: 38rem) {
  .page--no-scroll {
    position: fixed;
    overflow: hidden; } }

.page__logged-in {
  padding-left: 4.375rem; }
  @media only screen and (max-width: 38rem) {
    .page__logged-in {
      padding-left: 0; } }
  .page__logged-in main {
    padding: 6.5625em 4.375em; }
    @media only screen and (max-width: 38rem) {
      .page__logged-in main {
        padding: 6.5625em 1.25em; } }
    @media only screen and (max-width: 26rem) {
      .page__logged-in main {
        padding: 6.5625em 0.625em; } }
  .page__logged-in .page__with-side-panel {
    padding-right: 0;
    transition: padding 0.35s cubic-bezier(0.77, 0, 0.175, 1); }
    .page__logged-in .page__with-side-panel--small {
      padding-right: calc(25vw - 20px); }
      @media only screen and (max-width: 90rem) {
        .page__logged-in .page__with-side-panel--small {
          padding-right: 0; } }
    .page__logged-in .page__with-side-panel main {
      width: 100%; }

.page--without-clinic .page__logged-in {
  padding-left: 0; }
