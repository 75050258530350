@charset "UTF-8";
@font-face {
  font-family: "iconfont";
  src: url("/iconfont.eot");
  /* IE9 Compat Modes */
  src: url("/iconfont.eot?#iefix") format("embedded-opentype"), url("/iconfont.woff") format("woff"), url("/iconfont.ttf") format("truetype"), url("/iconfont.svg") format("svg");
  /* Legacy iOS */ }

.icon {
  font-style: normal;
  font-weight: 400; }
  .icon.accordion-close:before {
    font-family: "iconfont";
    content: ""; }
  .icon.accordion-open:before {
    font-family: "iconfont";
    content: ""; }
  .icon.add-folder:before {
    font-family: "iconfont";
    content: ""; }
  .icon.add:before {
    font-family: "iconfont";
    content: ""; }
  .icon.admin:before {
    font-family: "iconfont";
    content: ""; }
  .icon.all-day:before {
    font-family: "iconfont";
    content: ""; }
  .icon.arrow-left:before {
    font-family: "iconfont";
    content: ""; }
  .icon.arrow-right:before {
    font-family: "iconfont";
    content: ""; }
  .icon.assignment:before {
    font-family: "iconfont";
    content: ""; }
  .icon.association-clinic:before {
    font-family: "iconfont";
    content: ""; }
  .icon.association:before {
    font-family: "iconfont";
    content: ""; }
  .icon.bars:before {
    font-family: "iconfont";
    content: ""; }
  .icon.calendar-2:before {
    font-family: "iconfont";
    content: ""; }
  .icon.calendar-today:before {
    font-family: "iconfont";
    content: ""; }
  .icon.calendar:before {
    font-family: "iconfont";
    content: ""; }
  .icon.call-list:before {
    font-family: "iconfont";
    content: ""; }
  .icon.checkbox-off:before {
    font-family: "iconfont";
    content: ""; }
  .icon.checkbox-on:before {
    font-family: "iconfont";
    content: ""; }
  .icon.checkbox-square:before {
    font-family: "iconfont";
    content: ""; }
  .icon.circle-add:before {
    font-family: "iconfont";
    content: ""; }
  .icon.circle-check:before {
    font-family: "iconfont";
    content: ""; }
  .icon.circle-info:before {
    font-family: "iconfont";
    content: ""; }
  .icon.circlo-close:before {
    font-family: "iconfont";
    content: ""; }
  .icon.clinic:before {
    font-family: "iconfont";
    content: ""; }
  .icon.close-slim:before {
    font-family: "iconfont";
    content: ""; }
  .icon.close:before {
    font-family: "iconfont";
    content: ""; }
  .icon.closed-folder:before {
    font-family: "iconfont";
    content: ""; }
  .icon.copy:before {
    font-family: "iconfont";
    content: ""; }
  .icon.delete:before {
    font-family: "iconfont";
    content: ""; }
  .icon.disconnect:before {
    font-family: "iconfont";
    content: ""; }
  .icon.dotted-arrow-right:before {
    font-family: "iconfont";
    content: ""; }
  .icon.dotted-arrow:before {
    font-family: "iconfont";
    content: ""; }
  .icon.edit:before {
    font-family: "iconfont";
    content: ""; }
  .icon.email:before {
    font-family: "iconfont";
    content: ""; }
  .icon.featured:before {
    font-family: "iconfont";
    content: ""; }
  .icon.handle-h:before {
    font-family: "iconfont";
    content: ""; }
  .icon.handle-v:before {
    font-family: "iconfont";
    content: ""; }
  .icon.help-circle:before {
    font-family: "iconfont";
    content: ""; }
  .icon.help:before {
    font-family: "iconfont";
    content: ""; }
  .icon.history:before {
    font-family: "iconfont";
    content: ""; }
  .icon.info:before {
    font-family: "iconfont";
    content: ""; }
  .icon.lock-open:before {
    font-family: "iconfont";
    content: ""; }
  .icon.lock:before {
    font-family: "iconfont";
    content: ""; }
  .icon.lodging:before {
    font-family: "iconfont";
    content: ""; }
  .icon.magnifying-glass:before {
    font-family: "iconfont";
    content: ""; }
  .icon.map:before {
    font-family: "iconfont";
    content: ""; }
  .icon.marker:before {
    font-family: "iconfont";
    content: ""; }
  .icon.notifications:before {
    font-family: "iconfont";
    content: ""; }
  .icon.open-folder:before {
    font-family: "iconfont";
    content: ""; }
  .icon.pencil:before {
    font-family: "iconfont";
    content: ""; }
  .icon.piechart:before {
    font-family: "iconfont";
    content: ""; }
  .icon.pin:before {
    font-family: "iconfont";
    content: ""; }
  .icon.profile:before {
    font-family: "iconfont";
    content: ""; }
  .icon.schedule:before {
    font-family: "iconfont";
    content: ""; }
  .icon.settings:before {
    font-family: "iconfont";
    content: ""; }
  .icon.small-down-arrow:before {
    font-family: "iconfont";
    content: ""; }
  .icon.small-up-arrow:before {
    font-family: "iconfont";
    content: ""; }
  .icon.status-check:before {
    font-family: "iconfont";
    content: ""; }
  .icon.surgery-optional:before {
    font-family: "iconfont";
    content: ""; }
  .icon.surgery:before {
    font-family: "iconfont";
    content: ""; }
  .icon.time:before {
    font-family: "iconfont";
    content: ""; }
  .icon.timesheet:before {
    font-family: "iconfont";
    content: ""; }
  .icon.triangle:before {
    font-family: "iconfont";
    content: ""; }
  .icon.tsa:before {
    font-family: "iconfont";
    content: ""; }
  .icon.validation:before {
    font-family: "iconfont";
    content: ""; }
  .icon.vet:before {
    font-family: "iconfont";
    content: ""; }

.content-list__tools {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 4.6875rem; }
  @media only screen and (max-width: 38rem) {
    .content-list__tools {
      display: block; } }
  .content-list__tools__search {
    width: 15.625rem;
    max-width: 100%; }
    @media only screen and (max-width: 38rem) {
      .content-list__tools__search {
        width: 100%;
        margin-bottom: 0.625rem; } }
    .content-list__tools__search .field {
      width: 100%;
      padding-bottom: 0;
      margin-right: 0.3125rem; }
    .content-list__tools__search form {
      display: flex;
      align-items: center; }

.content-list__content-item {
  color: #403b42;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0.9375em 0;
  border-bottom: 1px solid #d8dfe5; }
  @media only screen and (max-width: 28.125rem) {
    .content-list__content-item {
      display: block; }
      .content-list__content-item .form__actions {
        justify-content: flex-start; } }
  .content-list__content-item .user__name {
    font-size: 1.25em;
    display: inline-block;
    margin: 0 0.5rem 0.625rem 0; }
  .content-list__content-item .user__mail {
    font-size: .85em;
    pointer-events: none;
    margin-bottom: 0.625rem; }
  .content-list__content-item .user__address {
    font-size: .85em;
    margin-bottom: 0.3125rem; }
  .content-list__content-item .user__phone {
    font-size: .85em;
    margin-bottom: 0; }
    .content-list__content-item .user__phone a {
      pointer-events: none; }
  .content-list__content-item .user__role {
    display: inline-block;
    font-size: 0.5625em;
    line-height: 1.125em;
    font-weight: 700;
    text-transform: uppercase;
    padding: 0.375rem 0.375rem 0.1875rem;
    border-radius: 0.1875rem;
    letter-spacing: .1em;
    color: #403b42;
    margin-right: 0.375rem; }
    .content-list__content-item .user__role.admin {
      background-color: #90c6de; }
    .content-list__content-item .user__role.clinic_manager {
      background-color: #f9b55a; }
    .content-list__content-item .user__role.substitute {
      background-color: #94d394; }
  .content-list__content-item__button {
    padding: 0 0 0 1.25em; }
    .content-list__content-item__button:first-child {
      padding-left: 0; }
    @media only screen and (max-width: 38rem) {
      .content-list__content-item__button {
        margin-top: 1.5625em;
        margin-bottom: 0.625em; } }
    .content-list__content-item__button:before {
      color: #90c6de;
      margin-right: 0.625em;
      font-size: 0.875rem;
      position: relative;
      top: 0.1875rem; }
    .content-list__content-item__button--edit:before {
      font-family: "iconfont";
      font-style: normal;
      font-weight: 400;
      content: ""; }
    .content-list__content-item__button--disable:before {
      font-family: "iconfont";
      font-style: normal;
      font-weight: 400;
      content: ""; }
    .content-list__content-item__button--enable:before {
      font-family: "iconfont";
      font-style: normal;
      font-weight: 400;
      content: ""; }
  .content-list__content-item--discarded .user {
    opacity: .5; }
  @media only screen and (max-width: 46.875rem) {
    .content-list__content-item .user {
      display: flex;
      flex-direction: column; } }

.content-list__button {
  margin: 0.9375rem auto 0;
  display: block; }

.form__actions {
  display: flex;
  justify-content: center; }
