@charset "UTF-8";
@font-face {
  font-family: "iconfont";
  src: url("/iconfont.eot");
  /* IE9 Compat Modes */
  src: url("/iconfont.eot?#iefix") format("embedded-opentype"), url("/iconfont.woff") format("woff"), url("/iconfont.ttf") format("truetype"), url("/iconfont.svg") format("svg");
  /* Legacy iOS */ }

.icon {
  font-style: normal;
  font-weight: 400; }
  .icon.accordion-close:before {
    font-family: "iconfont";
    content: ""; }
  .icon.accordion-open:before {
    font-family: "iconfont";
    content: ""; }
  .icon.add-folder:before {
    font-family: "iconfont";
    content: ""; }
  .icon.add:before {
    font-family: "iconfont";
    content: ""; }
  .icon.admin:before {
    font-family: "iconfont";
    content: ""; }
  .icon.all-day:before {
    font-family: "iconfont";
    content: ""; }
  .icon.arrow-left:before {
    font-family: "iconfont";
    content: ""; }
  .icon.arrow-right:before {
    font-family: "iconfont";
    content: ""; }
  .icon.assignment:before {
    font-family: "iconfont";
    content: ""; }
  .icon.association-clinic:before {
    font-family: "iconfont";
    content: ""; }
  .icon.association:before {
    font-family: "iconfont";
    content: ""; }
  .icon.bars:before {
    font-family: "iconfont";
    content: ""; }
  .icon.calendar-2:before {
    font-family: "iconfont";
    content: ""; }
  .icon.calendar-today:before {
    font-family: "iconfont";
    content: ""; }
  .icon.calendar:before {
    font-family: "iconfont";
    content: ""; }
  .icon.call-list:before {
    font-family: "iconfont";
    content: ""; }
  .icon.checkbox-off:before {
    font-family: "iconfont";
    content: ""; }
  .icon.checkbox-on:before {
    font-family: "iconfont";
    content: ""; }
  .icon.checkbox-square:before {
    font-family: "iconfont";
    content: ""; }
  .icon.circle-add:before {
    font-family: "iconfont";
    content: ""; }
  .icon.circle-check:before {
    font-family: "iconfont";
    content: ""; }
  .icon.circle-info:before {
    font-family: "iconfont";
    content: ""; }
  .icon.circlo-close:before {
    font-family: "iconfont";
    content: ""; }
  .icon.clinic:before {
    font-family: "iconfont";
    content: ""; }
  .icon.close-slim:before {
    font-family: "iconfont";
    content: ""; }
  .icon.close:before {
    font-family: "iconfont";
    content: ""; }
  .icon.closed-folder:before {
    font-family: "iconfont";
    content: ""; }
  .icon.copy:before {
    font-family: "iconfont";
    content: ""; }
  .icon.delete:before {
    font-family: "iconfont";
    content: ""; }
  .icon.disconnect:before {
    font-family: "iconfont";
    content: ""; }
  .icon.dotted-arrow-right:before {
    font-family: "iconfont";
    content: ""; }
  .icon.dotted-arrow:before {
    font-family: "iconfont";
    content: ""; }
  .icon.edit:before {
    font-family: "iconfont";
    content: ""; }
  .icon.email:before {
    font-family: "iconfont";
    content: ""; }
  .icon.featured:before {
    font-family: "iconfont";
    content: ""; }
  .icon.handle-h:before {
    font-family: "iconfont";
    content: ""; }
  .icon.handle-v:before {
    font-family: "iconfont";
    content: ""; }
  .icon.help-circle:before {
    font-family: "iconfont";
    content: ""; }
  .icon.help:before {
    font-family: "iconfont";
    content: ""; }
  .icon.history:before {
    font-family: "iconfont";
    content: ""; }
  .icon.info:before {
    font-family: "iconfont";
    content: ""; }
  .icon.lock-open:before {
    font-family: "iconfont";
    content: ""; }
  .icon.lock:before {
    font-family: "iconfont";
    content: ""; }
  .icon.lodging:before {
    font-family: "iconfont";
    content: ""; }
  .icon.magnifying-glass:before {
    font-family: "iconfont";
    content: ""; }
  .icon.map:before {
    font-family: "iconfont";
    content: ""; }
  .icon.marker:before {
    font-family: "iconfont";
    content: ""; }
  .icon.notifications:before {
    font-family: "iconfont";
    content: ""; }
  .icon.open-folder:before {
    font-family: "iconfont";
    content: ""; }
  .icon.pencil:before {
    font-family: "iconfont";
    content: ""; }
  .icon.piechart:before {
    font-family: "iconfont";
    content: ""; }
  .icon.pin:before {
    font-family: "iconfont";
    content: ""; }
  .icon.profile:before {
    font-family: "iconfont";
    content: ""; }
  .icon.schedule:before {
    font-family: "iconfont";
    content: ""; }
  .icon.settings:before {
    font-family: "iconfont";
    content: ""; }
  .icon.small-down-arrow:before {
    font-family: "iconfont";
    content: ""; }
  .icon.small-up-arrow:before {
    font-family: "iconfont";
    content: ""; }
  .icon.status-check:before {
    font-family: "iconfont";
    content: ""; }
  .icon.surgery-optional:before {
    font-family: "iconfont";
    content: ""; }
  .icon.surgery:before {
    font-family: "iconfont";
    content: ""; }
  .icon.time:before {
    font-family: "iconfont";
    content: ""; }
  .icon.timesheet:before {
    font-family: "iconfont";
    content: ""; }
  .icon.triangle:before {
    font-family: "iconfont";
    content: ""; }
  .icon.tsa:before {
    font-family: "iconfont";
    content: ""; }
  .icon.validation:before {
    font-family: "iconfont";
    content: ""; }
  .icon.vet:before {
    font-family: "iconfont";
    content: ""; }

.side-panel {
  position: fixed;
  top: 0;
  height: 100vh;
  background-color: #fff;
  box-shadow: -5px 0 15px 0 rgba(0, 0, 0, 0.15);
  transition: right 0.35s cubic-bezier(0.77, 0, 0.175, 1);
  z-index: 50;
  overflow: hidden;
  right: -100vw;
  padding-right: 1.25rem; }
  .side-panel__section {
    height: 100%;
    overflow: hidden; }
  .side-panel--visible {
    right: -20px;
    min-width: 25rem;
    max-width: 100vw;
    width: 25vw; }
    @media only screen and (max-width: 90rem) {
      .side-panel--visible {
        min-width: 0;
        max-width: auto;
        width: 35vw; } }
    @media only screen and (max-width: 62.5rem) {
      .side-panel--visible {
        min-width: 23.75rem;
        max-width: 26.25rem;
        width: 50vw; } }
    @media only screen and (max-width: 38rem) {
      .side-panel--visible {
        min-width: 0;
        max-width: 100%;
        width: 100%;
        right: 0;
        padding-right: 0; } }
  .side-panel__head {
    background-color: #fff;
    border-bottom: 1px solid #f7f8f9;
    box-shadow: 0 3px 15px 0 rgba(0, 0, 0, 0.15);
    padding: 6.875em 1.5625em 2.1875em;
    position: relative; }
    @media (min-height: 1000px) {
      .side-panel__head--notifications {
        position: sticky;
        top: 0;
        z-index: +1; } }
    .side-panel__head h1 {
      font-size: 0.875em;
      text-transform: uppercase;
      color: #403b42;
      pointer-events: none; }
    .side-panel__head__icon {
      position: relative;
      padding-left: 2.375em; }
      .side-panel__head__icon:before {
        position: absolute;
        top: -50%;
        font-size: 1.5em;
        color: #90c6de;
        left: 0; }
      .side-panel__head__icon--clinic:before {
        font-family: "iconfont";
        font-style: normal;
        font-weight: 400;
        content: ""; }
      .side-panel__head__icon--edit:before {
        font-family: "iconfont";
        font-style: normal;
        font-weight: 400;
        content: ""; }
      .side-panel__head__icon--help:before {
        font-family: "iconfont";
        font-style: normal;
        font-weight: 400;
        content: ""; }
      .side-panel__head__icon--help:before {
        color: #90c6de;
        font-size: 1.8em; }
      .side-panel__head__icon--notifications:before {
        font-family: "iconfont";
        font-style: normal;
        font-weight: 400;
        content: ""; }
      .side-panel__head__icon--notifications:before {
        top: -30%; }
      .side-panel__head__icon--profile:before {
        font-family: "iconfont";
        font-style: normal;
        font-weight: 400;
        content: ""; }
      .side-panel__head__icon--settings:before {
        font-family: "iconfont";
        font-style: normal;
        font-weight: 400;
        content: ""; }
  .side-panel__close-btn {
    position: absolute;
    top: 6.5rem;
    right: 1.5625rem;
    font-size: 1.125rem;
    color: #a0adb9;
    padding-right: 0; }
    .side-panel__close-btn:before {
      font-family: "iconfont";
      font-style: normal;
      font-weight: 400;
      content: ""; }
    .side-panel__close-btn--arrow:before {
      font-family: "iconfont";
      font-style: normal;
      font-weight: 400;
      content: ""; }
  .side-panel__section__wrapper {
    overflow: auto;
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    -webkit-overflow-scrolling: touch; }
    .side-panel__section__wrapper::-webkit-scrollbar {
      display: none; }
  .side-panel__content {
    width: 100%;
    display: flex;
    flex-direction: column;
    flex-shrink: 0;
    flex-grow: 1;
    align-items: stretch; }
    .side-panel__content > div {
      width: 100%;
      min-height: 100%; }

.side-panel__form {
  color: #403b42;
  width: 100%;
  min-height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between; }
  .side-panel__form .basic-loader {
    position: absolute;
    top: 25px;
    left: 0;
    width: calc(100% - 20px); }
    @media only screen and (max-width: 38rem) {
      .side-panel__form .basic-loader {
        width: 100%; } }
  .side-panel__form--submitting {
    opacity: .5;
    pointer-events: none;
    cursor: not-allowed; }
  .side-panel__form__group:not(:last-child) {
    border-bottom: 1px solid rgba(216, 223, 229, 0.75); }
  .side-panel__form__section {
    padding: 2.1875em 1.5625em 1.25em;
    border-bottom: 1px solid rgba(216, 223, 229, 0.75); }
    .side-panel__form__section:last-child {
      border-bottom: none; }
    .side-panel__form__section--admin {
      border-top: 1px #414a52 solid; }
    .side-panel__form__section--continued {
      border-bottom: none;
      padding-bottom: 0; }
    .side-panel__form__section--padded {
      padding-bottom: 3.75em; }
      @media only screen and (max-width: 38rem) and (max-height: 750px) {
        .side-panel__form__section--padded {
          padding-bottom: 5.625em; } }
    .side-panel__form__section--profile {
      padding: 1.5625em 1.5625em 1.25em;
      background-color: rgba(65, 74, 82, 0.05); }
      .side-panel__form__section--profile .profile {
        display: flex;
        white-space: normal; }
      .side-panel__form__section--profile .profile__image {
        flex-grow: 0;
        flex-shrink: 0;
        border-radius: 50%;
        width: 1.875rem;
        height: 1.875rem;
        background-color: #90c6de;
        margin-right: 0.625rem;
        background-size: cover;
        background-position: center center; }
        .side-panel__form__section--profile .profile__image--empty {
          color: #3a434a;
          border-radius: 50%;
          font-size: 1.2em;
          text-align: center;
          line-height: 1.5625em;
          overflow: hidden; }
          .side-panel__form__section--profile .profile__image--empty:before {
            font-family: "iconfont";
            font-style: normal;
            font-weight: 400;
            content: ""; }
      .side-panel__form__section--profile .profile__infos {
        display: flex;
        align-items: center; }
        .side-panel__form__section--profile .profile__infos__title {
          padding-top: .2em;
          font-size: 0.875rem;
          line-height: 1.25rem;
          font-weight: 700;
          display: block; }
    .side-panel__form__section__title {
      text-transform: uppercase;
      border-bottom: 1px solid rgba(216, 223, 229, 0.75);
      font-size: 0.75em;
      font-weight: 600;
      padding-bottom: 1.5625em;
      margin-bottom: 1.25em;
      display: flex;
      width: 100%; }
  .side-panel__form__subsection {
    padding: 0.9375em 0 0.625em; }
    .side-panel__form__subsection:first-child {
      padding-top: 0; }
    .side-panel__form__subsection:last-child {
      padding-bottom: 0; }
  .side-panel__form__name {
    font-size: 1.25rem;
    font-weight: 700;
    text-align: center;
    color: #414a52;
    margin: 1.375rem auto 0.9375rem;
    max-width: 22.5rem; }
  .side-panel__form .form__actions {
    border-top: 1px solid #f7f8f9;
    padding: 1.5625em 1.25em;
    box-shadow: 0 3px 15px 0 rgba(0, 0, 0, 0.15);
    width: 100%;
    background-color: #fff;
    z-index: 10;
    text-align: center;
    display: flex;
    justify-content: center; }
    .side-panel__form .form__actions > button {
      margin: 0 0.3125rem;
      width: 50%; }
    .side-panel__form .form__actions .form__submit {
      background: #414a52;
      color: #fff;
      padding: 1.0625em 1.125em 0.875em;
      text-transform: uppercase;
      font-size: 0.6875em;
      line-height: 1.0625em;
      font-weight: 700;
      border: none;
      cursor: pointer;
      border-radius: 0.1875em;
      outline: none;
      letter-spacing: 0.8px;
      padding: 1.125em 1.125em 0.8125em; }
      .side-panel__form .form__actions .form__submit:hover {
        background-color: #384047;
        color: #fff; }
      .side-panel__form .form__actions .form__submit[disabled] {
        opacity: 0.4;
        background: #414a52;
        cursor: default;
        color: #fff; }
    .side-panel__form .form__actions .form__cancel {
      background: #a0adb9;
      color: #fff;
      padding: 1.0625em 1.125em 0.875em;
      text-transform: uppercase;
      font-size: 0.6875em;
      line-height: 1.0625em;
      font-weight: 700;
      border: none;
      cursor: pointer;
      border-radius: 0.1875em;
      outline: none;
      letter-spacing: 0.8px;
      padding: 1.125em 1.125em 0.8125em; }
      .side-panel__form .form__actions .form__cancel:hover {
        background-color: #94a3b0;
        color: #fff; }
      .side-panel__form .form__actions .form__cancel[disabled] {
        opacity: 0.4;
        background: #a0adb9;
        cursor: default;
        color: #fff; }
  .side-panel__form .form__link {
    padding: 0;
    font-size: 0.75em;
    color: #4c4648;
    position: relative;
    bottom: 0.625rem;
    margin-top: 0.9375em; }
    .side-panel__form .form__link:hover {
      text-decoration: underline;
      color: #403b42; }
  .side-panel__form .field__checkbox__label span {
    font-size: 0.875em; }
  .side-panel__form .field__textarea__wrapper {
    width: 100%; }
  .side-panel__form .field__textarea {
    color: currentColor;
    outline: none;
    border-color: #d8dfe5;
    padding: 0.625rem;
    font-size: 0.875em;
    line-height: 1.5em;
    resize: vertical; }
    .side-panel__form .field__textarea:focus {
      border-color: #a0adb9; }
    .side-panel__form .field__textarea[readonly], .side-panel__form .field__textarea[disabled] {
      background-color: #f7f8f9;
      border-color: #f7f8f9;
      cursor: default; }
    .side-panel__form .field__textarea[disabled] {
      color: #a0adb9; }
  .side-panel__form__hint {
    line-height: 1.5em;
    display: block; }
    .side-panel__form__hint--alert {
      color: #f76b7e;
      margin-top: 1.25em;
      padding: 0.6875em 0.875em 0.5625em;
      color: white;
      background-color: #f76b7e;
      border-radius: 0.1875rem;
      font-size: 0.75em; }
  .side-panel__form__info {
    display: inline-block;
    padding: 0 0 1em; }
  .side-panel__form .form__disconnect {
    background: #a0adb9;
    color: #fff;
    padding: 1.0625em 1.125em 0.875em;
    text-transform: uppercase;
    font-size: 0.6875em;
    line-height: 1.0625em;
    font-weight: 700;
    border: none;
    cursor: pointer;
    border-radius: 0.1875em;
    outline: none;
    letter-spacing: 0.8px;
    position: relative;
    font-size: 0.6875em;
    text-transform: uppercase;
    padding: 0.75rem 1.75rem 0.5625rem 0.625rem;
    letter-spacing: .05em; }
    .side-panel__form .form__disconnect:hover {
      background-color: #94a3b0;
      color: #fff; }
    .side-panel__form .form__disconnect[disabled] {
      opacity: 0.4;
      background: #a0adb9;
      cursor: default;
      color: #fff; }
    .side-panel__form .form__disconnect:before {
      font-family: "iconfont";
      font-style: normal;
      font-weight: 400;
      content: ""; }
    .side-panel__form .form__disconnect:before {
      position: absolute;
      top: 0.625rem;
      right: 0.6875rem; }
  .side-panel__form .DateRangePicker_picker {
    z-index: 50; }
  .side-panel__form .DateInput_fang {
    z-index: 52; }
  @media only screen and (max-width: 700px) {
    @supports (-webkit-overflow-scrolling: touch) {
      .side-panel__form {
        /* CSS specific to iOS devices */ }
        .side-panel__form .form__actions {
          padding-bottom: 7.5rem; } } }
  @media only screen and (max-width: 38rem) and (min-width: 26rem) {
    .side-panel__form__section > * {
      max-width: 400px;
      margin-left: auto;
      margin-right: auto; } }

.form__spacing {
  padding: 1.875em 1.5625em 0.9375em; }
  .form__spacing--disconnect {
    padding: 1.25em 1.5625em 0.9375em;
    border-bottom: solid 1px rgba(216, 223, 229, 0.75);
    text-align: right; }
  .form__spacing--disconnect + div .side-panel__form__section:first-child {
    padding-top: 1.875em; }

.form__submit {
  background: #414a52;
  color: #fff;
  padding: 1.0625em 1.125em 0.875em;
  text-transform: uppercase;
  font-size: 0.6875em;
  line-height: 1.0625em;
  font-weight: 700;
  border: none;
  cursor: pointer;
  border-radius: 0.1875em;
  outline: none;
  letter-spacing: 0.8px; }
  .form__submit:hover {
    background-color: #384047;
    color: #fff; }
  .form__submit[disabled] {
    opacity: 0.4;
    background: #414a52;
    cursor: default;
    color: #fff; }

.form__cancel {
  background: #a0adb9;
  color: #fff;
  padding: 1.0625em 1.125em 0.875em;
  text-transform: uppercase;
  font-size: 0.6875em;
  line-height: 1.0625em;
  font-weight: 700;
  border: none;
  cursor: pointer;
  border-radius: 0.1875em;
  outline: none;
  letter-spacing: 0.8px; }
  .form__cancel:hover {
    background-color: #94a3b0;
    color: #fff; }
  .form__cancel[disabled] {
    opacity: 0.4;
    background: #a0adb9;
    cursor: default;
    color: #fff; }
