@charset "UTF-8";
@font-face {
  font-family: "iconfont";
  src: url("/iconfont.eot");
  /* IE9 Compat Modes */
  src: url("/iconfont.eot?#iefix") format("embedded-opentype"), url("/iconfont.woff") format("woff"), url("/iconfont.ttf") format("truetype"), url("/iconfont.svg") format("svg");
  /* Legacy iOS */ }

.icon {
  font-style: normal;
  font-weight: 400; }
  .icon.accordion-close:before {
    font-family: "iconfont";
    content: ""; }
  .icon.accordion-open:before {
    font-family: "iconfont";
    content: ""; }
  .icon.add-folder:before {
    font-family: "iconfont";
    content: ""; }
  .icon.add:before {
    font-family: "iconfont";
    content: ""; }
  .icon.admin:before {
    font-family: "iconfont";
    content: ""; }
  .icon.all-day:before {
    font-family: "iconfont";
    content: ""; }
  .icon.arrow-left:before {
    font-family: "iconfont";
    content: ""; }
  .icon.arrow-right:before {
    font-family: "iconfont";
    content: ""; }
  .icon.assignment:before {
    font-family: "iconfont";
    content: ""; }
  .icon.association-clinic:before {
    font-family: "iconfont";
    content: ""; }
  .icon.association:before {
    font-family: "iconfont";
    content: ""; }
  .icon.bars:before {
    font-family: "iconfont";
    content: ""; }
  .icon.calendar-2:before {
    font-family: "iconfont";
    content: ""; }
  .icon.calendar-today:before {
    font-family: "iconfont";
    content: ""; }
  .icon.calendar:before {
    font-family: "iconfont";
    content: ""; }
  .icon.call-list:before {
    font-family: "iconfont";
    content: ""; }
  .icon.checkbox-off:before {
    font-family: "iconfont";
    content: ""; }
  .icon.checkbox-on:before {
    font-family: "iconfont";
    content: ""; }
  .icon.checkbox-square:before {
    font-family: "iconfont";
    content: ""; }
  .icon.circle-add:before {
    font-family: "iconfont";
    content: ""; }
  .icon.circle-check:before {
    font-family: "iconfont";
    content: ""; }
  .icon.circle-info:before {
    font-family: "iconfont";
    content: ""; }
  .icon.circlo-close:before {
    font-family: "iconfont";
    content: ""; }
  .icon.clinic:before {
    font-family: "iconfont";
    content: ""; }
  .icon.close-slim:before {
    font-family: "iconfont";
    content: ""; }
  .icon.close:before {
    font-family: "iconfont";
    content: ""; }
  .icon.closed-folder:before {
    font-family: "iconfont";
    content: ""; }
  .icon.copy:before {
    font-family: "iconfont";
    content: ""; }
  .icon.delete:before {
    font-family: "iconfont";
    content: ""; }
  .icon.disconnect:before {
    font-family: "iconfont";
    content: ""; }
  .icon.dotted-arrow-right:before {
    font-family: "iconfont";
    content: ""; }
  .icon.dotted-arrow:before {
    font-family: "iconfont";
    content: ""; }
  .icon.edit:before {
    font-family: "iconfont";
    content: ""; }
  .icon.email:before {
    font-family: "iconfont";
    content: ""; }
  .icon.featured:before {
    font-family: "iconfont";
    content: ""; }
  .icon.handle-h:before {
    font-family: "iconfont";
    content: ""; }
  .icon.handle-v:before {
    font-family: "iconfont";
    content: ""; }
  .icon.help-circle:before {
    font-family: "iconfont";
    content: ""; }
  .icon.help:before {
    font-family: "iconfont";
    content: ""; }
  .icon.history:before {
    font-family: "iconfont";
    content: ""; }
  .icon.info:before {
    font-family: "iconfont";
    content: ""; }
  .icon.lock-open:before {
    font-family: "iconfont";
    content: ""; }
  .icon.lock:before {
    font-family: "iconfont";
    content: ""; }
  .icon.lodging:before {
    font-family: "iconfont";
    content: ""; }
  .icon.magnifying-glass:before {
    font-family: "iconfont";
    content: ""; }
  .icon.map:before {
    font-family: "iconfont";
    content: ""; }
  .icon.marker:before {
    font-family: "iconfont";
    content: ""; }
  .icon.notifications:before {
    font-family: "iconfont";
    content: ""; }
  .icon.open-folder:before {
    font-family: "iconfont";
    content: ""; }
  .icon.pencil:before {
    font-family: "iconfont";
    content: ""; }
  .icon.piechart:before {
    font-family: "iconfont";
    content: ""; }
  .icon.pin:before {
    font-family: "iconfont";
    content: ""; }
  .icon.profile:before {
    font-family: "iconfont";
    content: ""; }
  .icon.schedule:before {
    font-family: "iconfont";
    content: ""; }
  .icon.settings:before {
    font-family: "iconfont";
    content: ""; }
  .icon.small-down-arrow:before {
    font-family: "iconfont";
    content: ""; }
  .icon.small-up-arrow:before {
    font-family: "iconfont";
    content: ""; }
  .icon.status-check:before {
    font-family: "iconfont";
    content: ""; }
  .icon.surgery-optional:before {
    font-family: "iconfont";
    content: ""; }
  .icon.surgery:before {
    font-family: "iconfont";
    content: ""; }
  .icon.time:before {
    font-family: "iconfont";
    content: ""; }
  .icon.timesheet:before {
    font-family: "iconfont";
    content: ""; }
  .icon.triangle:before {
    font-family: "iconfont";
    content: ""; }
  .icon.tsa:before {
    font-family: "iconfont";
    content: ""; }
  .icon.validation:before {
    font-family: "iconfont";
    content: ""; }
  .icon.vet:before {
    font-family: "iconfont";
    content: ""; }

.day-availability {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between; }
  .day-availability--date {
    text-transform: uppercase;
    font-size: 0.5625rem;
    color: #a0adb9;
    padding: 0.75em 0.625em 0.5em 0;
    border-radius: 0.3125em;
    letter-spacing: .1em;
    width: 2.25rem;
    text-align: center; }
    .day-availability--date span {
      color: #414a52;
      font-size: 1.0625rem;
      display: block;
      padding-top: 0.3125em;
      font-weight: 500;
      letter-spacing: .05em; }
    @media only screen and (max-width: 26rem) {
      .day-availability--date {
        padding-right: 0;
        font-size: 0.5625rem; }
        .day-availability--date span {
          font-size: 1rem; } }
  .day-availability__schedule {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 0.75rem; }
    .day-availability__schedule .day-availability__arrow {
      padding: 0.125rem 0.75rem 0; }
    @media only screen and (max-width: 38rem) {
      .day-availability__schedule {
        flex-grow: 1;
        max-width: 18.75rem; } }
  .day-availability__tooltip {
    position: absolute;
    z-index: +1;
    top: 0.625rem;
    bottom: -0.625rem;
    left: 0.75rem;
    padding: 0 0.625rem;
    height: calc(100% - 1.25rem);
    width: calc(100% - 1.5rem);
    background-color: #414a52;
    color: white;
    border-radius: 0.1875rem;
    display: flex;
    align-items: center;
    justify-content: center; }
    .day-availability__tooltip:after {
      content: "";
      left: 100%;
      top: 50%;
      border: solid transparent;
      height: 0;
      width: 0;
      position: absolute;
      pointer-events: none;
      border-color: rgba(136, 183, 213, 0);
      border-left-color: #414a52;
      border-width: 0.375rem;
      margin-top: -0.375rem; }
    .day-availability__tooltip span {
      display: inline-block;
      font-size: 0.75em;
      line-height: 1.25em;
      text-align: center; }
  .day-availability .field .field__checkbox__label {
    margin-right: 0;
    color: #414a52; }
    .day-availability .field .field__checkbox__label:before {
      margin-right: 0;
      border-color: currentColor; }
    .day-availability .field .field__checkbox__label span {
      display: none; }
  .day-availability .field.field--border {
    width: 5.3125rem;
    position: relative;
    top: 0.3125rem; }
    .day-availability .field.field--border .field__label {
      display: none; }
  .day-availability .field.field--select {
    min-width: 5.625rem;
    margin: 0;
    padding: 0.625rem 0; }
    .day-availability .field.field--select label {
      display: none; }
    .day-availability .field.field--select .field__error {
      width: 18.75rem;
      max-width: 18.75rem;
      bottom: -0.3125rem; }
    .day-availability .field.field--select select {
      text-align: center;
      text-align-last: center; }
    .day-availability .field.field--select .field__select__wrapper:before {
      display: none; }
  .day-availability__arrow {
    display: block;
    color: #d8dfe5; }
    .day-availability__arrow:before {
      font-family: "iconfont";
      font-style: normal;
      font-weight: 400;
      content: ""; }
  .day-availability__checkbox {
    padding: 0 0 0 0.625em;
    margin: 0; }
    .day-availability__checkbox .field__checkbox__label {
      padding: 0.96875rem 0 0.625rem; }
