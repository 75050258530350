.basic-loader {
  position: fixed;
  width: 100%;
  height: 100vh;
  top: 0;
  left: 4.375rem;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 49;
  -webkit-user-select: none;
  /* Safari */
  -moz-user-select: none;
  /* Firefox */
  -ms-user-select: none;
  /* IE10+/Edge */
  user-select: none;
  /* Standard */ }
  @media only screen and (max-width: 38rem) {
    .basic-loader {
      left: 0; } }
  .basic-loader:before {
    content: "";
    display: block;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 20;
    opacity: 0.85;
    background-color: #f7f8f9; }
  .basic-loader img {
    position: absolute;
    z-index: 21;
    width: 6.25rem; }

.small-loader {
  align-items: center;
  display: flex;
  justify-content: center;
  margin-left: 0.9375rem;
  padding-bottom: 0.25rem;
  z-index: 49;
  -webkit-user-select: none;
  /* Safari */
  -moz-user-select: none;
  /* Firefox */
  -ms-user-select: none;
  /* IE10+/Edge */
  user-select: none;
  /* Standard */ }
  @media only screen and (max-width: 38rem) {
    .small-loader {
      left: 0; } }
  .small-loader img {
    position: absolute;
    z-index: 21;
    width: 1.125rem; }
