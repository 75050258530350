.field__input {
  width: 100%;
  outline: none;
  background-color: #fff;
  font-size: 0.875em;
  font-family: "F37Ginger", "HelveticaNeue-Light", "Helvetica Neue Light", "Helvetica Neue", Helvetica, Arial, "Lucida Grande", sans-serif;
  letter-spacing: 0.0625rem;
  color: #a0adb9;
  padding: 0.875em 0.625em 0.625em;
  border-radius: 0.1875em;
  border: 0.0625em solid #fff;
  -webkit-appearance: none; }
  .field__input:focus {
    border-color: #a0adb9; }
  .field__input[readonly], .field__input[disabled] {
    background-color: #f7f8f9;
    border-color: #f7f8f9;
    cursor: default; }
  .field__input[disabled] {
    color: #d8dfe5; }
  .field__input.field--invalid {
    border-color: #f76b7e; }

.field--light .field__input {
  border-color: #d8dfe5;
  color: #414a52; }
  .field--light .field__input:focus {
    border-color: #a0adb9; }
  .field--light .field__input[readonly], .field--light .field__input[disabled] {
    background-color: #f7f8f9;
    border-color: #f7f8f9; }
  .field--light .field__input.field--invalid {
    border-color: #f76b7e; }

.field--transparent .field__input {
  background: transparent; }
  .field--transparent .field__input[readonly], .field--transparent .field__input[disabled] {
    background-color: #f7f8f9; }

.field--full-light .field__input {
  background: #f3f5f6;
  border-color: #f3f5f6;
  color: #414a52; }
  .field--full-light .field__input:focus {
    border-color: #f7f8f9; }
  .field--full-light .field__input[readonly], .field--full-light .field__input[disabled] {
    background-color: #f7f8f9;
    border-color: #f7f8f9; }
  .field--full-light .field__input[disabled] {
    color: #a0adb9; }

.field--aside {
  width: 100%; }
  .field--aside label {
    display: flex;
    justify-content: space-between;
    align-items: center; }
  .field--aside .field__label {
    order: 1;
    position: static;
    width: 40%;
    display: block;
    flex-shrink: 0; }
  .field--aside input, .field--aside select {
    order: 2;
    flex-grow: 0;
    display: inline-block; }
  .field--aside .field__error {
    bottom: -0.25rem; }

.field--aside-select {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center; }
  .field--aside-select .field__label {
    order: 1;
    position: static;
    width: 40%;
    display: block;
    flex-shrink: 0; }
  .field--aside-select select, .field--aside-select .field__select__wrapper {
    order: 2;
    flex-grow: 0;
    width: 100%; }
  .field--aside-select .field__error {
    bottom: -0.875rem; }
