.action-confirmation__content {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: nowrap;
  position: fixed;
  width: 100%;
  left: 0;
  bottom: 0;
  background-color: #90c6de;
  padding: 0.9375em;
  z-index: 110;
  box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.45); }

.action-confirmation:before {
  content: "";
  display: block;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 109;
  background-color: #90c6de;
  opacity: 0.5; }

.action-confirmation p {
  margin: 0.3125em 0.625em 0.3125em 0;
  max-width: 100%; }

.action-confirmation .form__actions {
  margin: 0.3125em 0; }
  .action-confirmation .form__actions > *:last-child {
    margin-left: 0.625em; }
