.schedule__agenda table.schedule__agenda__head {
  border: none; }
  .schedule__agenda table.schedule__agenda__head .rbc-header {
    border: none !important; }

.schedule__agenda .schedule__agenda__content .rbc-agenda-event-cell {
  border: none !important; }

.schedule__agenda .schedule__agenda__content .rbc-agenda-time-cell {
  padding: 0 !important; }

.schedule__agenda .schedule__agenda__content .rbc-agenda-table {
  border-left: none;
  border-right: none; }

.schedule__agenda .schedule__agenda__content .rbc-agenda-date-cell {
  padding: 0.3125em;
  vertical-align: middle; }

.schedule__agenda .schedule__agenda__content .schedule__agenda__day {
  text-transform: uppercase;
  font-size: 0.5rem;
  color: #a0adb9;
  text-align: center;
  padding: 0.8125em 0.625em 0.25em;
  border-radius: 0.3125em;
  flex-grow: 0;
  flex-shrink: 0;
  width: 2.25rem; }
  .schedule__agenda .schedule__agenda__content .schedule__agenda__day span {
    color: #414a52;
    font-size: 1rem;
    display: block;
    padding-top: 0.1875em;
    font-weight: 600; }
  .schedule__agenda .schedule__agenda__content .schedule__agenda__day--today {
    background-color: #414a52;
    color: #fff; }
    .schedule__agenda .schedule__agenda__content .schedule__agenda__day--today span {
      color: #fff; }

.schedule__agenda .schedule__event--holiday {
  color: #a3a0a0;
  text-align: left;
  position: initial;
  padding: 0.5em 0;
  font-size: 0.75em;
  margin: 0;
  width: auto;
  max-width: 100%;
  white-space: normal; }

.schedule__agenda .schedule__event {
  margin: 0; }

.rbc-agenda-view {
  overflow: initial; }

.rbc-agenda-view table.rbc-agenda-table tbody > tr + tr {
  border-bottom: 1px solid #d8dfe5; }

.rbc-agenda-view table.rbc-agenda-table tbody > tr > td + td {
  border-left: 1px solid #d8dfe5; }
