@charset "UTF-8";
@font-face {
  font-family: "iconfont";
  src: url("/iconfont.eot");
  /* IE9 Compat Modes */
  src: url("/iconfont.eot?#iefix") format("embedded-opentype"), url("/iconfont.woff") format("woff"), url("/iconfont.ttf") format("truetype"), url("/iconfont.svg") format("svg");
  /* Legacy iOS */ }

.icon {
  font-style: normal;
  font-weight: 400; }
  .icon.accordion-close:before {
    font-family: "iconfont";
    content: ""; }
  .icon.accordion-open:before {
    font-family: "iconfont";
    content: ""; }
  .icon.add-folder:before {
    font-family: "iconfont";
    content: ""; }
  .icon.add:before {
    font-family: "iconfont";
    content: ""; }
  .icon.admin:before {
    font-family: "iconfont";
    content: ""; }
  .icon.all-day:before {
    font-family: "iconfont";
    content: ""; }
  .icon.arrow-left:before {
    font-family: "iconfont";
    content: ""; }
  .icon.arrow-right:before {
    font-family: "iconfont";
    content: ""; }
  .icon.assignment:before {
    font-family: "iconfont";
    content: ""; }
  .icon.association-clinic:before {
    font-family: "iconfont";
    content: ""; }
  .icon.association:before {
    font-family: "iconfont";
    content: ""; }
  .icon.bars:before {
    font-family: "iconfont";
    content: ""; }
  .icon.calendar-2:before {
    font-family: "iconfont";
    content: ""; }
  .icon.calendar-today:before {
    font-family: "iconfont";
    content: ""; }
  .icon.calendar:before {
    font-family: "iconfont";
    content: ""; }
  .icon.call-list:before {
    font-family: "iconfont";
    content: ""; }
  .icon.checkbox-off:before {
    font-family: "iconfont";
    content: ""; }
  .icon.checkbox-on:before {
    font-family: "iconfont";
    content: ""; }
  .icon.checkbox-square:before {
    font-family: "iconfont";
    content: ""; }
  .icon.circle-add:before {
    font-family: "iconfont";
    content: ""; }
  .icon.circle-check:before {
    font-family: "iconfont";
    content: ""; }
  .icon.circle-info:before {
    font-family: "iconfont";
    content: ""; }
  .icon.circlo-close:before {
    font-family: "iconfont";
    content: ""; }
  .icon.clinic:before {
    font-family: "iconfont";
    content: ""; }
  .icon.close-slim:before {
    font-family: "iconfont";
    content: ""; }
  .icon.close:before {
    font-family: "iconfont";
    content: ""; }
  .icon.closed-folder:before {
    font-family: "iconfont";
    content: ""; }
  .icon.copy:before {
    font-family: "iconfont";
    content: ""; }
  .icon.delete:before {
    font-family: "iconfont";
    content: ""; }
  .icon.disconnect:before {
    font-family: "iconfont";
    content: ""; }
  .icon.dotted-arrow-right:before {
    font-family: "iconfont";
    content: ""; }
  .icon.dotted-arrow:before {
    font-family: "iconfont";
    content: ""; }
  .icon.edit:before {
    font-family: "iconfont";
    content: ""; }
  .icon.email:before {
    font-family: "iconfont";
    content: ""; }
  .icon.featured:before {
    font-family: "iconfont";
    content: ""; }
  .icon.handle-h:before {
    font-family: "iconfont";
    content: ""; }
  .icon.handle-v:before {
    font-family: "iconfont";
    content: ""; }
  .icon.help-circle:before {
    font-family: "iconfont";
    content: ""; }
  .icon.help:before {
    font-family: "iconfont";
    content: ""; }
  .icon.history:before {
    font-family: "iconfont";
    content: ""; }
  .icon.info:before {
    font-family: "iconfont";
    content: ""; }
  .icon.lock-open:before {
    font-family: "iconfont";
    content: ""; }
  .icon.lock:before {
    font-family: "iconfont";
    content: ""; }
  .icon.lodging:before {
    font-family: "iconfont";
    content: ""; }
  .icon.magnifying-glass:before {
    font-family: "iconfont";
    content: ""; }
  .icon.map:before {
    font-family: "iconfont";
    content: ""; }
  .icon.marker:before {
    font-family: "iconfont";
    content: ""; }
  .icon.notifications:before {
    font-family: "iconfont";
    content: ""; }
  .icon.open-folder:before {
    font-family: "iconfont";
    content: ""; }
  .icon.pencil:before {
    font-family: "iconfont";
    content: ""; }
  .icon.piechart:before {
    font-family: "iconfont";
    content: ""; }
  .icon.pin:before {
    font-family: "iconfont";
    content: ""; }
  .icon.profile:before {
    font-family: "iconfont";
    content: ""; }
  .icon.schedule:before {
    font-family: "iconfont";
    content: ""; }
  .icon.settings:before {
    font-family: "iconfont";
    content: ""; }
  .icon.small-down-arrow:before {
    font-family: "iconfont";
    content: ""; }
  .icon.small-up-arrow:before {
    font-family: "iconfont";
    content: ""; }
  .icon.status-check:before {
    font-family: "iconfont";
    content: ""; }
  .icon.surgery-optional:before {
    font-family: "iconfont";
    content: ""; }
  .icon.surgery:before {
    font-family: "iconfont";
    content: ""; }
  .icon.time:before {
    font-family: "iconfont";
    content: ""; }
  .icon.timesheet:before {
    font-family: "iconfont";
    content: ""; }
  .icon.triangle:before {
    font-family: "iconfont";
    content: ""; }
  .icon.tsa:before {
    font-family: "iconfont";
    content: ""; }
  .icon.validation:before {
    font-family: "iconfont";
    content: ""; }
  .icon.vet:before {
    font-family: "iconfont";
    content: ""; }

/* ==================================================== */
/* General */
/* ==================================================== */
.faq-content p,
.helpcenter__faq p {
  color: #787877;
  font-weight: 400;
  line-height: 1.7;
  font-size: 1.375rem; }

.faq-content .title p,
.helpcenter__faq .title p {
  color: #787877;
  font-size: 1.375rem;
  font-weight: 400;
  margin-bottom: 2.1875rem;
  max-width: 50rem; }

.faq-content .helpcenter__accordion__panel p,
.helpcenter__faq .helpcenter__accordion__panel p {
  font-size: 1.375rem; }

.faq-content .title h2,
.helpcenter__faq .title h2 {
  font-size: 2.8125rem;
  font-weight: 600;
  margin: 0 0 1.5625rem 0;
  text-align: center; }

.faq-content h3,
.helpcenter__faq h3 {
  font-size: 2.1875rem;
  padding: 0 0 3.75rem; }

.faq-content h4,
.helpcenter__faq h4 {
  font-size: 1.75rem;
  padding-bottom: 2rem; }

.faq-content h5,
.helpcenter__faq h5 {
  font-size: 1.375rem; }

.faq-content h1,
.faq-content h2,
.faq-content h3,
.faq-content h4,
.faq-content h5,
.helpcenter__faq h1,
.helpcenter__faq h2,
.helpcenter__faq h3,
.helpcenter__faq h4,
.helpcenter__faq h5 {
  color: #414a52;
  line-height: 1.3; }

.faq-content section,
.helpcenter__faq section {
  margin-bottom: 7.5rem; }

.faq-content #replacement .faq-warning,
.helpcenter__faq #replacement .faq-warning {
  margin: 0;
  padding: 0; }
  .faq-content #replacement .faq-warning .faq-warning-content,
  .helpcenter__faq #replacement .faq-warning .faq-warning-content {
    padding: 0; }

.faq-content .addShadow,
.helpcenter__faq .addShadow {
  box-shadow: 0.625rem 0.625rem 1.5625rem rgba(56, 56, 56, 0.1); }

.faq-content a.email,
.helpcenter__faq a.email {
  text-decoration: underline;
  font-weight: 900; }
  .faq-content a.email:hover,
  .helpcenter__faq a.email:hover {
    color: #90c6de; }

.faq-content .nobreak,
.helpcenter__faq .nobreak {
  white-space: nowrap; }

@media (max-width: 62.5rem) {
  .helpcenter__faq .helpcenter__accordion__panel p {
    font-size: 1rem; } }

@media (max-width: 46.875rem) {
  .faq-content .title h2,
  .helpcenter__faq .title h2 {
    font-size: 2.1875rem; }
  .faq-content h3,
  .helpcenter__faq h3 {
    font-size: 1.75rem;
    padding: 0 0 2.5rem; }
  .faq-content h4,
  .helpcenter__faq h4 {
    font-size: 1.375rem; }
  .faq-content h5,
  .helpcenter__faq h5 {
    font-size: 1.125rem; }
  .faq-content .title p,
  .helpcenter__faq .title p {
    font-size: 1.125rem; }
  .faq-content p,
  .helpcenter__faq p {
    font-size: 1.125rem; }
  .faq-content section,
  .helpcenter__faq section {
    margin-bottom: 5rem; } }

@media (max-width: 29.375rem) {
  .faq-content .title h2,
  .helpcenter__faq .title h2 {
    font-size: 1.875rem;
    margin-bottom: 0; }
  .faq-content h3,
  .helpcenter__faq h3 {
    font-size: 1.5rem;
    padding-bottom: 1.75rem; }
  .faq-content h4,
  .helpcenter__faq h4 {
    font-size: 1.125rem;
    padding-bottom: 1.25rem; }
  .faq-content h5,
  .helpcenter__faq h5 {
    font-size: 1rem; }
  .faq-content .title p,
  .helpcenter__faq .title p {
    font-size: 1rem;
    margin-bottom: 0; }
  .faq-content p,
  .helpcenter__faq p {
    font-size: 1rem;
    margin-bottom: 0; }
  .faq-content p:last-child,
  .helpcenter__faq p:last-child {
    padding-bottom: 0; } }

/* ==================================================== */
/* icons Section */
/* ==================================================== */
.faq-content .icon {
  color: #414a52;
  position: relative;
  top: 0.25rem;
  text-align: center;
  line-height: 1.875rem;
  padding: .2em;
  padding-bottom: 0;
  font-size: 1.5625rem; }
  .faq-content .icon--profile:before {
    font-family: "iconfont";
    font-style: normal;
    font-weight: 400;
    content: ""; }
  .faq-content .icon--clinic:before {
    font-family: "iconfont";
    font-style: normal;
    font-weight: 400;
    content: ""; }
  .faq-content .icon--list2:before {
    font-family: "iconfont";
    font-style: normal;
    font-weight: 400;
    content: ""; }
  .faq-content .icon--calendar:before {
    font-family: "iconfont";
    font-style: normal;
    font-weight: 400;
    content: ""; }
  .faq-content .icon--delete:before {
    font-family: "iconfont";
    font-style: normal;
    font-weight: 400;
    content: ""; }
  .faq-content .icon--pencil:before {
    font-family: "iconfont";
    font-style: normal;
    font-weight: 400;
    content: ""; }
  .faq-content .icon--schedule:before {
    font-family: "iconfont";
    font-style: normal;
    font-weight: 400;
    content: ""; }
  .faq-content .icon--circle-add:before {
    font-family: "iconfont";
    font-style: normal;
    font-weight: 400;
    content: ""; }
  .faq-content .icon--circle-check:before {
    font-family: "iconfont";
    font-style: normal;
    font-weight: 400;
    content: ""; }
  .faq-content .icon--circle-close:before {
    font-family: "iconfont";
    font-style: normal;
    font-weight: 400;
    content: ""; }
  .faq-content .icon--timesheet:before {
    font-family: "iconfont";
    font-style: normal;
    font-weight: 400;
    content: ""; }
  .faq-content .icon--question:before {
    font-family: "iconfont";
    font-style: normal;
    font-weight: 400;
    content: ""; }

.faq-content .faq-warning-icon,
.faq-content .faq-card-tip-icon {
  position: relative;
  top: 0.125rem;
  font-size: 1.5625rem; }

.faq-content .blue {
  color: #90c6de; }

.faq-content .orange {
  color: #f9b55a; }

.faq-content .green {
  color: #94d394; }

.faq-content .red {
  color: #f76b7e; }

.faq-content .purple {
  color: #c49ee8; }

.faq-content .button {
  background-color: #90c6de;
  color: #ffffff;
  display: inline-block;
  justify-items: center;
  border-radius: 0.25rem;
  height: 1.875rem;
  width: 1.875rem;
  line-height: 2.2;
  margin: 0 0.625rem; }
  .faq-content .button:before {
    font-family: "iconfont";
    font-style: normal;
    font-weight: 400;
    content: ""; }
  .faq-content .button__label--bold {
    font-weight: bolder; }

.faq-content .underlined {
  text-decoration: underline; }

.faq-content .centered {
  display: flex;
  justify-content: center;
  text-align: center; }

@media (max-width: 29.375rem) {
  .faq-content .icon {
    line-height: 1.875rem;
    padding: .2em;
    padding-bottom: 0;
    font-size: 1.25rem; }
  .faq-content .faq-warning-icon,
  .faq-content .faq-card-tip-icon {
    font-size: 1.375rem;
    top: 0.5rem; }
  .faq-content .button {
    background-color: #90c6de;
    color: #ffffff;
    display: inline-block;
    justify-items: center;
    border-radius: 0.25rem;
    height: 1.875rem;
    width: 1.875rem;
    line-height: 2.2;
    margin: 0 0.625rem; }
    .faq-content .button:before {
      font-family: "iconfont";
      font-style: normal;
      font-weight: 400;
      content: ""; }
    .faq-content .button__label--bold {
      font-weight: bolder; } }

/* ==================================================== */
/* FAQ Card Section */
/* ==================================================== */
#faq-card-section {
  background-color: #eeeeee;
  padding: 6.25rem;
  display: grid;
  grid-template-columns: 75rem;
  justify-content: center; }

.faq-card-container {
  display: grid;
  justify-content: space-between;
  align-items: center;
  grid-template-areas: '';
  grid-template-columns: repeat(3, auto);
  grid-template-rows: auto; }

.faq-card {
  background-color: #ffffff;
  padding: 2.8125rem;
  display: grid;
  height: 6.25rem;
  width: 14.0625rem;
  justify-items: center;
  align-items: center;
  grid-template-areas: '';
  grid-template-columns: auto;
  grid-template-rows: 4.0625rem 3.125rem;
  border-radius: 0.25rem; }

.faq-card:hover {
  border: 0.25rem solid #90c6de;
  width: 13.5625rem;
  height: 5.75rem; }

.faq-card-active {
  border: 0.25rem solid #90c6de;
  width: 13.5625rem;
  height: 5.75rem; }

.faq-card img {
  width: 4.0625rem;
  height: 4.0625rem; }

.faq-card-title h3 {
  padding: 1.25rem; }

@media (max-width: 84.375rem) {
  #faq-card-section {
    padding: 6.25rem;
    grid-template-columns: 90%;
    justify-content: center;
    padding-left: 0.9375rem;
    padding-right: 0.9375rem; } }

@media (max-width: 71.875rem) {
  .faq-card-container {
    display: grid;
    justify-content: center;
    align-items: center;
    grid-template-areas: '';
    grid-template-rows: repeat(3, auto);
    grid-template-columns: auto;
    row-gap: 3.125rem; } }

@media (max-width: 25rem) {
  .faq-card {
    background-color: #ffffff;
    padding: 0rem;
    display: grid;
    height: 6.25rem;
    width: 6.25rem;
    justify-items: center;
    align-items: center;
    grid-template-areas: '';
    grid-template-columns: auto;
    grid-template-rows: auto;
    border-radius: 0.25rem; }
  .faq-card:hover {
    border: 0.25rem solid #90c6de;
    width: 13.5625rem;
    height: 5.75rem; }
  .faq-card-active {
    border: 0.25rem solid #90c6de;
    /* width: rem(217); */
    height: 5.75rem; }
  .faq-card img {
    width: 4.0625rem;
    height: 4.0625rem; }
  .faq-card-title h3 {
    padding: 0rem; }
  .faq-card-container {
    display: grid;
    width: 100%;
    justify-content: center;
    align-items: center;
    grid-template-areas: '';
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: auto;
    row-gap: 0rem;
    column-gap: 0; } }

/* ==================================================== */
/* FAQ Content Section */
/* ==================================================== */
#faq-section {
  background-color: #fafafa; }

.accordion-container {
  display: flex;
  white-space: nowrap;
  flex-direction: column;
  margin: 0 auto; }
  .accordion-container button:hover {
    color: #90c6de; }

.accordion {
  background-color: #ffffff;
  padding: 1.5625rem;
  /* margin-top: rem(20); */
  text-align: left;
  border-radius: 0.25rem;
  border: none;
  outline: none; }

.accordion ~ .accordion {
  margin-top: 0.125rem; }

.accordion h3 {
  transition: ease-in-out 300ms;
  max-width: 90%; }

.accordion:hover:before {
  content: '\f067'; }

.panel {
  padding: 0 1.25rem 0 0;
  background-color: white;
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.2s ease-out;
  border-bottom-left-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;
  box-shadow: 0.625rem 0.625rem 1.5625rem rgba(56, 56, 56, 0.1); }

.panel p {
  max-width: 90%;
  padding-bottom: 3.125rem;
  padding-left: 0;
  padding: 0.625rem 1.5625rem 2.1875rem; }

.panel p ~ p {
  padding-top: 0;
  padding-bottom: 2.1875rem;
  margin: 0; }

.active {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  box-shadow: none; }

.active h3 {
  color: #90c6de; }

.active.accordion:before {
  color: #90c6de;
  content: '\f068';
  right: 2.5rem; }

hr {
  margin: 0.9375rem 2.1875rem 1.5625rem;
  max-width: 90%;
  color: #ffffff; }

@media (max-width: 56.25rem) {
  .accordion {
    padding: 0.625rem 0.9375rem;
    /* margin: 0 0 rem(10); */ }
  .accordion ~ .accordion {
    margin: 0.125rem 0 0; }
  .accordion:before {
    right: 1.5625rem !important; } }

@media (max-width: 23.4375rem) {
  .accordion {
    padding: 0.625rem 0.9375rem; }
  .accordion h3 {
    max-width: 80%; }
  .panel p {
    padding-left: 0; } }

#faq-content-section {
  background-color: #eeeeee;
  display: grid;
  padding: 0.9375rem; }

.faq-content-container {
  display: grid;
  max-width: 75rem;
  justify-self: center;
  justify-content: center;
  align-items: center;
  grid-template-columns: minmax(auto, 75rem);
  grid-template-rows: auto; }

.faq-content {
  background-color: #ffffff;
  margin: 0 auto;
  display: grid;
  max-width: 75rem; }

.faq-content .panel {
  box-shadow: none !important;
  border-bottom: 0.125rem solid #eee; }

.faq-content-title {
  padding: 0 0 3.125rem;
  justify-self: center;
  text-align: center;
  font-weight: normal; }

.faq-content-title h3 {
  text-transform: unset;
  padding: 0; }

.faq-content-title .title p {
  padding-top: 1.25rem;
  font-weight: 400; }

.accordion-light {
  position: relative;
  cursor: pointer;
  outline: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  z-index: 1;
  color: #adadad;
  padding: 1.875rem 0;
  margin: 0;
  -webkit-transition: height ease-in-out 300ms;
  -moz-transition: height ease-in-out 300ms;
  -ms-transition: height ease-in-out 300ms;
  -o-transition: height ease-in-out 300ms;
  transition: height ease-in-out 300ms; }

@media (max-width: 71.875rem) {
  .faq-content-title {
    padding: 0 0 3.4375rem; } }

/* ==================================================== */
/* FAQ Tutoriel section Section */
/* ==================================================== */
.faq-content {
  padding: 7.5rem 5.625rem 0; }

@media (max-width: 75rem) {
  .faq-content {
    padding: 3.75rem; } }

.faq-content-main {
  padding: 0 0 1.25rem; }

.faq-content-title-card-container + .faq-content-main {
  padding-top: 0; }

/* title */
/* subtitle */
.faq-content-main p {
  padding-bottom: 2rem; }

.faq-content-main img {
  width: 100%;
  margin: 0 0 3.75rem; }

.faq-content-main p ~ img:not(:last-child) {
  width: 100%;
  margin: 1.5625rem 0 6.25rem; }

.faq-content-main p ~ img + p ~ img {
  width: 100%;
  margin: 3.125rem 0; }

@media (max-width: 46.875rem) {
  .faq-content-main p ~ img + p ~ img,
  .faq-content-main p ~ img:not(:last-child) {
    margin-top: 0; } }

/* legend */
.legend-row {
  display: grid;
  justify-content: center;
  align-items: flex-start;
  grid-template-areas: 'icon   content';
  grid-template-columns: auto auto;
  grid-template-rows: auto;
  padding: 0rem 1.875rem 0rem 0; }

.faq-legend-icon {
  grid-area: icon;
  padding-right: 1.25rem; }

@media (max-width: 31.25rem) {
  .legend-row {
    padding: 1.25rem 0; } }

@media (max-width: 25rem) {
  .legend-row {
    grid-template-columns: 4.6875rem auto; }
  .faq-content-legend {
    padding: 0.625rem; } }

/* Card in title section - check list */
.faq-content-title-card-container {
  display: grid;
  justify-content: center;
  align-items: center;
  grid-template-areas: '';
  grid-template-columns: minmax(auto, 42.1875rem);
  grid-template-rows: minmax(auto, 14.6875rem); }

.faq-content-title-card-container p {
  justify-self: center;
  padding: 4.0625rem 0; }

.faq-content-title-card {
  display: grid;
  padding: 1.5625rem 2.1875rem; }

.faq-content-title-card-title {
  justify-self: center;
  padding-bottom: 1.875rem; }

.faq-content-title-card-list {
  justify-self: flex-start; }
  .faq-content-title-card-list li {
    margin: 0.9375rem 0; }
  .faq-content-title-card-list--circle-check {
    color: #94d394;
    padding: 0 0.9375rem; }
    .faq-content-title-card-list--circle-check:before {
      font-family: "iconfont";
      font-style: normal;
      font-weight: 400;
      content: ""; }

.faq-content-title-card-list li {
  color: #787877; }

.faq-content-title-card-list .fa-check-circle {
  padding: 0.9375rem 0.9375rem 0.9375rem 0;
  color: #94d394; }

@media (max-width: 23.4375rem) {
  .faq-content-title-card {
    padding: 1.25rem; }
  .faq-content h3 {
    text-align: left; } }

/* Card tip */
.faq-card-tip {
  padding: 1.875rem 2.5rem;
  margin: 0 0 7.5rem;
  display: grid;
  justify-content: center;
  align-items: center;
  grid-template-areas: 'icon   content' 'img    img';
  grid-template-columns: auto auto;
  grid-template-rows: auto auto; }

.faq-card-tip-icon {
  grid-area: icon;
  align-self: flex-start;
  padding: 0.3125rem 0.625rem; }

.faq-card-tip-content {
  grid-area: content;
  padding-bottom: 0.9375rem; }
  .faq-card-tip-content p {
    margin: 0; }

.faq-card-tip-img {
  grid-area: img;
  display: grid;
  justify-items: center; }

.faq-card-tip-img img {
  width: 100%; }

@media (max-width: 50rem) {
  .faq-card-tip {
    padding: 1.875rem 1.25rem; } }

@media (max-width: 25rem) {
  .faq-card-tip {
    padding: 1.875rem 0.625rem;
    grid-template-columns: 2.5rem auto; }
  .faq-card-tip-icon {
    padding: 0; } }

@media (max-width: 20rem) {
  .faq-card-tip {
    margin: 1.875rem 0 1.5625rem; } }

/* Card warning */
.faq-warning {
  display: grid;
  justify-content: center;
  align-items: center;
  grid-template-areas: 'icon   content' 'img    img';
  grid-template-columns: auto auto;
  grid-template-rows: auto auto;
  padding: 0 0 3.75rem; }

.faq-warning:last-child {
  padding: 0; }

.faq-warning-icon {
  grid-area: icon;
  font-size: 1.5625rem;
  align-self: flex-start;
  padding: 0.3125rem 0.625rem 0.3125rem 0; }

.faq-warning-content p ~ p {
  padding-top: 0.625rem; }

.faq-warning-content {
  grid-area: content;
  padding-bottom: 1.5625rem; }

@media (max-width: 25rem) {
  .faq-warning {
    grid-template-columns: 2.5rem auto;
    grid-template-rows: auto auto; }
  .faq-warning-icon {
    justify-self: center;
    padding: 0 0 0.625rem 0; } }

/* FAQ Two Columns */
.faq-content-two-col {
  display: grid;
  align-items: flex-start;
  grid-template-areas: 'title  title  . img' 'icon   step   . img';
  column-gap: 0;
  grid-template-columns: 3.125rem minmax(auto, 28.125rem) auto auto;
  grid-template-rows: minmax(auto, 6.25rem) auto; }

.faq-content-two-col {
  padding: 0 0 6.25rem; }

.faq-content-two-col ~ .faq-content-two-col {
  padding-bottom: 7.5rem; }

.faq-content-two-col-title {
  grid-area: title;
  color: #787877;
  justify-self: flex-start; }

.faq-content-two-col-step {
  grid-area: step;
  align-self: flex-start; }

.faq-content-two-col-icon {
  grid-area: icon;
  align-self: flex-start;
  justify-self: center; }
  .faq-content-two-col-icon--arrow-right {
    color: #94d394; }
    .faq-content-two-col-icon--arrow-right:before {
      font-family: "iconfont";
      font-style: normal;
      font-weight: 400;
      content: ""; }

.faq-content-two-col-icon {
  color: #94d394;
  padding-right: 0.625rem; }

.faq-content-two-col-img {
  grid-area: img;
  justify-self: flex-end; }

@media (max-width: 62.5rem) {
  .faq-content-two-col {
    grid-template-areas: 'img    img     ' '.      title   ' 'icon   step    ';
    grid-template-columns: 6.25rem auto;
    grid-template-rows: auto auto auto; }
  .faq-content-two-col {
    padding-bottom: 3.125rem; }
  .faq-content-two-col ~ .faq-content-two-col {
    padding-top: 6.25rem; }
  .faq-content-two-col-title {
    grid-area: title;
    color: #787877;
    padding: 1.25rem;
    justify-self: flex-start; }
  .faq-content-two-col-title {
    padding: 1.25rem 0; }
  .faq-content-two-col-step {
    grid-area: step;
    align-self: flex-start;
    justify-self: flex-start;
    max-width: 80%; }
  .faq-content-two-col-icon {
    grid-area: icon;
    align-self: flex-start;
    justify-self: flex-end; }
  .faq-content-two-col-img {
    grid-area: img;
    justify-self: center;
    padding: 1.25rem; } }

@media (max-width: 46.875rem) {
  .faq-content-two-col {
    grid-template-areas: '.      title   ' 'icon   step    ' 'img    img     ';
    grid-template-columns: 2.5rem auto;
    grid-template-rows: auto auto auto;
    padding: 0 0 1.5625rem; }
  .faq-content-two-col ~ .faq-content-two-col {
    padding: 1.875rem 0 3.75rem; } }

@media (max-width: 37.5rem) {
  .faq-content-two-col {
    grid-template-areas: 'img    img     ' 'title      title   ' 'icon   step    ';
    grid-template-columns: auto auto;
    justify-content: center;
    max-width: 28.125rem;
    grid-template-rows: auto auto auto;
    justify-self: center; }
  .faq-content-two-col ~ .faq-content-two-col {
    padding: 6.25rem 0 0; }
  .faq-content-two-col-title {
    grid-area: title;
    color: #787877;
    padding: 1.25rem;
    justify-self: flex-start; }
  .faq-content-two-col-title {
    padding: 1.25rem 0; }
  .faq-content-two-col-step {
    grid-area: step;
    align-self: flex-start;
    justify-self: flex-start;
    max-width: 100%; }
  .faq-content-two-col-icon {
    grid-area: icon;
    align-self: flex-start;
    justify-self: flex-end; }
  .faq-content-two-col-img {
    grid-area: img;
    justify-self: center;
    padding: 1.25rem; } }

@media (max-width: 29.375rem) {
  .faq-content-two-col {
    max-width: 100%; }
  .faq-content-two-col img {
    max-width: 100%; }
  .faq-content-two-col ~ .faq-content-two-col,
  .faq-content-two-col {
    padding: 0.9375rem; } }

/* FAQ list */
.faq-content-list {
  display: grid;
  grid-template-areas: 'item';
  grid-template-columns: auto;
  grid-template-rows: auto auto auto;
  justify-content: flex-start;
  padding-bottom: 1.5625rem; }
  .faq-content-list + p {
    padding-bottom: 3.125rem; }

#proposition .faq-content-list + .faq-content-list {
  padding-bottom: 5rem; }

#replacement .faq-content-list + :not(.faq-content-list) {
  padding: 2.5rem 0 7.5rem; }

.faq-content-list-item {
  grid-area: item;
  display: grid;
  justify-content: center;
  align-items: flex-start;
  grid-template-areas: 'bullet     item-title' '.          item-content' '.          item-content2';
  grid-template-columns: auto auto;
  grid-template-rows: auto auto; }
  .faq-content-list-item h5 {
    grid-area: item-title;
    text-align: left;
    color: #787877;
    padding: 0;
    font-weight: bolder; }
  .faq-content-list-item p {
    grid-area: item-content; }
  .faq-content-list-item p + p {
    grid-area: item-content2; }
  .faq-content-list-item p:not(:last-child) {
    margin: 0.625rem 0 0; }

.faq-content-list-bullet {
  grid-area: bullet;
  padding: 0.625rem 1.25rem 0 0;
  align-content: flex-start; }
  .faq-content-list-bullet--circle:before {
    font-family: "iconfont";
    font-style: normal;
    font-weight: 400;
    content: ""; }

@media (max-width: 46.875rem) {
  .faq-content-list-bullet {
    padding: 0 1.25rem 0 0; }
    .faq-content-list-bullet--circle {
      font-size: 0.75rem; } }

.faq-content-sublist {
  grid-area: item-content;
  display: grid;
  grid-template-areas: 'sub' 'sub2';
  grid-template-columns: auto;
  grid-template-rows: auto auto;
  padding-left: 2.5rem;
  padding-top: 1.875rem; }

.subitem,
.subitem2 {
  display: grid;
  grid-template-areas: 'subicon    subcontent';
  grid-template-columns: auto auto;
  grid-template-rows: auto; }

.subitem {
  grid-area: sub; }

.subitem2 {
  grid-area: sub2;
  padding-top: 1.875rem; }

.faq-content-subitem-icon {
  grid-area: subicon;
  padding-top: 0.3125rem;
  padding-right: 0.9375rem; }
  .faq-content-subitem-icon--star:before {
    font-family: "iconfont";
    font-style: normal;
    font-weight: 400;
    content: ""; }
  .faq-content-subitem-icon--piechart:before {
    font-family: "iconfont";
    font-style: normal;
    font-weight: 400;
    content: ""; }

.subitem p,
.subitem2 p {
  grid-area: subcontent; }

@media (max-width: 46.875rem) {
  .faq-content-sublist {
    padding-left: 0; } }

@media (max-width: 31.25rem) {
  .faq-content-list-item {
    grid-template-columns: 2.5rem auto; }
  .subitem,
  .subitem2 {
    grid-template-columns: 1.875rem auto; }
  .faq-content-list-bullet {
    justify-self: center;
    padding: 0.1875rem 0 0; } }

/* ==================================================== */
/* FAQ Self-employed Section */
/* ==================================================== */
.title-center h3 {
  display: grid !important;
  justify-content: center !important;
  padding-bottom: 3.125rem; }

.title-center p ~ p {
  padding-bottom: 2.5rem; }

.subtitle-center h4 {
  display: grid !important;
  justify-content: center !important; }

.content-center p {
  display: grid !important;
  justify-content: center !important;
  text-align: center; }

.faq-content > p {
  padding-bottom: 3.125rem; }

.faq-content > .circle-card-container {
  display: grid;
  /* column-gap: ; */
  justify-content: space-evenly !important;
  justify-self: center;
  grid-template-areas: 'c-card1     c-card2' !important;
  grid-template-columns: repeat(2, minmax(auto, 21.875rem)) !important;
  grid-template-rows: auto;
  padding-bottom: 3.125rem;
  column-gap: 9.375rem; }

@media (max-width: 62.5rem) {
  .faq-content > .circle-card-container {
    column-gap: 3.125rem; } }

@media (max-width: 53.125rem) {
  .faq-content > .circle-card-container {
    column-gap: 0;
    row-gap: 0;
    grid-template-areas: 'c-card1' 'c-card2' !important;
    grid-template-columns: repeat(1, minmax(auto, 21.875rem)) !important;
    grid-template-rows: repeat(2, minmax(auto, 28.125rem)); } }

/* ==================================================== */
/* ABC Section */
/* ==================================================== */
@media (max-width: 71.875rem) {
  .faq-content {
    padding: 3.75rem; }
  .accordion-light {
    padding: 1.25rem 0; }
  .accordion-light:before {
    right: 1.5625rem; }
  .accordion-light h3 {
    max-width: 80%; } }

@media (max-width: 50rem) {
  .faq-content {
    padding: 3.75rem 1.5625rem; } }

@media (max-width: 25rem) {
  .faq-content {
    padding: 2.5rem 0.9375rem; }
  .accordion-light {
    padding: 0.625rem; }
  .accordion-light h3 {
    max-width: 80%;
    padding: 0; }
  .faq-content-title-card-list .fa-check-circle {
    padding: 0.625rem 0.625rem 0; } }

@media (max-width: 31.25rem) {
  #faq-content-section {
    padding: 0.9375rem 0; }
  .faq-content h3 {
    text-align: left; }
  .faq-content-title-card-title h3 {
    text-align: center; }
  .faq-content-title p {
    padding-top: 2.8125rem; }
  .faq-content-title-card-title {
    padding-bottom: 0.9375rem; } }

/* ==================================================== */
/* Loop Section */
/* ==================================================== */
#faq-loop {
  background-color: #eeeeee; }

#faq-loop h2 {
  padding: 6.25rem 0 1.25rem 6.25rem;
  letter-spacing: 0.125rem; }

/* ==================================================== */
/* Contact Information Section */
/* ==================================================== */
.contact__container {
  display: grid;
  justify-items: center;
  grid-template-areas: '';
  grid-template-columns: auto;
  grid-template-rows: auto;
  text-align: center; }

.contact__image {
  margin: 4.375rem 0 1.5625rem; }
  .contact__image:before {
    font-family: "iconfont";
    font-style: normal;
    font-weight: 400;
    content: ""; }
  .contact__image:before {
    color: #ffffff;
    font-size: 1.875rem;
    font-weight: 600;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #90c6de;
    width: 3.75rem;
    height: 3.75rem;
    border-radius: 50%;
    font-weight: 600 !important; }

.contact__title {
  margin-bottom: 1.25rem; }
  .contact__title h4 {
    font-weight: 600;
    padding-bottom: 0; }

.contact__paragraph {
  margin-bottom: 1.875rem;
  max-width: 56.25rem; }

.helpcenter__faq {
  display: flex;
  flex-direction: column;
  align-items: center; }
  .helpcenter__faq__container {
    background-color: #ffffff;
    box-shadow: 10px 10px 25px rgba(56, 56, 56, 0.35);
    max-width: 75rem;
    padding: 5.625rem 5.625rem 0; }
  .helpcenter__faq__title {
    width: 100%;
    margin-bottom: 6.25rem; }
  @media (max-width: 31.25rem) {
    .helpcenter__faq__title {
      margin-bottom: 3.125rem; } }

.divider {
  height: 2px;
  width: 80%;
  background-color: #b2bfcb;
  margin: 0 auto; }
