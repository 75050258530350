@charset "UTF-8";
@font-face {
  font-family: "iconfont";
  src: url("/iconfont.eot");
  /* IE9 Compat Modes */
  src: url("/iconfont.eot?#iefix") format("embedded-opentype"), url("/iconfont.woff") format("woff"), url("/iconfont.ttf") format("truetype"), url("/iconfont.svg") format("svg");
  /* Legacy iOS */ }

.icon {
  font-style: normal;
  font-weight: 400; }
  .icon.accordion-close:before {
    font-family: "iconfont";
    content: ""; }
  .icon.accordion-open:before {
    font-family: "iconfont";
    content: ""; }
  .icon.add-folder:before {
    font-family: "iconfont";
    content: ""; }
  .icon.add:before {
    font-family: "iconfont";
    content: ""; }
  .icon.admin:before {
    font-family: "iconfont";
    content: ""; }
  .icon.all-day:before {
    font-family: "iconfont";
    content: ""; }
  .icon.arrow-left:before {
    font-family: "iconfont";
    content: ""; }
  .icon.arrow-right:before {
    font-family: "iconfont";
    content: ""; }
  .icon.assignment:before {
    font-family: "iconfont";
    content: ""; }
  .icon.association-clinic:before {
    font-family: "iconfont";
    content: ""; }
  .icon.association:before {
    font-family: "iconfont";
    content: ""; }
  .icon.bars:before {
    font-family: "iconfont";
    content: ""; }
  .icon.calendar-2:before {
    font-family: "iconfont";
    content: ""; }
  .icon.calendar-today:before {
    font-family: "iconfont";
    content: ""; }
  .icon.calendar:before {
    font-family: "iconfont";
    content: ""; }
  .icon.call-list:before {
    font-family: "iconfont";
    content: ""; }
  .icon.checkbox-off:before {
    font-family: "iconfont";
    content: ""; }
  .icon.checkbox-on:before {
    font-family: "iconfont";
    content: ""; }
  .icon.checkbox-square:before {
    font-family: "iconfont";
    content: ""; }
  .icon.circle-add:before {
    font-family: "iconfont";
    content: ""; }
  .icon.circle-check:before {
    font-family: "iconfont";
    content: ""; }
  .icon.circle-info:before {
    font-family: "iconfont";
    content: ""; }
  .icon.circlo-close:before {
    font-family: "iconfont";
    content: ""; }
  .icon.clinic:before {
    font-family: "iconfont";
    content: ""; }
  .icon.close-slim:before {
    font-family: "iconfont";
    content: ""; }
  .icon.close:before {
    font-family: "iconfont";
    content: ""; }
  .icon.closed-folder:before {
    font-family: "iconfont";
    content: ""; }
  .icon.copy:before {
    font-family: "iconfont";
    content: ""; }
  .icon.delete:before {
    font-family: "iconfont";
    content: ""; }
  .icon.disconnect:before {
    font-family: "iconfont";
    content: ""; }
  .icon.dotted-arrow-right:before {
    font-family: "iconfont";
    content: ""; }
  .icon.dotted-arrow:before {
    font-family: "iconfont";
    content: ""; }
  .icon.edit:before {
    font-family: "iconfont";
    content: ""; }
  .icon.email:before {
    font-family: "iconfont";
    content: ""; }
  .icon.featured:before {
    font-family: "iconfont";
    content: ""; }
  .icon.handle-h:before {
    font-family: "iconfont";
    content: ""; }
  .icon.handle-v:before {
    font-family: "iconfont";
    content: ""; }
  .icon.help-circle:before {
    font-family: "iconfont";
    content: ""; }
  .icon.help:before {
    font-family: "iconfont";
    content: ""; }
  .icon.history:before {
    font-family: "iconfont";
    content: ""; }
  .icon.info:before {
    font-family: "iconfont";
    content: ""; }
  .icon.lock-open:before {
    font-family: "iconfont";
    content: ""; }
  .icon.lock:before {
    font-family: "iconfont";
    content: ""; }
  .icon.lodging:before {
    font-family: "iconfont";
    content: ""; }
  .icon.magnifying-glass:before {
    font-family: "iconfont";
    content: ""; }
  .icon.map:before {
    font-family: "iconfont";
    content: ""; }
  .icon.marker:before {
    font-family: "iconfont";
    content: ""; }
  .icon.notifications:before {
    font-family: "iconfont";
    content: ""; }
  .icon.open-folder:before {
    font-family: "iconfont";
    content: ""; }
  .icon.pencil:before {
    font-family: "iconfont";
    content: ""; }
  .icon.piechart:before {
    font-family: "iconfont";
    content: ""; }
  .icon.pin:before {
    font-family: "iconfont";
    content: ""; }
  .icon.profile:before {
    font-family: "iconfont";
    content: ""; }
  .icon.schedule:before {
    font-family: "iconfont";
    content: ""; }
  .icon.settings:before {
    font-family: "iconfont";
    content: ""; }
  .icon.small-down-arrow:before {
    font-family: "iconfont";
    content: ""; }
  .icon.small-up-arrow:before {
    font-family: "iconfont";
    content: ""; }
  .icon.status-check:before {
    font-family: "iconfont";
    content: ""; }
  .icon.surgery-optional:before {
    font-family: "iconfont";
    content: ""; }
  .icon.surgery:before {
    font-family: "iconfont";
    content: ""; }
  .icon.time:before {
    font-family: "iconfont";
    content: ""; }
  .icon.timesheet:before {
    font-family: "iconfont";
    content: ""; }
  .icon.triangle:before {
    font-family: "iconfont";
    content: ""; }
  .icon.tsa:before {
    font-family: "iconfont";
    content: ""; }
  .icon.validation:before {
    font-family: "iconfont";
    content: ""; }
  .icon.vet:before {
    font-family: "iconfont";
    content: ""; }

.field {
  display: inline-block;
  margin: 0.3125em 0 0.3125em;
  position: relative;
  padding: 0.9375em 0 1.25em; }
  .field--inner {
    padding-top: 0; }
    .field--inner .field__label {
      pointer-events: none;
      position: absolute;
      margin-bottom: 0;
      padding: 0.3125em 0.625em 0;
      top: initial; }
    .field--inner label {
      position: relative;
      display: flex;
      align-items: center; }
    .field--inner .field__input:focus + .field__label {
      display: none; }
    .field--inner.field--dirty .field__label {
      display: none; }
  .field--align-right .field__input, .field--align-right .field__label {
    text-align: right; }
  .field--light .field__label, .field--full-light .field__label {
    color: #414a52; }
  .field__with-icon .field__label {
    padding-left: 2.5em; }
    .field__with-icon .field__label:before {
      position: absolute;
      left: 0.625em;
      margin-top: -2px;
      font-size: 1.125em; }
  .field__with-icon--email .field__label:before {
    font-family: "iconfont";
    font-style: normal;
    font-weight: 400;
    content: ""; }
  .field__with-icon--lock .field__label:before {
    font-family: "iconfont";
    font-style: normal;
    font-weight: 400;
    content: ""; }
  .field__with-icon--right .field__label {
    padding-right: 2.5em; }
    .field__with-icon--right .field__label:before {
      position: absolute;
      right: 0.625em;
      margin-top: -2px;
      font-size: 1.125em; }
  .field__with-icon--right--calendar .field__label:before {
    font-family: "iconfont";
    font-style: normal;
    font-weight: 400;
    content: ""; }
  .field--border .field__input {
    border: 1px solid #d8dfe5; }
  .field__wanted-count {
    width: 100%;
    padding-top: .25em;
    padding-bottom: 0; }
    .field__wanted-count.field--error {
      padding-bottom: 1.875em; }
    .field__wanted-count label {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%; }
    .field__wanted-count .field__label {
      position: initial;
      font-size: 0.75em;
      color: #414a52; }
    .field__wanted-count input {
      width: 3.75em;
      order: 2;
      border-radius: 0.1875em;
      border: 0.0625em solid #d8dfe5; }
  .field--hidden .field__label {
    display: none; }
  .field .field--invalid, .field.field--invalid {
    border-color: #f76b7e; }

.field__error {
  position: absolute;
  bottom: -0.125rem;
  color: #d93025;
  font-size: 0.75em;
  display: block;
  left: 0;
  height: 1.5625em;
  white-space: normal;
  min-width: 10rem; }

.field__label {
  width: 100%;
  display: block;
  margin-bottom: 0.1875em;
  font-size: 0.75em;
  color: #a0adb9;
  position: absolute;
  top: 0; }

.fields-one-col {
  display: flex;
  width: calc(100% + 1.25em);
  margin: 0 -0.625em; }
  .fields-one-col > * {
    width: 100%;
    padding-left: 0.625em;
    padding-right: 0.625em; }
  .fields-one-col .field .field__error {
    left: 0.6875em; }
  .fields-one-col--conditional {
    background-color: #f3f5f6;
    position: relative;
    flex-direction: column;
    margin-bottom: 1em;
    padding: .5em 0 .1em 0; }
    .fields-one-col--conditional:before {
      content: "";
      width: 0;
      height: 0;
      border-left: 0.625rem solid transparent;
      border-bottom: 0.625rem solid #f3f5f6;
      border-right: 0.625rem solid transparent;
      position: absolute;
      bottom: 100%;
      right: 50%; }
    .fields-one-col--conditional .field {
      width: 100%; }
    .fields-one-col--conditional .field__select__wrapper {
      background-color: white; }
    .fields-one-col--conditional .radio-custom__hide {
      background-color: #f3f5f6; }

.fields-two-col {
  display: flex;
  width: calc(100% + 1.25em);
  justify-content: space-between;
  margin: 0 -0.625em; }
  .fields-two-col > * {
    width: 50%;
    padding-left: 0.625em;
    padding-right: 0.625em; }
  .fields-two-col .field .field__error {
    left: 0.6875em; }

.side-panel .field--light .field__label {
  color: #a0adb9; }

.field__autocomplete {
  padding-bottom: 0.9375em;
  padding-top: 0; }
  .field__autocomplete > * {
    width: 100%; }
    .field__autocomplete > * > div {
      background: #fff !important;
      font-size: 0.875em;
      font-family: "F37Ginger", "HelveticaNeue-Light", "Helvetica Neue Light", "Helvetica Neue", Helvetica, Arial, "Lucida Grande", sans-serif;
      letter-spacing: 0.0625rem;
      color: #a0adb9;
      padding: 0 !important;
      border-radius: 0.1875em;
      border: 0.0625em solid #d8dfe5;
      box-shadow: none !important;
      z-index: 5; }
  .field__autocomplete input {
    width: 100%;
    outline: none;
    background: #fff;
    font-size: 0.875em;
    font-family: "F37Ginger", "HelveticaNeue-Light", "Helvetica Neue Light", "Helvetica Neue", Helvetica, Arial, "Lucida Grande", sans-serif;
    letter-spacing: 0.0625rem;
    color: #414a52;
    padding: 0.75em 0.625em;
    border-radius: 0.1875em;
    border: 0.0625em solid #d8dfe5; }
  .field__autocomplete-result {
    padding: 0.625em;
    cursor: default; }
    .field__autocomplete-result--highlighted {
      background-color: #d8eaf2;
      color: #414a52;
      cursor: pointer; }

.field__checkbox-list {
  display: flex;
  font-size: 0.75em;
  margin-top: 0.3125em;
  margin-bottom: 0.3125em;
  padding-top: 0.9375em;
  padding-bottom: 0.9375em; }
  .field__checkbox-list__label {
    display: block;
    font-size: 0.875rem;
    line-height: 1.125rem;
    width: 40%;
    flex-shrink: 0;
    padding-right: 1.25rem;
    color: #403b42; }
  .field__checkbox-list__wrapper {
    display: flex;
    flex-direction: column; }
    .field__checkbox-list__wrapper--no-label {
      padding: 0 0.9375rem; }
  .field__checkbox-list__other .field:first-child {
    padding-bottom: 0; }
  .field__checkbox-list__other .field--no-label .field__label {
    display: none; }
  .field__checkbox-list__other .field--no-label .field__error {
    bottom: -1.7em;
    left: 0; }
  .field__checkbox-list .field {
    padding: 0 0 0.75rem; }
    .field__checkbox-list .field:last-child {
      padding-bottom: 0.25rem; }
  .field__checkbox-list .field__checkbox__label {
    color: #414a52;
    font-size: 0.875rem;
    padding-bottom: 0.125rem; }
    .field__checkbox-list .field__checkbox__label:before {
      top: -0.125em; }
  .field__checkbox-list .field__checkbox--round__label {
    padding-top: 0.125rem;
    color: #414a52;
    line-height: 1.125rem; }
    .field__checkbox-list .field__checkbox--round__label:before {
      border: 1px solid #a0adb9;
      position: relative;
      top: -0.125rem; }
  .field__checkbox-list .field__checkbox--round .field__checkbox__label span {
    font-size: 0.875rem;
    color: #403b42; }
  @media only screen and (max-width: 25rem) {
    .field__checkbox-list {
      flex-wrap: wrap; }
      .field__checkbox-list__label {
        width: 100%;
        padding-bottom: 0.75rem; } }

.field--locked .field {
  width: 100%;
  position: relative; }
  .field--locked .field:before {
    font-family: "iconfont";
    font-style: normal;
    font-weight: 400;
    content: ""; }
  .field--locked .field:before {
    position: absolute;
    height: 1.125rem;
    top: calc(50% - 9px);
    right: 0.625em;
    color: #a0adb9; }

input[type="text"], input[type="password"], input[type="tel"], input[type="number"], input[type="date"], input[type="datetime"] {
  -webkit-appearance: none; }

.side-panel__form__section__title + .field__react-date {
  margin-top: 0; }
