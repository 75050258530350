@charset "UTF-8";
@font-face {
  font-family: "iconfont";
  src: url("/iconfont.eot");
  /* IE9 Compat Modes */
  src: url("/iconfont.eot?#iefix") format("embedded-opentype"), url("/iconfont.woff") format("woff"), url("/iconfont.ttf") format("truetype"), url("/iconfont.svg") format("svg");
  /* Legacy iOS */ }

.icon {
  font-style: normal;
  font-weight: 400; }
  .icon.accordion-close:before {
    font-family: "iconfont";
    content: ""; }
  .icon.accordion-open:before {
    font-family: "iconfont";
    content: ""; }
  .icon.add-folder:before {
    font-family: "iconfont";
    content: ""; }
  .icon.add:before {
    font-family: "iconfont";
    content: ""; }
  .icon.admin:before {
    font-family: "iconfont";
    content: ""; }
  .icon.all-day:before {
    font-family: "iconfont";
    content: ""; }
  .icon.arrow-left:before {
    font-family: "iconfont";
    content: ""; }
  .icon.arrow-right:before {
    font-family: "iconfont";
    content: ""; }
  .icon.assignment:before {
    font-family: "iconfont";
    content: ""; }
  .icon.association-clinic:before {
    font-family: "iconfont";
    content: ""; }
  .icon.association:before {
    font-family: "iconfont";
    content: ""; }
  .icon.bars:before {
    font-family: "iconfont";
    content: ""; }
  .icon.calendar-2:before {
    font-family: "iconfont";
    content: ""; }
  .icon.calendar-today:before {
    font-family: "iconfont";
    content: ""; }
  .icon.calendar:before {
    font-family: "iconfont";
    content: ""; }
  .icon.call-list:before {
    font-family: "iconfont";
    content: ""; }
  .icon.checkbox-off:before {
    font-family: "iconfont";
    content: ""; }
  .icon.checkbox-on:before {
    font-family: "iconfont";
    content: ""; }
  .icon.checkbox-square:before {
    font-family: "iconfont";
    content: ""; }
  .icon.circle-add:before {
    font-family: "iconfont";
    content: ""; }
  .icon.circle-check:before {
    font-family: "iconfont";
    content: ""; }
  .icon.circle-info:before {
    font-family: "iconfont";
    content: ""; }
  .icon.circlo-close:before {
    font-family: "iconfont";
    content: ""; }
  .icon.clinic:before {
    font-family: "iconfont";
    content: ""; }
  .icon.close-slim:before {
    font-family: "iconfont";
    content: ""; }
  .icon.close:before {
    font-family: "iconfont";
    content: ""; }
  .icon.closed-folder:before {
    font-family: "iconfont";
    content: ""; }
  .icon.copy:before {
    font-family: "iconfont";
    content: ""; }
  .icon.delete:before {
    font-family: "iconfont";
    content: ""; }
  .icon.disconnect:before {
    font-family: "iconfont";
    content: ""; }
  .icon.dotted-arrow-right:before {
    font-family: "iconfont";
    content: ""; }
  .icon.dotted-arrow:before {
    font-family: "iconfont";
    content: ""; }
  .icon.edit:before {
    font-family: "iconfont";
    content: ""; }
  .icon.email:before {
    font-family: "iconfont";
    content: ""; }
  .icon.featured:before {
    font-family: "iconfont";
    content: ""; }
  .icon.handle-h:before {
    font-family: "iconfont";
    content: ""; }
  .icon.handle-v:before {
    font-family: "iconfont";
    content: ""; }
  .icon.help-circle:before {
    font-family: "iconfont";
    content: ""; }
  .icon.help:before {
    font-family: "iconfont";
    content: ""; }
  .icon.history:before {
    font-family: "iconfont";
    content: ""; }
  .icon.info:before {
    font-family: "iconfont";
    content: ""; }
  .icon.lock-open:before {
    font-family: "iconfont";
    content: ""; }
  .icon.lock:before {
    font-family: "iconfont";
    content: ""; }
  .icon.lodging:before {
    font-family: "iconfont";
    content: ""; }
  .icon.magnifying-glass:before {
    font-family: "iconfont";
    content: ""; }
  .icon.map:before {
    font-family: "iconfont";
    content: ""; }
  .icon.marker:before {
    font-family: "iconfont";
    content: ""; }
  .icon.notifications:before {
    font-family: "iconfont";
    content: ""; }
  .icon.open-folder:before {
    font-family: "iconfont";
    content: ""; }
  .icon.pencil:before {
    font-family: "iconfont";
    content: ""; }
  .icon.piechart:before {
    font-family: "iconfont";
    content: ""; }
  .icon.pin:before {
    font-family: "iconfont";
    content: ""; }
  .icon.profile:before {
    font-family: "iconfont";
    content: ""; }
  .icon.schedule:before {
    font-family: "iconfont";
    content: ""; }
  .icon.settings:before {
    font-family: "iconfont";
    content: ""; }
  .icon.small-down-arrow:before {
    font-family: "iconfont";
    content: ""; }
  .icon.small-up-arrow:before {
    font-family: "iconfont";
    content: ""; }
  .icon.status-check:before {
    font-family: "iconfont";
    content: ""; }
  .icon.surgery-optional:before {
    font-family: "iconfont";
    content: ""; }
  .icon.surgery:before {
    font-family: "iconfont";
    content: ""; }
  .icon.time:before {
    font-family: "iconfont";
    content: ""; }
  .icon.timesheet:before {
    font-family: "iconfont";
    content: ""; }
  .icon.triangle:before {
    font-family: "iconfont";
    content: ""; }
  .icon.tsa:before {
    font-family: "iconfont";
    content: ""; }
  .icon.validation:before {
    font-family: "iconfont";
    content: ""; }
  .icon.vet:before {
    font-family: "iconfont";
    content: ""; }

.matchmaking__miniprofile {
  position: relative;
  transition: all ease-in-out 200ms; }
  .matchmaking__miniprofile__wrapper {
    -webkit-animation: slide-right 300ms 1;
    -moz-animation: slide-right 300ms 1;
    -ms-animation: slide-right 300ms 1;
    -o-animation: slide-right 300ms 1;
    animation: slide-right 300ms 1;
    background-color: white;
    border-radius: 0.25rem;
    box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.25);
    cursor: default;
    left: 1.5625rem;
    margin: 0 auto;
    max-width: 400px;
    min-width: 255px;
    overflow: hidden;
    position: absolute;
    top: -1.25rem;
    z-index: +100;
    overflow: visible; }

@-webkit-keyframes slide-right {
  0% {
    transform: translateX(-20px);
    opacity: 0; }
  100% {
    transform: translateX(0);
    opacity: 1; } }

@-moz-keyframes slide-right {
  0% {
    transform: translateX(-20px);
    opacity: 0; }
  100% {
    transform: translateX(0);
    opacity: 1; } }

@-ms-keyframes slide-right {
  .matchmaking__miniprofile__wrapper 0% {
    transform: translateX(-20px);
    opacity: 0; }
  .matchmaking__miniprofile__wrapper 100% {
    transform: translateX(0);
    opacity: 1; } }

@-o-keyframes slide-right {
  0% {
    transform: translateX(-20px);
    opacity: 0; }
  100% {
    transform: translateX(0);
    opacity: 1; } }

@keyframes slide-right {
  0% {
    transform: translateX(-20px);
    opacity: 0; }
  100% {
    transform: translateX(0);
    opacity: 1; } }
    .matchmaking__miniprofile__wrapper:after {
      content: "";
      position: absolute;
      top: 1.375rem;
      left: 0%;
      margin-left: -14px;
      border-width: 7px;
      border-style: solid;
      border-color: transparent white transparent transparent; }
  .matchmaking__miniprofile__header {
    align-items: center;
    display: flex;
    font-size: 0.9375rem;
    font-weight: 600;
    justify-content: space-between;
    padding: 1.125rem 0.25rem 0.875rem 1.125rem;
    text-align: left;
    text-transform: uppercase;
    font-size: 0.9375rem;
    color: #414a52;
    font-weight: 700;
    text-transform: uppercase;
    letter-spacing: .05em;
    padding: 1.125rem 1.125rem 0.875rem; }
    .matchmaking__miniprofile__header button {
      all: unset;
      padding: 0.1875rem 0;
      cursor: pointer; }
  .matchmaking__miniprofile__icon {
    border-radius: 50%;
    padding: 0.625rem 0.625rem 0.3125rem;
    transition: all ease-in-out 200ms; }
    .matchmaking__miniprofile__icon--copy-address {
      font-size: 0.875rem; }
      .matchmaking__miniprofile__icon--copy-address:before {
        font-family: "iconfont";
        font-style: normal;
        font-weight: 400;
        content: ""; }
    .matchmaking__miniprofile__icon--map {
      font-size: 0.875rem; }
      .matchmaking__miniprofile__icon--map:before {
        font-family: "iconfont";
        font-style: normal;
        font-weight: 400;
        content: ""; }
    .matchmaking__miniprofile__icon--edit {
      font-size: 0.8125rem;
      color: #a0adb9; }
      .matchmaking__miniprofile__icon--edit:before {
        font-family: "iconfont";
        font-style: normal;
        font-weight: 400;
        content: ""; }
    .matchmaking__miniprofile__icon:hover {
      color: #4c4648; }
  .matchmaking__miniprofile__section {
    align-items: center;
    background-color: rgba(65, 74, 82, 0.05);
    border-top: 0.0625em solid #d8dfe5;
    display: flex;
    flex-direction: column;
    font-size: 0.6875rem;
    justify-content: space-between;
    padding: 0.75rem 1.125rem;
    text-align: left; }
    .matchmaking__miniprofile__section p {
      font-size: 0.6875rem;
      margin-top: 0.1875rem; }
    .matchmaking__miniprofile__section__title {
      font-size: 0.6875rem;
      font-weight: 600; }
    .matchmaking__miniprofile__section--address {
      align-items: flex-start;
      min-height: 5.125rem; }
    .matchmaking__miniprofile__section--adminNote {
      white-space: pre-line;
      background-color: #fff; }
  .matchmaking__miniprofile--selected {
    left: 1.5625rem; }
