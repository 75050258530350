/* http://meyerweb.com/eric/tools/css/reset/
   v2.0 | 20110126
   License: none (public domain)
*/
html, body, div, span, applet, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, a, abbr, acronym, address, big, cite, code, del, dfn, em, img, ins, kbd, q, s, samp, small, strike, strong, sub, sup, tt, var, b, u, i, center, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td, article, aside, canvas, details, embed, figure, figcaption, footer, header, hgroup, menu, nav, output, ruby, section, summary, time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline; }

/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure, footer, header, hgroup, menu, nav, section {
  display: block; }

body {
  line-height: 1; }

ol, ul {
  list-style: none; }

blockquote, q {
  quotes: none; }

blockquote:before, blockquote:after {
  content: '';
  content: none; }

q:before, q:after {
  content: '';
  content: none; }

table {
  border-collapse: collapse;
  border-spacing: 0; }

* {
  box-sizing: border-box; }

button {
  outline: none;
  background: none;
  border: none;
  cursor: pointer; }

@font-face {
  font-family: "F37Ginger";
  src: url("../../assets/fonts/f37ginger/F37Ginger-Bold.otf");
  font-weight: 600;
  font-style: normal; }

@font-face {
  font-family: "F37Ginger";
  src: url("../../assets/fonts/f37ginger/F37Ginger-BoldItalic.otf");
  font-weight: 600;
  font-style: italic; }

@font-face {
  font-family: "F37Ginger";
  src: url("../../assets/fonts/f37ginger/F37Ginger-Italic.otf");
  font-weight: 400;
  font-style: italic; }

@font-face {
  font-family: "F37Ginger";
  src: url("../../assets/fonts/f37ginger/F37Ginger-Light.otf");
  font-weight: 300;
  font-style: normal; }

@font-face {
  font-family: "F37Ginger";
  src: url("../../assets/fonts/f37ginger/F37Ginger-LightItalic.otf");
  font-weight: 300;
  font-style: italic; }

@font-face {
  font-family: "F37Ginger";
  src: url("../../assets/fonts/f37ginger/F37Ginger-Regular.otf");
  font-weight: 400;
  font-style: normal; }

@font-face {
  font-family: "F37Ginger";
  src: url("../../assets/fonts/f37ginger/F37Ginger-Thin.otf");
  font-weight: 200;
  font-style: normal; }

@font-face {
  font-family: "F37Ginger";
  src: url("../../assets/fonts/f37ginger/F37Ginger-ThinItalic.otf");
  font-weight: 200;
  font-style: italic; }

@font-face {
  font-family: "iconfont";
  src: url("../../assets/fonts/icons/iconfont.eot");
  src: url("../../assets/fonts/icons/iconfont.eot?#iefix") format("embedded-opentype"), url("../../assets/fonts/icons/iconfont.woff") format("woff");
  font-weight: 400;
  font-style: normal; }

html,
button,
input,
select,
textarea {
  color: #000; }

h1, h2, h3, h4, h5, h6 {
  font-weight: normal; }

p {
  font-size: 1.1em;
  font-weight: 300;
  line-height: 1.5;
  margin: 0 auto 2em;
  max-width: 48em; }

a {
  text-decoration: none;
  border: none;
  outline: none;
  color: #797979; }
  a:hover, a:active {
    text-decoration: none;
    outline: none;
    color: inherit; }
  a:link {
    outline: none; }

strong, b {
  font-weight: bold; }

ul {
  padding: 0;
  margin: 0;
  list-style: none; }

hr {
  display: block;
  height: 1px;
  border: 0;
  border-top: 1px solid #ccc;
  margin: 1em 0;
  padding: 0; }

sup,
sub {
  text-transform: none; }

img {
  max-width: 100%; }

body {
  font-size: 1rem;
  font-family: "F37Ginger", "HelveticaNeue-Light", "Helvetica Neue Light", "Helvetica Neue", Helvetica, Arial, "Lucida Grande", sans-serif;
  font-weight: 400;
  letter-spacing: 0.0625em;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #f7f8f9; }

html,
button,
input,
select,
textarea {
  color: #000000;
  font-family: "F37Ginger", "HelveticaNeue-Light", "Helvetica Neue Light", "Helvetica Neue", Helvetica, Arial, "Lucida Grande", sans-serif; }

h1, h2, h3, h4, h5, h6 {
  font-weight: normal; }

a {
  text-decoration: none;
  border: none;
  outline: none; }
  a:hover, a:active {
    text-decoration: none;
    outline: none; }
  a:link {
    outline: none; }

strong, b {
  font-weight: bold; }

ul {
  padding: 0;
  margin: 0;
  list-style: none; }

hr {
  display: block;
  height: 1px;
  border: 0;
  border-top: 1px solid #ccc;
  margin: 1em 0;
  padding: 0; }

sup,
sub {
  text-transform: none; }

p {
  line-height: 1.25rem;
  margin: 0 0 1em; }

img {
  max-width: 100%; }

button, [role="button"] {
  outline: none;
  cursor: pointer; }
