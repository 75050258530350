@charset "UTF-8";
@font-face {
  font-family: "iconfont";
  src: url("/iconfont.eot");
  /* IE9 Compat Modes */
  src: url("/iconfont.eot?#iefix") format("embedded-opentype"), url("/iconfont.woff") format("woff"), url("/iconfont.ttf") format("truetype"), url("/iconfont.svg") format("svg");
  /* Legacy iOS */ }

.icon {
  font-style: normal;
  font-weight: 400; }
  .icon.accordion-close:before {
    font-family: "iconfont";
    content: ""; }
  .icon.accordion-open:before {
    font-family: "iconfont";
    content: ""; }
  .icon.add-folder:before {
    font-family: "iconfont";
    content: ""; }
  .icon.add:before {
    font-family: "iconfont";
    content: ""; }
  .icon.admin:before {
    font-family: "iconfont";
    content: ""; }
  .icon.all-day:before {
    font-family: "iconfont";
    content: ""; }
  .icon.arrow-left:before {
    font-family: "iconfont";
    content: ""; }
  .icon.arrow-right:before {
    font-family: "iconfont";
    content: ""; }
  .icon.assignment:before {
    font-family: "iconfont";
    content: ""; }
  .icon.association-clinic:before {
    font-family: "iconfont";
    content: ""; }
  .icon.association:before {
    font-family: "iconfont";
    content: ""; }
  .icon.bars:before {
    font-family: "iconfont";
    content: ""; }
  .icon.calendar-2:before {
    font-family: "iconfont";
    content: ""; }
  .icon.calendar-today:before {
    font-family: "iconfont";
    content: ""; }
  .icon.calendar:before {
    font-family: "iconfont";
    content: ""; }
  .icon.call-list:before {
    font-family: "iconfont";
    content: ""; }
  .icon.checkbox-off:before {
    font-family: "iconfont";
    content: ""; }
  .icon.checkbox-on:before {
    font-family: "iconfont";
    content: ""; }
  .icon.checkbox-square:before {
    font-family: "iconfont";
    content: ""; }
  .icon.circle-add:before {
    font-family: "iconfont";
    content: ""; }
  .icon.circle-check:before {
    font-family: "iconfont";
    content: ""; }
  .icon.circle-info:before {
    font-family: "iconfont";
    content: ""; }
  .icon.circlo-close:before {
    font-family: "iconfont";
    content: ""; }
  .icon.clinic:before {
    font-family: "iconfont";
    content: ""; }
  .icon.close-slim:before {
    font-family: "iconfont";
    content: ""; }
  .icon.close:before {
    font-family: "iconfont";
    content: ""; }
  .icon.closed-folder:before {
    font-family: "iconfont";
    content: ""; }
  .icon.copy:before {
    font-family: "iconfont";
    content: ""; }
  .icon.delete:before {
    font-family: "iconfont";
    content: ""; }
  .icon.disconnect:before {
    font-family: "iconfont";
    content: ""; }
  .icon.dotted-arrow-right:before {
    font-family: "iconfont";
    content: ""; }
  .icon.dotted-arrow:before {
    font-family: "iconfont";
    content: ""; }
  .icon.edit:before {
    font-family: "iconfont";
    content: ""; }
  .icon.email:before {
    font-family: "iconfont";
    content: ""; }
  .icon.featured:before {
    font-family: "iconfont";
    content: ""; }
  .icon.handle-h:before {
    font-family: "iconfont";
    content: ""; }
  .icon.handle-v:before {
    font-family: "iconfont";
    content: ""; }
  .icon.help-circle:before {
    font-family: "iconfont";
    content: ""; }
  .icon.help:before {
    font-family: "iconfont";
    content: ""; }
  .icon.history:before {
    font-family: "iconfont";
    content: ""; }
  .icon.info:before {
    font-family: "iconfont";
    content: ""; }
  .icon.lock-open:before {
    font-family: "iconfont";
    content: ""; }
  .icon.lock:before {
    font-family: "iconfont";
    content: ""; }
  .icon.lodging:before {
    font-family: "iconfont";
    content: ""; }
  .icon.magnifying-glass:before {
    font-family: "iconfont";
    content: ""; }
  .icon.map:before {
    font-family: "iconfont";
    content: ""; }
  .icon.marker:before {
    font-family: "iconfont";
    content: ""; }
  .icon.notifications:before {
    font-family: "iconfont";
    content: ""; }
  .icon.open-folder:before {
    font-family: "iconfont";
    content: ""; }
  .icon.pencil:before {
    font-family: "iconfont";
    content: ""; }
  .icon.piechart:before {
    font-family: "iconfont";
    content: ""; }
  .icon.pin:before {
    font-family: "iconfont";
    content: ""; }
  .icon.profile:before {
    font-family: "iconfont";
    content: ""; }
  .icon.schedule:before {
    font-family: "iconfont";
    content: ""; }
  .icon.settings:before {
    font-family: "iconfont";
    content: ""; }
  .icon.small-down-arrow:before {
    font-family: "iconfont";
    content: ""; }
  .icon.small-up-arrow:before {
    font-family: "iconfont";
    content: ""; }
  .icon.status-check:before {
    font-family: "iconfont";
    content: ""; }
  .icon.surgery-optional:before {
    font-family: "iconfont";
    content: ""; }
  .icon.surgery:before {
    font-family: "iconfont";
    content: ""; }
  .icon.time:before {
    font-family: "iconfont";
    content: ""; }
  .icon.timesheet:before {
    font-family: "iconfont";
    content: ""; }
  .icon.triangle:before {
    font-family: "iconfont";
    content: ""; }
  .icon.tsa:before {
    font-family: "iconfont";
    content: ""; }
  .icon.validation:before {
    font-family: "iconfont";
    content: ""; }
  .icon.vet:before {
    font-family: "iconfont";
    content: ""; }

.timesheets__wrapper {
  position: relative;
  padding-bottom: 3.125em; }

.timesheets__export {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 0.625em; }
  .timesheets__export .field {
    padding: 0;
    margin: 0;
    display: flex;
    align-items: center; }
    .timesheets__export .field__label {
      display: block;
      position: relative;
      top: 0.1875rem;
      width: auto;
      margin-right: 0.625em; }
    .timesheets__export .field__select__wrapper {
      flex-shrink: 0;
      width: 8.125em; }
    .timesheets__export .field button {
      background: #90c6de;
      color: #fff;
      padding: 1.0625em 1.125em 0.875em;
      text-transform: uppercase;
      font-size: 0.6875em;
      line-height: 1.0625em;
      font-weight: 700;
      border: none;
      cursor: pointer;
      border-radius: 0.1875em;
      outline: none;
      letter-spacing: 0.8px;
      margin-left: 0.625em;
      margin-right: 0.625em;
      flex-shrink: 0; }
      .timesheets__export .field button:hover {
        background-color: #80bed9;
        color: #fff; }
      .timesheets__export .field button[disabled] {
        opacity: 0.4;
        background: #90c6de;
        cursor: default;
        color: #fff; }
  .timesheets__export a {
    background: #94d394;
    color: #fff;
    padding: 1.0625em 1.125em 0.875em;
    text-transform: uppercase;
    font-size: 0.6875em;
    line-height: 1.0625em;
    font-weight: 700;
    border: none;
    cursor: pointer;
    border-radius: 0.1875em;
    outline: none;
    letter-spacing: 0.8px; }
    .timesheets__export a:hover {
      background-color: #86cd86;
      color: #fff; }
    .timesheets__export a[disabled] {
      opacity: 0.4;
      background: #94d394;
      cursor: default;
      color: #fff; }

.timesheets__toolbar {
  padding-bottom: 2.1875em;
  border-bottom: 1px solid #d8dfe5;
  display: flex;
  justify-content: space-between;
  align-items: center; }
  .timesheets__toolbar > * {
    display: inline-block; }
  .timesheets__toolbar__title {
    position: relative; }
    .timesheets__toolbar__title > h2 {
      display: inline-block;
      font-size: 1.875em;
      font-weight: 700;
      margin-right: 0.9375em;
      color: #414a52;
      position: relative;
      top: 0.1875em;
      padding: 0;
      text-transform: inherit;
      text-align: left; }
  .timesheets__toolbar__actions {
    display: flex;
    justify-content: flex-end;
    align-items: center; }
  .timesheets__toolbar .field {
    padding: 0;
    margin: 0;
    display: flex;
    align-items: center; }
    .timesheets__toolbar .field__label {
      display: block;
      position: relative;
      top: 0.1875rem; }
    .timesheets__toolbar .field__select__wrapper {
      min-width: 7.1875rem;
      margin-left: 0.5rem;
      margin-right: 0.75rem; }
  .timesheets__toolbar .DateRangePickerInput {
    background: none;
    display: flex;
    align-items: center; }
  .timesheets__toolbar .DateRangePickerInput_arrow {
    margin: 0 0.625em;
    display: block; }
  .timesheets__toolbar .DateInput .DateInput_input {
    border-radius: 0.375em;
    font-size: 0.75em;
    padding: 0.8125em 1.5625em 0.4375em 0.75em;
    text-align: left; }
  .timesheets__toolbar .DateInput {
    width: 7.8125em;
    background: none;
    margin-bottom: 0; }
    .timesheets__toolbar .DateInput:before {
      margin-right: 0.625em;
      text-align: right;
      display: none; }
    .timesheets__toolbar .DateInput:after {
      top: 0.75em; }
  .timesheets__toolbar .SingleDatePicker_picker {
    z-index: +2; }
  .timesheets__toolbar .SingleDatePickerInput__withBorder {
    border: none; }
  .timesheets__toolbar .DayPicker .CalendarDay__highlighted_calendar,
  .timesheets__toolbar .DayPicker .CalendarDay__highlighted_calendar:active {
    background-color: #d8eaf2; }
    .timesheets__toolbar .DayPicker .CalendarDay__highlighted_calendar:before,
    .timesheets__toolbar .DayPicker .CalendarDay__highlighted_calendar:active:before {
      display: none; }
    .timesheets__toolbar .DayPicker .CalendarDay__highlighted_calendar:hover,
    .timesheets__toolbar .DayPicker .CalendarDay__highlighted_calendar:active:hover {
      background-color: #b2d5e5; }
  .timesheets__toolbar .DayPicker .CalendarDay__selected,
  .timesheets__toolbar .DayPicker .CalendarDay__selected:active,
  .timesheets__toolbar .DayPicker .CalendarDay__selected:hover {
    background-color: #90c6de; }
  @media (max-width: 62.5rem) {
    .timesheets__toolbar {
      display: block;
      padding-bottom: 1.5625em; }
      .timesheets__toolbar__actions {
        padding-top: 1.25rem;
        justify-content: flex-start; } }
  @media only screen and (max-width: 26rem) {
    .timesheets__toolbar__title > h2 {
      font-size: 1.5em; } }

.user-timesheets {
  padding: 2.1875rem 0 0.625rem;
  border-bottom: 1px solid #d8dfe5; }
  .user-timesheets:last-child {
    border-bottom: none; }
  .user-timesheets__user {
    display: flex;
    align-items: center;
    padding-right: 0.75rem; }
    .user-timesheets__user__image {
      border-radius: 50%;
      background-color: #d8dfe5;
      width: 3.125rem;
      height: 3.125rem;
      flex-grow: 0;
      flex-shrink: 0;
      margin-right: 0.75rem;
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center; }
      .user-timesheets__user__image--empty {
        color: #a0adb9;
        border-radius: 50%;
        font-size: 1.95em;
        text-align: center;
        line-height: 1.5625em;
        padding-left: .05em; }
        .user-timesheets__user__image--empty:before {
          font-family: "iconfont";
          font-style: normal;
          font-weight: 400;
          content: ""; }
    .user-timesheets__user__name {
      color: #414a52;
      display: flex;
      align-items: center; }

.timesheet-card {
  margin: 2.1875em 0;
  box-shadow: 0 5px 15px 0 rgba(0, 0, 0, 0.15);
  border-left: 4px #b2bfcb solid;
  border-radius: 0.1875rem;
  display: flex;
  flex-direction: column;
  overflow: hidden; }
  .timesheet-card__header {
    padding: 1.875rem 3.125rem;
    background-color: white;
    display: flex;
    justify-content: space-between;
    font-size: 0.8125em;
    line-height: 1.5em; }
  .timesheet-card__content {
    padding: 1.875rem 3.125rem;
    background-color: #fbfbfc;
    border-top: solid 1px rgba(65, 74, 82, 0.1); }
  .timesheet-card__part {
    display: flex;
    flex-shrink: 0;
    flex-grow: 0;
    align-items: center;
    width: calc(50% - 100px);
    color: #414a52; }
    .timesheet-card__part--20 {
      width: 12.5rem; }
    .timesheet-card__part--even {
      justify-content: space-evenly; }
    .timesheet-card__part--between {
      max-width: 25rem; }
      .timesheet-card__part--between > * {
        width: 28%; }
      .timesheet-card__part--between > :first-child {
        width: 70%; }
    .timesheet-card__part--right {
      justify-content: space-between;
      padding-left: 3.125rem; }
  .timesheet-card__desc {
    color: #a0adb9;
    max-width: 100%;
    padding-right: 1.875rem; }
  .timesheet-card__form {
    padding: 0rem 3.125rem;
    background-color: #fbfbfc;
    border-top: solid 1px rgba(65, 74, 82, 0.1); }
    .timesheet-card__form__footer {
      display: none;
      text-align: center;
      padding: 1.0625em 2.1875em;
      border-top: solid 1px #d8dfe5; }
  .timesheet-card__footer {
    display: none;
    border-top: solid 1px rgba(65, 74, 82, 0.1);
    padding: 1rem 3.125rem;
    background-color: white;
    text-align: center; }
  .timesheet-card__submit {
    background: #94d394;
    color: #fff;
    padding: 1.0625em 1.125em 0.875em;
    text-transform: uppercase;
    font-size: 0.6875em;
    line-height: 1.0625em;
    font-weight: 700;
    border: none;
    cursor: pointer;
    border-radius: 0.1875em;
    outline: none;
    letter-spacing: 0.8px;
    font-size: 0.75rem; }
    .timesheet-card__submit:hover {
      background-color: #86cd86;
      color: #fff; }
    .timesheet-card__submit[disabled] {
      opacity: 0.4;
      background: #94d394;
      cursor: default;
      color: #fff; }
    .timesheet-card__submit--disabled {
      background-color: #d8dfe5;
      cursor: not-allowed; }
  .timesheet-card__submitted {
    font-size: 0.6875rem;
    text-align: center;
    letter-spacing: .05em;
    color: #b2bfcb;
    display: block;
    padding: 0.625em 0.5em 0.4375em;
    border-radius: 0.375em;
    text-transform: uppercase;
    border: 1px solid #b2bfcb; }
  .timesheet-card__late {
    color: #f9b55a;
    margin-left: 0.625em;
    font-size: 1.875em; }
    .timesheet-card__late:before {
      font-family: "iconfont";
      font-style: normal;
      font-weight: 400;
      content: ""; }
  .timesheet-card__range {
    display: flex;
    align-items: center;
    color: #414a52;
    padding-right: 1.875rem; }
    .timesheet-card__range__day {
      background-color: #d8dfe5;
      border-radius: 0.25em;
      width: 3.25rem;
      height: 3.25rem;
      font-size: 0.75rem;
      line-height: 1.125rem;
      text-align: center;
      text-transform: uppercase;
      font-weight: 400;
      display: flex;
      justify-content: center;
      align-items: center; }
    .timesheet-card__range strong {
      display: block;
      font-size: 1.4375rem;
      padding-top: 0.125rem; }
    .timesheet-card__range__spacer {
      font-size: 1.5rem;
      margin: 0 0.625rem;
      color: #a0adb9; }
      .timesheet-card__range__spacer:before {
        font-family: "iconfont";
        font-style: normal;
        font-weight: 400;
        content: ""; }
  .timesheet-card__icon {
    position: relative; }
    .timesheet-card__icon--info {
      top: 0.10938em; }
      .timesheet-card__icon--info:before {
        font-family: "iconfont";
        font-style: normal;
        font-weight: 400;
        content: ""; }
    .timesheet-card__icon--message {
      padding: 0 1.25rem 0 0; }
      .timesheet-card__icon--message:before {
        font-family: "iconfont";
        font-style: normal;
        font-weight: 400;
        content: ""; }
    .timesheet-card__icon--adminNote {
      padding: 0 0.625rem 0 0; }
      .timesheet-card__icon--adminNote:before {
        font-family: "iconfont";
        font-style: normal;
        font-weight: 400;
        content: ""; }
  .timesheet-card--purple {
    border-left-color: #c49ee8; }
  .timesheet-card--purple .timesheet-card__range__day {
    background-color: #c49ee8; }
  .timesheet-card--orange {
    border-left-color: #f9b55a; }
  .timesheet-card--orange .timesheet-card__range__day {
    background-color: #f9b55a; }
  .timesheet-card .list-item {
    font-weight: 300;
    width: 100%;
    color: #414a52;
    padding: 0;
    font-size: 0.8125rem; }
    .timesheet-card .list-item__wrapper {
      display: flex;
      flex-wrap: nowrap;
      justify-content: space-between;
      padding: 1.25em 0;
      border-bottom: 1px solid #d8dfe5;
      width: 100%; }
    .timesheet-card .list-item:last-child .list-item__wrapper {
      border-bottom: none; }
    .timesheet-card .list-item__actions {
      white-space: nowrap;
      padding-left: 0.625rem;
      text-align: right;
      height: 100%;
      display: flex;
      align-items: center; }
    .timesheet-card .list-item__action {
      color: #a0adb9;
      font-size: 1rem;
      padding: 0; }
      .timesheet-card .list-item__action:hover {
        color: #414a52; }
      .timesheet-card .list-item__action--edit {
        color: #94d394; }
        .timesheet-card .list-item__action--edit:before {
          font-family: "iconfont";
          font-style: normal;
          font-weight: 400;
          content: ""; }
        .timesheet-card .list-item__action--edit:hover {
          color: #a0adb9; }
      .timesheet-card .list-item__action--delete:before {
        font-family: "iconfont";
        font-style: normal;
        font-weight: 400;
        content: ""; }
    .timesheet-card .list-item__fulldate {
      display: flex;
      align-items: center;
      padding-right: 1.25rem; }
    .timesheet-card .list-item__date {
      width: 36px;
      text-transform: uppercase;
      font-size: 0.5em;
      text-align: center;
      padding: 0.625em 0;
      border-radius: 0.3125em; }
      .timesheet-card .list-item__date span.date {
        font-size: 1rem;
        display: block;
        padding-top: 0.3125em;
        font-weight: 600; }
      .timesheet-card .list-item__date + .list-item__month {
        padding-left: 0.9375em; }
      .timesheet-card .list-item__date .month {
        padding-top: 0.25em;
        display: none; }
    .timesheet-card .list-item__month {
      white-space: nowrap;
      text-transform: capitalize; }
    .timesheet-card .list-item__hours {
      position: relative;
      font-weight: 700;
      white-space: nowrap; }
      .timesheet-card .list-item__hours--large {
        min-width: 8.4375rem; }
      .timesheet-card .list-item__hours .arrow {
        margin: 0 0.625rem 0 0.25rem; }
      .timesheet-card .list-item__hours [type="text"] {
        border: none;
        width: 3.625rem;
        background-color: transparent;
        outline: none;
        font-weight: 700;
        font-size: 0.8125rem;
        color: #414a52;
        letter-spacing: .05em;
        padding: 0;
        text-align: center; }
        .timesheet-card .list-item__hours [type="text"].single {
          width: 2.5rem; }
      .timesheet-card .list-item__hours > label {
        display: none;
        position: absolute;
        top: -10%;
        font-weight: 500;
        font-size: .8em;
        color: #b2bfcb; }
        .timesheet-card .list-item__hours > label span {
          background-color: #fbfbfc;
          padding: 0 0.625em; }
      .timesheet-card .list-item__hours .tooltip__wrapper {
        position: relative; }
      .timesheet-card .list-item__hours .tooltip {
        bottom: calc(100% + 0.7rem); }
    .timesheet-card .list-item__user {
      display: flex;
      align-items: center;
      position: relative;
      cursor: default;
      line-height: 0.8125rem; }
      .timesheet-card .list-item__user__picture {
        background-color: #d8dfe5;
        margin-right: 0.625em;
        color: #414a52;
        border-radius: 50%;
        font-size: 1.25em;
        display: block;
        height: 1.5625em;
        width: 1.5625em;
        text-align: center;
        line-height: 1.5625em;
        flex-shrink: 0; }
    .timesheet-card .list-item .timesheet-card__submit {
      font-size: 0.625rem;
      line-height: 0.625rem;
      padding: 1em 0.75em 0.625em;
      position: relative;
      top: -0.09375em;
      margin-left: 0.625rem; }
    .timesheet-card .list-item .arrow {
      font-size: 80%;
      color: #a3a0a0; }
      .timesheet-card .list-item .arrow:before {
        font-family: "iconfont";
        font-style: normal;
        font-weight: 400;
        content: ""; }
  @media only screen and (max-width: 81.25rem) {
    .timesheet-card__infos {
      width: calc(100% - 200px); }
    .timesheet-card__headings {
      display: none; }
    .timesheet-card__desc {
      font-size: 0.75rem; }
    .timesheet-card .list-item__hours {
      text-align: center; }
    .timesheet-card .list-item__hours label {
      display: block;
      left: 0;
      width: 100%;
      z-index: +1; }
    .timesheet-card .list-item__hours .border {
      display: block;
      border: solid 1px #d8dfe5;
      position: absolute;
      left: 0.25rem;
      right: 0.25rem;
      top: -5%;
      bottom: 0;
      border-radius: 0.1875em; } }
  @media only screen and (max-width: 70rem) {
    .timesheet-card__header, .timesheet-card__form, .timesheet-card__footer {
      padding-left: 2.5rem;
      padding-right: 2.5rem; }
    .timesheet-card .list-item__date {
      padding-left: 0; }
      .timesheet-card .list-item__date .month {
        display: block; }
    .timesheet-card .list-item__month {
      display: none; } }
  @media only screen and (max-width: 59.375rem) {
    .timesheet-card__header, .timesheet-card__form, .timesheet-card__footer {
      padding-left: 1.875rem;
      padding-right: 1.875rem; }
    .timesheet-card__infos {
      width: auto;
      max-width: 80%; }
    .timesheet-card__actions {
      width: auto; } }
  @media only screen and (max-width: 56.25rem) {
    .timesheet-card__part--right {
      padding-left: 0; }
    .timesheet-card .list-item .list-item__wrapper {
      flex-wrap: wrap; }
    .timesheet-card .list-item .timesheet-card__part:nth-child(1) {
      order: 1;
      width: auto;
      max-width: 60%; }
    .timesheet-card .list-item .timesheet-card__part:nth-child(2) {
      padding-top: 0.625rem;
      order: 2;
      width: 100%;
      max-width: 100%;
      flex-shrink: 0;
      justify-content: space-between; }
    .timesheet-card .list-item .timesheet-card__part:nth-child(3) {
      order: 1;
      width: auto;
      justify-content: space-between;
      width: 100%; }
    .timesheet-card .list-item__hours:first-child .border {
      left: 0; }
    .timesheet-card .list-item__hours:last-child .border {
      right: 0; } }
  @media only screen and (max-width: 50rem) {
    .timesheet-card__header, .timesheet-card__form, .timesheet-card__footer {
      padding-left: 1.25rem;
      padding-right: 1.25rem; }
    .timesheet-card__footer {
      display: block; }
    .timesheet-card__infos {
      max-width: 100%; }
    .timesheet-card__actions {
      display: none; }
    .timesheet-card__desc {
      padding-right: 0; } }
  @media only screen and (max-width: 43.75rem) {
    .timesheet-card__header {
      padding-top: 1.5625rem;
      padding-bottom: 1.25rem; }
    .timesheet-card__footer {
      padding-top: 0.75rem;
      padding-bottom: 0.75rem; }
    .timesheet-card .list-item .timesheet-card__part:nth-child(1) {
      order: 0;
      max-width: 100%; }
    .timesheet-card .list-item .timesheet-card__part:nth-child(3) {
      order: 1;
      max-width: 100%;
      padding-top: 0.375rem;
      padding-left: 0;
      display: flex;
      justify-content: space-between; }
      .timesheet-card .list-item .timesheet-card__part:nth-child(3) .list-item__hours {
        text-align: left; }
      .timesheet-card .list-item .timesheet-card__part:nth-child(3) .list-item__hours .field input {
        font-size: 0.8125rem; } }
  @media only screen and (max-width: 31.25rem) {
    .timesheet-card__infos {
      flex-wrap: wrap; }
    .timesheet-card__range {
      padding-right: 0; }
    .timesheet-card__desc {
      padding-top: 1rem; }
    .timesheet-card .list-item .timesheet-card__part:nth-child(2) {
      flex-wrap: wrap; }
    .timesheet-card .list-item__hours {
      width: 50%; }
      .timesheet-card .list-item__hours .field {
        margin-bottom: 0; }
      .timesheet-card .list-item__hours--large {
        width: 100%;
        margin-bottom: 1rem; }
      .timesheet-card .list-item__hours:first-child .border {
        left: 0;
        right: 0; }
      .timesheet-card .list-item__hours:nth-child(2) .border {
        left: 0; }
      .timesheet-card .list-item__hours:nth-child(3) .border {
        right: 0; }
      .timesheet-card .list-item__hours:last-child {
        width: 100%;
        margin-top: 1rem; }
      .timesheet-card .list-item__hours:last-child .border {
        right: 0;
        left: 0; } }

.timesheets-text {
  margin-top: 2.1875em;
  padding: 2.1875em 3.125em 1.875em;
  background-color: white;
  border: solid 1px #f7f8f9;
  border-left: solid 4px #b2bfcb;
  border-top-left-radius: 0.3125em;
  border-bottom-left-radius: 0.3125em;
  box-shadow: 0 5px 15px 0 rgba(0, 0, 0, 0.05); }
  .timesheets-text p {
    margin-bottom: 0; }

.schedule-fields {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 0.3125em;
  padding-bottom: 0.9375em; }
  .schedule-fields__arrow {
    display: block;
    color: #d8dfe5; }
    .schedule-fields__arrow:before {
      font-family: "iconfont";
      font-style: normal;
      font-weight: 400;
      content: ""; }
  .schedule-fields .field {
    flex-grow: 1;
    max-width: 7.5rem; }
  .schedule-fields__date {
    width: 36px;
    text-transform: uppercase;
    font-size: 0.5em;
    text-align: center;
    padding: 0.625em 0;
    border-radius: 0.3125em;
    font-weight: 300;
    margin-right: 0.875rem; }
    .schedule-fields__date span.date {
      font-size: 1.0625rem;
      display: block;
      padding-top: 0.3125em;
      font-weight: 600; }
    .schedule-fields__date .month {
      padding-top: 0.25em; }

.break-fields .radio-boolean {
  padding-top: 0;
  padding-bottom: 1.25rem;
  margin-top: 0;
  margin-bottom: 0; }

.break-fields .radio-boolean__label {
  text-transform: uppercase;
  font-size: 0.75rem;
  display: flex; }

.break-fields__subfields {
  border-top: 1px solid #d8dfe5;
  padding-top: 0.75rem; }

.break-fields .fields-one-col--conditional {
  width: 100%;
  margin-left: 0;
  margin-right: 0; }
