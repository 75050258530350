@charset "UTF-8";
@font-face {
  font-family: "iconfont";
  src: url("/iconfont.eot");
  /* IE9 Compat Modes */
  src: url("/iconfont.eot?#iefix") format("embedded-opentype"), url("/iconfont.woff") format("woff"), url("/iconfont.ttf") format("truetype"), url("/iconfont.svg") format("svg");
  /* Legacy iOS */ }

.icon {
  font-style: normal;
  font-weight: 400; }
  .icon.accordion-close:before {
    font-family: "iconfont";
    content: ""; }
  .icon.accordion-open:before {
    font-family: "iconfont";
    content: ""; }
  .icon.add-folder:before {
    font-family: "iconfont";
    content: ""; }
  .icon.add:before {
    font-family: "iconfont";
    content: ""; }
  .icon.admin:before {
    font-family: "iconfont";
    content: ""; }
  .icon.all-day:before {
    font-family: "iconfont";
    content: ""; }
  .icon.arrow-left:before {
    font-family: "iconfont";
    content: ""; }
  .icon.arrow-right:before {
    font-family: "iconfont";
    content: ""; }
  .icon.assignment:before {
    font-family: "iconfont";
    content: ""; }
  .icon.association-clinic:before {
    font-family: "iconfont";
    content: ""; }
  .icon.association:before {
    font-family: "iconfont";
    content: ""; }
  .icon.bars:before {
    font-family: "iconfont";
    content: ""; }
  .icon.calendar-2:before {
    font-family: "iconfont";
    content: ""; }
  .icon.calendar-today:before {
    font-family: "iconfont";
    content: ""; }
  .icon.calendar:before {
    font-family: "iconfont";
    content: ""; }
  .icon.call-list:before {
    font-family: "iconfont";
    content: ""; }
  .icon.checkbox-off:before {
    font-family: "iconfont";
    content: ""; }
  .icon.checkbox-on:before {
    font-family: "iconfont";
    content: ""; }
  .icon.checkbox-square:before {
    font-family: "iconfont";
    content: ""; }
  .icon.circle-add:before {
    font-family: "iconfont";
    content: ""; }
  .icon.circle-check:before {
    font-family: "iconfont";
    content: ""; }
  .icon.circle-info:before {
    font-family: "iconfont";
    content: ""; }
  .icon.circlo-close:before {
    font-family: "iconfont";
    content: ""; }
  .icon.clinic:before {
    font-family: "iconfont";
    content: ""; }
  .icon.close-slim:before {
    font-family: "iconfont";
    content: ""; }
  .icon.close:before {
    font-family: "iconfont";
    content: ""; }
  .icon.closed-folder:before {
    font-family: "iconfont";
    content: ""; }
  .icon.copy:before {
    font-family: "iconfont";
    content: ""; }
  .icon.delete:before {
    font-family: "iconfont";
    content: ""; }
  .icon.disconnect:before {
    font-family: "iconfont";
    content: ""; }
  .icon.dotted-arrow-right:before {
    font-family: "iconfont";
    content: ""; }
  .icon.dotted-arrow:before {
    font-family: "iconfont";
    content: ""; }
  .icon.edit:before {
    font-family: "iconfont";
    content: ""; }
  .icon.email:before {
    font-family: "iconfont";
    content: ""; }
  .icon.featured:before {
    font-family: "iconfont";
    content: ""; }
  .icon.handle-h:before {
    font-family: "iconfont";
    content: ""; }
  .icon.handle-v:before {
    font-family: "iconfont";
    content: ""; }
  .icon.help-circle:before {
    font-family: "iconfont";
    content: ""; }
  .icon.help:before {
    font-family: "iconfont";
    content: ""; }
  .icon.history:before {
    font-family: "iconfont";
    content: ""; }
  .icon.info:before {
    font-family: "iconfont";
    content: ""; }
  .icon.lock-open:before {
    font-family: "iconfont";
    content: ""; }
  .icon.lock:before {
    font-family: "iconfont";
    content: ""; }
  .icon.lodging:before {
    font-family: "iconfont";
    content: ""; }
  .icon.magnifying-glass:before {
    font-family: "iconfont";
    content: ""; }
  .icon.map:before {
    font-family: "iconfont";
    content: ""; }
  .icon.marker:before {
    font-family: "iconfont";
    content: ""; }
  .icon.notifications:before {
    font-family: "iconfont";
    content: ""; }
  .icon.open-folder:before {
    font-family: "iconfont";
    content: ""; }
  .icon.pencil:before {
    font-family: "iconfont";
    content: ""; }
  .icon.piechart:before {
    font-family: "iconfont";
    content: ""; }
  .icon.pin:before {
    font-family: "iconfont";
    content: ""; }
  .icon.profile:before {
    font-family: "iconfont";
    content: ""; }
  .icon.schedule:before {
    font-family: "iconfont";
    content: ""; }
  .icon.settings:before {
    font-family: "iconfont";
    content: ""; }
  .icon.small-down-arrow:before {
    font-family: "iconfont";
    content: ""; }
  .icon.small-up-arrow:before {
    font-family: "iconfont";
    content: ""; }
  .icon.status-check:before {
    font-family: "iconfont";
    content: ""; }
  .icon.surgery-optional:before {
    font-family: "iconfont";
    content: ""; }
  .icon.surgery:before {
    font-family: "iconfont";
    content: ""; }
  .icon.time:before {
    font-family: "iconfont";
    content: ""; }
  .icon.timesheet:before {
    font-family: "iconfont";
    content: ""; }
  .icon.triangle:before {
    font-family: "iconfont";
    content: ""; }
  .icon.tsa:before {
    font-family: "iconfont";
    content: ""; }
  .icon.validation:before {
    font-family: "iconfont";
    content: ""; }
  .icon.vet:before {
    font-family: "iconfont";
    content: ""; }

.clinic-subform__row {
  margin-bottom: 0.625em;
  display: flex;
  align-items: flex-start; }
  .clinic-subform__row > * {
    width: 100%;
    margin: 0 0.625em; }
    .clinic-subform__row > *:first-child {
      margin-left: 0; }
    .clinic-subform__row > *:last-child {
      margin-right: 0; }

.clinic-subform__delete {
  background: #f76b7e;
  color: #FFF;
  padding: 1.0625em 1.125em 0.875em;
  text-transform: uppercase;
  font-size: 0.6875em;
  line-height: 1.0625em;
  font-weight: 700;
  border: none;
  cursor: pointer;
  border-radius: 0.1875em;
  outline: none;
  letter-spacing: 0.8px;
  width: 2.35938rem;
  height: 2.35938rem;
  margin: 0; }
  .clinic-subform__delete:before {
    font-family: "iconfont";
    font-style: normal;
    font-weight: 400;
    content: ""; }
  .clinic-subform__delete:hover {
    background-color: #f6586d;
    color: #FFF; }
  .clinic-subform__delete[disabled] {
    opacity: 0.4;
    background: #f76b7e;
    cursor: default;
    color: #FFF; }

.opening-hour {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 0.625em;
  position: relative; }
  .opening-hour:first-child {
    padding-top: 0.625em; }
  .opening-hour__day {
    text-transform: capitalize;
    font-size: 0.8125rem;
    color: #3a434a;
    text-align: left;
    padding: 0.625em 0.625em 0.625em 0;
    border-radius: 0.3125em;
    width: 38%; }
  .opening-hour .field {
    flex-grow: 1;
    max-width: 4.6875rem;
    position: static; }
    .opening-hour .field.field--select {
      margin: 0;
      padding: 0.3125rem 0; }
      .opening-hour .field.field--select label {
        display: none; }
      .opening-hour .field.field--select select {
        text-align: center;
        text-align-last: center; }
      .opening-hour .field.field--select .field__error {
        width: 17.1875em;
        bottom: -1.125rem;
        left: 39%; }
  .opening-hour .field__select__wrapper:before {
    display: none; }
  .opening-hour__arrow {
    display: block;
    color: #d8dfe5;
    padding: 0.25rem 1rem 0; }
    .opening-hour__arrow:before {
      font-family: "iconfont";
      font-style: normal;
      font-weight: 400;
      content: ""; }

.manager__user {
  width: 20rem;
  padding: 0.625rem 0.9375rem 0.625rem 0;
  display: flex;
  flex-grow: 0;
  flex-shrink: 0;
  align-items: center;
  font-weight: 300;
  font-size: 0.75rem;
  line-height: 1.125rem;
  color: #414a52;
  z-index: +5;
  position: sticky;
  left: 0;
  top: 0; }
  .manager__user__image {
    border-radius: 50%;
    background-color: #d8dfe5;
    width: 1.875rem;
    height: 1.875rem;
    flex-grow: 0;
    flex-shrink: 0;
    margin-right: 0.75rem;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat; }
    .manager__user__image--empty {
      color: #414a52;
      border-radius: 50%;
      font-size: 1.125rem;
      text-align: center;
      line-height: 1.6875rem;
      padding-left: .1em; }
      .manager__user__image--empty:before {
        font-family: "iconfont";
        font-style: normal;
        font-weight: 400;
        content: ""; }
  .manager__user__name {
    color: #414a52;
    display: flex;
    align-items: center; }
