@charset "UTF-8";
@font-face {
  font-family: "iconfont";
  src: url("/iconfont.eot");
  /* IE9 Compat Modes */
  src: url("/iconfont.eot?#iefix") format("embedded-opentype"), url("/iconfont.woff") format("woff"), url("/iconfont.ttf") format("truetype"), url("/iconfont.svg") format("svg");
  /* Legacy iOS */ }

.icon {
  font-style: normal;
  font-weight: 400; }
  .icon.accordion-close:before {
    font-family: "iconfont";
    content: ""; }
  .icon.accordion-open:before {
    font-family: "iconfont";
    content: ""; }
  .icon.add-folder:before {
    font-family: "iconfont";
    content: ""; }
  .icon.add:before {
    font-family: "iconfont";
    content: ""; }
  .icon.admin:before {
    font-family: "iconfont";
    content: ""; }
  .icon.all-day:before {
    font-family: "iconfont";
    content: ""; }
  .icon.arrow-left:before {
    font-family: "iconfont";
    content: ""; }
  .icon.arrow-right:before {
    font-family: "iconfont";
    content: ""; }
  .icon.assignment:before {
    font-family: "iconfont";
    content: ""; }
  .icon.association-clinic:before {
    font-family: "iconfont";
    content: ""; }
  .icon.association:before {
    font-family: "iconfont";
    content: ""; }
  .icon.bars:before {
    font-family: "iconfont";
    content: ""; }
  .icon.calendar-2:before {
    font-family: "iconfont";
    content: ""; }
  .icon.calendar-today:before {
    font-family: "iconfont";
    content: ""; }
  .icon.calendar:before {
    font-family: "iconfont";
    content: ""; }
  .icon.call-list:before {
    font-family: "iconfont";
    content: ""; }
  .icon.checkbox-off:before {
    font-family: "iconfont";
    content: ""; }
  .icon.checkbox-on:before {
    font-family: "iconfont";
    content: ""; }
  .icon.checkbox-square:before {
    font-family: "iconfont";
    content: ""; }
  .icon.circle-add:before {
    font-family: "iconfont";
    content: ""; }
  .icon.circle-check:before {
    font-family: "iconfont";
    content: ""; }
  .icon.circle-info:before {
    font-family: "iconfont";
    content: ""; }
  .icon.circlo-close:before {
    font-family: "iconfont";
    content: ""; }
  .icon.clinic:before {
    font-family: "iconfont";
    content: ""; }
  .icon.close-slim:before {
    font-family: "iconfont";
    content: ""; }
  .icon.close:before {
    font-family: "iconfont";
    content: ""; }
  .icon.closed-folder:before {
    font-family: "iconfont";
    content: ""; }
  .icon.copy:before {
    font-family: "iconfont";
    content: ""; }
  .icon.delete:before {
    font-family: "iconfont";
    content: ""; }
  .icon.disconnect:before {
    font-family: "iconfont";
    content: ""; }
  .icon.dotted-arrow-right:before {
    font-family: "iconfont";
    content: ""; }
  .icon.dotted-arrow:before {
    font-family: "iconfont";
    content: ""; }
  .icon.edit:before {
    font-family: "iconfont";
    content: ""; }
  .icon.email:before {
    font-family: "iconfont";
    content: ""; }
  .icon.featured:before {
    font-family: "iconfont";
    content: ""; }
  .icon.handle-h:before {
    font-family: "iconfont";
    content: ""; }
  .icon.handle-v:before {
    font-family: "iconfont";
    content: ""; }
  .icon.help-circle:before {
    font-family: "iconfont";
    content: ""; }
  .icon.help:before {
    font-family: "iconfont";
    content: ""; }
  .icon.history:before {
    font-family: "iconfont";
    content: ""; }
  .icon.info:before {
    font-family: "iconfont";
    content: ""; }
  .icon.lock-open:before {
    font-family: "iconfont";
    content: ""; }
  .icon.lock:before {
    font-family: "iconfont";
    content: ""; }
  .icon.lodging:before {
    font-family: "iconfont";
    content: ""; }
  .icon.magnifying-glass:before {
    font-family: "iconfont";
    content: ""; }
  .icon.map:before {
    font-family: "iconfont";
    content: ""; }
  .icon.marker:before {
    font-family: "iconfont";
    content: ""; }
  .icon.notifications:before {
    font-family: "iconfont";
    content: ""; }
  .icon.open-folder:before {
    font-family: "iconfont";
    content: ""; }
  .icon.pencil:before {
    font-family: "iconfont";
    content: ""; }
  .icon.piechart:before {
    font-family: "iconfont";
    content: ""; }
  .icon.pin:before {
    font-family: "iconfont";
    content: ""; }
  .icon.profile:before {
    font-family: "iconfont";
    content: ""; }
  .icon.schedule:before {
    font-family: "iconfont";
    content: ""; }
  .icon.settings:before {
    font-family: "iconfont";
    content: ""; }
  .icon.small-down-arrow:before {
    font-family: "iconfont";
    content: ""; }
  .icon.small-up-arrow:before {
    font-family: "iconfont";
    content: ""; }
  .icon.status-check:before {
    font-family: "iconfont";
    content: ""; }
  .icon.surgery-optional:before {
    font-family: "iconfont";
    content: ""; }
  .icon.surgery:before {
    font-family: "iconfont";
    content: ""; }
  .icon.time:before {
    font-family: "iconfont";
    content: ""; }
  .icon.timesheet:before {
    font-family: "iconfont";
    content: ""; }
  .icon.triangle:before {
    font-family: "iconfont";
    content: ""; }
  .icon.tsa:before {
    font-family: "iconfont";
    content: ""; }
  .icon.validation:before {
    font-family: "iconfont";
    content: ""; }
  .icon.vet:before {
    font-family: "iconfont";
    content: ""; }

.header {
  background-color: #fff;
  box-shadow: 0 5px 15px 0 rgba(0, 0, 0, 0.075);
  position: fixed;
  width: 100%;
  height: 4.375em;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-left: 4.375rem;
  top: 0;
  left: 0;
  z-index: 90; }
  @media only screen and (max-width: 38rem) {
    .header {
      padding-left: 0; } }
  @media only screen and (max-width: 43.75rem) {
    .header .matchmaking__toolbar {
      display: none; } }
  .header > * {
    flex-shrink: 0;
    order: 1; }
    .header > *.header__title {
      flex-shrink: 1;
      flex-grow: 1;
      order: 2; }
      @media only screen and (max-width: 62.5rem) {
        .header > *.header__title {
          display: none; } }
  .header .header__group {
    height: 100%;
    display: flex; }
    .header .header__group--last {
      order: 3; }
  .header--no-clinic {
    padding-left: 0; }
    .header--no-clinic .header__menu-toggle {
      display: none; }
    .header--no-clinic .header__vetmatch-logo {
      display: block; }

.header__menu-toggle {
  background: none;
  border: none;
  padding: 0;
  outline: none;
  height: 4.375rem;
  cursor: pointer;
  width: 4.375rem;
  border-right: 1px solid #f7f8f9;
  transition: background-color 0.15s cubic-bezier(0.77, 0, 0.175, 1); }
  .header__menu-toggle:hover {
    background-color: #d8eaf2; }
    .header__menu-toggle:hover > span {
      background-color: white; }
  .header__menu-toggle > span {
    content: "";
    display: block;
    width: 1.25rem;
    height: 0.125rem;
    background: #a0adb9;
    margin: 0 auto 0.375rem;
    transition: background-color 0.15s cubic-bezier(0.77, 0, 0.175, 1); }

.header__menu-toggle--close {
  border: none;
  height: 4.375rem;
  width: 4.375rem;
  background: none;
  cursor: pointer;
  outline: none;
  position: relative;
  display: none;
  justify-content: center;
  align-items: center;
  background-color: #403b42; }
  .header__menu-toggle--close:hover > span {
    width: 1.5625rem; }
  .header__menu-toggle--close > span {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 0.125rem;
    width: 1.25rem;
    background-color: #fff;
    position: absolute;
    transition: all 0.15s cubic-bezier(0.77, 0, 0.175, 1); }
    .header__menu-toggle--close > span:first-child {
      transform: rotate(45deg); }
    .header__menu-toggle--close > span:last-child {
      transform: rotate(-45deg); }

@media only screen and (max-width: 38rem) {
  .header--open {
    box-shadow: none; }
    .header--open .header__menu-toggle--close {
      display: flex; }
    .header--open .header__menu-toggle {
      display: none; } }

.header__title {
  margin: 0 1.25em;
  text-transform: uppercase;
  color: #a0adb9; }

.header__vetmatch-logo {
  display: none; }
  @media only screen and (max-width: 38rem) {
    .header__vetmatch-logo {
      display: block; } }
  .header__vetmatch-logo a {
    width: 4.375rem;
    height: 4.375rem;
    display: flex;
    align-items: center;
    background-color: #90c6de; }
  .header__vetmatch-logo img {
    width: 2.625rem;
    height: 2.625rem;
    display: block;
    margin: 0 auto; }

.header__link {
  background: none;
  border: none;
  outline: none;
  height: 100%;
  cursor: pointer;
  width: 4.375rem;
  border-left: 1px solid #f7f8f9;
  transition: background-color 0.15s cubic-bezier(0.77, 0, 0.175, 1);
  font-size: 1.5em;
  color: #a0adb9;
  order: 4;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center; }
  .header__link:hover {
    background-color: #d8eaf2;
    color: #a0adb9; }
  .header__link:before {
    position: relative;
    top: -.05em; }
  .header__link--help {
    font-size: 1.75em; }
    .header__link--help:before {
      font-family: "iconfont";
      font-style: normal;
      font-weight: 400;
      content: ""; }
  .header__link--profile:before {
    font-family: "iconfont";
    font-style: normal;
    font-weight: 400;
    content: ""; }
  .header__link--notifications {
    position: relative; }
    .header__link--notifications:before {
      font-family: "iconfont";
      font-style: normal;
      font-weight: 400;
      content: ""; }
    .header__link--notifications--count, .header__link--notifications--count:hover {
      content: "";
      padding: 0.95em 0.4em;
      position: absolute;
      display: block;
      color: white;
      font-size: 0.38em;
      font-weight: bold;
      line-height: 0.5em;
      top: 1.25em;
      right: 1.25em;
      width: auto;
      height: 2em;
      border-radius: 1em;
      background: #f76b7e;
      min-width: 2em; }
  .header__link--settings:before {
    font-family: "iconfont";
    font-style: normal;
    font-weight: 400;
    content: ""; }
  .header__link--clinic:before {
    font-family: "iconfont";
    font-style: normal;
    font-weight: 400;
    content: ""; }
  .header__link--close {
    font-size: 1.25em; }
    .header__link--close:before {
      font-family: "iconfont";
      font-style: normal;
      font-weight: 400;
      content: ""; }
    .header__link--close.active:after,
    .header__link--close span,
    .header__link--close span:hover {
      display: none; }

.matchmaking__toolbar__schedule form {
  width: 100%; }
  .matchmaking__toolbar__schedule form .field__select__wrapper select {
    padding-right: 35px;
    width: 100%; }
  .matchmaking__toolbar__schedule form .field__select__wrapper:before {
    top: calc(50% - 0.5em); }

.clinic-switcher {
  display: none;
  background-color: #fff;
  box-shadow: 0 5px 15px 0 rgba(0, 0, 0, 0.075);
  position: relative;
  width: 100%;
  top: 4.375em; }
  .clinic-switcher .field {
    padding: 0.3125em 0;
    width: 100%; }
  .clinic-switcher .matchmaking__toolbar__schedule, .clinic-switcher form {
    width: 100%; }
  @media only screen and (max-width: 38rem) {
    .clinic-switcher {
      padding-left: 0; } }
  @media only screen and (max-width: 43.75rem) {
    .clinic-switcher {
      display: block; } }
