@charset "UTF-8";
@font-face {
  font-family: "iconfont";
  src: url("/iconfont.eot");
  /* IE9 Compat Modes */
  src: url("/iconfont.eot?#iefix") format("embedded-opentype"), url("/iconfont.woff") format("woff"), url("/iconfont.ttf") format("truetype"), url("/iconfont.svg") format("svg");
  /* Legacy iOS */ }

.icon {
  font-style: normal;
  font-weight: 400; }
  .icon.accordion-close:before {
    font-family: "iconfont";
    content: ""; }
  .icon.accordion-open:before {
    font-family: "iconfont";
    content: ""; }
  .icon.add-folder:before {
    font-family: "iconfont";
    content: ""; }
  .icon.add:before {
    font-family: "iconfont";
    content: ""; }
  .icon.admin:before {
    font-family: "iconfont";
    content: ""; }
  .icon.all-day:before {
    font-family: "iconfont";
    content: ""; }
  .icon.arrow-left:before {
    font-family: "iconfont";
    content: ""; }
  .icon.arrow-right:before {
    font-family: "iconfont";
    content: ""; }
  .icon.assignment:before {
    font-family: "iconfont";
    content: ""; }
  .icon.association-clinic:before {
    font-family: "iconfont";
    content: ""; }
  .icon.association:before {
    font-family: "iconfont";
    content: ""; }
  .icon.bars:before {
    font-family: "iconfont";
    content: ""; }
  .icon.calendar-2:before {
    font-family: "iconfont";
    content: ""; }
  .icon.calendar-today:before {
    font-family: "iconfont";
    content: ""; }
  .icon.calendar:before {
    font-family: "iconfont";
    content: ""; }
  .icon.call-list:before {
    font-family: "iconfont";
    content: ""; }
  .icon.checkbox-off:before {
    font-family: "iconfont";
    content: ""; }
  .icon.checkbox-on:before {
    font-family: "iconfont";
    content: ""; }
  .icon.checkbox-square:before {
    font-family: "iconfont";
    content: ""; }
  .icon.circle-add:before {
    font-family: "iconfont";
    content: ""; }
  .icon.circle-check:before {
    font-family: "iconfont";
    content: ""; }
  .icon.circle-info:before {
    font-family: "iconfont";
    content: ""; }
  .icon.circlo-close:before {
    font-family: "iconfont";
    content: ""; }
  .icon.clinic:before {
    font-family: "iconfont";
    content: ""; }
  .icon.close-slim:before {
    font-family: "iconfont";
    content: ""; }
  .icon.close:before {
    font-family: "iconfont";
    content: ""; }
  .icon.closed-folder:before {
    font-family: "iconfont";
    content: ""; }
  .icon.copy:before {
    font-family: "iconfont";
    content: ""; }
  .icon.delete:before {
    font-family: "iconfont";
    content: ""; }
  .icon.disconnect:before {
    font-family: "iconfont";
    content: ""; }
  .icon.dotted-arrow-right:before {
    font-family: "iconfont";
    content: ""; }
  .icon.dotted-arrow:before {
    font-family: "iconfont";
    content: ""; }
  .icon.edit:before {
    font-family: "iconfont";
    content: ""; }
  .icon.email:before {
    font-family: "iconfont";
    content: ""; }
  .icon.featured:before {
    font-family: "iconfont";
    content: ""; }
  .icon.handle-h:before {
    font-family: "iconfont";
    content: ""; }
  .icon.handle-v:before {
    font-family: "iconfont";
    content: ""; }
  .icon.help-circle:before {
    font-family: "iconfont";
    content: ""; }
  .icon.help:before {
    font-family: "iconfont";
    content: ""; }
  .icon.history:before {
    font-family: "iconfont";
    content: ""; }
  .icon.info:before {
    font-family: "iconfont";
    content: ""; }
  .icon.lock-open:before {
    font-family: "iconfont";
    content: ""; }
  .icon.lock:before {
    font-family: "iconfont";
    content: ""; }
  .icon.lodging:before {
    font-family: "iconfont";
    content: ""; }
  .icon.magnifying-glass:before {
    font-family: "iconfont";
    content: ""; }
  .icon.map:before {
    font-family: "iconfont";
    content: ""; }
  .icon.marker:before {
    font-family: "iconfont";
    content: ""; }
  .icon.notifications:before {
    font-family: "iconfont";
    content: ""; }
  .icon.open-folder:before {
    font-family: "iconfont";
    content: ""; }
  .icon.pencil:before {
    font-family: "iconfont";
    content: ""; }
  .icon.piechart:before {
    font-family: "iconfont";
    content: ""; }
  .icon.pin:before {
    font-family: "iconfont";
    content: ""; }
  .icon.profile:before {
    font-family: "iconfont";
    content: ""; }
  .icon.schedule:before {
    font-family: "iconfont";
    content: ""; }
  .icon.settings:before {
    font-family: "iconfont";
    content: ""; }
  .icon.small-down-arrow:before {
    font-family: "iconfont";
    content: ""; }
  .icon.small-up-arrow:before {
    font-family: "iconfont";
    content: ""; }
  .icon.status-check:before {
    font-family: "iconfont";
    content: ""; }
  .icon.surgery-optional:before {
    font-family: "iconfont";
    content: ""; }
  .icon.surgery:before {
    font-family: "iconfont";
    content: ""; }
  .icon.time:before {
    font-family: "iconfont";
    content: ""; }
  .icon.timesheet:before {
    font-family: "iconfont";
    content: ""; }
  .icon.triangle:before {
    font-family: "iconfont";
    content: ""; }
  .icon.tsa:before {
    font-family: "iconfont";
    content: ""; }
  .icon.validation:before {
    font-family: "iconfont";
    content: ""; }
  .icon.vet:before {
    font-family: "iconfont";
    content: ""; }

.login-page {
  background-color: #90c6de;
  height: 100vh;
  min-height: 53.125rem;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column; }
  .login-page--modal {
    justify-content: flex-start; }
  .login-page fieldset {
    padding: 6.25rem 2.1875em 2.1875em;
    border: 1px solid #fff;
    max-width: 21.875em;
    border-radius: 0.3125em;
    position: relative; }
  .login-page .field {
    width: 100%;
    padding-bottom: 0.625rem; }
    .login-page .field--remember-me {
      padding-top: 0.25rem; }
    .login-page .field .field__error {
      position: static;
      padding-top: 0.625em; }
  .login-page a {
    color: #fff;
    font-size: 0.75em;
    text-decoration: underline; }
    .login-page a:hover {
      text-decoration: none;
      color: #fff; }
  .login-page button {
    background: #414a52;
    color: #fff;
    padding: 1.0625em 1.125em 0.875em;
    text-transform: uppercase;
    font-size: 0.6875em;
    line-height: 1.0625em;
    font-weight: 700;
    border: none;
    cursor: pointer;
    border-radius: 0.1875em;
    outline: none;
    letter-spacing: 0.8px;
    padding: 1em 1.125em 0.9375em;
    margin-top: 1.5625em; }
    .login-page button:hover {
      background-color: #384047;
      color: #fff; }
    .login-page button[disabled] {
      opacity: 0.4;
      background: #414a52;
      cursor: default;
      color: #fff; }
  .login-page .form__actions {
    display: flex;
    justify-content: center; }
  .login-page .field__checkbox__label {
    color: #fff;
    font-size: 0.75rem; }
    .login-page .field__checkbox__label:before {
      margin-right: 0.625rem;
      border-color: #fff; }
  .login-page .field__checkbox:checked + .field__checkbox__label:after {
    background-color: #fff;
    top: 0.15rem; }
  .login-page__logos {
    position: absolute;
    width: 100%;
    left: 0;
    top: -1.25rem; }
    .login-page__logos > * {
      display: block; }
    .login-page__logos span {
      display: block;
      width: 3.25rem;
      margin: 0 auto;
      background-color: #90c6de;
      text-align: center; }
    .login-page__logos__logo {
      width: 2.625rem;
      height: 2.625rem;
      margin: 0 auto 0.9375rem; }
    .login-page__logos__title {
      width: 6.9375rem;
      margin: 0 auto; }
  .login-page .basic-loader {
    position: absolute;
    height: 100%;
    left: 0; }
    .login-page .basic-loader img {
      top: 35%; }
  .login-page .modal__container {
    display: flex;
    justify-content: center;
    background-color: #ffffff;
    border-radius: 0.625rem;
    box-shadow: 0.25rem 0.25rem 0.9375rem rgba(58, 67, 74, 0.25);
    color: #403b42;
    flex-direction: column;
    font-size: 0.875rem;
    margin: 0.9375rem 0.625rem 3.4375rem;
    max-width: 37.5rem;
    padding: 2.1875rem 1.875rem;
    text-align: center; }
    .login-page .modal__container .modal__box {
      align-self: center;
      font-size: 0.75rem;
      padding: 0.9375rem 0.3125rem;
      max-width: 34.375rem; }
      .login-page .modal__container .modal__box--grey {
        background-color: #f0f0f0; }
    .login-page .modal__container__link {
      margin: 0.625rem 0 1.875rem; }
      .login-page .modal__container__link--large {
        font-size: 1.375rem; }
    .login-page .modal__container b, .login-page .modal__container a {
      color: #414a52; }
    .login-page .modal__container button {
      margin: 0 0 1.25rem;
      background-color: #90c6de;
      padding: 0.9375rem 1.25rem; }
    .login-page .modal__container p {
      margin: 0.625rem 0; }
    .login-page .modal__container p:first-child {
      margin-top: 0; }
    .login-page .modal__container p:last-child {
      margin-bottom: 0; }
    @media (max-width: 40.625rem) {
      .login-page .modal__container {
        justify-self: flex-start;
        font-size: 12px;
        margin: 0.9375rem 6.25rem 2.1875rem;
        padding: 1.25rem 0.9375rem;
        max-width: 95%; } }
  @media (max-width: 40.625rem) {
    .login-page {
      justify-content: flex-start; } }

.forgot-password-page {
  background-color: #90c6de;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center; }
  .forgot-password-page h1 {
    color: #fff;
    text-transform: uppercase;
    margin-bottom: 2.8125em;
    text-align: center; }
  .forgot-password-page .forgot-password__fieldset {
    padding: 6.25rem 2.1875em 2.1875em;
    border: 1px solid #fff;
    max-width: 21.875em;
    border-radius: 0.3125em;
    position: relative; }
  .forgot-password-page .field {
    width: 100%;
    padding-bottom: 0.625rem; }
    .forgot-password-page .field .field__error {
      position: static;
      padding-top: 0.625em; }
  .forgot-password-page button {
    background: #414a52;
    color: #fff;
    padding: 1.0625em 1.125em 0.875em;
    text-transform: uppercase;
    font-size: 0.6875em;
    line-height: 1.0625em;
    font-weight: 700;
    border: none;
    cursor: pointer;
    border-radius: 0.1875em;
    outline: none;
    letter-spacing: 0.8px; }
    .forgot-password-page button:hover {
      background-color: #384047;
      color: #fff; }
    .forgot-password-page button[disabled] {
      opacity: 0.4;
      background: #414a52;
      cursor: default;
      color: #fff; }
  .forgot-password-page .form__actions {
    display: flex;
    justify-content: center; }
  .forgot-password-page .forgot-password__success {
    padding-left: 1.25em;
    color: #fff;
    position: relative;
    font-size: 0.875em; }
    .forgot-password-page .forgot-password__success:before {
      content: "";
      background-color: #fff;
      height: 100%;
      width: 0.1875em;
      display: block;
      position: absolute;
      left: 0;
      top: 0; }
  .forgot-password-page .forgot-password__failed {
    padding-left: 1.25em;
    color: #d93025;
    position: relative;
    font-size: 0.875em; }
    .forgot-password-page .forgot-password__failed:before {
      content: "";
      background-color: #d93025;
      height: 100%;
      width: 0.1875em;
      display: block;
      position: absolute;
      left: 0;
      top: 0; }
  .forgot-password-page a {
    color: #fff;
    font-size: 0.75em;
    text-decoration: underline; }
    .forgot-password-page a:hover {
      text-decoration: none;
      color: #fff; }
  .forgot-password-page .field--inner.field--dirty .field__error {
    position: initial;
    padding-top: 0.625em; }

.activation-page {
  background-color: #90c6de;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center; }
  .activation-page h1 {
    color: #fff;
    text-transform: uppercase;
    margin-bottom: 2.8125em;
    text-align: center; }
  .activation-page .activation__fieldset {
    padding: 5rem 2.1875em 1.5625em;
    border: 1px solid #fff;
    max-width: 23.4375em;
    min-height: 21.875em;
    border-radius: 0.3125em;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center; }
  .activation-page .field {
    width: 100%;
    padding-bottom: 0.625rem; }
    .activation-page .field .field__error {
      position: static;
      padding-top: 0.625em; }
  .activation-page button {
    background: #414a52;
    color: #fff;
    padding: 1.0625em 1.125em 0.875em;
    text-transform: uppercase;
    font-size: 0.6875em;
    line-height: 1.0625em;
    font-weight: 700;
    border: none;
    cursor: pointer;
    border-radius: 0.1875em;
    outline: none;
    letter-spacing: 0.8px; }
    .activation-page button:hover {
      background-color: #384047;
      color: #fff; }
    .activation-page button[disabled] {
      opacity: 0.4;
      background: #414a52;
      cursor: default;
      color: #fff; }
  .activation-page .form__actions {
    display: flex;
    justify-content: center; }
  .activation-page .activation__success {
    padding-left: 1.25em;
    color: #fff;
    position: relative;
    font-size: 0.875em; }
    .activation-page .activation__success:before {
      content: "";
      background-color: #fff;
      height: 100%;
      width: 0.1875em;
      display: block;
      position: absolute;
      left: 0;
      top: 0; }
  .activation-page .activation__failed {
    padding-left: 1.25em;
    color: #d93025;
    position: relative;
    font-size: 0.875em; }
    .activation-page .activation__failed:before {
      content: "";
      background-color: #d93025;
      height: 100%;
      width: 0.1875em;
      display: block;
      position: absolute;
      left: 0;
      top: 0; }
  .activation-page a {
    color: #fff;
    font-size: 0.75em;
    text-decoration: underline; }
    .activation-page a:hover {
      text-decoration: none;
      color: #fff; }
  .activation-page .field--inner.field--dirty .field__error {
    position: initial;
    padding-top: 0.625em; }

.log-nav {
  display: flex;
  justify-content: center;
  margin-top: 1.25em; }

.form-error {
  padding-left: 1.25em;
  color: #d93025;
  position: relative;
  font-style: 0.875em;
  margin-bottom: 0.9375em; }
  .form-error:before {
    content: "";
    background-color: #d93025;
    height: 100%;
    width: 0.1875em;
    display: block;
    position: absolute;
    left: 0;
    top: 0; }

.password-requirements {
  padding-bottom: 0.9375em; }
  .password-requirements__item {
    font-size: 0.75em;
    line-height: 1.25em;
    margin-bottom: 1em;
    opacity: 0.45;
    position: relative;
    padding-left: 1.5rem; }
    .password-requirements__item:before {
      font-family: "iconfont";
      font-style: normal;
      font-weight: 400;
      content: ""; }
    .password-requirements__item:before {
      position: absolute;
      font-size: 0.9375rem;
      top: -0.0625em;
      left: 0; }
    .password-requirements__item--valid {
      opacity: 1; }
      .password-requirements__item--valid:before {
        font-family: "iconfont";
        font-style: normal;
        font-weight: 400;
        content: ""; }
      .password-requirements__item--valid:before {
        color: #94d394; }
    .password-requirements__item--invalid {
      opacity: 1; }
      .password-requirements__item--invalid:before {
        color: #f76b7e; }

.form--login .password-requirements {
  color: white; }
  .form--login .password-requirements__item--valid:before {
    color: #43a443; }
  .form--login .password-requirements__item--invalid:before {
    color: #f65369; }

.form__submit__arrow {
  padding-left: 0.625rem; }
  .form__submit__arrow:before {
    font-family: "iconfont";
    font-style: normal;
    font-weight: 400;
    content: ""; }
  .form__submit__arrow:before {
    position: relative;
    top: 0.0625rem; }

.edit-password .side-panel__form__section {
  padding-top: 1.5em;
  padding-bottom: .65em; }

.edit-password .field {
  padding-bottom: .9em; }

.edit-password .password-requirements {
  padding-top: .35em; }
