@charset "UTF-8";
@font-face {
  font-family: "iconfont";
  src: url("/iconfont.eot");
  /* IE9 Compat Modes */
  src: url("/iconfont.eot?#iefix") format("embedded-opentype"), url("/iconfont.woff") format("woff"), url("/iconfont.ttf") format("truetype"), url("/iconfont.svg") format("svg");
  /* Legacy iOS */ }

.icon {
  font-style: normal;
  font-weight: 400; }
  .icon.accordion-close:before {
    font-family: "iconfont";
    content: ""; }
  .icon.accordion-open:before {
    font-family: "iconfont";
    content: ""; }
  .icon.add-folder:before {
    font-family: "iconfont";
    content: ""; }
  .icon.add:before {
    font-family: "iconfont";
    content: ""; }
  .icon.admin:before {
    font-family: "iconfont";
    content: ""; }
  .icon.all-day:before {
    font-family: "iconfont";
    content: ""; }
  .icon.arrow-left:before {
    font-family: "iconfont";
    content: ""; }
  .icon.arrow-right:before {
    font-family: "iconfont";
    content: ""; }
  .icon.assignment:before {
    font-family: "iconfont";
    content: ""; }
  .icon.association-clinic:before {
    font-family: "iconfont";
    content: ""; }
  .icon.association:before {
    font-family: "iconfont";
    content: ""; }
  .icon.bars:before {
    font-family: "iconfont";
    content: ""; }
  .icon.calendar-2:before {
    font-family: "iconfont";
    content: ""; }
  .icon.calendar-today:before {
    font-family: "iconfont";
    content: ""; }
  .icon.calendar:before {
    font-family: "iconfont";
    content: ""; }
  .icon.call-list:before {
    font-family: "iconfont";
    content: ""; }
  .icon.checkbox-off:before {
    font-family: "iconfont";
    content: ""; }
  .icon.checkbox-on:before {
    font-family: "iconfont";
    content: ""; }
  .icon.checkbox-square:before {
    font-family: "iconfont";
    content: ""; }
  .icon.circle-add:before {
    font-family: "iconfont";
    content: ""; }
  .icon.circle-check:before {
    font-family: "iconfont";
    content: ""; }
  .icon.circle-info:before {
    font-family: "iconfont";
    content: ""; }
  .icon.circlo-close:before {
    font-family: "iconfont";
    content: ""; }
  .icon.clinic:before {
    font-family: "iconfont";
    content: ""; }
  .icon.close-slim:before {
    font-family: "iconfont";
    content: ""; }
  .icon.close:before {
    font-family: "iconfont";
    content: ""; }
  .icon.closed-folder:before {
    font-family: "iconfont";
    content: ""; }
  .icon.copy:before {
    font-family: "iconfont";
    content: ""; }
  .icon.delete:before {
    font-family: "iconfont";
    content: ""; }
  .icon.disconnect:before {
    font-family: "iconfont";
    content: ""; }
  .icon.dotted-arrow-right:before {
    font-family: "iconfont";
    content: ""; }
  .icon.dotted-arrow:before {
    font-family: "iconfont";
    content: ""; }
  .icon.edit:before {
    font-family: "iconfont";
    content: ""; }
  .icon.email:before {
    font-family: "iconfont";
    content: ""; }
  .icon.featured:before {
    font-family: "iconfont";
    content: ""; }
  .icon.handle-h:before {
    font-family: "iconfont";
    content: ""; }
  .icon.handle-v:before {
    font-family: "iconfont";
    content: ""; }
  .icon.help-circle:before {
    font-family: "iconfont";
    content: ""; }
  .icon.help:before {
    font-family: "iconfont";
    content: ""; }
  .icon.history:before {
    font-family: "iconfont";
    content: ""; }
  .icon.info:before {
    font-family: "iconfont";
    content: ""; }
  .icon.lock-open:before {
    font-family: "iconfont";
    content: ""; }
  .icon.lock:before {
    font-family: "iconfont";
    content: ""; }
  .icon.lodging:before {
    font-family: "iconfont";
    content: ""; }
  .icon.magnifying-glass:before {
    font-family: "iconfont";
    content: ""; }
  .icon.map:before {
    font-family: "iconfont";
    content: ""; }
  .icon.marker:before {
    font-family: "iconfont";
    content: ""; }
  .icon.notifications:before {
    font-family: "iconfont";
    content: ""; }
  .icon.open-folder:before {
    font-family: "iconfont";
    content: ""; }
  .icon.pencil:before {
    font-family: "iconfont";
    content: ""; }
  .icon.piechart:before {
    font-family: "iconfont";
    content: ""; }
  .icon.pin:before {
    font-family: "iconfont";
    content: ""; }
  .icon.profile:before {
    font-family: "iconfont";
    content: ""; }
  .icon.schedule:before {
    font-family: "iconfont";
    content: ""; }
  .icon.settings:before {
    font-family: "iconfont";
    content: ""; }
  .icon.small-down-arrow:before {
    font-family: "iconfont";
    content: ""; }
  .icon.small-up-arrow:before {
    font-family: "iconfont";
    content: ""; }
  .icon.status-check:before {
    font-family: "iconfont";
    content: ""; }
  .icon.surgery-optional:before {
    font-family: "iconfont";
    content: ""; }
  .icon.surgery:before {
    font-family: "iconfont";
    content: ""; }
  .icon.time:before {
    font-family: "iconfont";
    content: ""; }
  .icon.timesheet:before {
    font-family: "iconfont";
    content: ""; }
  .icon.triangle:before {
    font-family: "iconfont";
    content: ""; }
  .icon.tsa:before {
    font-family: "iconfont";
    content: ""; }
  .icon.validation:before {
    font-family: "iconfont";
    content: ""; }
  .icon.vet:before {
    font-family: "iconfont";
    content: ""; }

.mandates__toolbar {
  padding-bottom: 2em;
  border-bottom: 1px solid #d8dfe5;
  display: flex;
  justify-content: space-between;
  align-items: center; }
  .mandates__toolbar > * {
    display: inline-block; }
  .mandates__toolbar__title {
    position: relative;
    min-height: 2.1875em; }
    .mandates__toolbar__title > h2 {
      display: inline-block;
      font-size: 1.875em;
      font-weight: 700;
      margin-right: 0.9375em;
      color: #414a52;
      position: relative;
      padding: 0;
      min-width: 15.625rem;
      text-align: left;
      top: 0.1875em; }
  .mandates__toolbar__actions {
    position: relative;
    bottom: -0.125em; }
  .mandates__toolbar__show-past {
    background: #a0adb9;
    color: #FFF;
    padding: 1.0625em 1.125em 0.875em;
    text-transform: uppercase;
    font-size: 0.6875em;
    line-height: 1.0625em;
    font-weight: 700;
    border: none;
    cursor: pointer;
    border-radius: 0.1875em;
    outline: none;
    letter-spacing: 0.8px; }
    .mandates__toolbar__show-past:hover {
      background-color: #94a3b0;
      color: #FFF; }
    .mandates__toolbar__show-past[disabled] {
      opacity: 0.4;
      background: #a0adb9;
      cursor: default;
      color: #FFF; }
  .mandates__toolbar__show-current {
    background: #94d394;
    color: #FFF;
    padding: 1.0625em 1.125em 0.875em;
    text-transform: uppercase;
    font-size: 0.6875em;
    line-height: 1.0625em;
    font-weight: 700;
    border: none;
    cursor: pointer;
    border-radius: 0.1875em;
    outline: none;
    letter-spacing: 0.8px; }
    .mandates__toolbar__show-current:hover {
      background-color: #86cd86;
      color: #FFF; }
    .mandates__toolbar__show-current[disabled] {
      opacity: 0.4;
      background: #94d394;
      cursor: default;
      color: #FFF; }
  @media only screen and (max-width: 47.9rem) {
    .mandates__toolbar {
      padding-bottom: 0.5em;
      border-bottom: 0px; } }
  @media only screen and (max-width: 26rem) {
    .mandates__toolbar {
      flex-wrap: wrap; }
      .mandates__toolbar__title > h2 {
        font-size: 1.5em; }
      .mandates__toolbar__actions {
        width: 100%;
        padding-top: 0.5rem; } }

.mandates-list__wrapper {
  position: relative;
  padding-bottom: 3.125em; }

.mandates-list__back-to-list {
  margin-bottom: 1.875em; }
  .mandates-list__back-to-list a {
    text-decoration: underline; }

.mandate-card {
  margin: 2.1875em 0; }
  .mandate-card__header {
    display: flex; }
  .mandate-card__tab {
    position: relative;
    padding: .85em 3em 0.65em 1em;
    display: block;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    color: #414a52;
    font-size: 0.875em;
    line-height: 1.125em;
    vertical-align: center; }
    .mandate-card__tab:before {
      font-family: "iconfont";
      font-style: normal;
      font-weight: 400;
      content: ""; }
    .mandate-card__tab:before {
      color: white;
      position: relative;
      bottom: -3px;
      margin-right: .5em;
      font-size: 1.25em;
      line-height: 0.75em; }
    .mandate-card__tab span {
      padding-right: .5em; }
    .mandate-card__tab:after {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      z-index: -1;
      border-top-left-radius: 0.25rem;
      border-top-right-radius: 0.25rem;
      background: #f9b55a;
      content: '';
      transform: perspective(3px) rotateX(1.2deg) translateZ(-0.8px);
      transform-origin: 0 0;
      backface-visibility: hidden; }
  .mandate-card__form-error {
    color: #f76b7e;
    margin-top: 0.8em; }
  .mandate-card__add-time {
    position: relative;
    font-size: 0.875em;
    line-height: 1.125em;
    color: #414a52; }
    .mandate-card__add-time:before {
      font-family: "iconfont";
      font-style: normal;
      font-weight: 400;
      content: ""; }
    .mandate-card__add-time:before {
      color: #f9b55a;
      position: relative;
      bottom: -3px;
      margin-right: .5em;
      font-size: 1.25em;
      line-height: 0.75em; }
    .mandate-card__add-time .short {
      display: none; }
  .mandate-card__content {
    background-color: white;
    box-shadow: 0 5px 15px 0 rgba(0, 0, 0, 0.15);
    border-left: 4px #90c6de solid;
    border-radius: 0.1875rem;
    display: flex;
    flex-direction: row;
    overflow: hidden; }
    .mandate-card__content > * {
      width: 100%; }
    .mandate-card__content__list {
      display: flex;
      flex-direction: column;
      overflow-x: auto; }
  .mandate-card__header:not(.mandate-card__header--empty) + .mandate-card__content {
    border-top-left-radius: 0; }
  .mandate-card__content__header {
    display: flex; }
    .mandate-card__content__header h3 {
      font-size: 1.125em;
      line-height: 1.25em;
      color: #414a52;
      padding: 0; }
    .mandate-card__content__header__container:first-child:not(:last-child) {
      border-right: 0.0625rem solid #d8dfe5;
      margin-right: 1.25rem;
      min-width: 10.625rem; }
    .mandate-card__content__header__address {
      display: flex;
      flex-direction: column; }
      .mandate-card__content__header__address p {
        font-size: 0.875em;
        line-height: 0.875em;
        color: rgba(65, 74, 82, 0.6);
        padding: 0.5rem 0 0;
        margin: 0; }
    .mandate-card__content__header__proposal-details {
      display: flex;
      flex-direction: column; }
      .mandate-card__content__header__proposal-details .proposal-details {
        display: flex; }
        .mandate-card__content__header__proposal-details .proposal-details__icon {
          color: #414a52;
          font-size: 0.75rem; }
          .mandate-card__content__header__proposal-details .proposal-details__icon--consult:before {
            font-family: "iconfont";
            font-style: normal;
            font-weight: 400;
            content: ""; }
          .mandate-card__content__header__proposal-details .proposal-details__icon--distance:before {
            font-family: "iconfont";
            font-style: normal;
            font-weight: 400;
            content: ""; }
          .mandate-card__content__header__proposal-details .proposal-details__icon--lodging:before {
            font-family: "iconfont";
            font-style: normal;
            font-weight: 400;
            content: ""; }
          .mandate-card__content__header__proposal-details .proposal-details__icon--price:before {
            font-family: "iconfont";
            font-style: normal;
            font-weight: 400;
            content: ""; }
          .mandate-card__content__header__proposal-details .proposal-details__icon--surgery:before {
            font-family: "iconfont";
            font-style: normal;
            font-weight: 400;
            content: ""; }
        .mandate-card__content__header__proposal-details .proposal-details span {
          color: #414a52;
          font-size: 0.8125rem;
          font-weight: 300;
          margin-left: 0.9375rem;
          margin-top: 0.125rem; }
        .mandate-card__content__header__proposal-details .proposal-details:not(:last-child) {
          margin-bottom: 0.625rem; }
  .mandate-card__details {
    width: 30%;
    flex-shrink: 0;
    padding: 2.1875em 2.1875em;
    color: #414a52;
    overflow: hidden;
    position: relative; }
    .mandate-card__details .mandate-card__content__header > div:first-child {
      padding-right: 1.25em; }
    .mandate-card__details__image {
      border-radius: 50%;
      background-color: #d8dfe5;
      width: 3.125em;
      height: 3.125em;
      flex-grow: 0;
      flex-shrink: 0; }
    .mandate-card__details h3 {
      display: inline; }
    .mandate-card__details__desc {
      font-size: 0.8125em;
      line-height: 1.75em;
      color: #414a52;
      margin: 1.75em 0 0;
      padding: 1.75em 0 0;
      max-width: 100%;
      border-top: solid 1px #d8dfe5; }
    .mandate-card__details__body {
      padding-top: 1.25rem; }
      .mandate-card__details__body p {
        font-size: 0.875rem;
        line-height: 1.5rem;
        color: rgba(65, 74, 82, 0.7);
        padding-top: 1.25rem;
        border-top: 1px solid #d8dfe5;
        max-width: 100%; }
    .mandate-card__details__head {
      display: flex; }
      .mandate-card__details__head > * {
        padding: 0 0 0 1.25em; }
        .mandate-card__details__head > *:first-child {
          border-right: 1px solid #d8dfe5;
          padding: 0 1.25em 0 0; }
      .mandate-card__details__head:not(:first-child) {
        margin-top: 1.25rem;
        padding-top: 1.25rem;
        border-top: solid 1px #d8dfe5; }
    .mandate-card__details__show-desc + .mandate-card__details__head, .mandate-card__details__hide-desc + .mandate-card__details__head {
      margin-top: 0rem;
      padding-top: 0rem;
      border-top: none; }
    .mandate-card__details__wrapper {
      display: none; }
  .mandate-card__form {
    flex-shrink: 0;
    flex-grow: 1;
    background-color: #fbfbfc;
    border: solid 0px rgba(65, 74, 82, 0);
    border-left: solid 1px rgba(65, 74, 82, 0.1); }
    .mandate-card__form .mandate-card__content__header {
      justify-content: space-between;
      padding-top: 2.1875em;
      padding-bottom: 1.75em;
      border-bottom: solid 1px #d8dfe5;
      margin-left: 3.125em;
      margin-right: 3.125em;
      align-items: center; }
    .mandate-card__form--full-width {
      padding: 2.1875em 0 0; }
      .mandate-card__form--full-width .mandate-card__content__header {
        padding-left: 3.125em;
        padding-right: 3.125em;
        border-bottom: 0; }
    .mandate-card__form__footer {
      display: none;
      text-align: center; }
  .mandate-card__tag {
    padding: 0.375em 0.375em 0.125em;
    background-color: #414a52;
    border-radius: 0.1875em;
    color: white;
    font-size: 0.75em;
    font-weight: 700;
    letter-spacing: 0;
    white-space: nowrap;
    display: inline-block; }
    .mandate-card__tag--desktop {
      margin: 0 0.125em 0 0.75em; }
    .mandate-card__tag--mobile {
      display: none;
      margin-top: 0.625em;
      width: 100%;
      text-align: center; }
  .mandate-card__submit {
    background: #94d394;
    color: #fff;
    padding: 1.0625em 1.125em 0.875em;
    text-transform: uppercase;
    font-size: 0.6875em;
    line-height: 1.0625em;
    font-weight: 700;
    border: none;
    cursor: pointer;
    border-radius: 0.1875em;
    outline: none;
    letter-spacing: 0.8px;
    font-size: 0.75em; }
    .mandate-card__submit:hover {
      background-color: #86cd86;
      color: #fff; }
    .mandate-card__submit[disabled] {
      opacity: 0.4;
      background: #94d394;
      cursor: default;
      color: #fff; }
    .mandate-card__submit--disabled, .mandate-card__submit--disabled:hover {
      background-color: #d8dfe5;
      cursor: not-allowed; }
  .mandate-card__delete {
    background: #414a52;
    color: #fff;
    padding: 1.0625em 1.125em 0.875em;
    text-transform: uppercase;
    font-size: 0.6875em;
    line-height: 1.0625em;
    font-weight: 700;
    border: none;
    cursor: pointer;
    border-radius: 0.1875em;
    outline: none;
    letter-spacing: 0.8px;
    font-size: 0.75em; }
    .mandate-card__delete:hover {
      background-color: #384047;
      color: #fff; }
    .mandate-card__delete[disabled] {
      opacity: 0.4;
      background: #414a52;
      cursor: default;
      color: #fff; }
    .mandate-card__delete--disabled {
      background-color: #d8dfe5;
      cursor: not-allowed; }
  .mandate-card__range {
    display: flex;
    align-items: center;
    color: #414a52; }
    .mandate-card__range__day {
      background-color: #90c6de;
      border-radius: 0.25em;
      width: 3.25rem;
      height: 3.25rem;
      font-size: 0.75rem;
      line-height: 1.125rem;
      text-align: center;
      text-transform: uppercase;
      font-weight: 400;
      display: flex;
      justify-content: center;
      align-items: center; }
    .mandate-card__range strong {
      display: block;
      font-size: 1.4375rem;
      padding-top: 0.125rem; }
    .mandate-card__range__spacer {
      font-size: 1.5rem;
      margin: 0 0.625rem;
      color: #a0adb9; }
      .mandate-card__range__spacer:before {
        font-family: "iconfont";
        font-style: normal;
        font-weight: 400;
        content: ""; }
  .mandate-card__wanted {
    display: block;
    text-align: center;
    margin: 1.25em 0 0;
    font-weight: 700; }
  .mandate-card__list > * {
    display: block;
    margin-bottom: 0.625em;
    font-size: 0.8125rem;
    line-height: 1.25rem;
    font-weight: 300;
    position: relative;
    padding-left: 1.5rem; }
    .mandate-card__list > *:before {
      position: absolute;
      top: -0.125em;
      left: 0;
      font-size: 0.875rem; }
    .mandate-card__list > *:last-child {
      margin-bottom: 0; }
    .mandate-card__list > * small {
      font-size: .9em; }
  .mandate-card .list-item .list-item__remaining .type__icon--extended {
    color: #90c6de; }
  .mandate-card--blue .mandate-card__content {
    border-left-color: #90c6de; }
  .mandate-card--blue .mandate-card__tab:after,
  .mandate-card--blue .mandate-card__range__day {
    background-color: #90c6de; }
  .mandate-card--blue .list-item .list-item__remaining .type__icon--extended {
    color: #90c6de; }
  .mandate-card--green .mandate-card__content {
    border-left-color: #94d394; }
  .mandate-card--green .mandate-card__tab:after,
  .mandate-card--green .mandate-card__range__day {
    background-color: #94d394; }
  .mandate-card--green .list-item .list-item__remaining .type__icon--extended {
    color: #94d394; }
  .mandate-card--orange .mandate-card__content {
    border-left-color: #f9b55a; }
  .mandate-card--orange .mandate-card__tab:after,
  .mandate-card--orange .mandate-card__range__day {
    background-color: #f9b55a; }
  .mandate-card--orange .list-item .list-item__remaining .type__icon--extended {
    color: #f9b55a; }
  .mandate-card--grey .mandate-card__content {
    border-left-color: #b2bfcb; }
  .mandate-card--grey .mandate-card__tab:after,
  .mandate-card--grey .mandate-card__range__day {
    background-color: #d8dfe5; }
  .mandate-card--grey .list-item .list-item__remaining .type__icon--extended {
    color: #a0adb9; }
  .mandate-card--purple .mandate-card__content {
    border-left-color: #c49ee8; }
  .mandate-card--purple .mandate-card__tab:after,
  .mandate-card--purple .mandate-card__range__day {
    background-color: #c49ee8; }
  .mandate-card--purple .list-item .list-item__remaining .type__icon--extended {
    color: #c49ee8; }
  .mandate-card--yellow .mandate-card__content {
    border-left-color: #f5e067; }
  .mandate-card--yellow .mandate-card__tab:after,
  .mandate-card--yellow .mandate-card__range__day {
    background-color: #f5e067; }
  .mandate-card--yellow .list-item .list-item__remaining .type__icon--extended {
    color: #f5e067; }
  @media only screen and (max-width: 90.625rem) {
    .mandate-card__details {
      padding-left: 1.5625rem;
      padding-right: 1.5625rem; }
    .mandate-card__details .mandate-card__content__header > div:first-child {
      padding-right: 1em; } }
  @media only screen and (max-width: 84.375rem) {
    .mandate-card__details {
      padding: 2.1875em 1.875em; }
      .mandate-card__details__show-desc, .mandate-card__details__hide-desc {
        display: block; }
      .mandate-card__details__desc {
        display: none; }
        .mandate-card__details__desc--open {
          display: block; }
    .mandate-card__details__wrapper {
      display: inline-block;
      position: relative;
      margin: 1.25rem 0.9375rem 0 0; }
      .mandate-card__details__wrapper__show-message, .mandate-card__details__wrapper__hide-message {
        color: #a0adb9;
        font-size: 0.75em;
        text-decoration: underline; }
        .mandate-card__details__wrapper__show-message:hover, .mandate-card__details__wrapper__hide-message:hover {
          color: #414a52;
          text-decoration: none; }
        .mandate-card__details__wrapper__show-message:before, .mandate-card__details__wrapper__hide-message:before {
          font-family: "iconfont";
          font-style: normal;
          font-weight: 400;
          content: ""; }
      .mandate-card__details__wrapper__hide-message:before {
        font-family: "iconfont";
        font-style: normal;
        font-weight: 400;
        content: ""; }
      .mandate-card__details__wrapper:before {
        position: absolute;
        color: #a0adb9;
        left: 3rem;
        top: 0rem;
        font-size: 0.625rem; }
    .mandate-card__content {
      display: block; }
    .mandate-card__details {
      width: 100%;
      padding-left: 3.125rem;
      padding-right: 3.125rem; }
    .mandate-card__form {
      width: auto;
      min-width: 100%;
      border-left: solid 0px rgba(65, 74, 82, 0);
      border-top: solid 1px #d8dfe5; } }
  @media only screen and (max-width: 73.4375rem) {
    .mandate-card__details {
      padding-left: 2.1875rem;
      padding-right: 2.1875rem; }
    .mandate-card__form {
      padding: 0 0; }
      .mandate-card__form .mandate-card__content__header {
        margin-left: 2.1875em;
        margin-right: 2.1875em; } }
  @media only screen and (max-width: 53.125rem) {
    .mandate-card__details {
      padding: 2.1875em 1.875em; }
      .mandate-card__details__show-desc, .mandate-card__details__hide-desc {
        display: block; }
      .mandate-card__details__desc {
        display: none; }
        .mandate-card__details__desc--open {
          display: block; }
      .mandate-card__details .mandate-card__content__header {
        margin-bottom: 0;
        padding-bottom: 0;
        border-bottom: 0; }
    .mandate-card__form {
      padding: 0; }
      .mandate-card__form .mandate-card__content__header {
        display: none; }
      .mandate-card__form__footer {
        padding: 1.0625em 2.1875em;
        border-top: solid 1px #d8dfe5;
        display: block; }
    .mandate-card__content__header__container:first-child {
      min-width: unset;
      width: 50%; }
    .mandate-card__content__header__container:last-child {
      padding-top: 1.25rem; } }
  @media only screen and (max-width: 41.25rem) {
    .mandate-card__details {
      padding-top: 1.875rem;
      padding-bottom: 1.875rem; }
      .mandate-card__details__image {
        border-radius: 50%;
        background-color: #d8dfe5;
        width: 2.5em;
        height: 2.5em;
        flex-grow: 0;
        flex-shrink: 0; }
      .mandate-card__details .mandate-card__tag {
        font-size: .5em; }
    .mandate-card__add-time .short {
      display: inline-block; }
    .mandate-card__add-time .long {
      display: none; }
    .mandate-card__content__header p {
      font-size: 0.75em; }
    .mandate-card__tag--desktop {
      display: none; }
    .mandate-card__tag--mobile {
      display: inline-block;
      width: auto; } }
  @media only screen and (max-width: 28.125rem) {
    .mandate-card__details {
      padding: 1.25rem 0.9375rem; }
      .mandate-card__details__show-desc, .mandate-card__details__hide-desc {
        right: 0.9375rem; }
    .mandate-card__details__head {
      display: block; }
      .mandate-card__details__head > * {
        padding: 1.25em 0 0 0;
        text-align: center; }
        .mandate-card__details__head > *:first-child {
          border-right: none;
          border-bottom: 1px solid #d8dfe5;
          padding: 0 0 1.25em 0; }
      .mandate-card__details__head .mandate-card__list {
        text-align: left; }
      .mandate-card__details__head:not(:first-child) {
        margin-top: 1.25rem;
        padding-top: 1.25rem;
        border-top: solid 1px #d8dfe5; }
    .mandate-card .mandate-card__range {
      justify-content: center; }
    .mandate-card__content__header__container:first-child {
      min-width: unset; } }
  @media only screen and (max-width: 21.875rem) {
    .mandate-card__content__header {
      flex-direction: column; }
      .mandate-card__content__header__container:first-child {
        border-bottom: solid 0.0625rem #d8dfe5;
        border-right: none;
        padding-bottom: 1.25rem;
        width: 100%; }
      .mandate-card__content__header__container:first-child:not(:last-child) {
        border-right: none; }
      .mandate-card__content__header__container:last-child {
        padding-top: 1.25rem; } }
  .mandate-card .mandate-card__form__list > *:last-child .list-item__header {
    border-bottom: none; }
  .mandate-card .mandate-card__form__list > *:last-child .list-item--open:last-child .list-item__header,
  .mandate-card .mandate-card__form__list > .list-item--open:last-child .list-item__header {
    border-bottom: 1px solid #d8dfe5; }
  .mandate-card .mandate-card__form__list > *:last-child .list-item--open:last-child .list-item__drawer {
    border-bottom: none; }
  .mandate-card .list-item {
    font-weight: 300;
    width: 100%;
    color: #414a52;
    padding: 0 3.125em; }
    .mandate-card .list-item--open {
      background-color: rgba(216, 223, 229, 0.25); }
      .mandate-card .list-item--open .list-item__drawer {
        border-bottom: 1px solid #d8dfe5; }
    .mandate-card .list-item--discarded {
      background: repeating-linear-gradient(35deg, transparent, transparent 4px, rgba(160, 173, 185, 0.05) 4px, rgba(160, 173, 185, 0.05) 8px), transparent; }
      .mandate-card .list-item--discarded .list-item__infos > * {
        opacity: 0.2; }
    .mandate-card .list-item--has-drawer .list-item__infos {
      pointer-events: auto; }
    .mandate-card .list-item--proposal, .mandate-card .list-item--pending {
      background: rgba(160, 173, 185, 0.1); }
    .mandate-card .list-item__header {
      padding: 0.75em 0;
      display: flex;
      width: 100%;
      align-items: center;
      justify-content: space-between;
      border-bottom: 1px solid rgba(216, 223, 229, 0.5); }
    .mandate-card .list-item__infos {
      font-size: 0.8125rem;
      line-height: 1.0625rem;
      display: flex;
      flex-grow: 1;
      align-items: center;
      -webkit-user-select: none;
      /* Safari */
      -moz-user-select: none;
      /* Firefox */
      -ms-user-select: none;
      /* IE10+/Edge */
      user-select: none;
      /* Standard */
      pointer-events: none; }
      .mandate-card .list-item__infos > * {
        padding-left: 2.1875em; }
        .mandate-card .list-item__infos > *:first-child {
          margin-left: 0; }
    .mandate-card .list-item__actions {
      white-space: nowrap;
      padding-left: 1.5em;
      min-width: 7.375rem;
      text-align: right; }
      .mandate-card .list-item__actions--both {
        display: flex; }
      .mandate-card .list-item__actions--full-width {
        min-width: 11.8125em;
        flex-direction: row; }
      .mandate-card .list-item__actions .tooltip__wrapper {
        padding: 0 0.75rem; }
        .mandate-card .list-item__actions .tooltip__wrapper .tooltip {
          right: 1.25rem;
          bottom: calc(100% + 0.75rem); }
        .mandate-card .list-item__actions .tooltip__wrapper:last-child {
          padding-right: 0; }
          .mandate-card .list-item__actions .tooltip__wrapper:last-child .tooltip {
            right: 0.625rem; }
        .mandate-card .list-item__actions .tooltip__wrapper:not(:last-child) {
          border-right: solid 1px #d8dfe5; }
    .mandate-card .list-item__action {
      color: #a0adb9;
      font-size: 1rem;
      padding: 0; }
      .mandate-card .list-item__action:last-child {
        margin-left: 0.875em; }
      .mandate-card .list-item__action:hover {
        color: #414a52; }
      .mandate-card .list-item__action--edit:before {
        font-family: "iconfont";
        font-style: normal;
        font-weight: 400;
        content: ""; }
      .mandate-card .list-item__action--delete:before {
        font-family: "iconfont";
        font-style: normal;
        font-weight: 400;
        content: ""; }
      .mandate-card .list-item__action--pending_admin, .mandate-card .list-item__action--confirmed {
        font-size: 1.5625rem;
        color: #94d394; }
        .mandate-card .list-item__action--pending_admin:before, .mandate-card .list-item__action--confirmed:before {
          font-family: "iconfont";
          font-style: normal;
          font-weight: 400;
          content: ""; }
      .mandate-card .list-item__action--rejected {
        font-size: 1.5625rem;
        color: #f76b7e; }
        .mandate-card .list-item__action--rejected:before {
          font-family: "iconfont";
          font-style: normal;
          font-weight: 400;
          content: ""; }
      .mandate-card .list-item__action--pending_clinic, .mandate-card .list-item__action--pending {
        font-size: 1.5625rem;
        color: #f9b55a; }
        .mandate-card .list-item__action--pending_clinic:before, .mandate-card .list-item__action--pending:before {
          font-family: "iconfont";
          font-style: normal;
          font-weight: 400;
          content: ""; }
      .mandate-card .list-item__action--expired {
        font-size: 1.5625rem;
        color: #d8dfe5; }
        .mandate-card .list-item__action--expired:before {
          font-family: "iconfont";
          font-style: normal;
          font-weight: 400;
          content: ""; }
    .mandate-card .list-item__priority {
      padding-left: 0;
      padding-right: 0.375rem;
      opacity: 0; }
    .mandate-card .list-item__date {
      width: 36px;
      text-transform: uppercase;
      font-size: 0.4375rem;
      line-height: 0.625rem;
      text-align: center;
      padding: 0.625em 0;
      border-radius: 0.3125em; }
      .mandate-card .list-item__date span.date {
        font-size: 1rem;
        line-height: 0.6875rem;
        display: block;
        padding-top: 0.3125em;
        padding-bottom: 0.1875em;
        font-weight: 600; }
      .mandate-card .list-item__date + .list-item__month {
        padding-left: 0.9375em; }
      .mandate-card .list-item__date .month {
        padding-top: 0.25em;
        display: none; }
    .mandate-card .list-item__month {
      min-width: 7.5rem;
      text-transform: capitalize; }
    .mandate-card .list-item__hours {
      padding-right: 0.375rem;
      font-weight: 700;
      white-space: nowrap;
      letter-spacing: 0;
      width: 9.0625rem; }
      .mandate-card .list-item__hours .type__icon {
        display: inline-block;
        margin-bottom: 0;
        margin-right: 0.375rem; }
    .mandate-card .list-item__message {
      color: #414a52;
      position: relative; }
      .mandate-card .list-item__message:before {
        font-family: "iconfont";
        font-style: normal;
        font-weight: 400;
        content: ""; }
      .mandate-card .list-item__message:after {
        content: "";
        position: absolute;
        background: #f76b7e;
        border-radius: 100%;
        height: 0.45em;
        right: -0.12em;
        top: -0.05em;
        width: 0.45em; }
    .mandate-card .list-item__type small {
      font-size: .85em; }
    .mandate-card .list-item__type .type__text span {
      display: inline-block; }
    .mandate-card .list-item__type .type__text--text-only {
      display: inline-block !important; }
    .mandate-card .list-item__type .type__text span:first-child:not(:last-child):after {
      content: '&';
      display: inline-block;
      padding: 0 .2em; }
    .mandate-card .list-item__type .type__icon {
      display: none;
      padding: 0 0.125em; }
    .mandate-card .list-item__lodging .type__icon--lodging {
      display: none; }
    .mandate-card .list-item__remaining {
      display: flex;
      align-items: center;
      flex-grow: 1;
      flex-shrink: 1;
      white-space: nowrap; }
      .mandate-card .list-item__remaining .type__icon:before {
        font-size: 1.5rem;
        padding-right: 0.5rem; }
    .mandate-card .list-item__drawer {
      font-size: 0.8125rem;
      position: relative;
      display: flex;
      justify-content: center; }
      .mandate-card .list-item__drawer__infos {
        margin-right: 2.1875rem;
        padding: 1.875em 0.625em 1.5625em; }
      .mandate-card .list-item__drawer__close {
        padding: 0.3125em 0.6875em;
        margin: 0;
        display: block;
        position: absolute;
        top: 1.875em;
        right: 0.625em;
        color: #b2bfcb;
        z-index: +1; }
        .mandate-card .list-item__drawer__close:before {
          font-family: "iconfont";
          font-style: normal;
          font-weight: 400;
          content: ""; }
        .mandate-card .list-item__drawer__close:hover {
          color: #414a52; }
      .mandate-card .list-item__drawer__message {
        display: flex;
        flex-direction: column;
        align-items: center; }
        .mandate-card .list-item__drawer__message--title {
          font-weight: 700;
          margin-bottom: 0.625rem; }
      .mandate-card .list-item__drawer .list-item__btn {
        font-weight: 700;
        margin-right: 1.25em;
        display: block;
        width: 100%; }
        .mandate-card .list-item__drawer .list-item__btn:last-child {
          margin-right: 0; }
        .mandate-card .list-item__drawer .list-item__btn--delete {
          background: #f76b7e;
          color: #fff;
          padding: 1.0625em 1.125em 0.875em;
          text-transform: uppercase;
          font-size: 0.6875em;
          line-height: 1.0625em;
          font-weight: 700;
          border: none;
          cursor: pointer;
          border-radius: 0.1875em;
          outline: none;
          letter-spacing: 0.8px;
          font-size: .9em; }
          .mandate-card .list-item__drawer .list-item__btn--delete:hover {
            background-color: #f6586d;
            color: #fff; }
          .mandate-card .list-item__drawer .list-item__btn--delete[disabled] {
            opacity: 0.4;
            background: #f76b7e;
            cursor: default;
            color: #fff; }
        .mandate-card .list-item__drawer .list-item__btn--edit {
          background: #414a52;
          color: #fff;
          padding: 1.0625em 1.125em 0.875em;
          text-transform: uppercase;
          font-size: 0.6875em;
          line-height: 1.0625em;
          font-weight: 700;
          border: none;
          cursor: pointer;
          border-radius: 0.1875em;
          outline: none;
          letter-spacing: 0.8px;
          font-size: .9em; }
          .mandate-card .list-item__drawer .list-item__btn--edit:hover {
            background-color: #384047;
            color: #fff; }
          .mandate-card .list-item__drawer .list-item__btn--edit[disabled] {
            opacity: 0.4;
            background: #414a52;
            cursor: default;
            color: #fff; }
    .mandate-card .list-item__bullet {
      position: relative;
      padding-left: 2.5rem;
      padding-bottom: 0.375rem; }
      .mandate-card .list-item__bullet:last-child {
        padding-bottom: 0; }
      .mandate-card .list-item__bullet:before {
        content: "-";
        position: absolute;
        top: 0;
        left: 0;
        font-size: 0.875em; }
      .mandate-card .list-item__bullet strong {
        display: block;
        font-weight: 700;
        margin-bottom: 0.375em; }
      .mandate-card .list-item__bullet--disabled:before {
        color: #a0adb9; }
      .mandate-card .list-item__bullet--hours:before {
        font-family: "iconfont";
        font-style: normal;
        font-weight: 400;
        content: ""; }
      .mandate-card .list-item__bullet--marker:before {
        font-family: "iconfont";
        font-style: normal;
        font-weight: 400;
        content: ""; }
      .mandate-card .list-item__bullet--surgery:before {
        font-family: "iconfont";
        font-style: normal;
        font-weight: 400;
        content: ""; }
      .mandate-card .list-item__bullet--consult:before {
        font-family: "iconfont";
        font-style: normal;
        font-weight: 400;
        content: ""; }
      .mandate-card .list-item__bullet--lodging:before {
        font-family: "iconfont";
        font-style: normal;
        font-weight: 400;
        content: ""; }
      .mandate-card .list-item__bullet--info {
        margin-top: 0.625rem; }
        .mandate-card .list-item__bullet--info:before {
          font-family: "iconfont";
          font-style: normal;
          font-weight: 400;
          content: ""; }
        .mandate-card .list-item__bullet--info:first-child {
          margin-top: 0; }
      .mandate-card .list-item__bullet--price:before {
        font-family: "iconfont";
        font-style: normal;
        font-weight: 400;
        content: ""; }
    .mandate-card .list-item__distance {
      width: 25%; }
      .mandate-card .list-item__distance .distance__tag {
        display: none;
        padding: 0.375em 0.375em 0.125em;
        background-color: #414a52;
        border-radius: 0.1875em;
        color: white;
        font-size: 0.875em;
        font-weight: 700;
        letter-spacing: 0; }
    .mandate-card .list-item .radio-accept {
      justify-content: flex-end; }
    .mandate-card .list-item .arrow {
      font-size: 80%;
      color: #a3a0a0;
      padding-left: .35em;
      padding-right: .35em; }
      .mandate-card .list-item .arrow:before {
        font-family: "iconfont";
        font-style: normal;
        font-weight: 400;
        content: ""; }
    .mandate-card .list-item .type__icon {
      color: #a0adb9; }
      .mandate-card .list-item .type__icon--surgery:before {
        font-family: "iconfont";
        font-style: normal;
        font-weight: 400;
        content: ""; }
      .mandate-card .list-item .type__icon--consult:before {
        font-family: "iconfont";
        font-style: normal;
        font-weight: 400;
        content: ""; }
      .mandate-card .list-item .type__icon--lodging:before {
        font-family: "iconfont";
        font-style: normal;
        font-weight: 400;
        content: ""; }
      .mandate-card .list-item .type__icon--flex {
        opacity: 0; }
        .mandate-card .list-item .type__icon--flex:before {
          font-family: "iconfont";
          font-style: normal;
          font-weight: 400;
          content: ""; }
      .mandate-card .list-item .type__icon--priority:before {
        font-family: "iconfont";
        font-style: normal;
        font-weight: 400;
        content: ""; }
      .mandate-card .list-item .type__icon--extended:before {
        font-family: "iconfont";
        font-style: normal;
        font-weight: 400;
        content: ""; }
      .mandate-card .list-item .type__icon--active {
        color: #414a52;
        opacity: 1; }
      .mandate-card .list-item .type__icon--invisible {
        opacity: 0; }
    @media only screen and (max-width: 102.5rem) {
      .mandate-card .list-item__lodging {
        width: auto; }
        .mandate-card .list-item__lodging--text {
          display: none; }
        .mandate-card .list-item__lodging .type__icon--lodging {
          display: inline-block; } }
    @media only screen and (max-width: 84.375rem) {
      .mandate-card .list-item__lodging {
        width: 35%; }
        .mandate-card .list-item__lodging--text {
          display: inline-block; }
        .mandate-card .list-item__lodging .type__icon--lodging {
          display: none; } }
    @media only screen and (max-width: 73.4375rem) {
      .mandate-card .list-item {
        padding: 0 2.1875em; }
        .mandate-card .list-item--proposal, .mandate-card .list-item--pending {
          background: none; }
        .mandate-card .list-item--has-drawer .list-item__infos {
          pointer-events: auto; }
        .mandate-card .list-item__hours {
          min-width: 25%;
          flex-shrink: 0; }
        .mandate-card .list-item__type {
          width: 15%;
          white-space: nowrap; }
          .mandate-card .list-item__type .type__text {
            display: none; }
          .mandate-card .list-item__type .type__icon {
            display: inline-block; }
          .mandate-card .list-item__type .type__icon--lodging {
            display: none; } }
    @media only screen and (max-width: 70rem) {
      .mandate-card .list-item__infos {
        padding-right: 0.625em; }
        .mandate-card .list-item__infos > * {
          margin-left: 1.25em;
          padding-left: 1.875em; }
        .mandate-card .list-item__infos > .list-item__priority {
          padding-left: 0; }
        .mandate-card .list-item__infos > .list-item__remaining {
          padding-left: 0;
          flex-grow: 1; }
      .mandate-card .list-item__date {
        padding-left: 0; }
        .mandate-card .list-item__date .month {
          display: block; }
      .mandate-card .list-item__month {
        display: none; }
      .mandate-card .list-item__hours {
        min-width: 20%; }
      .mandate-card .list-item__type {
        width: auto; } }
    @media only screen and (max-width: 53.125rem) {
      .mandate-card .list-item {
        padding: 0; }
        .mandate-card .list-item__header {
          padding-left: 0.625em;
          padding-right: 1.25em; }
        .mandate-card .list-item__drawer {
          padding-left: 2.5em;
          padding-right: 2.5em; }
          .mandate-card .list-item__drawer__close {
            right: 2.25em; } }
    @media only screen and (max-width: 47.9rem) {
      .mandate-card .list-item__infos {
        justify-content: flex-start; }
        .mandate-card .list-item__infos > * {
          flex-shrink: 0; }
      .mandate-card .list-item__date {
        padding-left: 0; }
        .mandate-card .list-item__date .month {
          display: block; }
      .mandate-card .list-item__message {
        order: 4; }
      .mandate-card .list-item__month {
        display: none; }
      .mandate-card .list-item__hours {
        padding-left: 0.9375em;
        flex-grow: 0;
        min-width: 0;
        order: 3; }
      .mandate-card .list-item__type {
        order: 2; }
        .mandate-card .list-item__type .type__icon--lodging {
          display: inline-block; }
      .mandate-card .list-item__lodging {
        display: none; }
      .mandate-card .list-item__actions {
        padding-left: 7.5%;
        min-width: 0; } }
    @media only screen and (max-width: 38rem) {
      .mandate-card .list-item__infos > * {
        margin-left: 0.625em;
        padding-left: 0.625em; }
      .mandate-card .list-item__date {
        order: 1; }
      .mandate-card .list-item__hours {
        order: 3; }
      .mandate-card .list-item__message {
        order: 4; }
      .mandate-card .list-item__type {
        width: auto;
        order: 2; }
      .mandate-card .list-item__distance {
        width: auto; }
      .mandate-card .list-item .mandate-item__user {
        order: 4; }
      .mandate-card .list-item .mandate-item__user__picture {
        display: block; }
      .mandate-card .list-item__remaining {
        order: 5; }
        .mandate-card .list-item__remaining .type__icon:before {
          font-size: 1.25rem;
          padding-right: 0.375rem; }
      .mandate-card .list-item__actions {
        padding-left: 0.3125em; } }
    @media only screen and (max-width: 28.125rem) {
      .mandate-card .list-item__header {
        padding: 0.9375em; }
      .mandate-card .list-item .mandate-item__user {
        width: 100%;
        justify-content: center;
        margin-left: 0;
        margin-bottom: 0.625rem; } }
    .mandate-card .list-item--clinic .type__icon--priority:before {
      position: relative;
      top: -0.0625rem; }
    .mandate-card .list-item--clinic .type__icon--flex:before {
      position: relative;
      top: 0; }
    .mandate-card .list-item--clinic .list-item__infos--mobile {
      display: none; }
      .mandate-card .list-item--clinic .list-item__infos--mobile .list-item__date {
        padding-top: 0.4375em;
        padding-bottom: 0.1875em; }
    .mandate-card .list-item--clinic .list-item__column {
      display: flex;
      flex-direction: column;
      order: 4;
      margin: 0.0875em 0; }
    .mandate-card .list-item--clinic .list-item__substitute-name {
      margin: 0.1875rem 0 0.1875rem; }
    .mandate-card .list-item--clinic .list-item__time {
      margin-top: 0.125rem;
      margin-right: 0.3125rem; }
    .mandate-card .list-item--clinic .substitute {
      display: flex;
      position: relative;
      top: -0.25rem;
      margin-bottom: -0.25rem; }
      .mandate-card .list-item--clinic .substitute__pending, .mandate-card .list-item--clinic .substitute__name {
        display: block;
        width: 100%;
        white-space: nowrap;
        font-size: 1rem;
        margin-top: 0.125rem; }
      .mandate-card .list-item--clinic .substitute__name {
        font-weight: 700; }
      .mandate-card .list-item--clinic .substitute__profile {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        margin-top: 0.5rem; }
      .mandate-card .list-item--clinic .substitute__desc {
        display: block;
        width: 100%;
        margin-top: 0.5rem;
        line-height: 1.0625rem;
        padding-right: 10%; }
      .mandate-card .list-item--clinic .substitute .type__icon {
        font-size: 0.9375rem;
        line-height: 0.75rem; }
        .mandate-card .list-item--clinic .substitute .type__icon:before {
          position: relative; }
        .mandate-card .list-item--clinic .substitute .type__icon--surgery:before {
          top: 0.125rem; }
        .mandate-card .list-item--clinic .substitute .type__icon--consult {
          top: 0; }
      .mandate-card .list-item--clinic .substitute__picture {
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
        background-color: #a0adb9;
        color: white;
        margin-right: 0.875rem;
        border-radius: 50%;
        font-size: 1.25em;
        display: block;
        height: 2.5em;
        width: 2.5em;
        text-align: center;
        line-height: 2.5em;
        flex-shrink: 0; }
        .mandate-card .list-item--clinic .substitute__picture--empty:before {
          font-family: "iconfont";
          font-style: normal;
          font-weight: 400;
          content: ""; }
    @media only screen and (max-width: 59.375rem) {
      .mandate-card .list-item--clinic .list-item__infos--desktop {
        display: none; }
      .mandate-card .list-item--clinic .list-item__infos--mobile {
        display: flex; }
      .mandate-card .list-item--clinic.list-item--has-drawer .list-item__infos {
        pointer-events: auto; } }
    @media only screen and (max-width: 28.125rem) {
      .mandate-card .list-item--clinic .list-item__infos + div {
        width: auto; }
      .mandate-card .list-item--clinic .mandate-item__actions {
        width: auto;
        justify-content: right; }
      .mandate-card .list-item--clinic .list-item__substitute-name {
        letter-spacing: .05em; }
      .mandate-card .list-item--clinic .list-item__time {
        letter-spacing: 0; } }
    @media only screen and (max-width: 26rem) {
      .mandate-card .list-item__infos > * {
        margin-left: 1.25em;
        padding-left: 1.25em; }
      .mandate-card .list-item__infos .list-item__hours {
        padding: 0;
        width: 6.875rem; }
      .mandate-card .list-item__message {
        margin: 0;
        padding: 0; }
      .mandate-card .list-item .mandate-item__user__picture {
        display: none; } }
    @media only screen and (max-width: 21.875rem) {
      .mandate-card .list-item__infos > * {
        margin-left: 0.625em;
        padding-left: 0.625em; }
      .mandate-card .list-item__type .type__text--text-only span {
        display: block; } }

.mandates-text {
  margin-top: 2.1875em;
  padding: 2.1875em 3.125em 1.875em;
  background-color: white;
  border: solid 1px #f7f8f9;
  border-left: solid 4px #f9b55a;
  border-top-left-radius: 0.3125em;
  border-bottom-left-radius: 0.3125em;
  box-shadow: 0 5px 15px 0 rgba(0, 0, 0, 0.05); }
  .mandates-text p {
    margin-bottom: 0; }
  .mandates-text--confirmed {
    border-left-color: #94d394; }
  .mandates-text--regular {
    border-left-color: #90c6de; }

.mandate-item {
  width: 100%;
  align-items: center;
  padding: 0 3.125em;
  color: #414a52; }
  .mandate-item__wrapper {
    padding: 1.25em 0;
    border-top: 1px solid #d8dfe5;
    display: flex;
    justify-content: space-between; }
  .mandate-item--discarded {
    background: repeating-linear-gradient(35deg, transparent, transparent 4px, rgba(160, 173, 185, 0.05) 4px, rgba(160, 173, 185, 0.05) 8px), transparent; }
    .mandate-item--discarded .mandate-item__date, .mandate-item--discarded .mandate-item__time-range, .mandate-item--discarded .mandate-item__user {
      opacity: 0.2; }
  .mandate-item--proposal {
    background: rgba(160, 173, 185, 0.05); }
    .mandate-item--proposal .mandate-item__date, .mandate-item--proposal .mandate-item__time-range, .mandate-item--proposal .mandate-item__user {
      opacity: 1; }
  .mandate-item .schedule__event__picto {
    margin: 0 0.625em 0 0;
    position: relative;
    top: 0.125em; }
  .mandate-item__date {
    display: flex;
    align-items: center;
    width: 15.625em;
    flex-shrink: 0; }
    .mandate-item__date > * {
      margin-right: 0.9375em; }
    .mandate-item__date .list-item__date {
      margin-left: 5px; }
  .mandate-item__time-range {
    display: flex;
    align-items: center;
    width: 15.625em;
    flex-shrink: 0; }
    .mandate-item__time-range .schedule__event__to:before {
      color: #a0adb9; }
  .mandate-item__experience__tag, .mandate-item__price__tag, .mandate-item__distance__tag {
    display: inline-block;
    background-color: #dadcdd;
    border-radius: 0.3125em;
    color: #414a52;
    font-size: 0.625rem;
    font-weight: 700;
    letter-spacing: 0;
    line-height: 0.75rem;
    margin-left: 0.375rem;
    padding: 0.5em 0.5em 0.1875em;
    white-space: nowrap; }
    .mandate-item__experience__tag--hidden, .mandate-item__price__tag--hidden, .mandate-item__distance__tag--hidden {
      opacity: 0; }
  .mandate-item__user {
    width: 100%;
    display: flex;
    align-items: center;
    flex-shrink: 1;
    position: relative;
    cursor: default;
    line-height: 0.8125rem; }
    .mandate-item__user *:first-child {
      margin-left: 0; }
    .mandate-item__user .tooltip {
      min-width: 200px;
      position: absolute;
      font-size: 0.625rem;
      line-height: 1.25em;
      bottom: calc(100% + 0.2625rem);
      z-index: 2;
      left: 15px;
      padding: 0.4375rem;
      border-radius: 0.125rem;
      background-color: #414a52;
      text-align: center;
      display: none;
      color: #fff; }
      .mandate-item__user .tooltip:before {
        content: "";
        width: 0;
        height: 0;
        border-right: 0.625rem solid transparent;
        border-top: 0.625rem solid #414a52;
        position: absolute;
        bottom: -0.5625rem;
        left: 0; }
    .mandate-item__user:hover .tooltip {
      display: block; }
    .mandate-item__user__infos {
      line-height: 120%; }
      .mandate-item__user__infos span {
        margin-bottom: 0.1875em; }
    .mandate-item__user__name {
      display: block;
      width: 100%;
      white-space: nowrap; }
    .mandate-item__user__picture {
      background-color: #d8dfe5;
      margin-right: 0.625em;
      color: #414a52;
      border-radius: 50%;
      font-size: 1.25em;
      display: block;
      height: 2.5em;
      width: 2.5em;
      text-align: center;
      line-height: 2.5em;
      flex-shrink: 0; }
      .mandate-item__user__picture:before {
        font-family: "iconfont";
        font-style: normal;
        font-weight: 400;
        content: ""; }
      .mandate-item__user__picture--filled {
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat; }
        .mandate-item__user__picture--filled:before {
          display: none; }
    .mandate-item__user__preferences {
      margin-right: 0.5em;
      display: flex;
      align-items: center; }
      .mandate-item__user__preferences .list-item__time {
        margin-right: 0.3125rem;
        font-weight: 700; }
      .mandate-item__user__preferences .type__icon {
        position: relative;
        top: 1px; }
    .mandate-item__user__tags {
      display: flex;
      align-items: center; }
      .mandate-item__user__tags .mandate-item__distance__tag {
        margin-left: 0; }
      .mandate-item__user__tags div:not(:last-child) {
        margin-right: 0.3125rem; }
    .mandate-item__user--confirmed .mandate-item__user__picture:before {
      font-family: "iconfont";
      font-style: normal;
      font-weight: 400;
      content: ""; }
    .mandate-item__user--confirmed .mandate-item__user__picture--empty:before {
      font-family: "iconfont";
      font-style: normal;
      font-weight: 400;
      content: ""; }
  .mandate-item__actions {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: center; }
    .mandate-item__actions__delete:before {
      font-family: "iconfont";
      font-style: normal;
      font-weight: 400;
      content: ""; }
    .mandate-item__actions__edit {
      margin-right: 0.625em; }
      .mandate-item__actions__edit:before {
        font-family: "iconfont";
        font-style: normal;
        font-weight: 400;
        content: ""; }
    .mandate-item__actions__delete, .mandate-item__actions__edit {
      color: #a0adb9; }
      .mandate-item__actions__delete:hover, .mandate-item__actions__edit:hover {
        color: #4c4648; }
    .mandate-item__actions__enable {
      background: #a0adb9;
      color: #fff;
      padding: 1.0625em 1.125em 0.875em;
      text-transform: uppercase;
      font-size: 0.6875em;
      line-height: 1.0625em;
      font-weight: 700;
      border: none;
      cursor: pointer;
      border-radius: 0.1875em;
      outline: none;
      letter-spacing: 0.8px;
      font-size: 0.625em;
      padding-bottom: 0.6875em; }
      .mandate-item__actions__enable:hover {
        background-color: #94a3b0;
        color: #fff; }
      .mandate-item__actions__enable[disabled] {
        opacity: 0.4;
        background: #a0adb9;
        cursor: default;
        color: #fff; }
    .mandate-item__actions__confirmed {
      margin-right: 0.625em;
      font-size: 1.5em;
      color: #94d394; }
      .mandate-item__actions__confirmed:before {
        font-family: "iconfont";
        font-style: normal;
        font-weight: 400;
        content: ""; }
    .mandate-item__actions__wait {
      width: 1.5625em;
      height: 1.5625em; }
      .mandate-item__actions__wait img {
        width: 1.5625em; }
  @media only screen and (max-width: 90.625rem) {
    .mandate-item__date {
      width: 30%; }
    .mandate-item__time-range, .mandate-item__user, .mandate-item__actions {
      flex-grow: 1;
      flex-shrink: 1;
      width: auto; }
    .mandate-item__time-range {
      width: 20%; }
    .mandate-item__user .tooltip {
      max-width: 100%; } }
  @media only screen and (max-width: 70rem) {
    .mandate-item__date {
      min-width: 5.625rem;
      width: 15%; } }
  @media only screen and (max-width: 53.125rem) {
    .mandate-item {
      padding-left: 1.875em;
      padding-right: 1.875em; }
      .mandate-item:first-child .mandate-item__wrapper {
        border-top: 0; }
      .mandate-item__date {
        min-width: 5rem; }
      .mandate-item__time-range, .mandate-item__user {
        font-size: 0.75rem; }
      .mandate-item .schedule__event__picto {
        margin-right: 0.3125em; }
      .mandate-item .list-item__date {
        margin-right: 0; } }
