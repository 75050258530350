.schedule__day-header--date {
  border-radius: 0.1875rem;
  display: inline-block;
  width: 2.1875rem;
  padding: 0.6875rem 0 0.5rem;
  text-align: center;
  font-weight: 300; }
  .schedule__day-header--date span {
    display: none; }

.rbc-now .schedule__day-header--date {
  background-color: #414a52;
  color: #fff; }

.rbc-off-range .schedule__day-header--date {
  opacity: .5; }
