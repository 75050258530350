@charset "UTF-8";
@font-face {
  font-family: "iconfont";
  src: url("/iconfont.eot");
  /* IE9 Compat Modes */
  src: url("/iconfont.eot?#iefix") format("embedded-opentype"), url("/iconfont.woff") format("woff"), url("/iconfont.ttf") format("truetype"), url("/iconfont.svg") format("svg");
  /* Legacy iOS */ }

.icon {
  font-style: normal;
  font-weight: 400; }
  .icon.accordion-close:before {
    font-family: "iconfont";
    content: ""; }
  .icon.accordion-open:before {
    font-family: "iconfont";
    content: ""; }
  .icon.add-folder:before {
    font-family: "iconfont";
    content: ""; }
  .icon.add:before {
    font-family: "iconfont";
    content: ""; }
  .icon.admin:before {
    font-family: "iconfont";
    content: ""; }
  .icon.all-day:before {
    font-family: "iconfont";
    content: ""; }
  .icon.arrow-left:before {
    font-family: "iconfont";
    content: ""; }
  .icon.arrow-right:before {
    font-family: "iconfont";
    content: ""; }
  .icon.assignment:before {
    font-family: "iconfont";
    content: ""; }
  .icon.association-clinic:before {
    font-family: "iconfont";
    content: ""; }
  .icon.association:before {
    font-family: "iconfont";
    content: ""; }
  .icon.bars:before {
    font-family: "iconfont";
    content: ""; }
  .icon.calendar-2:before {
    font-family: "iconfont";
    content: ""; }
  .icon.calendar-today:before {
    font-family: "iconfont";
    content: ""; }
  .icon.calendar:before {
    font-family: "iconfont";
    content: ""; }
  .icon.call-list:before {
    font-family: "iconfont";
    content: ""; }
  .icon.checkbox-off:before {
    font-family: "iconfont";
    content: ""; }
  .icon.checkbox-on:before {
    font-family: "iconfont";
    content: ""; }
  .icon.checkbox-square:before {
    font-family: "iconfont";
    content: ""; }
  .icon.circle-add:before {
    font-family: "iconfont";
    content: ""; }
  .icon.circle-check:before {
    font-family: "iconfont";
    content: ""; }
  .icon.circle-info:before {
    font-family: "iconfont";
    content: ""; }
  .icon.circlo-close:before {
    font-family: "iconfont";
    content: ""; }
  .icon.clinic:before {
    font-family: "iconfont";
    content: ""; }
  .icon.close-slim:before {
    font-family: "iconfont";
    content: ""; }
  .icon.close:before {
    font-family: "iconfont";
    content: ""; }
  .icon.closed-folder:before {
    font-family: "iconfont";
    content: ""; }
  .icon.copy:before {
    font-family: "iconfont";
    content: ""; }
  .icon.delete:before {
    font-family: "iconfont";
    content: ""; }
  .icon.disconnect:before {
    font-family: "iconfont";
    content: ""; }
  .icon.dotted-arrow-right:before {
    font-family: "iconfont";
    content: ""; }
  .icon.dotted-arrow:before {
    font-family: "iconfont";
    content: ""; }
  .icon.edit:before {
    font-family: "iconfont";
    content: ""; }
  .icon.email:before {
    font-family: "iconfont";
    content: ""; }
  .icon.featured:before {
    font-family: "iconfont";
    content: ""; }
  .icon.handle-h:before {
    font-family: "iconfont";
    content: ""; }
  .icon.handle-v:before {
    font-family: "iconfont";
    content: ""; }
  .icon.help-circle:before {
    font-family: "iconfont";
    content: ""; }
  .icon.help:before {
    font-family: "iconfont";
    content: ""; }
  .icon.history:before {
    font-family: "iconfont";
    content: ""; }
  .icon.info:before {
    font-family: "iconfont";
    content: ""; }
  .icon.lock-open:before {
    font-family: "iconfont";
    content: ""; }
  .icon.lock:before {
    font-family: "iconfont";
    content: ""; }
  .icon.lodging:before {
    font-family: "iconfont";
    content: ""; }
  .icon.magnifying-glass:before {
    font-family: "iconfont";
    content: ""; }
  .icon.map:before {
    font-family: "iconfont";
    content: ""; }
  .icon.marker:before {
    font-family: "iconfont";
    content: ""; }
  .icon.notifications:before {
    font-family: "iconfont";
    content: ""; }
  .icon.open-folder:before {
    font-family: "iconfont";
    content: ""; }
  .icon.pencil:before {
    font-family: "iconfont";
    content: ""; }
  .icon.piechart:before {
    font-family: "iconfont";
    content: ""; }
  .icon.pin:before {
    font-family: "iconfont";
    content: ""; }
  .icon.profile:before {
    font-family: "iconfont";
    content: ""; }
  .icon.schedule:before {
    font-family: "iconfont";
    content: ""; }
  .icon.settings:before {
    font-family: "iconfont";
    content: ""; }
  .icon.small-down-arrow:before {
    font-family: "iconfont";
    content: ""; }
  .icon.small-up-arrow:before {
    font-family: "iconfont";
    content: ""; }
  .icon.status-check:before {
    font-family: "iconfont";
    content: ""; }
  .icon.surgery-optional:before {
    font-family: "iconfont";
    content: ""; }
  .icon.surgery:before {
    font-family: "iconfont";
    content: ""; }
  .icon.time:before {
    font-family: "iconfont";
    content: ""; }
  .icon.timesheet:before {
    font-family: "iconfont";
    content: ""; }
  .icon.triangle:before {
    font-family: "iconfont";
    content: ""; }
  .icon.tsa:before {
    font-family: "iconfont";
    content: ""; }
  .icon.validation:before {
    font-family: "iconfont";
    content: ""; }
  .icon.vet:before {
    font-family: "iconfont";
    content: ""; }

.field__react-date {
  width: 100%; }

.DateRangePickerInput__withBorder {
  border: none;
  align-items: center;
  justify-content: space-between;
  width: 100%; }

.DateInput_input__focused {
  border: none; }

.DateInput_input {
  width: 100%;
  outline: none;
  background-color: #fff;
  font-size: 0.875em;
  font-family: "F37Ginger", "HelveticaNeue-Light", "Helvetica Neue Light", "Helvetica Neue", Helvetica, Arial, "Lucida Grande", sans-serif;
  font-weight: 300;
  letter-spacing: 0.0625rem;
  color: #414a52;
  padding: 0.875em 1.5625em 0.625em 0.625em;
  border-radius: 0.1875em;
  border: 0.0625em solid #d8dfe5;
  cursor: pointer; }
  .DateInput_input[disabled], .DateInput_input[readonly] {
    background-color: #f7f8f9;
    border-color: #f7f8f9;
    cursor: default; }
  .DateInput_input[disabled] {
    color: #a0adb9; }
  .DateInput_input.DateInput_input__focused {
    border-color: #90c6de;
    background-color: #eff6fa; }

.DateInput {
  position: relative; }
  .DateInput:after {
    font-family: "iconfont";
    font-style: normal;
    font-weight: 400;
    content: "";
    color: rgba(160, 173, 185, 0.5);
    position: absolute;
    top: 0.9375em;
    right: 0.625em;
    pointer-events: none; }

.DateRangePicker {
  width: 100%; }
  .DateRangePicker_picker {
    z-index: 5;
    min-width: 100%;
    top: calc(100% + 10px) !important; }
    @media only screen and (max-width: 38rem) and (max-height: 750px) {
      .DateRangePicker_picker {
        top: 100% !important; } }
  .DateRangePicker .DayPicker {
    min-width: 100%; }
    .DateRangePicker .DayPicker > div > div {
      margin-left: auto;
      margin-right: auto; }
  .DateRangePicker .DateInput_fang {
    display: none; }
  .DateRangePicker .DateRangePickerInput {
    display: flex;
    align-items: center; }
  .DateRangePicker .DateInput {
    position: relative;
    margin-bottom: 0; }
    .DateRangePicker .DateInput:after {
      font-family: "iconfont";
      font-style: normal;
      font-weight: 400;
      content: "";
      color: rgba(160, 173, 185, 0.5);
      position: absolute;
      top: 0.6875em;
      right: 0.625em;
      pointer-events: none; }
    .DateRangePicker .DateInput:before {
      display: none; }
    .DateRangePicker .DateInput .DateInput_input {
      background-color: white;
      padding: 0.875em 2.5em 0.375em 0.625em;
      border: solid 1px #d8dfe5;
      line-height: 1.34375em; }
      .DateRangePicker .DateInput .DateInput_input.DateInput_input__focused {
        border-color: #414a52; }
  .DateRangePicker .DateRangePickerInput_arrow {
    padding: 0 0.3125rem;
    opacity: .5; }
  @media only screen and (min-width: 38rem) {
    .DateRangePicker .DateRangePickerInput {
      display: inline-block; }
    .DateRangePicker .DateInput {
      position: relative;
      display: flex;
      justify-content: space-between;
      align-items: center;
      flex-shrink: 0;
      width: 100%;
      margin-bottom: 1.25rem; }
      .DateRangePicker .DateInput:last-child {
        margin-bottom: 0; }
      .DateRangePicker .DateInput:after {
        font-family: "iconfont";
        font-style: normal;
        font-weight: 400;
        content: "";
        color: rgba(160, 173, 185, 0.5);
        position: absolute;
        right: 0.625em;
        pointer-events: none; }
      .DateRangePicker .DateInput:before {
        display: block;
        width: 5rem;
        font-size: 0.8125rem;
        font-weight: 300; }
      .DateRangePicker .DateInput .DateInput_input {
        max-width: 13.75rem;
        text-align: right;
        padding-right: 2.5em;
        padding-left: 0.625em; }
    .DateRangePicker .DateRangePickerInput_arrow {
      display: none; } }

.react-dates-lang-wrapper-fr .DateInput:first-child:before {
  content: 'D' "\00e9" 'but'; }

.react-dates-lang-wrapper-fr .DateInput:last-child:before {
  content: "Fin"; }

.react-dates-lang-wrapper-en .DateInput:first-child:before {
  content: 'Start'; }

.react-dates-lang-wrapper-en .DateInput:last-child:before {
  content: "End"; }

.CalendarMonth_table td {
  text-align: left;
  padding: 0.3125em; }

.CalendarMonth_caption, .DayPicker_weekHeader_li small {
  text-transform: capitalize; }

.DateInput_fang {
  margin-top: 1px;
  z-index: 6; }

.CalendarDay__highlighted_calendar,
.CalendarDay__highlighted_calendar:hover,
.CalendarDay__highlighted_calendar:active {
  background-color: transparent;
  position: relative; }
  .CalendarDay__highlighted_calendar:before,
  .CalendarDay__highlighted_calendar:hover:before,
  .CalendarDay__highlighted_calendar:active:before {
    content: "";
    height: 0.3125rem;
    width: 0.3125rem;
    position: absolute;
    top: 0.1875rem;
    right: 0.1875rem;
    border-radius: 50%;
    background-color: #90c6de; }

.CalendarDay__selected, .CalendarDay__selected:active, .CalendarDay__selected:hover {
  background-color: #90c6de;
  border-color: #90c6de; }

.CalendarDay__selected_span,
.CalendarDay__selected_span:hover,
.CalendarDay__selected_span:active,
.CalendarDay__hovered_span,
.CalendarDay__hovered_span:hover,
.CalendarDay__hovered_span:active {
  background-color: #d8eaf2;
  border-color: #d8eaf2; }
