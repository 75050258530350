@charset "UTF-8";
@font-face {
  font-family: "iconfont";
  src: url("/iconfont.eot");
  /* IE9 Compat Modes */
  src: url("/iconfont.eot?#iefix") format("embedded-opentype"), url("/iconfont.woff") format("woff"), url("/iconfont.ttf") format("truetype"), url("/iconfont.svg") format("svg");
  /* Legacy iOS */ }

.icon {
  font-style: normal;
  font-weight: 400; }
  .icon.accordion-close:before {
    font-family: "iconfont";
    content: ""; }
  .icon.accordion-open:before {
    font-family: "iconfont";
    content: ""; }
  .icon.add-folder:before {
    font-family: "iconfont";
    content: ""; }
  .icon.add:before {
    font-family: "iconfont";
    content: ""; }
  .icon.admin:before {
    font-family: "iconfont";
    content: ""; }
  .icon.all-day:before {
    font-family: "iconfont";
    content: ""; }
  .icon.arrow-left:before {
    font-family: "iconfont";
    content: ""; }
  .icon.arrow-right:before {
    font-family: "iconfont";
    content: ""; }
  .icon.assignment:before {
    font-family: "iconfont";
    content: ""; }
  .icon.association-clinic:before {
    font-family: "iconfont";
    content: ""; }
  .icon.association:before {
    font-family: "iconfont";
    content: ""; }
  .icon.bars:before {
    font-family: "iconfont";
    content: ""; }
  .icon.calendar-2:before {
    font-family: "iconfont";
    content: ""; }
  .icon.calendar-today:before {
    font-family: "iconfont";
    content: ""; }
  .icon.calendar:before {
    font-family: "iconfont";
    content: ""; }
  .icon.call-list:before {
    font-family: "iconfont";
    content: ""; }
  .icon.checkbox-off:before {
    font-family: "iconfont";
    content: ""; }
  .icon.checkbox-on:before {
    font-family: "iconfont";
    content: ""; }
  .icon.checkbox-square:before {
    font-family: "iconfont";
    content: ""; }
  .icon.circle-add:before {
    font-family: "iconfont";
    content: ""; }
  .icon.circle-check:before {
    font-family: "iconfont";
    content: ""; }
  .icon.circle-info:before {
    font-family: "iconfont";
    content: ""; }
  .icon.circlo-close:before {
    font-family: "iconfont";
    content: ""; }
  .icon.clinic:before {
    font-family: "iconfont";
    content: ""; }
  .icon.close-slim:before {
    font-family: "iconfont";
    content: ""; }
  .icon.close:before {
    font-family: "iconfont";
    content: ""; }
  .icon.closed-folder:before {
    font-family: "iconfont";
    content: ""; }
  .icon.copy:before {
    font-family: "iconfont";
    content: ""; }
  .icon.delete:before {
    font-family: "iconfont";
    content: ""; }
  .icon.disconnect:before {
    font-family: "iconfont";
    content: ""; }
  .icon.dotted-arrow-right:before {
    font-family: "iconfont";
    content: ""; }
  .icon.dotted-arrow:before {
    font-family: "iconfont";
    content: ""; }
  .icon.edit:before {
    font-family: "iconfont";
    content: ""; }
  .icon.email:before {
    font-family: "iconfont";
    content: ""; }
  .icon.featured:before {
    font-family: "iconfont";
    content: ""; }
  .icon.handle-h:before {
    font-family: "iconfont";
    content: ""; }
  .icon.handle-v:before {
    font-family: "iconfont";
    content: ""; }
  .icon.help-circle:before {
    font-family: "iconfont";
    content: ""; }
  .icon.help:before {
    font-family: "iconfont";
    content: ""; }
  .icon.history:before {
    font-family: "iconfont";
    content: ""; }
  .icon.info:before {
    font-family: "iconfont";
    content: ""; }
  .icon.lock-open:before {
    font-family: "iconfont";
    content: ""; }
  .icon.lock:before {
    font-family: "iconfont";
    content: ""; }
  .icon.lodging:before {
    font-family: "iconfont";
    content: ""; }
  .icon.magnifying-glass:before {
    font-family: "iconfont";
    content: ""; }
  .icon.map:before {
    font-family: "iconfont";
    content: ""; }
  .icon.marker:before {
    font-family: "iconfont";
    content: ""; }
  .icon.notifications:before {
    font-family: "iconfont";
    content: ""; }
  .icon.open-folder:before {
    font-family: "iconfont";
    content: ""; }
  .icon.pencil:before {
    font-family: "iconfont";
    content: ""; }
  .icon.piechart:before {
    font-family: "iconfont";
    content: ""; }
  .icon.pin:before {
    font-family: "iconfont";
    content: ""; }
  .icon.profile:before {
    font-family: "iconfont";
    content: ""; }
  .icon.schedule:before {
    font-family: "iconfont";
    content: ""; }
  .icon.settings:before {
    font-family: "iconfont";
    content: ""; }
  .icon.small-down-arrow:before {
    font-family: "iconfont";
    content: ""; }
  .icon.small-up-arrow:before {
    font-family: "iconfont";
    content: ""; }
  .icon.status-check:before {
    font-family: "iconfont";
    content: ""; }
  .icon.surgery-optional:before {
    font-family: "iconfont";
    content: ""; }
  .icon.surgery:before {
    font-family: "iconfont";
    content: ""; }
  .icon.time:before {
    font-family: "iconfont";
    content: ""; }
  .icon.timesheet:before {
    font-family: "iconfont";
    content: ""; }
  .icon.triangle:before {
    font-family: "iconfont";
    content: ""; }
  .icon.tsa:before {
    font-family: "iconfont";
    content: ""; }
  .icon.validation:before {
    font-family: "iconfont";
    content: ""; }
  .icon.vet:before {
    font-family: "iconfont";
    content: ""; }

.rbc-row-segment {
  padding: 0 0.625rem; }
  .rbc-row-segment .rbc-event {
    border-radius: 0;
    padding: 0;
    background: none;
    outline: none;
    overflow: initial; }
  .rbc-row-segment .rbc-event-content {
    overflow: initial; }

.rbc-row-content {
  z-index: initial; }

.rbc-month-row {
  overflow: initial; }

.schedule__event {
  padding: 0.625rem 0.625rem 0.625rem 0.8125rem;
  border-radius: 0.125rem;
  position: relative;
  font-size: 0.75rem;
  color: #414a52;
  transition: all 0.125s cubic-bezier(0.77, 0, 0.175, 1); }
  .schedule__event__title {
    font-weight: 600;
    display: inline-block;
    margin-bottom: 0.625em;
    max-width: 100%;
    overflow: hidden;
    text-overflow: ellipsis; }
  .schedule__event--availability, .schedule__event--replacement, .schedule__event--proposal {
    background-color: #d8eaf2;
    border-left: 4px solid #90c6de; }
    .schedule__event--availability .schedule__event-details__head--date:before, .schedule__event--replacement .schedule__event-details__head--date:before, .schedule__event--proposal .schedule__event-details__head--date:before {
      font-family: "iconfont";
      font-style: normal;
      font-weight: 400;
      content: ""; }
    .schedule__event--availability .schedule__event-details__head--date:before, .schedule__event--replacement .schedule__event-details__head--date:before, .schedule__event--proposal .schedule__event-details__head--date:before {
      color: #90c6de; }
    .schedule__event--availability .schedule__event__toggle:before, .schedule__event--replacement .schedule__event__toggle:before, .schedule__event--proposal .schedule__event__toggle:before {
      background-color: #90c6de; }
  .schedule__event--replacement {
    box-sizing: border-box; }
    @media only screen and (max-width: 62.5rem) {
      .schedule__event--replacement {
        top: initial !important; } }
    @media only screen and (min-width: 62.5rem) {
      .schedule__event--replacement {
        margin: 0 -0.625rem; }
        .schedule__event--replacement.schedule__event--first {
          border-left: 4px solid #90c6de;
          margin-left: -0.3rem; }
        .schedule__event--replacement.schedule__event--last {
          margin-right: 3px; }
        .schedule__event--replacement.schedule__event--last.schedule__event--first {
          border-left: 4px solid #90c6de;
          margin-left: -0.3rem;
          margin-right: 0; } }
    .schedule__event--replacement .schedule__event-details__head__date:before {
      font-family: "iconfont";
      font-style: normal;
      font-weight: 400;
      content: ""; }
    .schedule__event--replacement .schedule__event-details__head__date:before {
      color: #90c6de; }
    .schedule__event--replacement .schedule__event__toggle:before {
      background-color: #90c6de; }
  .schedule__event--pending {
    background-color: #fde4c5;
    border-left: 4px solid #f9b55a; }
    .schedule__event--pending .schedule__event-details__head--date:before {
      font-family: "iconfont";
      font-style: normal;
      font-weight: 400;
      content: ""; }
    .schedule__event--pending .schedule__event-details__head--date:before {
      color: #f9b55a; }
  .schedule__event--confirmed {
    background-color: #d9efd9;
    border-left: 4px solid #94d394; }
    .schedule__event--confirmed .schedule__event-details__head--date:before {
      font-family: "iconfont";
      font-style: normal;
      font-weight: 400;
      content: ""; }
    .schedule__event--confirmed .schedule__event-details__head--date:before {
      color: #94d394; }
  .schedule__event__to:before {
    font-family: "iconfont";
    font-style: normal;
    font-weight: 400;
    content: ""; }
  .schedule__event__to:before {
    font-size: 0.75em;
    margin: 0 0.3125rem; }
  .schedule__event .schedule__event__toggle {
    outline: none;
    overflow: hidden; }
  .schedule__event--holiday {
    color: #a3a0a0;
    text-align: right;
    position: absolute;
    padding: 1.625em 0 0.625em 2em;
    margin: 0 0.625em;
    top: 0;
    width: 11.2857%;
    max-width: 11.2857%;
    white-space: normal;
    cursor: default;
    font-weight: 300; }
  .schedule__event--pending {
    background-color: #fde4c5;
    border-left: 4px solid #f9b55a; }
    .schedule__event--pending .schedule__event-details__head--date:before {
      font-family: "iconfont";
      font-style: normal;
      font-weight: 400;
      content: ""; }
    .schedule__event--pending .schedule__event-details__head--date:before {
      color: #f9b55a; }
    .schedule__event--pending .schedule__event__toggle:before {
      background-color: #f9b55a; }
    @media only screen and (min-width: 62.5rem) {
      .schedule__event--pending.schedule__event--first {
        border-left: 4px solid #f9b55a; }
      .schedule__event--pending.schedule__event--last.schedule__event--first {
        border-left: 4px solid #f9b55a; } }
    .schedule__event--pending .schedule__event-details__head__date:before {
      font-family: "iconfont";
      font-style: normal;
      font-weight: 400;
      content: ""; }
    .schedule__event--pending .schedule__event-details__head__date:before {
      color: #f9b55a; }
  .schedule__event--confirmed {
    background-color: #d9efd9;
    border-left: 4px solid #94d394; }
    .schedule__event--confirmed .schedule__event-details__head--date:before {
      font-family: "iconfont";
      font-style: normal;
      font-weight: 400;
      content: ""; }
    .schedule__event--confirmed .schedule__event-details__head--date:before {
      color: #94d394; }
    .schedule__event--confirmed .schedule__event__toggle:before {
      background-color: #94d394; }
    @media only screen and (min-width: 62.5rem) {
      .schedule__event--confirmed.schedule__event--first {
        border-left: 4px solid #94d394; }
      .schedule__event--confirmed.schedule__event--last.schedule__event--first {
        border-left: 4px solid #94d394; } }
    .schedule__event--confirmed .schedule__event-details__head__date:before {
      font-family: "iconfont";
      font-style: normal;
      font-weight: 400;
      content: ""; }
    .schedule__event--confirmed .schedule__event-details__head__date:before {
      color: #94d394; }
  .schedule__event--pending_clinic, .schedule__event--pending_admin {
    background: repeating-linear-gradient(35deg, transparent, transparent 5px, rgba(148, 211, 148, 0.35) 5px, rgba(148, 211, 148, 0.35) 10px), #d9efd9;
    border-left: 4px solid #94d394; }
    .schedule__event--pending_clinic .schedule__event-details__head--date:before, .schedule__event--pending_admin .schedule__event-details__head--date:before {
      font-family: "iconfont";
      font-style: normal;
      font-weight: 400;
      content: ""; }
    .schedule__event--pending_clinic .schedule__event-details__head--date:before, .schedule__event--pending_admin .schedule__event-details__head--date:before {
      color: #94d394; }
    .schedule__event--pending_clinic .schedule__event__toggle:before, .schedule__event--pending_admin .schedule__event__toggle:before {
      background-color: #94d394; }
  .schedule__event--disabled {
    background: repeating-linear-gradient(35deg, transparent, transparent 5px, rgba(0, 0, 0, 0.035) 5px, rgba(0, 0, 0, 0.035) 10px), #f7f8f9;
    border-color: #d8dfe5; }
    .schedule__event--disabled .schedule__event-details__head--date:before {
      font-family: "iconfont";
      font-style: normal;
      font-weight: 400;
      content: ""; }
    .schedule__event--disabled .schedule__event-details__head--date:before {
      color: #a0adb9; }
    .schedule__event--disabled .schedule__event__toggle:before {
      background-color: #d8dfe5; }
    @media only screen and (min-width: 62.5rem) {
      .schedule__event--disabled.schedule__event--first {
        border-left: 4px solid #a0adb9; }
      .schedule__event--disabled.schedule__event--last.schedule__event--first {
        border-left: 4px solid #a0adb9; } }
  .schedule__event--holiday {
    color: #a3a0a0;
    text-align: right;
    position: absolute;
    padding: 1.625em 0 0.625em 2em;
    margin: 0 0.625em;
    top: 0;
    width: 11.2857%;
    max-width: 11.2857%;
    white-space: normal;
    cursor: default;
    font-weight: 300; }
  .schedule__event__to:before {
    font-family: "iconfont";
    font-style: normal;
    font-weight: 400;
    content: ""; }
  .schedule__event__to:before {
    font-size: 0.75em;
    margin: 0 0.3125rem; }
  .schedule__event .schedule__event__toggle {
    outline: none;
    overflow: hidden;
    font-weight: 300; }
    .schedule__event .schedule__event__toggle:before {
      content: "";
      position: absolute;
      left: -4px;
      height: 100%;
      width: 4px;
      top: 0;
      border-top-left-radius: 0.125rem;
      border-bottom-left-radius: 0.125rem;
      transition: width 200ms cubic-bezier(0.77, 0, 0.175, 1); }
  .schedule__event--toggled {
    box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.075); }
    .schedule__event--toggled .schedule__event__toggle:before {
      width: 6px; }
  @media only screen and (min-width: 47.9rem) {
    .schedule__event:hover {
      box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.075); }
      .schedule__event:hover .schedule__event__toggle:before {
        width: 6px; } }

.schedule__event-details {
  position: absolute;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;
  background-color: #fff;
  box-shadow: 0 1px 20px 0 rgba(0, 0, 0, 0.16);
  z-index: 20;
  margin-top: 1.5625rem;
  min-width: 18.75rem;
  max-width: 18.75rem; }
  .schedule__event-details:before {
    font-family: "iconfont";
    font-style: normal;
    font-weight: 400;
    content: ""; }
  @media only screen and (max-width: 62.5rem) {
    .schedule__event-details {
      min-width: initial;
      max-width: 18.75rem; } }
  @media only screen and (max-width: 43.75rem) {
    .schedule__event-details {
      width: calc(100% + 4px);
      max-width: initial;
      margin-left: -4px; } }
  .schedule__event-details:before {
    width: 100%;
    text-align: center;
    margin: 0 auto;
    display: block;
    position: absolute;
    top: -0.75rem;
    color: #fff; }
  .schedule__event-details__head {
    padding: 1.125rem;
    color: #414a52;
    border-bottom: 1px solid #d8dfe5;
    display: flex;
    align-items: center;
    justify-content: space-between; }
    .schedule__event-details__head--date {
      display: flex;
      align-items: center;
      font-size: 1.125em;
      font-weight: 700;
      text-transform: uppercase;
      letter-spacing: .05em;
      position: relative;
      top: 0.1875rem; }
      .schedule__event-details__head--date:before {
        font-size: 1.4em;
        color: #90c6de;
        margin-right: 0.375em;
        position: relative;
        top: -0.125em; }
    .schedule__event-details__head__close {
      float: right;
      color: #a0adb9; }
      .schedule__event-details__head__close:before {
        font-family: "iconfont";
        font-style: normal;
        font-weight: 400;
        content: ""; }
  .schedule__event-details__pending {
    text-transform: uppercase;
    font-weight: 500;
    font-size: 0.5625rem;
    padding: 0.875rem 1.125rem 0.625rem;
    letter-spacing: .09em;
    color: #414a52;
    border-bottom: 1px solid #d8dfe5;
    background: repeating-linear-gradient(35deg, transparent, transparent 5px, rgba(148, 211, 148, 0.25) 5px, rgba(148, 211, 148, 0.25) 10px), rgba(217, 239, 217, 0.9); }
  .schedule__event-details__body {
    padding: 1.125rem;
    border-bottom: 1px solid #d8dfe5; }
    .schedule__event-details__body > span {
      display: block;
      white-space: normal; }
    .schedule__event-details__body > * {
      margin-bottom: 0.375rem;
      font-weight: 300; }
      .schedule__event-details__body > *:before {
        font-size: 0.8125rem;
        position: relative;
        top: 0.125rem;
        display: inline-block;
        width: 1.75rem; }
  .schedule__event-details__time:before {
    font-family: "iconfont";
    font-style: normal;
    font-weight: 400;
    content: ""; }
  .schedule__event-details__distance:before {
    font-family: "iconfont";
    font-style: normal;
    font-weight: 400;
    content: ""; }
  .schedule__event-details__surgery:before {
    font-family: "iconfont";
    font-style: normal;
    font-weight: 400;
    content: ""; }
  .schedule__event-details__technician:before {
    font-family: "iconfont";
    font-style: normal;
    font-weight: 400;
    content: ""; }
  .schedule__event-details__veterinarian:before {
    font-family: "iconfont";
    font-style: normal;
    font-weight: 400;
    content: ""; }
  .schedule__event-details__accommodation--available:before {
    font-family: "iconfont";
    font-style: normal;
    font-weight: 400;
    content: ""; }
  .schedule__event-details__accommodation--unavailable:before {
    font-family: "iconfont";
    font-style: normal;
    font-weight: 400;
    content: ""; }
  .schedule__event-details__accommodation--unavailable:before {
    color: #a0adb9; }
  .schedule__event-details__info strong {
    display: block;
    margin-top: 1.25rem;
    margin-bottom: 0.25rem; }
    .schedule__event-details__info strong:before {
      font-family: "iconfont";
      font-style: normal;
      font-weight: 400;
      content: ""; }
    .schedule__event-details__info strong:before {
      font-size: 0.75rem;
      display: inline-block;
      width: 1.75rem;
      position: relative;
      top: 0.1875rem; }
  .schedule__event-details__info span {
    padding-left: 1.75rem;
    display: block;
    line-height: 1.4em; }
  .schedule__event-details__consultation:before {
    font-family: "iconfont";
    font-style: normal;
    font-weight: 400;
    content: ""; }
  .schedule__event-details__clinic {
    background-color: rgba(65, 74, 82, 0.05);
    padding: 1.125rem;
    border-bottom: 1px solid #d8dfe5; }
    .schedule__event-details__clinic .clinic {
      display: flex;
      white-space: normal; }
    .schedule__event-details__clinic .clinic__image {
      flex-grow: 0;
      flex-shrink: 0;
      border-radius: 50%;
      width: 1.875rem;
      height: 1.875rem;
      background-color: #90c6de;
      margin-right: 0.625rem;
      background-size: cover;
      background-position: center center; }
    .schedule__event-details__clinic .clinic__infos__title {
      font-size: 0.875rem;
      line-height: 1.25rem;
      font-weight: 700;
      display: block;
      padding-bottom: 0.375rem; }
      .schedule__event-details__clinic .clinic__infos__title--pending {
        padding-bottom: 0; }
    .schedule__event-details__clinic .clinic__infos__desc {
      font-size: 0.75rem;
      line-height: 1.25rem;
      font-weight: 400;
      display: block;
      opacity: 0.6; }
      .schedule__event-details__clinic .clinic__infos__desc span:nth-child(2):before {
        content: ' & '; }
  .schedule__event-details__foot {
    padding: 1.125rem;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center; }
    .schedule__event-details__foot .schedule__event-details__btn {
      flex-shrink: 0;
      flex-grow: 1;
      margin: 0 0.3125rem;
      font-size: 0.75rem;
      max-width: 50%; }
      .schedule__event-details__foot .schedule__event-details__btn:first-child {
        margin-left: 0; }
      .schedule__event-details__foot .schedule__event-details__btn:last-child {
        margin-right: 0; }
      .schedule__event-details__foot .schedule__event-details__btn--delete {
        max-width: 15%; }
  .schedule__event-details__btn--delete {
    background: #f76b7e;
    color: #fff;
    padding: 1.0625em 1.125em 0.875em;
    text-transform: uppercase;
    font-size: 0.6875em;
    line-height: 1.0625em;
    font-weight: 700;
    border: none;
    cursor: pointer;
    border-radius: 0.1875em;
    outline: none;
    letter-spacing: 0.8px; }
    .schedule__event-details__btn--delete:hover {
      background-color: #f6586d;
      color: #fff; }
    .schedule__event-details__btn--delete[disabled] {
      opacity: 0.4;
      background: #f76b7e;
      cursor: default;
      color: #fff; }
    .schedule__event-details__btn--delete.icon {
      height: 2.25rem; }
      .schedule__event-details__btn--delete.icon:before {
        font-family: "iconfont";
        font-style: normal;
        font-weight: 400;
        content: ""; }
  .schedule__event-details__btn--modif {
    background: #414a52;
    color: #fff;
    padding: 1.0625em 1.125em 0.875em;
    text-transform: uppercase;
    font-size: 0.6875em;
    line-height: 1.0625em;
    font-weight: 700;
    border: none;
    cursor: pointer;
    border-radius: 0.1875em;
    outline: none;
    letter-spacing: 0.8px; }
    .schedule__event-details__btn--modif:hover {
      background-color: #384047;
      color: #fff; }
    .schedule__event-details__btn--modif[disabled] {
      opacity: 0.4;
      background: #414a52;
      cursor: default;
      color: #fff; }
  .schedule__event-details__btn--details {
    background: #414a52;
    color: #fff;
    padding: 1.0625em 1.125em 0.875em;
    text-transform: uppercase;
    font-size: 0.6875em;
    line-height: 1.0625em;
    font-weight: 700;
    border: none;
    cursor: pointer;
    border-radius: 0.1875em;
    outline: none;
    letter-spacing: 0.8px; }
    .schedule__event-details__btn--details:hover {
      background-color: #384047;
      color: #fff; }
    .schedule__event-details__btn--details[disabled] {
      opacity: 0.4;
      background: #414a52;
      cursor: default;
      color: #fff; }
  .schedule__event-details--left:before {
    margin: 0;
    width: auto;
    left: 0.9375rem; }
  .schedule__event-details--right {
    margin-left: 0;
    margin-right: 0; }
    .schedule__event-details--right:before {
      margin: 0;
      width: auto;
      right: 0.9375rem; }
  .schedule__event-details--top {
    margin-top: 0;
    margin-bottom: 0.9375rem;
    bottom: 100%; }
    .schedule__event-details--top:before {
      top: auto;
      bottom: -0.75rem;
      transform: rotate(180deg); }
  .schedule__event-details__user-status {
    background-color: #f7f8f9;
    padding: 1.125rem; }
    .schedule__event-details__user-status__pending {
      font-size: 1.25em;
      display: flex;
      align-items: center; }
      .schedule__event-details__user-status__pending:before {
        font-family: "iconfont";
        font-style: normal;
        font-weight: 400;
        content: ""; }
      .schedule__event-details__user-status__pending:before {
        color: #f9b55a;
        font-size: 1.25em;
        margin-right: 0.625em; }
    .schedule__event-details__user-status--accepted {
      display: flex; }
    .schedule__event-details__user-status__picture {
      font-size: 1.75em; }
      .schedule__event-details__user-status__picture:before {
        font-family: "iconfont";
        font-style: normal;
        font-weight: 400;
        content: ""; }
      .schedule__event-details__user-status__picture:before {
        background-color: #fff;
        padding: 0.3125em;
        border-radius: 50%; }
    .schedule__event-details__user-status__content {
      padding-left: 0.625em; }
      .schedule__event-details__user-status__content > * {
        display: block;
        margin-bottom: 0.3125em; }
    .schedule__event-details__user-status__name {
      margin-bottom: 0.625em;
      font-size: 1.25em;
      font-weight: 700; }

.schedule__event__pictos {
  float: left;
  height: 100%;
  margin-right: 0.625rem;
  padding-right: 0.625rem;
  border-right: 1px solid #a0adb9; }

.schedule__event__picto {
  display: block;
  color: #a0adb9; }
  .schedule__event__picto:first-child {
    margin-bottom: 0.3125em; }
  .schedule__event__picto--flex:before {
    font-family: "iconfont";
    font-style: normal;
    font-weight: 400;
    content: ""; }
  .schedule__event__picto--priority:before {
    font-family: "iconfont";
    font-style: normal;
    font-weight: 400;
    content: ""; }
  .schedule__event__picto--active {
    color: #414a52; }

.rbc-month-row:nth-last-child(-n+2) .schedule__event-details {
  margin-top: 0;
  margin-bottom: 0.9375rem;
  bottom: 100%; }
  .rbc-month-row:nth-last-child(-n+2) .schedule__event-details:before {
    top: auto;
    bottom: -0.75rem;
    transform: rotate(180deg); }

.rbc-agenda-table tr:nth-last-child(-n+6):not(:nth-child(-n+4)) .schedule__event-details {
  margin-top: 0;
  margin-bottom: 0.9375rem;
  bottom: 100%; }
  .rbc-agenda-table tr:nth-last-child(-n+6):not(:nth-child(-n+4)) .schedule__event-details:before {
    top: auto;
    bottom: -0.75rem;
    transform: rotate(180deg); }
