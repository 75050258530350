@charset "UTF-8";
@font-face {
  font-family: "iconfont";
  src: url("/iconfont.eot");
  /* IE9 Compat Modes */
  src: url("/iconfont.eot?#iefix") format("embedded-opentype"), url("/iconfont.woff") format("woff"), url("/iconfont.ttf") format("truetype"), url("/iconfont.svg") format("svg");
  /* Legacy iOS */ }

.icon {
  font-style: normal;
  font-weight: 400; }
  .icon.accordion-close:before {
    font-family: "iconfont";
    content: ""; }
  .icon.accordion-open:before {
    font-family: "iconfont";
    content: ""; }
  .icon.add-folder:before {
    font-family: "iconfont";
    content: ""; }
  .icon.add:before {
    font-family: "iconfont";
    content: ""; }
  .icon.admin:before {
    font-family: "iconfont";
    content: ""; }
  .icon.all-day:before {
    font-family: "iconfont";
    content: ""; }
  .icon.arrow-left:before {
    font-family: "iconfont";
    content: ""; }
  .icon.arrow-right:before {
    font-family: "iconfont";
    content: ""; }
  .icon.assignment:before {
    font-family: "iconfont";
    content: ""; }
  .icon.association-clinic:before {
    font-family: "iconfont";
    content: ""; }
  .icon.association:before {
    font-family: "iconfont";
    content: ""; }
  .icon.bars:before {
    font-family: "iconfont";
    content: ""; }
  .icon.calendar-2:before {
    font-family: "iconfont";
    content: ""; }
  .icon.calendar-today:before {
    font-family: "iconfont";
    content: ""; }
  .icon.calendar:before {
    font-family: "iconfont";
    content: ""; }
  .icon.call-list:before {
    font-family: "iconfont";
    content: ""; }
  .icon.checkbox-off:before {
    font-family: "iconfont";
    content: ""; }
  .icon.checkbox-on:before {
    font-family: "iconfont";
    content: ""; }
  .icon.checkbox-square:before {
    font-family: "iconfont";
    content: ""; }
  .icon.circle-add:before {
    font-family: "iconfont";
    content: ""; }
  .icon.circle-check:before {
    font-family: "iconfont";
    content: ""; }
  .icon.circle-info:before {
    font-family: "iconfont";
    content: ""; }
  .icon.circlo-close:before {
    font-family: "iconfont";
    content: ""; }
  .icon.clinic:before {
    font-family: "iconfont";
    content: ""; }
  .icon.close-slim:before {
    font-family: "iconfont";
    content: ""; }
  .icon.close:before {
    font-family: "iconfont";
    content: ""; }
  .icon.closed-folder:before {
    font-family: "iconfont";
    content: ""; }
  .icon.copy:before {
    font-family: "iconfont";
    content: ""; }
  .icon.delete:before {
    font-family: "iconfont";
    content: ""; }
  .icon.disconnect:before {
    font-family: "iconfont";
    content: ""; }
  .icon.dotted-arrow-right:before {
    font-family: "iconfont";
    content: ""; }
  .icon.dotted-arrow:before {
    font-family: "iconfont";
    content: ""; }
  .icon.edit:before {
    font-family: "iconfont";
    content: ""; }
  .icon.email:before {
    font-family: "iconfont";
    content: ""; }
  .icon.featured:before {
    font-family: "iconfont";
    content: ""; }
  .icon.handle-h:before {
    font-family: "iconfont";
    content: ""; }
  .icon.handle-v:before {
    font-family: "iconfont";
    content: ""; }
  .icon.help-circle:before {
    font-family: "iconfont";
    content: ""; }
  .icon.help:before {
    font-family: "iconfont";
    content: ""; }
  .icon.history:before {
    font-family: "iconfont";
    content: ""; }
  .icon.info:before {
    font-family: "iconfont";
    content: ""; }
  .icon.lock-open:before {
    font-family: "iconfont";
    content: ""; }
  .icon.lock:before {
    font-family: "iconfont";
    content: ""; }
  .icon.lodging:before {
    font-family: "iconfont";
    content: ""; }
  .icon.magnifying-glass:before {
    font-family: "iconfont";
    content: ""; }
  .icon.map:before {
    font-family: "iconfont";
    content: ""; }
  .icon.marker:before {
    font-family: "iconfont";
    content: ""; }
  .icon.notifications:before {
    font-family: "iconfont";
    content: ""; }
  .icon.open-folder:before {
    font-family: "iconfont";
    content: ""; }
  .icon.pencil:before {
    font-family: "iconfont";
    content: ""; }
  .icon.piechart:before {
    font-family: "iconfont";
    content: ""; }
  .icon.pin:before {
    font-family: "iconfont";
    content: ""; }
  .icon.profile:before {
    font-family: "iconfont";
    content: ""; }
  .icon.schedule:before {
    font-family: "iconfont";
    content: ""; }
  .icon.settings:before {
    font-family: "iconfont";
    content: ""; }
  .icon.small-down-arrow:before {
    font-family: "iconfont";
    content: ""; }
  .icon.small-up-arrow:before {
    font-family: "iconfont";
    content: ""; }
  .icon.status-check:before {
    font-family: "iconfont";
    content: ""; }
  .icon.surgery-optional:before {
    font-family: "iconfont";
    content: ""; }
  .icon.surgery:before {
    font-family: "iconfont";
    content: ""; }
  .icon.time:before {
    font-family: "iconfont";
    content: ""; }
  .icon.timesheet:before {
    font-family: "iconfont";
    content: ""; }
  .icon.triangle:before {
    font-family: "iconfont";
    content: ""; }
  .icon.tsa:before {
    font-family: "iconfont";
    content: ""; }
  .icon.validation:before {
    font-family: "iconfont";
    content: ""; }
  .icon.vet:before {
    font-family: "iconfont";
    content: ""; }

.header .matchmaking__toolbar {
  flex-shrink: 1;
  flex-grow: 1;
  width: 100%;
  height: 100%;
  border-left: 0.0625em solid #f7f8f9;
  order: 3;
  margin-left: -1px; }
  .header .matchmaking__toolbar + .header__title {
    min-width: calc(20rem - 4.375rem);
    margin: 0;
    padding: 0 1.25em; }

.matchmaking__toolbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 1.25em; }
  .matchmaking__toolbar__schedule {
    display: flex; }
  .matchmaking__toolbar button {
    font-size: 1.15em;
    color: #a0adb9;
    margin-left: 0.625rem;
    border-radius: 0.1875rem;
    transition: color 0.125s cubic-bezier(0.77, 0, 0.175, 1), border-color 0.125s cubic-bezier(0.77, 0, 0.175, 1);
    border: solid 0.0625em rgba(160, 173, 185, 0.2);
    position: relative;
    overflow: hidden;
    width: 2.5rem;
    height: 2.5rem;
    flex-grow: 0;
    flex-shrink: 0; }
    .matchmaking__toolbar button:hover {
      border-color: rgba(160, 173, 185, 0.4); }
    .matchmaking__toolbar button:before {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center; }
    .matchmaking__toolbar button:first-child {
      margin-left: 0; }
  .matchmaking__toolbar__action--prev:before {
    font-family: "iconfont";
    font-style: normal;
    font-weight: 400;
    content: ""; }
  .matchmaking__toolbar__action--next:before {
    font-family: "iconfont";
    font-style: normal;
    font-weight: 400;
    content: ""; }
  .matchmaking__toolbar__action--today {
    text-transform: uppercase;
    padding: 0 0.3125rem;
    top: 0; }
  .matchmaking__toolbar__today:before {
    font-family: "iconfont";
    font-style: normal;
    font-weight: 400;
    content: ""; }
  .matchmaking__toolbar__current-date {
    position: relative;
    margin: 0 0.625rem; }
    .matchmaking__toolbar__current-date .DateInput_input {
      border-radius: 0.1875rem;
      border: solid 0.0625em rgba(160, 173, 185, 0.2);
      color: #a0adb9;
      line-height: 0;
      padding: 0.8125rem 0.3125rem 0.5625rem 0.625rem;
      transition: color 0.125s cubic-bezier(0.77, 0, 0.175, 1), border-color 0.125s cubic-bezier(0.77, 0, 0.175, 1); }
      .matchmaking__toolbar__current-date .DateInput_input:is(:hover, :focus, :active) {
        border-color: rgba(160, 173, 185, 0.4); }
    .matchmaking__toolbar__current-date .DateInput:after {
      content: "";
      pointer-events: none;
      position: absolute;
      right: 0.625em;
      top: 0.625em; }
    .matchmaking__toolbar__current-date .month-picker__open {
      display: inline-block;
      font-size: 0.875em;
      margin: 0;
      color: #a0adb9;
      position: relative;
      text-transform: capitalize;
      width: auto;
      min-width: 15.625rem;
      text-align: left;
      padding: 0.3125em 0.75em 0; }
      .matchmaking__toolbar__current-date .month-picker__open:before {
        font-family: "iconfont";
        font-style: normal;
        font-weight: 400;
        content: ""; }
      .matchmaking__toolbar__current-date .month-picker__open:before {
        width: auto;
        left: auto;
        right: 0.75em;
        top: 0.0625rem; }
      .matchmaking__toolbar__current-date .month-picker__open.close:before {
        font-family: "iconfont";
        font-style: normal;
        font-weight: 400;
        content: ""; }
    .matchmaking__toolbar__current-date .month-picker__close {
      display: none; }
    .matchmaking__toolbar__current-date .CalendarDay__highlighted_calendar {
      background-color: #d8eaf2; }
      .matchmaking__toolbar__current-date .CalendarDay__highlighted_calendar:before {
        display: none; }
    .matchmaking__toolbar__current-date .CalendarDay__highlighted_calendar:hover, .matchmaking__toolbar__current-date .CalendarDay__default:hover {
      background-color: #f7f8f9; }
    .matchmaking__toolbar__current-date .CalendarDay__highlighted_calendar:active, .matchmaking__toolbar__current-date .CalendarDay__default:active {
      background-color: #90c6de;
      color: #ffffff; }
    .matchmaking__toolbar__current-date .CalendarDay__today {
      background-color: #414a52;
      color: #ffffff; }
      .matchmaking__toolbar__current-date .CalendarDay__today:is(:hover, :active) {
        background-color: #f7f8f9;
        color: #414a52; }
  .matchmaking__toolbar__month-selector input {
    display: block;
    position: absolute;
    left: 0;
    top: 0.3125em;
    z-index: 5;
    width: 100%;
    height: 100%;
    cursor: pointer;
    opacity: 0; }
  .matchmaking__toolbar__month-selector .calendar-container {
    background-color: white;
    position: absolute;
    top: -1px;
    z-index: 20;
    left: 0;
    width: 100%;
    box-shadow: 0px 2px 3px 0px rgba(0, 0, 0, 0.15);
    padding: 0.375em 0.375em 0.375em;
    border: solid 0.0625em #d8dfe5; }
    .matchmaking__toolbar__month-selector .calendar-container .section_mp {
      border-bottom: 1px solid #f7f8f9;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 0.625em 0;
      margin-bottom: 1.25em; }
      .matchmaking__toolbar__month-selector .calendar-container .section_mp > * {
        color: #414a52;
        margin: 0 0.3125em; }
    .matchmaking__toolbar__month-selector .calendar-container .arrows_mp {
      cursor: pointer; }
    .matchmaking__toolbar__month-selector .calendar-container > .col_mp {
      display: inline-block;
      width: 25%;
      text-align: center;
      padding: 0.625em 0;
      cursor: pointer; }
      .matchmaking__toolbar__month-selector .calendar-container > .col_mp:hover {
        background-color: #90c6de;
        color: #fff; }
  .matchmaking__toolbar__filters {
    line-height: 1rem;
    padding-bottom: 0.25em; }
    .matchmaking__toolbar__filters__title {
      font-size: 0.75rem;
      color: #414a52;
      margin-right: 0.25rem;
      white-space: nowrap; }
    @media only screen and (max-width: 1300px) {
      .matchmaking__toolbar__filters {
        text-align: right; }
        .matchmaking__toolbar__filters__title {
          display: block; } }
  .matchmaking__toolbar__filter {
    margin-left: 0.25rem; }
    .matchmaking__toolbar__filter input {
      display: none; }
      .matchmaking__toolbar__filter input:checked + span:before {
        font-family: "iconfont";
        font-style: normal;
        font-weight: 400;
        content: ""; }
    .matchmaking__toolbar__filter .checkbox {
      font-size: 1.125rem;
      color: currentColor; }
      .matchmaking__toolbar__filter .checkbox:before {
        font-family: "iconfont";
        font-style: normal;
        font-weight: 400;
        content: ""; }
      .matchmaking__toolbar__filter .checkbox:before {
        position: relative;
        top: 0.3125rem; }
    .matchmaking__toolbar__filter:hover {
      cursor: pointer; }
      .matchmaking__toolbar__filter:hover .checkbox {
        opacity: 0.8; }
    .matchmaking__toolbar__filter--rejected {
      color: #f76b7e; }
    .matchmaking__toolbar__filter--replacement, .matchmaking__toolbar__filter--availability, .matchmaking__toolbar__filter--empty {
      color: rgba(160, 173, 185, 0.65); }
    .matchmaking__toolbar__filter--expired {
      color: #f5e067; }
    .matchmaking__toolbar__filter--confirmed {
      color: #94d394; }
    .matchmaking__toolbar__filter--pending {
      color: #f9b55a; }
  .matchmaking__toolbar .checkbox-tooltip__wrapper {
    position: relative; }
    .matchmaking__toolbar .checkbox-tooltip__wrapper:hover .checkbox-tooltip {
      display: block; }
    .matchmaking__toolbar .checkbox-tooltip__wrapper .checkbox-tooltip {
      position: absolute;
      font-size: 0.75em;
      top: calc(100% + 0.5625rem);
      z-index: 3;
      right: 50%;
      padding: 0.625em;
      border-radius: 0.1875em;
      background-color: #414a52;
      text-align: center;
      line-height: 1.125em;
      display: none;
      color: #fff;
      white-space: nowrap; }
      .matchmaking__toolbar .checkbox-tooltip__wrapper .checkbox-tooltip:before {
        content: "";
        width: 0;
        height: 0;
        border-left: 0.625rem solid transparent;
        border-bottom: 0.625rem solid #414a52;
        position: absolute;
        top: -0.5625rem;
        right: 0; }
